import React, { useState } from "react";
import { Accordion, Card, Table } from "react-bootstrap";
import { data } from "./data";

function myTable(data) {
	return (
		<Table striped bordered hover variant="primary" responsive className="mb-5 myCustomTable">
			<thead>
				<tr>
					<th>Sr. No.</th>
					<th>Faculty</th>
					<th>Topics</th>
				</tr>
			</thead>
			<tbody>
				{data.map((row, index) => {
					return (
						<tr key={index} style={{ whiteSpace: "pre-wrap" }}>
							<td>{index + 1}</td>
							<td>
								<a href={row.fcLink || "mailto:" + row.email} target="_blank" rel="noreferrer">
									{row.fcName}
								</a>
							</td>
							<td>
								<ul>
									{row.topics.map((topic) => (
										<li>{topic}</li>
									))}
								</ul>
							</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
}

function AccordionCard({ i, handleAccClick, accKey, deptName, deptLink, deptData }) {
	return (
		<Card className="accordionCard">
			<Accordion.Toggle
				as={Card.Header}
				eventKey={String(i)}
				className="accordionHeader"
				onClick={() => {
					handleAccClick(i);
				}}
			>
				<span>{accKey === i ? "-" : "+"}</span>&nbsp;&nbsp;{deptName}
			</Accordion.Toggle>
			<Accordion.Collapse eventKey={String(i)}>
				<Card.Body>
					<p>
						Know more about the{" "}
						<a href={deptLink} target="_blank" rel="noreferrer">
							{deptName}
						</a>
					</p>
					{myTable(deptData)}
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	);
}

function RiissheTopics() {
	const [accKey, setAccKey] = useState(-1);

	const handleAccClick = (i) => {
		if (i === accKey) setAccKey(-1);
		else setAccKey(i);
	};

	return (
		<div>
			<h3 className="font-acme mt-5 mb-3">Research Topics</h3>
			<Accordion>
				{data.map((dept, i) => {
					return (
						<AccordionCard
							key={i}
							i={i}
							handleAccClick={handleAccClick}
							accKey={accKey}
							deptName={dept.deptName}
							deptLink={dept.deptLink}
							deptData={dept.data}
						/>
					);
				})}
			</Accordion>
		</div>
	);
}

export default RiissheTopics;
