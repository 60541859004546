import React, { useEffect, useRef, useState } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import { strpIsStudPdf} from "../../../../../assets";
import "./EmbedVideo.css";

const announcements = [
    {
        desc: () => {
            return (
                <Link to="/outreach/news">
                    Institute's Fellowship Triumph: Honoring Exceptional Faculties and Students 🆕
                </Link>
            );
        },
    },
    {
        desc: () => {
            return (
                <p>
                    <a href={strpIsStudPdf} target="_blank" rel="noreferrer">
                        Call for applications from the International students for PhD 
                        research program for the Academic Year 2024-25 🆕
                    </a>
                </p>
            );
        },
    },
    {
        desc: () => {
            return (
                <Link to="/outbound/Faculty/mobility_Grant">
                    Mobility Grant (2024-25) 🆕
                </Link>
            );
        },
    },
    {
        desc: () => {
            return (
                <Link to="/outbound/Faculty/internationalgrant">
                    International Travel Grant (2024-25) 🆕
                </Link>
            );
        },
    },
    {
        desc: () => {
            return (
                <Link to="/inbound/students/srp-is">
                    Short-Term Research Fellowships for International PhD Students (2024-25) 🆕
                </Link>
            );
        },
    },
    {
        desc: () => {
            return (
                <Link to="/inbound/students/srp-ugpg">
                    Semester Exchange Program International UG and PG Students(2024-25) 🆕
                </Link>
            );
        },
    },
    {
        desc: () => {
            return (
                <Link to="/outbound/ug_pg_fellowship">
                    UG and PG Students' Semester Exchange Program for IIT Indore Students(2024-25) 🆕
                </Link>
            );
        },
    },
];

const EmbedVideo = () => {
    const mainRef = useRef(null);
    const [allowScroll, setAllowScroll] = useState(true);

    useEffect(() => {
        function sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }
        if (mainRef.current && allowScroll) {
            var pos = 0;
            const interval = setInterval(async () => {
                if (mainRef.current && allowScroll) {
                    mainRef.current.scrollTop = pos;
                    if (pos === 0) {
                        await sleep(1500);
                        pos += 1;
                    } else if (pos === 1000) {
                        await sleep(1000);
                        pos = 0;
                    } else pos += 1;
                }
            }, 20);
            return () => clearInterval(interval);
        }
    }, [allowScroll]);

    const [width, setWidth] = useState(600);

    const updateDimensions = () => {
        // setWidth(window.innerWidth);
        console.log(`width: ${window.innerWidth}`);
        if (window.innerWidth >= 1000) {
            setWidth(600);
        } else {
            setWidth(0.9 * window.innerWidth);
        }
    };

    useEffect(() => {
        updateDimensions();
    }, []);

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <div className="embed-video container d-flex flex-wrap">
            <div className="embed-video-div m-0 p-0" style={{ marginTop: "100px !important" }}>
                <iframe
                    className="embed-iframe"
                    src="https://www.youtube.com/embed/tsVmMCoz4tY"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    width={width}
                    height={(9 / 16) * width}
                ></iframe>
            </div>
            <div className="announcementsDiv">
                <div className="announce">
                    <p className="announce-title font-acme">Announcements</p>
                    <hr />
                    <ListGroup
                        variant="flush"
                        ref={mainRef}
                        className="announce-text mt-2"
                        onMouseEnter={() => {
                            setAllowScroll(false);
                        }}
                        onMouseLeave={() => {
                            setAllowScroll(true);
                        }}
                    >
                        {announcements.map((item, id) => (
                            <ListGroupItem key={id}>
                                {typeof item.desc === "string" ? item.desc : item.desc()}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </div>
            </div>
        </div>
    );
};

export default EmbedVideo;
