import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { GlobalElearningProgramImage, GlobalElearningFlyerPDF, GlobalElearningProposalPDF,GlobalElearningProgramCalenderPDF } from "../../../../../assets";
// import { srpiasForm } from "../../../../../assets";
import { FiExternalLink } from "react-icons/fi";
import { AiFillFileText } from "react-icons/ai";

function GlobalElearning() {
  const [accKey, setAccKey] = useState(-1);

  const handleAccClick = (i) => {
    if (i === accKey) setAccKey(-1);
    else setAccKey(i);
  };

  return (
    <div className="container pb-5 pt-4">
      <h2 className="font-acme text-center mb-5">
      Global e-Learning Program for International Students and Faculties
      <br></br>
      (IIT-I Global e-Learning Program)

      </h2>
      <img
        src={GlobalElearningProgramImage}
        alt=""
        style={{ maxWidth: "90%", height: 400, alignSelf: 'center', width: "100%",marginLeft: "5%", marginBottom: "30px" }}
      />
        <div className="font-ubuntu text-justify mb-5 strp-intro">
        <b>Objectives of the Program:</b> IIT Indore is involved in high-quality research work at the forefront of the topics of global relevance. In this connection, with an objective to provide online training in new and advanced technology of Futuristic and Globally relevant areas in Energy, Environment, Climate Change, Artificial Intelligence, Machine Learning, Computational Tools, Applied Mathematics, Indian Culture and Language, but not only limited to these topics. IIT Indore would like to start a flagship program "Global e-Learning Program for International Students and Faculties - IIT-I Global e-Learning Program". 
        <br />
        <a href={GlobalElearningFlyerPDF} target="_blank" rel="noreferrer">
          <div className="appPortalBtn" style={{ width: 210 }}>
            <AiFillFileText /> Flyer of the program
          </div>
        </a>
      </div>
      <Accordion>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(0);
            }}
          >
            <span>{accKey === 0 ? "-" : "+"}</span>&nbsp;&nbsp;Participants: up to 25 participants per program
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
            The target participants will be the students (UG/PG/Ph.D.) and Early career faculties from our neighboring countries (SAARC), South-East Asia, Africa, Middle-east, and others. However, the program will be open to all interested participants from all over the world. Through this program, it is expected that IIT Indore will be able to expand its reach deep into these countries by showcasing the high-quality academic and research activities of IIT Indore. It is also expected that this program will help us in increasing the number of foreign students at IIT Indore. However, for better dissipation of knowledge, up to 25 participants per program are recommended.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="1"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(1);
            }}
          >
            <span>{accKey === 1 ? "-" : "+"}</span>&nbsp;&nbsp;Program Fee: USD 50 per participant 
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
            A nominal fee of USD 50 is proposed for this program so that we can reach many international participants through this program.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="2"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(2);
            }}
          >
            <span>{accKey === 2 ? "-" : "+"}</span>&nbsp;&nbsp;Program Structure
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <div>This e-Learning program will be conducted in Online mode only. It is highly recommended to have a good blend of lectures and tutorials/hands-on experience in the program module, and the focus should be more on engaging with the participants during the program. </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="3"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(3);
            }}
          >
            <span>{accKey === 3 ? "-" : "+"}</span>&nbsp;&nbsp;Duration
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <div>
              6 hours (one day)
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="4"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(4);
            }}
          >
            <span>{accKey === 4 ? "-" : "+"}</span>&nbsp;&nbsp;Fund distribution
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
            The generated funds through program fee will be distributed as per CEP guidelines (18% GST + 30% Institute Overhead + 52% Organizers). Disbursement of the Organizer’s share will be done as per the proposal in the application by the institute.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="5"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(5);
            }}
          >
            <span>{accKey === 5 ? "-" : "+"}</span>&nbsp;&nbsp;Application Procedure
            
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              <div>Under this program, faculty members of IIT Indore will be submitting a proposal (as per the template attached) in any of the listed areas to the International Relations Office for approval. The International Relations Office will extend all support from preparing the flyer of the program, wide publicity to all the possible international participants, coordinating with the participants, online form and fee collection, and other related work for the smooth conduct of the program.</div>
              <a href={GlobalElearningProposalPDF} target="_blank" rel="noreferrer">
                <div className="appPortalBtn">
                  <FiExternalLink /> Proposal Form
                </div>
              </a>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <div style={{fontSize : 25}}><b>Programs can be conducted only in a specific window in a year as described in the pdf attached.</b></div>
      <div><a href={GlobalElearningProgramCalenderPDF} target="_blank" rel="noreferrer">
          <div className="appPortalBtn" style={{ width: 210 }}>
            <AiFillFileText /> Program Calendar
          </div>
        </a></div>
    </div>
  );
}

export default GlobalElearning;
