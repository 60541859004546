import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { riisshe, riissheFlyerPdf } from "../../../../../assets";
// import { riissheForm } from "../../../../../assets";
import RiissheTopics from "./RiissheTopics";
import { AiFillFileText } from "react-icons/ai";

function Riisshe() {
  const [accKey, setAccKey] = useState(-1);

  const handleAccClick = (i) => {
    if (i === accKey) setAccKey(-1);
    else setAccKey(i);
  };

  return (
    <div className="container pb-5 pt-4">
      <h2 className="font-acme text-center mb-5">
        Research Internship for International Students in Science, Humanities and Engineering
        (RIISSHE)
      </h2>
      <img
        src={riisshe}
        alt=""
        style={{ maxWidth: "90%", marginLeft: "5%", marginBottom: "30px" }}
      />
      <div className="font-ubuntu text-justify mb-4 strp-intro">
        The Research Internship program (RIISSHE) aims to offer academic and research exposure to
        young dynamic international students from across the globe in the cutting-edge areas of
        research, technology and innovation of science, engineering and humanities. This program
        also aims to intensify the research aptitude of the students to effectively take up research
        as a career.
        <br />
        <a href={riissheFlyerPdf} target="_blank" rel="noreferrer">
          <div className="appPortalBtn" style={{ width: 210 }}>
            <AiFillFileText /> Flyer of the program
          </div>
        </a>
        <br />
      </div>
      <Accordion>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(0);
            }}
          >
            <span>{accKey === 0 ? "-" : "+"}</span>&nbsp;&nbsp;Who can apply?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              One must meet the following criteria to apply for this program:
              <ul>
                <li>
                  Anyone who is not a national of India will be considered as an International
                  Student including persons of Indian Origin (PIO), Overseas Citizens of India (OCI)
                  and Non-Resident Indians (NRI) for the purposes of Internship.
                </li>
                <li>
                  International students enrolled for a degree (or equivalent) at Undergraduate,
                  Postgraduate and Research Fellows level in an Institute/University of their home
                  country can apply for the research internship program at IIT Indore.
                </li>
                <li>
                  International students who are currently not enrolled but obtained his/her degree
                  (or equivalent) at Undergraduate, Postgraduate and Research Fellows level in the
                  past 2 year from an Institute/University of their home country can also apply for
                  the research internship program at IIT Indore.
                </li>
                <li>
                  International students enrolled or previously enrolled or obtained a degree (or
                  equivalent) for a program which can be classified under the broad fields of
                  Science, Humanities and Engineering are eligible to apply for this research
                  internship program at IIT Indore.
                </li>
                <li>The preferred language of the internship will be in English.</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="1"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(1);
            }}
          >
            <span>{accKey === 1 ? "-" : "+"}</span>&nbsp;&nbsp;Duration of Internship
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <div>1 to 6 months</div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="2"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(2);
            }}
          >
            <span>{accKey === 2 ? "-" : "+"}</span>&nbsp;&nbsp;Program Fees
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <div>
                A non-refundable internship fee of USD 200 per month (for SAARC, ASEAN, Middle east,
                and African countries) and USD 250 per month (other countries)
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="3"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(3);
            }}
          >
            <span>{accKey === 3 ? "-" : "+"}</span>&nbsp;&nbsp;Application Requirements and
            Procedure
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <ol>
                <li>Application package:</li>
                <ol type="a">
                  <li>
                    <a href='http://global.iiti.ac.in/' target="_blank" rel="noreferrer">
                      Application Form
                    </a>
                    .
                  </li>
                  <li>One-page CV.</li>
                  <li>
                    ‘Letter of Motivation’ describing your scientific interests and the reasons that
                    made you apply for this internship and the said research topic. Letter of
                    motivation should be ideally on one page and anyway not longer than two pages.
                  </li>
                  <li>
                    ‘Letter of Consent’ from a faculty member of the Indian Institute of Technology
                    Indore (IIT Indore). Write to them directly referring to this scheme and seeking
                    their consent to be guide during the Research Internship. Applicants academic
                    background is subjected to evaluation while issuance of the Letter of Consent.
                  </li>
                  <li>
                    ‘Letter of Recommendation’ from a faculty mentor (preferably by the applicant’s
                    parent university) who can describe from the applicant academic and research
                    credentials.
                  </li>
                  <li>Applicant’s degree transcripts.</li>
                  <li>
                    TOEFL or IELTS certificate or letter from the home institution or a certified
                    English teacher or a self-declaration (if English is listed as one of course in
                    degree transcripts, attach transcript copy) that the applicant can follow a
                    course conducted in English with ease and can speak and write reasonably well in
                    English as a researcher.
                  </li>
                </ol>
                <li>
                  Applications are invited round the year. There is no last date to receive the
                  applications.
                </li>
                <li>Acceptance of Internship shall be at the sole discretion of IIT Indore.</li>
                <li>
                  Please email your application along with mentioned (as per the point No. 1)
                  documents (in PDF format) in one zipped folder with subject heading: ‘
                  <strong>
                    Application for Research Internship in the area of &lt;mention your research
                    area here&gt; with &lt;Name of the Mentor from IIT Indore&gt;
                  </strong>
                  ’ to the International Relations office, IIT Indore at{" "}
                  <a href="mailto:intlinternship@iiti.ac.in">intlinternship@iiti.ac.in</a>
                </li>
                <li>
                  In case of any enquiry, please write to us at{" "}
                  <a href="mailto:intlinternship@iiti.ac.in">intlinternship@iiti.ac.in</a>.
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <RiissheTopics />
    </div>
  );
}

export default Riisshe;
