import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { image5, strpIsFacPdf } from "../../../../../assets";
// import { srpiasForm } from "../../../../../assets";
import { FiExternalLink } from "react-icons/fi";
import { AiFillFileText } from "react-icons/ai";

function ResearchersStrp() {
  const [accKey, setAccKey] = useState(-1);

  const handleAccClick = (i) => {
    if (i === accKey) setAccKey(-1);
    else setAccKey(i);
  };

  return (
    <div className="container pb-5 pt-4">
      <h2 className="font-acme text-center mb-5">
        Short-Term Research Program for International Academics and Scientists
      </h2>
      <img
        src={image5}
        alt=""
        style={{ maxWidth: "90%", marginLeft: "5%", marginBottom: "30px" }}
      />
      <div className="font-ubuntu text-justify mb-5 strp-intro">
        Short-Term Research Program for International Academics and Scientists (awarded for 1-3
        months, can be extended to 6 months with a sabbatical) is for the highly qualified
        enthusiastic university teachers, established academics or scientists from the institutes of
        higher education from SAARC, AFRICAN, MIDDLE EAST, ASEAN, and other developing countries to
        provide them the opportunity for a short-term research work at IIT Indore. The program aims
        to promote the exchange of knowledge and building networking amongst the colleagues and the
        institutes.
        <br />
        <br />
        Applications will be invited and evaluated twice annually (usually in January and July), and
        results will be announced in February and August, respectively.
        <br />
        <a href={strpIsFacPdf} target="_blank" rel="noreferrer">
          <div className="appPortalBtn" style={{ width: 210 }}>
            <AiFillFileText /> Flyer of the program
          </div>
        </a>
      </div>
      <Accordion>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(0);
            }}
          >
            <span>{accKey === 0 ? "-" : "+"}</span>&nbsp;&nbsp;Who can apply?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <ol>
                <li>
                  University teachers and established academics and scientists who holds a doctoral
                  degree and work at a university or research institute in their home country.
                </li>
                <li>Preference will be given to those institutes having MoU with IIT Indore.</li>
                <li>
                  At the time of application, the applicant should already be in possession of a
                  valid passport. The name on the application form should be identical to the one in
                  the passport.
                </li>
                <li>Letter confirming mentorship by a faculty of IIT Indore.</li>
                <li>
                  Preference will be given to the applicants having a partial financial support (for
                  living expenses or travel support) from the home institute/host mentor and/or
                  through a bilateral project. (A letter of financial support needs to be submitted)
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="1"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(1);
            }}
          >
            <span>{accKey === 1 ? "-" : "+"}</span>&nbsp;&nbsp;What can be funded?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <ul>
                {/*<li>
									Monthly living expenses subsidy of INR 50,000 for assistant professors and
									lecturers, and INR 55,000 for associate professors and professors. Living expenses
									subsidy will only be eligible if applicant does not receive any such support from
									his/her home institution. (A letter from the home institution needs to be submitted
									at the time of application)
								</li>*/}
                <li>
                  Round trip international airfare by economy class by shortest route from the
                  international airport in the home country of the applicant to the international
                  airport nearest to IIT Indore, visa fee and/or overseas medical insurance (as per
                  actuals to a maximum of INR 50,000 (for ASEAN, Africa, and Middle east countries)
                  and INR 30,000 (SAARC)).
                </li>
                {/*<li>
									Accommodation at IIT Indore Guest house and/or JC Bose apartment at 50% discounted
									charges (as per the availability). (Pre-booking will be required)
								</li>*/}
                <li>
                  Free medical facility at IIT Indore health centre. Visitor should ensure that
                  his/her visit to India is completely covered by a suitable insurance.
                </li>
                <li>
                  Awarded support cannot be used for travel only for attending conferences/
                  workshops/ meeting/ conventions or other such events.
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="2"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(2);
            }}
          >
            <span>{accKey === 2 ? "-" : "+"}</span>&nbsp;&nbsp;Duration of the funding
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <div>Awarded for 1-3 months, can be extended to 6 months with a sabbatical</div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="3"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(3);
            }}
          >
            <span>{accKey === 3 ? "-" : "+"}</span>&nbsp;&nbsp;Expectation from the awardee
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <div>
                It is expected that the incoming faculty get involved in various programs at IIT
                Indore during his/her visit such as course development, submission of joint project
                proposals, research articles/book chapter, short courses, training program and
                seminars/conferences. It is also expected that the awardee submits a short report
                describing the outcome from this program, and how it helps the awardee in her/his
                academic and research career.
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="4"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(4);
            }}
          >
            <span>{accKey === 4 ? "-" : "+"}</span>&nbsp;&nbsp;Selection Criteria
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <ol>
                <li>
                  Applications will be invited and evaluated twice in a year (January and July), and
                  results will be announced in February and August, respectively. However, awardees
                  need to avail the award within one year from the date of award.
                </li>
                <li>
                  Applications (relevant academic documents as endorsed by the home institute,
                  letter of hosting from a IIT Indore faculty, and a preliminary research plan for
                  the visit) have to be submitted to the International Office, IIT Indore.
                </li>
                <li>
                  Applications completed in all the aspects will be forwarded to the respective
                  departments, and nominations for the shortlisted candidates will be submitted by
                  the respective departments to the international office. Final decision on the
                  selected candidates will be made by the institute level selection committee
                  comprises of Student Mobility and Exchange Committee, International Relations
                  team, and/or External expert.
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="5"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(5);
            }}
          >
            <span>{accKey === 5 ? "-" : "+"}</span>&nbsp;&nbsp;Application Dates and Online Portal
            for applying
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              {/*<div>Application Period: January 15th, 2022 to February 14th, 2022</div>*/}
              <a href='http://global.iiti.ac.in/' target="_blank" rel="noreferrer">
                <div className="appPortalBtn">
                  <FiExternalLink /> Application Portal
                </div>
              </a>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default ResearchersStrp;
