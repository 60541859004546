import React, { useState } from 'react';
import './strp.css'; // Import your CSS file for styling

const FAQItem = ({ question, answer, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  // Function to render the answer content as React components
  const renderAnswerContent = () => {
    // If answer is a function, call it to render HTML content
    if (typeof answer === 'function') {
      return (
        <div className="faq-answer">
          <a name={`answer${index + 1}`} /> {/* Anchor link */}
          <p style={{marginTop: 10, marginBottom: 10}} ><b>A.</b> {answer()}</p>
        </div>
      );
    }

    // If answer is plain text, render it as is
    return (
      <div className="faq-answer">
        <a name={`answer${index + 1}`} /> {/* Anchor link */}
        <p style={{margin: 10}}><b>A.</b> {answer}</p>
      </div>
    );
  };

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`}>
      <div className="faq-question" onClick={toggleOpen}>
        <span className="question-number">{index + 1}.</span> {/* Question number */}
        {question}
        <span className={`icon ${isOpen ? 'open' : ''}`}>▼</span>
      </div>
      {isOpen && renderAnswerContent()} {/* Render answer content as React components */}
    </div>
  );
};

const FAQ = ({ faqData }) => {
  return (
    <div className="faq-container">
      {faqData.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} index={index} />
      ))}
    </div>
  );
};

export default FAQ;
