import React from "react";
import { Table } from "react-bootstrap";

export const IntStudents = () => {
    return (
        <div className="container">
            <h1 className="pt-4 mainTitle text-center mb-5">
                Degree & Exchange International Students
            </h1>
            <h2 className="font-acme">PhD</h2>
            <Table striped bordered hover variant="primary" responsive className="mb-3">
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Student</th>
                        <th>Course</th>
                        <th>Country</th>
                        <th>Admitted through</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                        <td>2023</td>
                        <td>Mr. Adolph Timothy Kasegenya</td>
                        <td>Ph.D. (EE)</td>
                        <td>Tanzania</td>
                        <td>ICCR</td>
                    </tr>
                    <tr>
                        <td>2022</td>
                        <td>Mr. Arifuzzaman Khan</td>
                        <td>Ph.D. (HSS)</td>
                        <td>Bangladesh</td>
                        <td>SAARC Finance - RBI</td>
                    </tr>
                    <tr>
                        <td>2021</td>
                        <td>Mr. Pawan Singh</td>
                        <td>Ph.D. (HSS)</td>
                        <td>Nepal</td>
                        <td>Nepal Scholarship</td>
                    </tr>
                    <tr>
                        <td>2021</td>
                        <td>Mr. Asrul Munazar</td>
                        <td>Ph.D. (HSS)</td>
                        <td>Indonesia</td>
                        <td>ASEAN</td>
                    </tr>
                    <tr>
                        <td>2020</td>
                        <td>Mr. Son Thanh Ngo*</td>
                        <td>Ph.D. (HSS)</td>
                        <td>Vietnam</td>
                        <td>ASEAN</td>
                    </tr>
                    <tr>
                        <td>2020</td>
                        <td>Ms. Huynh Vuong Uyen Thy*</td>
                        <td>Ph.D. (HSS)</td>
                        <td>Vietnam</td>
                        <td>ASEAN</td>
                    </tr>
                </tbody>
            </Table>
            <p>* Withdrawn admission due to personal reasons.</p>
            <h2 className="font-acme mt-5">Masters</h2>
            <Table striped bordered hover variant="primary" responsive className="mb-3">
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Student</th>
                        <th>Course</th>
                        <th>Country</th>
                        <th>Admitted through</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>2023-24</td>
                        <td>Mr. Sonu Kushwaha</td>
                        <td>M.Tech. (CSE)</td>
                        <td>Nepal</td>
                        <td>ISF</td>
                    </tr>
                    <tr>
                        <td>2023-24</td>
                        <td>Mr. Uzzal Saha</td>
                        <td>M.Tech. (CSE)</td>
                        <td>Bangladesh</td>
                        <td>ISF</td>
                    </tr>
                    <tr>
                        <td>2023-24</td>
                        <td>Mr. Rushab Jain</td>
                        <td>M.Sc. (Biotechnology)</td>
                        <td>Oman</td>
                        <td>SII (G2)</td>
                    </tr>
                    <tr>
                        <td>2022-24</td>
                        <td>Mr. Shukdeb Chandra Paul</td>
                        <td>MSc. (Chemistry)</td>
                        <td>Bangladesh</td>
                        <td>Study in India</td>
                    </tr>
                    <tr>
                        <td>2022-24</td>
                        <td>Ms. Lidiya Kumssa Fekadu</td>
                        <td>M.Tech. (Space Engg.)</td>
                        <td>Ethiopia</td>
                        <td>Study in India</td>
                    </tr>
                    <tr>
                        <td>2022-24</td>
                        <td>Mr. Bimal Bashyal</td>
                        <td>M.Tech. (MSD)</td>
                        <td>Nepal</td>
                        <td>ISF (International Self-Financed)</td>
                    </tr>
                    <tr>
                        <td>2021-23</td>
                        <td>Mr. Akah Precious Chiemena</td>
                        <td>M.Tech. (CSP)</td>
                        <td>Nigeria</td>
                        <td>ICCR</td>
                    </tr>
                    <tr>
                        <td>2021-23</td>
                        <td>Mr. Akash Yadav</td>
                        <td>M.Tech. (PIE)</td>
                        <td>Nepal</td>
                        <td>Nepal Scholarship</td>
                    </tr>
                    <tr>
                        <td>2021-23</td>
                        <td>Mr. Sumantra Aarya</td>
                        <td>M.Tech. (PIE)</td>
                        <td>Nepal</td>
                        <td>ISF (International Self-Financed)</td>
                    </tr>
                    <tr>
                        <td>2020-22</td>
                        <td>Mr. Musa Alhassan Shittu</td>
                        <td>M.Tech. (MSD)</td>
                        <td>Nigeria</td>
                        <td>Study In India</td>
                    </tr>
                    <tr>
                        <td>2019-21</td>
                        <td>Mr. Pravin Karna</td>
                        <td>MS Research - ME</td>
                        <td>Nepal</td>
                        <td>Study In India</td>
                    </tr>
                    <tr>
                        <td>2018-20</td>
                        <td>Mr. Sudipta Das</td>
                        <td>M.Tech. (EE)</td>
                        <td>Bangladesh</td>
                        <td>Study In India</td>
                    </tr>
                    <tr>
                        <td>2018-20</td>
                        <td>Mr. Sumit Kumar Gupta</td>
                        <td>M.Tech. (ME)</td>
                        <td>Nepal</td>
                        <td>Study In India</td>
                    </tr>
                </tbody>
            </Table>
            <h2 className="font-acme mt-5">Exchange</h2>
            <Table striped bordered hover variant="primary" responsive className="mb-5">
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Student</th>
                        <th>Course</th>
                        <th>Stream</th>
                        <th>Country</th>
                        <th>Duration</th>
                        <th>Admitted through</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                        <td>2023-24</td>
                        <td>Arjun Hasibuan</td>
                        <td>Department of Mathematics</td>
                        <td>PhD</td>
                        <td>Indonesia</td>
                        <td>180 Days</td>
                        <td>AI-RTF</td>
                    </tr>
                    <tr>
                        <td>2023-24</td>
                        <td>Myo Min Htwe</td>
                        <td>Department of EE</td>
                        <td>PhD</td>
                        <td>Myanmar</td>
                        <td>180 Days</td>
                        <td>AI-RTF</td>
                    </tr>
                    <tr>
                        <td>2023-24</td>
                        <td>Yonas Etafa Tasisa</td>
                        <td>Department of Chemistry</td>
                        <td>PhD</td>
                        <td>Ethiopia</td>
                        <td>360 days</td>
                        <td>MoU</td>
                    </tr>
                    <tr>
                        <td>2023-24</td>
                        <td>Tadesse Beyene Hulle</td>
                        <td>Department of Mechanical Engineering</td>
                        <td>PhD</td>
                        <td>Ethiopia</td>
                        <td>360 days</td>
                        <td>MoU</td>
                    </tr>
                    <tr>
                        <td>2023-24</td>
                        <td>Ms. Brigita De Vega</td>
                        <td>Department of Chemistry</td>
                        <td>PhD</td>
                        <td>The United Kingdom</td>
                        <td>90 days</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2023-24</td>
                        <td>Ms. Dibyashree Poudyal</td>
                        <td>Department of Civil Engg.</td>
                        <td>PhD</td>
                        <td>Malaysia</td>
                        <td>90 days</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2023-24</td>
                        <td>Mr. Raghu Nath Prajapati</td>
                        <td>Department of Civil Engg.</td>
                        <td>PhD</td>
                        <td>Malaysia</td>
                        <td>90 days</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2023-24</td>
                        <td>Ms. Halima Benali</td>
                        <td>Department of Mathematics</td>
                        <td>PhD</td>
                        <td>Morocco</td>
                        <td>90 days</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2022-23</td>
                        <td>Mr. Johirul Islam</td>
                        <td>Department of Computer Science Engg.</td>
                        <td>PhD</td>
                        <td>Finland</td>
                        <td>83 days</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2022-23</td>
                        <td>Mr. Lukas Steigleder</td>
                        <td>Department of Chemistry</td>
                        <td>PhD</td>
                        <td>Germany</td>
                        <td>40 days</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2022-23</td>
                        <td>Mr. Tim Seedorf</td>
                        <td>Department of Chemistry</td>
                        <td>PhD</td>
                        <td>Germany</td>
                        <td>40 days</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2022-23</td>
                        <td>Mr. Subha Deep Sahoo</td>
                        <td>Department of Electrical Engineering and Centre for Advanced Electronics</td>
                        <td>PhD</td>
                        <td>USA</td>
                        <td>30 Days</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2021-22</td>
                        <td>Mr. Nehal Ahmad</td>
                        <td>Department of Mathematics</td>
                        <td>PhD</td>
                        <td>Taiwan</td>
                        <td>9 Months</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2020-21</td>
                        <td>Miss Phattarawan Khamrat</td>
                        <td>Humanities and Social Sciences</td>
                        <td>SEPUG</td>
                        <td>Thailand</td>
                        <td>One-Semester</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2020-21</td>
                        <td>Mr. Don Ratanasith</td>
                        <td>Humanities and Social Sciences</td>
                        <td>SEPUG</td>
                        <td>Thailand</td>
                        <td>One-Semester</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2019-20</td>
                        <td>Mr. Pongnut Panichakul</td>
                        <td>HSS-Indian Studies</td>
                        <td>UG</td>
                        <td>Thailand</td>
                        <td>Spring Semester</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2019-20</td>
                        <td>Ms. Pisinee Nethin</td>
                        <td>HSS-Indian Studies</td>
                        <td>UG</td>
                        <td>Thailand</td>
                        <td>Spring Semester</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2019-20</td>
                        <td>Ms. Wachiraya Udomvorakulchai</td>
                        <td>HSS-Indian Studies</td>
                        <td>UG</td>
                        <td>Thailand</td>
                        <td>Spring Semester</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2019-20</td>
                        <td>Mr. Rattasorn Pongpitakkul</td>
                        <td>HSS-Indian Studies</td>
                        <td>UG</td>
                        <td>Thailand</td>
                        <td>Spring Semester</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2019-20</td>
                        <td>Mr. Premsub Duangprem</td>
                        <td>HSS-Indian Studies</td>
                        <td>UG</td>
                        <td>Thailand</td>
                        <td>Spring Semester</td>
                        <td>Exchange Program</td>
                    </tr>
                    <tr>
                        <td>2019-20</td>
                        <td>Mr. Thanasak Janthana</td>
                        <td>HSS-Indian Studies</td>
                        <td>UG</td>
                        <td>Thailand</td>
                        <td>Spring Semester</td>
                        <td>Exchange Program</td>
                    </tr>
                </tbody>
            </Table>
            <h2 className="font-acme mt-5">Researcher</h2>
            <Table striped bordered hover variant="primary" responsive className="mb-5">
                <thead>
                    <tr> 
                        
                        <th>Year</th>
                        <th>Student</th>
                        <th>Course</th>
                        <th>Host Faculty</th>
                        <th>Country</th>
                        <th>Admitted through</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>     
                       
                        <td>2023</td>
                        <td>Mr. Kouakou Ahoutou Paul</td>
                        <td>CV Raman Reseracher</td>
                        <td>Dr. Ajay Kumar Kushwaha, MEMS</td>
                        <td>Ivory Coast</td>
                        <td>CV Raman Reseracher Fellowship</td>
                        
                    </tr>
                    <tr>     
                       
                        <td>2023</td>
                        <td>Mr. Solomon Abebe</td>
                        <td>CV Raman Reseracher</td>
                        <td>Dr. M Tanveer, Mathematics</td>
                        <td>Ethiopia</td>
                        <td>CV Raman Reseracher Fellowship</td>
                        
                    </tr>
                    <tr>     
                       
                        <td>2023</td>
                        <td>Dr. Isuru Dasanayake</td>
                        <td>Researcher ISRF</td>
                        <td>Prof. Santosh K. Vishwakarma, EE</td>
                        <td>Sri Lanka</td>
                        <td>INSA-ISRF</td>
                       
                    </tr>
                    <tr>     
                       
                        <td>2023</td>
                        <td>Dr. Thanongsak Imjai</td>
                        <td>Researcher ISRF</td>
                        <td>Prof. Sandeep Chaudhary, CE</td>
                        <td>Thailand</td>
                        <td>INSA-ISRF</td>
                    </tr>
                    <tr>
                        <td>2022</td>
                        <td>Indrajith Devinda Nissanka</td>
                        <td>Researcher ISRF</td>
                        <td>Prof. S Dhinakaran, ME</td>
                        <td>Sri Lanka</td>
                        <td>INSA-ISRF</td>
                    </tr>
            </tbody>
            </Table>
            <br/>
        </div>
    );
};
