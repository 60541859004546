import {
    joemer,
    Girma,
    ram,
    Eson,
    shinjae,
    Halima,
    Dibyashree,
    Raghunath,
    React,
    BRIGITA,
    ug_pg_course,
    zinu,
    worku,
    MalkovIvan,
    TesfayeGenetiKenate,
    AbdetaletaAdulla,
    BerunaBaisaMuleta,
    kaushikaseelanatha,
     GirmaDejeneNage,
     RamprasadNeupane,
     WerkuAwekeTeso

    } from "../../../../../assets";

export const strp_international_phd = [
    {
        "Name":"BRIGITA DE VEGA",
        "dis":"from University College London, United Kingdom working with Prof. Deepak Kalaskar in the Division of Surgery and Interventional Science at University College London, UK did her fellowship under Prof. Apurba Das, Department of Chemistry, IIT Indore",
        img : BRIGITA,
    },
    {
        "Name":"DIBYASHREE POUDYAL",
        "dis":"from  Infrastructure University Kuala Lumpur, Malaysia collaborating with Dr. Norhaiza Nordin,  Engineering Department at Infrastructure University Kuala Lumpur, Malaysia  did her fellowship under Prof. Neelima Satyam, Department of Civil Engg. , IIT Indore",
        img : Dibyashree,
    },
    {
        "Name":"RAGHU NATH PRAJAPATI",
        "dis":"from  Infrastructure University Kuala Lumpur, Malaysia collaborating with Dr. Nor Azidawati Binti Haron, Lecturer, Civil Engineering Department of Infrastructure University Kuala Lumpur, Malaysia did his fellowship under Prof. Manish Kumar Goyal, Department of Civil Engg. , IIT Indore",
        img : Raghunath,
    },
    {
        "Name":"HALIMA BENALI",
        "dis":"from Sultan Moulay Slimane University, Morocco mentored by Dr. Mohamed Ch-Chaoui, Department of Mathematics and Informatics, Sultan Moulay Slimane University, Morocco did her fellowship under Dr. Bapan Ghosh, Department of Mathematics, IIT Indore",
        img : Halima,
    },
]
    
export const strp_international_phd1=[
    { 
        "Name":"Mr. Joemer A",
        "dis":"from National Tsing Hua mentored by Dr. Ruey-an Doong,  will be persuing his fellowship under Dr. Mayur Jain, Department of Civil Engineering, IIT Indore",
        img : joemer,
    },
    {
        "Name":"Mr. Shinjae Lee",
        "dis":"from Boston College, USA, mentored by Dr. Catherine will be persuing his fellowship under Dr. Ashok Kumar, department of Humanities and Social Sciences, IIT Indore",
        img : shinjae,
    },
    {
        "Name":"Mr. Ram Prasad Neupane",
        "dis":"from Walailak University,Thailand will be persuing his fellowship under Dr. Sandeep Chaudhary, department of Civil Engineering, IIT Indore",
        img:RamprasadNeupane,
        
    },
    {
        "Name":"Mr. Eson Zhao",
        "dis":"from University of Technology,Australia mentored by Dr. Saidul Islam will be persuing his fellowship under Dr. Amrendra Singh, department of chemistry, IIT Indore",
        img :Eson,
    },
    {
        "Name":"Mr. Malkov Ivan",
        "dis":"from University of Tyumen,Russia mentored by  Dr. Evgenil Burlakov will be persuing his fellowship under Dr Bapan Ghosh, department of Mathematics, IIT Indore",
        img :MalkovIvan,
        
    },
    {
        "Name":"Mr. Tesfaya Geneti Itenate",
        "dis":"from Jimma University, Ethiopia mentored by Dr. Ing.Fel‹adu Fufa will be persuing his fellowship under Dr. Ashootosh Mandpe , department of Civil Engineering,IIT Indore",
    img :TesfayeGenetiKenate,

        
    },
    {
        "Name":"Mr. Abdeta Leta Adulla",
        "dis":"from  Jimma University, Ethiopia mentored by Dr. Ing.Tamene Adugna will be persuing his fellowship under Dr. Manish Kumar Goyal , department of Civil Engineering, IIT Indore",
        img : AbdetaletaAdulla,
    },{
        "Name":"Mr. Beruna Baisa Muleta",
        "dis":"from Jimma University, Ethiopia mentored by Dr. Ing.Mesay Alemu will be persuing his fellowship under Dr. Pavan KumarKankar , department of Mechanical Engineering, IIT Indore",
        img : BerunaBaisaMuleta,
        
    },{
        "Name":"Mr. Girma Dejene Nage",
        "dis":"from Jimma University, Ethiopia  mentored by Dr. Venkata RaMayya will be persuing his fellowship underDr. Neelima Satyam, department of Civil Engineering, IIT Indore",
        img :GirmaDejeneNage,
    },
    {
        "Name":"Ms. SSKT Seelanatha",
        "dis":"from Wayamba University, SriLanka  mentored by Dr. Menuka Udugama will be persuing her fellowship under Dr.Mohanasundari Thangavel, department of Humanities and Social Sciences, IIT Indore",
        img : kaushikaseelanatha,
        
    },
]
export const strp_international_ugpg=[
    {
        "Name":"Ms. Zinu Jemberu",
        "dis":"from Jimma University, Ethiopia  will be persuing her  fellowship at department of Electrical Engineering, IIT Indore",
        img:zinu,
        
    },
    {
        "Name":"Mr. Worku Aweke Teso",
        "dis":"from Jimma University, Ethiopia will be persuing his fellowship at department of Civil Engineering, IIT Indore",
         img:WerkuAwekeTeso,
        
    },
    
    {
        "Name":"Mr. Abrham Teshale Tegegn",
        "dis":"from Dambi Dollo University, Ethiopia  will be persuing his  fellowship at department of Mechanical Engineering , IIT Indore",
        
        
    },
    
    {
        "Name":"Bezabih Kassie Ivlengist",
        "dis":"from Dambi Dollo University, Ethiopia  will be persuing his  fellowship at department of Mechanical Engineering , IIT Indore",
        
        
    },
    
    {
        "Name":"Mr. Tewodros Nega Yahuala",
        "dis":"from Dambi Dollo University, Ethiopia  will be persuing his  fellowship at department of Mechanical Engineering , IIT Indore",
        
    },
    
]



export const faqData_intphd = [
    {
        question: 'What documents must be submitted during the application process?',
        answer:() => (
            <>
                <p>
                The following documents may be required at the time of application submission as per your choice of program:  
                </p>
                <ul>
                    <li>Curriculum Vitae of Applicant</li>
                    <li>Curriculum Vitae of Faculty Supervisor from IIT Indore</li>
                    <li>Curriculum Vitae of Faculty Supervisor from Host Institute</li>
                    <li>Copy of Passport</li>
                    <li>Academic Degree/ Marksheet</li>
                    <li>Academic Degree/ Marksheet (Last Semester)</li>
                    <li>Letter of Consent from the Applicant's faculty supervisor of IIT Indore</li>
                    <li>Letter of Invitation from the Applicant's faculty supervisor of Host Institute</li>
                    <li>Research Proposal</li>
                    <li>Passport Size Photograph</li>
                    <li>TOEFL/IELTS/Recommendation from home Institute/Other documents (For International Students Only)</li>
                </ul>
            </>
        )   
    },
    {
        question: 'What are the visa requirements?',
        answer:() => (
            <>
                <p>
                    To apply for a visa, please visit the official website of the Bureau of Immigration (BOI), Government of India, <a href="https://boi.gov.in/content/apply-visa-online"> https://boi.gov.in/content/apply-visa-online </a> or <a  href="https://indianvisaonline.gov.in/visa/.">https://indianvisaonline.gov.in/visa/.</a>
                </p>
                <p>
                    You will need to submit an acceptance letter (written by the faculty supervisor of your host institute) or a consent letter (written by your faculty supervisor of your home institute) along with a No Objection Certificate (NOC) from the home institute at the time of your visa interview. You may also need to produce your last three months’ salary slips if you are a PhD student (the same can be requested by the finance section).
                </p>
            </>
        )
    },
    {
        question: 'Is there a particular format for Research Proposals that can be used?',
        answer: 'The answer is yes, the format can be found on the official website of International Relations (IR) at IIT Indore, under the Inbound and Outbound sections.'
    },
    {
        question:'Is there a foreign leave application to be completed?',
        answer:'The answer is yes. A signed ex-India leave application is to be submitted based on the approved NOC application.'
    },
    {
        question:'How to claim the received travel grant after joining the IITI campus?',
        answer:'All invoices, boarding passes, and tickets should be submitted in the originals along with the advance claim form to the IR office for further processing.'
    },
    {
        question:'How to receive the PhD fellowship in students’ accounts after joining the IITI campus?',
        answer: 'Upon enrolling at IIT Indore, students must open an Indian account with Canara Bank\'s Simrol Branch in order to receive the monthly fellowship. This is done by filling in Form C and providing the details of the account.'
    },
    {
        question:'How to pay for the Hostel and Mess fee after joining the IITI campus?',
        answer:'The transaction can be made using the Indian Bank account only.'
    },
    {
        question:'What about the Indian SIM card?',
        answer:'The same needs to be procured by the student when he/she lands at the Indore Airport.'
    },
    {
        question:'How to Complete the No Dues Formality Before Leaving the IITI Campus?',
        answer:'The student will be provided with a no-dues form from the IR Office. The form must be completed and signed by the relevant departments indicated in the form and submitted to the finance section for settlement. It is the responsibility of the student to close their Indian bank account before leaving the institution.'
    },
    {
        question:'Is it mandatory to submit a report upon completion of the program?',
        answer:'The answer is yes. A detailed report needs to be submitted after the completion of the program. Inbound students will receive a completion certificate based on the submission of the report.'
    },
    {
        question:'Is the Office of International Relations, IIT Indore, accountable for student visa applications?',
        answer:'The answer is No. The IR office is not responsible for a student’s visa application.'
    }
] 



export const faqData_IntUG_PG = [
    {
        question: 'What documents must be submitted during the application process?',
        answer:() => (
            <>
                <p>
                The following documents may be required at the time of application submission as per your choice of program:  
                </p>
                <ul>
                    <li>Curriculum Vitae of Applicant</li>
                    <li>Curriculum Vitae of Faculty Supervisor from IIT Indore</li>
                    <li>Curriculum Vitae of Faculty Supervisor from Host Institute</li>
                    <li>Copy of Passport</li>
                    <li>Academic Degree/ Marksheet</li>
                    <li>Academic Degree/ Marksheet (Last Semester)</li>
                    <li>Letter of Consent from the Applicant's faculty supervisor of IIT Indore</li>
                    <li>Letter of Invitation from the Applicant's faculty supervisor of Host Institute</li>
                    <li>Research Proposal</li>
                    <li>Passport Size Photograph</li>
                    <li>TOEFL/IELTS/Recommendation from home Institute/Other documents (For International Students Only)</li>
                </ul>
            </>
        )   
    },
    {
        question: 'What are the visa requirements?',
        answer:() => (
            <>
                <p>
                    To apply for a visa, please visit the official website of the Bureau of Immigration (BOI), Government of India, <a href="https://boi.gov.in/content/apply-visa-online"> https://boi.gov.in/content/apply-visa-online </a> or <a  href="https://indianvisaonline.gov.in/visa/.">https://indianvisaonline.gov.in/visa/.</a>
                </p>
                <p>
                    You will need to submit an acceptance letter (written by the faculty supervisor of your host institute) or a consent letter (written by your faculty supervisor of your home institute) along with a No Objection Certificate (NOC) from the home institute at the time of your visa interview. You may also need to produce your last three months’ salary slips if you are a PhD student (the same can be requested by the finance section).
                </p>
            </>
        )
    },
    {
        question:'How to arrange course mapping for UG/ PG students?',
        // answer:'Students need to arrange course mapping on their own by coordinating with the respective department head, the academic office of IIT Indore, and partner university where they want to enrol. This is the only way to get NOC for the student from home institute.'
        answer:() => (
            <>
            <p>
            Students need to arrange course mapping on their own by coordinating with the respective department head, the academic office of IIT Indore, and partner university where they want to enrol.Please refer to the below linked document
            </p>
            <a href={ug_pg_course}>Click here</a>
            </>
        )
    },
    {
        question: 'Is there a particular format for Research Proposals that can be used?',
        answer: 'The answer is yes, the format can be found on the official website of International Relations (IR) at IIT Indore, under the Inbound and Outbound sections.'
    },
    {
        question:'Is there a foreign leave application to be completed?',
        answer:'The answer is yes. A signed ex-India leave application is to be submitted based on the approved NOC application.'
    },
    {
        question:'How to claim the received travel grant after joining the IITI campus?',
        answer:'All invoices, boarding passes, and tickets should be submitted in the originals along with the advance claim form to the IR office for further processing.'
    },
    {
        question:'How to receive the PhD fellowship in students\' accounts after joining the IITI campus?',
        answer: 'Upon enrolling at IIT Indore, students must open an Indian account with Canara Bank\'s Simrol Branch in order to receive the monthly fellowship. This is done by filling in Form C and providing the details of the account.'
    },
    {
        question:'How to pay for the Hostel and Mess fee after joining the IITI campus?',
        answer:'The transaction can be made using the Indian Bank account only.'
    },
    {
        question:'What about the Indian SIM card?',
        answer:'The same needs to be procured by the student when he/she lands at the Indore Airport.'
    },
    {
        question:'How to Complete the No Dues Formality Before Leaving the IITI Campus?',
        answer:'The student will be provided with a no-dues form from the IR Office. The form must be completed and signed by the relevant departments indicated in the form and submitted to the finance section for settlement. It is the responsibility of the student to close their Indian bank account before leaving the institution.'
    },
    {
        question:'Is it mandatory to submit a report upon completion of the program?',
        answer:'The answer is yes. A detailed report needs to be submitted after the completion of the program. Inbound students will receive a completion certificate based on the submission of the report.'
    },
    {
        question:'Is the Office of International Relations, IIT Indore, accountable for student visa applications?',
        answer:'The answer is No. The IR office is not responsible for a student\'s visa application.'
    },
    {
        question:'Do you accept our students for semester exchange just with the MoU or is it necessary to sign a Student Exchange Agreement?',
        answer:'If we have a clause of Student exchange in the MoU with the host university, There will not be any requirement of Student Exchange Agreement separately.'
    },
    {
        question:'Is the first nomination required by the university?',
        answer:'Though this is an open call, we encourage the institute/university to nominate the student, and then they can apply. Institute/university can clearly mention the nominated students as per merit list. As the number of fellowships are limited and it is an open call, selection will be purely based on merit.'
    },
    {
        question:'Is there any nomination deadline other than the application one?',
        answer:'Yes,The nomination deadline is 05th of October 2023.'
    },
    {
        question:'Do you have any link for the courses? Are there any courses in English and which academic fields do they concern?',
        answer:() => (
            <>
                <p>IIT Indore is conducting a number of Academic programs, the link of which is mentioned here for your reference <a href="https://academic.iiti.ac.in/academic_program.php">Click here</a>
                </p>
            </>
        )
    }
] 