import React, { useState } from "react";
import {
  rajeshkumar,
  raghu,
  mc,
  abhijeet,
  dipak,
  priyansh,
  gourinath,
  abhinoy,
  manna,
  spatel,
  bashada,
  kcpradhan,
  HemaChandraJha,
  biswarup,
  chelvam,
  LalitBorana,
  ruchi,
  mirzaBaig,
  ayanMondal,
  shaibal,
  prabhat,
  prashanta,
  santanu,
  dhinakaran,
  DanSathiaraj,
  shirage,
  eswara,
  NareshKumawat,
  mohanasundari,
  Bhargav,
  nagendra,
  sudeshna,
  suman,
  suresh,
  neelima,
  ananya,
  debasis,
  deborah,
  sanjay,
  Debayan_Sarkar,

} from "../../../../assets";
import { Committee } from "./Committee/Committee";

export const CommitteesPage = ({ index }) => {
  const [committee1] = useState({
    title: "Outreach Committee",
    members: [
      [
        {
          name: "Prof. Raghunath Sahoo",
          designation: "IR - Convener",
          email: "adoiao@iiti.ac.in",
          img: raghu,
          phno: "+91-731-660-3329",
        },
      ],
      [
        {
          name: "Dr. Manoneeta Chakraborty",
          designation: "AASE - Member",
          email: "manoneeta@iiti.ac.in",
          img: mc,
        },
        {
          name: "Dr. Abhijeet Joshi",
          designation: "BSBE - Member",
          email: "abhijeet.joshi@iiti.ac.in",
          phno: "+91-731-660-3344",
          img: abhijeet,
        },
      ],
      [
        {
          name: "Dr. Dipak Kumar Roy",
          designation: "CH - Member",
          email: "dipak.roy@iiti.ac.in",
          phno: "+91-731-660-3335",
          img: dipak,
        },
        {
          name: " Dr. Priyansh Singh",
          designation: "CE - Member",
          email: "priyansh@iiti.ac.in",
          phno: "+91-7011321121",
          img: priyansh,
        },
      ],
      [
        {
          name: "Dr. Gourinath Banda",
          designation: "CSE - Member",
          email: "gourinath@iiti.ac.in",
          img: gourinath,
        },
        {
          name: "Dr. Abhinoy Kumar Singh",
          designation: "EE - Member",
          email: "abhinoy.singh@iiti.ac.in",
          phno: "+91-07324-306-884",
          img: abhinoy,
        },
      ],
      [
        {
          name: "Dr. Santanu Manna",
          designation: "MA - Member",
          email: "santanu@iiti.ac.in",
          phno: "+91-731-2438700 Ext. 924",
          img: manna,
        },
        {
          name: "Dr. Satyanarayan Patel",
          designation: "ME - Member",
          email: "spatel@iiti.ac.in",
          img: spatel,
        },
      ],
      [
        {
          name: "Dr. D. A. Basha",
          designation: "MEMS - Member",
          email: "bashada@iiti.ac.in",
          img: bashada,
        },
        {
          name: "Dr. Rajesh Kumar",
          designation: "PH - Member",
          email: "rajeshkumar@iiti.ac.in",
          phno: "+91 731 2438901",
          img: rajeshkumar,
        },
      ],
      [
        {
          name: "Dr. K C Pradhan",
          designation: "HSS - Member",
          email: "kcpradhan@iiti.ac.in",
          phno: "+91-731-2438 700 (Ext. 871)",
          img: kcpradhan,
        },
        {
          name: "Mr. Suresh Chandra Thakur",
          designation: "IR - Secretary",
          email: "drintl@iiti.ac.in",
          img: suresh,
          phno: "+91-731-660-3150",
        },
      ],
    ],
  });

  const [committee2] = useState({
    title: "Academic and Research Advisory Committee",
    members: [
      [
        {
          name: "Dr. Bhargav Vaidya",
          designation: "Member (DAASE)",
          email: "bvaidya@iiti.ac.in",
          phno: "+91-731-660-3254",
          img: Bhargav,
        },
        {
          name: "Dr. Suman Majumdar",
          designation: "Member (DAASE)",
          email: "suman.majumdar@iiti.ac.in",
          phno: "+91-731-660-3304",
          img: suman,
        },
      ],
      [
        {
          name: "Dr. Hem Chandra Jha",
          designation: "Member (BSBE)",
          email: "hemcjha@iiti.ac.in",
          phno: "+91-731-660-3263",
          img: HemaChandraJha,
        },
        {
          name: "Dr. Mirza Baig",
          designation: "Member (BSBE)",
          email: "msb.iit@iiti.ac.in",
          phno: "+91-731-660-3331",
          img: mirzaBaig,
        },
      ],
      [
        {
          name: "Prof. Biswarup Pathak",
          designation: "Member (CH)",
          email: "biswarup@iiti.ac.in",
          phno: "+91-731-660-3348",
          img: biswarup,
        },
        {
          name: "Dr. Chelvam Venkatesh",
          designation: "Member (CH)",
          email: "cvenkat@iiti.ac.in",
          phno: "+91-731-243-8789",
          img: chelvam,
        },
      ],
      [
        {
          name: "Dr. Lalit Borana",
          designation: "Member (CE)",
          email: "lalitborana@iiti.ac.in",
          phno: "+91-731-660-3286",
          img: LalitBorana,
        },
        {
          name: "Dr. Priyansh Singh",
          designation: "Member (CE)",
          email: "priyansh@iiti.ac.in",
          phno: "+91-731-660-3362",
          img: priyansh,
        },
      ],
      [
        {
          name: "Dr. Ayan Mondal",
          designation: "Member (CSE)",
          email: "ayanm@iiti.ac.in",
          phno: "+91-731-660-3426",
          img: ayanMondal,
        },
        {
          name: "Dr. Nagendra Kumar",
          designation: "Member (CSE)",
          email: "nagendra@iiti.ac.in",
          phno: "+91-731-660-3225",
          img: nagendra,
        },
      ],
      [
        {//change phone number
          name: "Prof. Shaibal Mukherjee",
          designation: "Member (EE)",
          email: "shaibal@iiti.ac.in",
          phno: "",
          img: shaibal,
        },
        {
          name: "Prof. Prabhat K. Upadhyay",
          designation: "Member (EE)",
          email: "pkupadhyay@iiti.ac.in",
          phno: "+91-731-660-3271",
          img: prabhat,
        },
      ],
      [
        {//change phone number
          name: "Dr. Prashanta Garain",
          designation: "Member (Math)",
          email: "pgarain@iiti.ac.in",
          phno: "",
          img: prashanta,
        },
        {
          name: "Dr. Santanu Manna",
          designation: "Member (Math)",
          email: "santanu@iiti.ac.in",
          phno: "+91-731-660-3319",
          img: santanu,
        },
      ],
      [
        {
          name: "Dr. Dan Sathiaraj",
          designation: "Member (ME)",
          email: "dansathiaraj@iiti.ac.in",
          phno: "+91-731-660-3217",
          img: DanSathiaraj,
        },
        {
          name: "Prof. S. Dhinakaran",
          designation: "Member (ME)",
          email: "sdhina@iiti.ac.in",
          phno: "+91-731-660-3240",
          img: dhinakaran,
        },
      ],
      [
        {
          name: "Prof. P. M. Shirage",
          designation: "Member (MEMS)",
          email: "pmshirage@iiti.ac.in",
          phno: "+91-731-243-8739",
          img: shirage,
        },
        {
          name: "Dr. Eswara Prasad Korimilli",
          designation: "Member (MEMS)",
          email: "eswar@iiti.ac.in",
          phno: "+91-732-430-6684",
          img: eswara,
        },
      ],
      [
        {//change phone number
          name: "Dr. Naresh Kumawat",
          designation: "Member (PHY)",
          email: "nkumawat@iiti.ac.in",
          phno: "",
          img: NareshKumawat,
        },
        {
          name: "Prof. Sudeshna Chattopadhyay",
          designation: "Member (PHY)",
          email: "sudeshna@iiti.ac.in",
          phno: "+91-732-430-6959",
          img: sudeshna,
        },
      ],
      [
        {
          name: "Prof. Ruchi Sharma",
          designation: "Member (HSS)",
          email: "ruchi@iiti.ac.in",
          phno: "+91-732-660-3587",
          img: ruchi,
        },
        {
          name: "Dr. Mohanasundari Thangavel",
          designation: "Member (HSS)",
          email: "mohana@iiti.ac.in",
          phno: "+91-731-660-3367",
          img: mohanasundari,
        },
      ],
    ],
  });

  const [committee3] = useState({
    title: "International Funding Scheme",
    members: [
      [
        {
          name: "Prof. Sanjay Kumar Singh",
          designation: "Convener",
          email: "adoir2@iiti.ac.in",
          phno: "+91-731-660-3350",
          img: sanjay,
        }
        
      ],
      [
        {
          name: "Prof. Neelima Satyam",
          designation: "Member",
          email: "neelima.satyam@iiti.ac.in",
          phno: "+91-731-660-3290",
          img: neelima,
        },
        {
          name: "Dr. Ananya Ghoshal",
          designation: "Member",
          email: "aghoshal@iiti.ac.in",
          phno: "+91-731-660-3297",
          img: ananya,
        },
      ],
      [
        {
          name: "Dr. Bhargav Pradeep Vaidya",
          designation: "Member",
          email: "bvaidya@iiti.ac.in",
          phno: "+91-731-660-3254",
          img: Bhargav,
        },
        {
          name: "Dr. Debayan Sarkar",
          designation: "Member",
          email: "sarkard@iiti.ac.in",
          phno: "+91-0731-660-3179",
          img: Debayan_Sarkar,
        },
      ],
      [
        {
          name: "Dr. Hem Chandra Jha",
          designation: "Member",
          email: "hemcjha@iiti.ac.in",
          phno: "+91-731-660-3263",
          img: HemaChandraJha,
        },
        
      ],
      
        
      
    ],
  });

  return (
    <div className="container-fluid">
      {index === 1 && <Committee committee={committee1} />}
      {index === 2 && <Committee committee={committee2} />}
      {index === 3 && <Committee committee={committee3} />}
    </div>
  );
};
