export const data = [
    {
        "Name of the Faculty": "Prof.Sarika Jalan",
        Department: "Physics",
        "Funding Agency": "DST",
        "Title of the Grant":"DST-DAAD Indo-German Joint project",
        "Foreign Collaborator(s)": "Dr.Anna Zakharova",
        
        Amount:"11,16,000",
        "Year of Award - Year of Completion":"3 years",
    },
    {
        "Name of the Faculty": "Prof.Sarika Jalan",
        Department: "Physics",
        "Funding Agency": "MPIPKS",
        "Title of the Grant":"MPIPKS visiting grant",
        "Foreign Collaborator(s)": "Germany",
        
        Amount:"3000 euro",
        "Year of Award - Year of Completion":"",
    },
    
    {
        "Name of the Faculty": "Prof Nirmala Menon",
        Department: "HSS",
        "Funding Agency": "EU Horizons Grant",
        "Title of the Grant":"CLS Infra Grant",
        "Foreign Collaborator(s)": "Prof Salvador Ros, Madrid, Spain",
        
        Amount:"6000 Euros + stay and travel",
        "Year of Award - Year of Completion":"4 months",
    },
    {
        "Name of the Faculty": "Prof Nirmala Menon",
        Department: "HSS",
        "Funding Agency": "-",
        "Title of the Grant":"Indo- Swiss bilateral",
        "Foreign Collaborator(s)": "Sara Kenderdine, EPLF, Switzerland",
        
        Amount:"~1,20,000,00",
        "Year of Award - Year of Completion":"3 years",
    },
    {
        "Name of the Faculty": "Dr. Santanu Manna",
        Department: "Mathematics",
        "Funding Agency": "Isaac Newton Institute for Mathematical Sciences, UK",
        "Title of the Grant":"INI ECR Bursary Award",
        "Foreign Collaborator(s)": "University of Cambridge, UK",
        
        Amount:"1,800 Pounds",
        "Year of Award - Year of Completion":"8 days",
    },
    {
        "Name of the Faculty": "Dr. Santanu Manna",
        Department: "Mathematics",
        "Funding Agency": "Taif University",
        "Title of the Grant":"International Research Collaboration project",
        "Foreign Collaborator(s)": "Dr. S.N. Althobati, Taif University, Saudi Arabia",
        
        Amount:"3,500 USD",
        "Year of Award - Year of Completion":"2 years",
    },
    {
        "Name of the Faculty": "Dr. Santanu Manna",
        
        Department: "Mathematics",
      
        "Funding Agency": "Ministry of Education and King Abdulaziz University, DSR, Jeddah, Saudi Arabia",
        "Title of the Grant":"International Research Collaboration project",
        "Foreign Collaborator(s)": "Dr. Mohammed Alkindri, King AbdulAziz University, Saudi Arabia",
        
        Amount:"3,000 USD",
        "Year of Award - Year of Completion":"1 years",
    },
    {
        "Name of the Faculty": "Dr. Ajay Kumar Kushwaha",
        
        Department: "MEMS",
      
        "Funding Agency": "DST",
        "Title of the Grant":"DST-MHSE",
        "Foreign Collaborator(s)": "Dr. Irina Alexandrovna Kurzina, Russia",
        
        Amount:"~ INR 61,00,000/-",
        "Year of Award - Year of Completion":"3 Year",
    },
    {
        "Name of the Faculty": "Dr. Hem Chandra Jha",
        
        Department: "BSBE",
      
        "Funding Agency": "IGSTC",
        "Title of the Grant":"IGSTC",
        "Foreign Collaborator(s)": "Prof Srikanth Karnati",
        
        Amount:"7,70,000/",
        "Year of Award - Year of Completion":"1 year",
    },
    {
        "Name of the Faculty": "Prof. Rajesh Kumar",
        
        Department: "Physics",
      
        "Funding Agency": "IGSTC",
        "Title of the Grant":"IGSTC",
        "Foreign Collaborator(s)": "Prof Nadja Bigall",
        
        Amount:"9,00,000/-",
        "Year of Award - Year of Completion":"1 year",
    },
    {
        "Name of the Faculty": "Dr. Suman Majumdar",
        
        Department: "AASE",
      
        "Funding Agency": "DST-SERB, Govt of India",
        "Title of the Grant":"SERB-SIRE Fellowship",
        "Foreign Collaborator(s)": "Prof. Thomas Greve",
        
        Amount:"9000 USD",
        "Year of Award - Year of Completion":"3 months (2022)",
    },
    {
        "Name of the Faculty": "Prof. Kapil Ahuja",
        
        Department: "CSE",
      
        "Funding Agency": "DAAD",
        "Title of the Grant":"IIT Indore - LU Hannover Partnership in Physics, Chemistry, Bioscience, and Computational Science",
        "Foreign Collaborator(s)": "Dr. Birgit Barden-Läufer (German PI) with 5 Indian Co-PIs and 8 German Co-PIs",
        
        Amount:"3.5 Crores",
        "Year of Award - Year of Completion":"4 years (2019-2023)",
    },
    {
        "Name of the Faculty": "Dr. Santanu Manna",
        Department: "Mathematics",
        "Funding Agency": "Taif University, Saudi Arabia,",
        "Title of the Grant":
            "Dynamic behaviour of material due to torsional wave propagation in porous media.",
        "Foreign Collaborator(s)": "Dr. Saad Nasser Althobaiti,",
        Amount: "1300 USD",
        "Year of Award - Year of Completion": "2021",
    },
    {
        "Name of the Faculty": "Dr. Aruna Tiwari",
        Department: "CSE",
        "Funding Agency":
            "Indo-Norwegian collaboration in intelligent offshore mechatronics systems (INMOST), Norwegian Research Council (RCN) under INTPART Scheme.",
        "Title of the Grant":
            "Indo-Norwegian collaboration in intelligent offshore mechatronics systems (INMOST), Norwegian Research Council (RCN) under INTPART Scheme.",
        "Foreign Collaborator(s)":
            "Lead Partner in Norway: Prof. Jing Zhou and Prof. Linga Reddy Cenkeramaddi, University of Agder (UiA), Norway",
        Amount: "56,20,000 NOK (42, 000 NOK for IIT Indore)",
        "Year of Award - Year of Completion": "2020 - 2023",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Funding Agency": "INTPART, Research Council Norway ",
        "Title of the Grant": "Reversing antimicrobial resistance (AMR)",
        "Foreign Collaborator(s)":
            "Prof. Tone Tonjum,  Head  Medical Microbiology, University of Oslo, Norway",
        Amount: "100000 NOK/year",
        "Year of Award - Year of Completion": "2020 - 2023",
    },
    {
        "Name of the Faculty": "Dr. Ashok Kumar Mocherla",
        Department: "School of HSS",
        "Funding Agency": "Arts and Humanities Research Council, UK",
        "Title of the Grant":
            "Researc h Networking Grant: Minorities on Campus - Dscrimination, equality and Politics of Nationalism on Indian HE",
        "Foreign Collaborator(s)": "Dr. Sariya Cheruvellil",
        Amount: "68,000 UK Pounds",
        "Year of Award - Year of Completion": "2020 - 2022",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Funding Agency": "DST - RFBR Indo-Russian joint research grant",
        "Title of the Grant":
            "Developement of theranostic targeted magnetic nanoparticles for diagnostics and treatment of tuberculosis.",
        "Foreign Collaborator(s)":
            "Dr. Maxim Shevtsov,  Institute of Cytology of Russian Academy of Sciences, Russia",
        Amount: "38.92 Lacs",
        "Year of Award - Year of Completion": "2020 – 2022",
    },
    {
        "Name of the Faculty": "Prof. Sanjay Kumar Singh",
        Department: "Chemistry",
        "Funding Agency": "Shastri Indo-Canadian Institute (SICI), Canada",
        "Title of the Grant":
            "A synergistic experimental and computational approach to develop novel catalytic processes for upgrading agri-waste biomass/bio-oil to biofuel",
        "Foreign Collaborator(s)": "Prof. S.H. Mushrif, University of Alberta, Canada",
        Amount: "Rs 10,00,000/- (Ongoing)",
        "Year of Award - Year of Completion": "2020 - 2022",
    },
    {
        "Name of the Faculty": "Dr. Biswarup Pathak",
        Department: "Chemistry",
        "Funding Agency": "Swedish Funding Agenicies",
        "Title of the Grant":
            "Rational Design of Transition Metal Based Nanoalloys/Two Dimensional Materials for Direct Conversion of CO 2 into a Gasoline Fuel",
        "Foreign Collaborator(s)": "Prof. Rajeev Ahuja (UPPSALA UNIVERSITY)",
        Amount: "Rs. Aprox. 50 Lakhs",
        "Year of Award - Year of Completion": "2020",
    },
    {
        "Name of the Faculty": "Prof. Suman Mukhopadhyay",
        Department: "Chemistry",
        "Funding Agency": "DST",
        "Title of the Grant":
            "Exploring ruthenium based metallodendrimers for improved anticancer activity and mode of action",
        "Foreign Collaborator(s)": "Prof. Ivo Grabchev",
        Amount: "11,82,350",
        "Year of Award - Year of Completion": "September, 2019 - August, 2022     ",
    },
    {
        "Name of the Faculty": "Prof. Bhargav Vaidya",
        Department: "Discipline of Astronomy, Astrophysics and Space Engineering (DAASE)",
        "Funding Agency": "Max Planck Society",
        "Title of the Grant":
            "SAJEMA : Simulating AGN Jets in the Era of Multi-messenger Astronomy",
        "Foreign Collaborator(s)": "Prof. Christian Fendt",
        Amount: "60,000 Euros (20000 Euros per year)",
        "Year of Award - Year of Completion": "July, 2019 - June, 2025 (3 years + 2 years)",
    },
    {
        "Name of the Faculty": "Preeti Anand Bhobe",
        Department: "Physics",
        "Funding Agency": "DAAD, Germany",
        "Title of the Grant":
            "A NEW PASSAGE TO INDIA - Partnership between Leibniz Universität Hannover (LUH) and IIT Indore",
        "Foreign Collaborator(s)": "Prof. Dr. Herbert Pfnür",
        Amount: "*this is an institute level project, 20,000-25,000€/year for all the researchers from both sides. ",
        "Year of Award - Year of Completion": "March, 2019 - February, 2023",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Funding Agency": "DST-SERB-Purdue (OVDF)",
        "Title of the Grant":
            "A novel prostate specific glutamate carboxypeptidase II inhibitor as a therapeutic agent for amyotrophic lateral sclerosis and targeting moiety for PSMA+ cancers",
        "Foreign Collaborator(s)": "Prof. Kavita Shah, Purdue University, USA",
        Amount: "Rs.25,00,000/-",
        "Year of Award - Year of Completion": "Feb, 2019 - Jan, 2020",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Funding Agency":
            "German Academic Exchange Service (DAAD) funded project LUH-IIT Indore Partnership (2019-2023) under the “A New Passage to India Program”",
        "Title of the Grant":
            "Ligand Targeted NIR Dye/Fe3O4 Nanoconjugates for dual (Optical/MRI) Imaging and Hyperthermia to treat Metastatic Castration Resistant Prostate Cancer",
        "Foreign Collaborator(s)":
            "Prof. Andreas Kirschning and Dr. Gerald Drager, LUH, GermanyProf. Andreas Kirschning and Dr. Gerald Drager, LUH, Germany",
        Amount: "Rs.55,00,000/-",
        "Year of Award - Year of Completion": "2019 - 2023",
    },
    {
        "Name of the Faculty": "Dr.I.A.Palani",
        Department: "Mechanical Engineering",
        "Funding Agency": "DST:RSF",
        "Title of the Grant": "DST:RSF",
        "Foreign Collaborator(s)": "Prof.Natalia Resnina, St petersburg state university, Russia",
        Amount: "89,00,000 INR",
        "Year of Award - Year of Completion": "2019 - 2022",
    },
    {
        "Name of the Faculty": "Prof. Sanjay Kumar Singh",
        Department: "Chemistry",
        "Funding Agency":
            "DAAD,(DAAD-IIT Masters Sandwich Scholarship) to Master's student Ms. Vanitha Reddy",
        "Title of the Grant": "Nanoparticle Catalysts for the Direct Synthesis of H2O2",
        "Foreign Collaborator(s)":
            "Dr. Silke Behrens, IKFT, Karlsruhe Institute of Technology, Germany",
        Amount: "NIL",
        "Year of Award - Year of Completion": "2019",
    },
    {
        "Name of the Faculty": "Dr.I.A.Palani",
        Department: "Mechanical Engineering",
        "Funding Agency": "DST:JSPS",
        "Title of the Grant": "DST:JSPS",
        "Foreign Collaborator(s)": "Prof.D Nakamura , Kyushu University, Japan",
        Amount: "9,00,000 INR",
        "Year of Award - Year of Completion": "2018 - 2020",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Funding Agency": "Common wealth, UK",
        "Title of the Grant":
            "Biosensor for quantitation of prostate specific membrane antigen (PSMA) in synthetic urine by using zinc oxide nanorods",
        "Foreign Collaborator(s)": "Prof. Paul Roach, Loughborough University, UK",
        Amount: "30,00,000/-",
        "Year of Award - Year of Completion": "Nov, 2018 - Oct, 2019",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "BSBE",
        "Funding Agency": "Common wealth, UK",
        "Title of the Grant":
            "Design, synthesize and evaluate small molecule targeted nanocarriers for the diagnosis and treatment of prostate cancer.",
        "Foreign Collaborator(s)": "Prof. Paul Roach, Loughborough University, UK",
        Amount: "30,00,000/-",
        "Year of Award - Year of Completion": "Nov, 2018 - Oct, 2019",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Funding Agency": "DAAD, Germany",
        "Title of the Grant":
            "Synthesis and biological evaluation of ligand targeted MRI contrast agent and nanoparticles as theranostic tool for hypoxic solid tumors",
        "Foreign Collaborator(s)": "Prof. Andreas Kirschning, LUH, Germany",
        Amount: "Rs.5,25,000/-",
        "Year of Award - Year of Completion": "Sep, 2018 - Mar, 2019",
    },
    {
        "Name of the Faculty": "Dr.I.A.Palani",
        Department: "Mechanical Engineering",
        "Funding Agency": "DST:DAAD",
        "Title of the Grant": "DST:DAAD",
        "Foreign Collaborator(s)": "Dr.Simon PaulyIFW dresden,Germany",
        Amount: "7,76,000 INR",
        "Year of Award - Year of Completion": "2017 - 2019",
    },
    {
        "Name of the Faculty": "Dr. Pritee Sharma",
        Department: "School of HSS",
        "Funding Agency": "EPFL Energy Center",
        "Title of the Grant": "Workshop Grant Collaborative",
        "Foreign Collaborator(s)":
            "Dr Vincent Moreau and Dr Anjali Narsimulu, EPFL Energy Center, Lausanne, Switzerland",
        Amount: "2500 Euros",
        "Year of Award - Year of Completion": "2017 - 2017",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Funding Agency": "Department of Biotechnology-British council",
        "Title of the Grant": "Diagnosis of Glioblastoma using Zinc Oxide Nanowires",
        "Foreign Collaborator(s)": "Prof. Paul Roach, Keele University, Staffordshire, UK",
        Amount: "15,00,000/-",
        "Year of Award - Year of Completion": "Feb, 2016 - July, 2016",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "BSBE",
        "Funding Agency": "Department of Biotechnology-British council",
        "Title of the Grant":
            "Diagnosis of Prostate cancer by Prostate Specific Membrane Antigen (PSMA) Targeted Quantum Dots",
        "Foreign Collaborator(s)": "Prof. Paul Roach, Keele University, Staffordshire, UK",
        Amount: "15,00,000/-",
        "Year of Award - Year of Completion": "Feb, 2016 - July, 2016",
    },
    {
        "Name of the Faculty": "Dr. Pritee Sharma",
        Department: "School of HSS",
        "Funding Agency": "Rockefellor Foundation,",
        "Title of the Grant": "Resilient Cities Network Grant",
        "Foreign Collaborator(s)": "Dr Umamaheshwar Rajashekhar and Dr. Diane Archer",
        Amount: "5500 GBP",
        "Year of Award - Year of Completion": "2015 - 2016",
    },
    {
        "Name of the Faculty": "Prof. Suman Mukhopadhyay",
        Department: "Chemistry",
        "Funding Agency": "DST",
        "Title of the Grant":
            "Metal complex catalysts anchored on\r\nnano‐sized magnetic materials : syntheses and application in hydrocarbons oxidation and hydroisomerization reactions",
        "Foreign Collaborator(s)": "Dr. Gopal S Mishra",
        Amount: "5,00,000",
        "Year of Award - Year of Completion": "August, 2014 - July, 2017",
    },
    {
        "Name of the Faculty": "Dr.I.A.Palani",
        Department: "Mechanical Engineering",
        "Funding Agency": "DST:JSPS",
        "Title of the Grant": "DST:JSPS",
        "Foreign Collaborator(s)": "Prof.T.Okada, Kyushu University, Japan",
        Amount: "7,00,000 INR",
        "Year of Award - Year of Completion": "2014 - 2016",
    },
    {
        "Name of the Faculty": "Dr. Pritee Sharma",
        Department: "School of HSS",
        "Funding Agency": "NIHU, Hiroshima University, Japan",
        "Title of the Grant": "International Areas Studies Speaker",
        "Foreign Collaborator(s)": "Dr Miharu yui and Professor Hidenori Okahashi",
        Amount: "45000 Japanese Yen",
        "Year of Award - Year of Completion": "2014 - 2014",
    },
    {
        "Name of the Faculty": "Dr. Nirmala Menon",
        Department: "School of HSS",
        "Funding Agency": "UKEIRI",
        "Foreign Collaborator(s)": "Prof. Deborah Sutton",
        Amount: "12 Lacs",
    },
    {
        "Name of the Faculty": "Dr. Nirmala Menon",
        Department: "School of HSS",
        "Funding Agency": "University of Kent Grant",
        "Foreign Collaborator(s)": "Prof. Michael Falk",
        Amount: "Result Awaited",
    },
    {
        "Name of the Faculty": "Dr. Nirmala Menon",
        Department: "School of HSS",
        "Funding Agency": "EC Collaborator Grant",
        "Foreign Collaborator(s)": "Prof. Paul Arthur",
        Amount: "10,000 AUD",
    },
    {
        "Name of the Faculty": "Dr. Nirmala Menon",
        Department: "School of HSS",
        "Funding Agency": "Academic Europaea",
        "Foreign Collaborator(s)": "Prof. Dorota Kolodeiwieczyk",
        Amount: "5000 Euros",
    },
    {
        "Name of the Faculty": "Dr. Nirmala Menon",
        Department: "School of HSS",
        "Funding Agency": "Open Science Collaborator",
        "Foreign Collaborator(s)": "Prof. Don O' Donnell",
    },
    {
        "Name of the Faculty": "Dr. Nirmala Menon",
        Department: "School of HSS",
        "Foreign Collaborator(s)": "Prof. Marika Preziuso",
    },
    {
        "Name of the Faculty": "Dr. Nirmala Menon",
        Department: "School of HSS",
        "Funding Agency": "MOE Grant",
        "Foreign Collaborator(s)": "Prof. Sumathi Ramaswamy",
        Amount: "16 Lacs",
    },
    {
        "Name of the Faculty": "Dr. Nirmala Menon",
        Department: "School of HSS",
        "Funding Agency": "UNICEF Project",
        "Foreign Collaborator(s)": "UNICEF",
        Amount: "42 Lacs",
    },
    {
        "Name of the Faculty": "Dr. Nirmala Menon",
        Department: "School of HSS",
        "Funding Agency": "MOE Grant",
        "Foreign Collaborator(s)": "Prof. Kavita Daiya",
    },

    
];
