export const data = [
  // {
  //   Ranking: 4,
  //   Heading: "THE World University Ranking 2022 India",
  // },
  // {
  //   Ranking: 9,
  //   //396 in world
  //   Heading: "QS World University Ranking 2023 India",
  // },
  {
    Ranking: 16,
    Heading: "Engineering Institute in India NIRF 2024",
  },
  {
    Ranking: 33,
    Heading: "Overall Institute in India NIRF 2024",
  },
];
