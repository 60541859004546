// Short term research program page

import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { proposal,Flyer_mobility ,avatar} from "../../../../assets";
import { FiExternalLink } from "react-icons/fi";
import { AiFillFileText } from "react-icons/ai";
import { mobility_prof, mobility_prof2} from "./data.js";
import "./strp.css"


function MobilityGrant() {
  const [accKey, setAccKey] = useState(-1);

  const handleAccClick = (i) => {
    if (i === accKey) setAccKey(-1);
    else setAccKey(i);
  };

  return (
    <div className="container pb-5 pt-4">
      <h2 className="font-acme text-center mb-5">
        Mobility Grant (2024-2025)
      </h2>
      {/* <img
        src={image6}
        alt="-"
        style={{ maxWidth: "90%", marginLeft: "5%", marginBottom: "30px" }}
      /> */}
     <h2 className="font-acme text-center mb-5">
          Mobility Grant awardees (2023-24)
        </h2>
      <div class="strp-internation-phd ">
        {mobility_prof2.map((item) => {
           const imgSrc = item["img"] || avatar;
          return (
            <div class="card" style={{ width: '21rem' }}>
             <img src={imgSrc} style={{ height: '20rem' }} class="card-img-top" />
              <div class="card-body">
                <h5 class="card-title">Name: {item["Name"]}</h5>
                <hr></hr>
                <p class="card-text">Department: {item["dis"]}</p>
              </div>
            </div>
          );
        })}
      </div>
      <h2 class="font-acme text-center mb-5">
        Our previous year's awardees (2022-2023)
      </h2>
      <div class="strp-internation-phd">
        {mobility_prof.map((item) => {
          return (
            <div class="card" style={{ width: '21rem' }}>
              <img src={item["img"]} style={{ height: '20rem' }} class="card-img-top" />
              <div class="card-body">
                <h5 class="card-title">Name: {item["Name"]}</h5>
                <hr></hr>
                <p class="card-text">Department: {item["dis"]}</p>
              </div>
            </div>
          );
        })}
      </div>
      <br />
      <div className="font-ubuntu text-justify mb-4 strp-intro">
        
        {/* <p style={{ color: 'Red', fontWeight:'bold'}}>Application deadline for current call: <span>30th November 2022</span></p> */}
        <a href={Flyer_mobility} target="_blank" rel="noreferrer">
          <div className="appPortalBtn" style={{ width: 210 }}>
            <AiFillFileText /> Flyer of the program
          </div>
        </a>
        <br />
      </div>
      <Accordion>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="1"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(0);
            }}
          >
            <span>{accKey === 0 ? "-" : "+"}</span>&nbsp;&nbsp;Who can apply?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <ul>
                <li>
                  IIT Indore faculty member who initiated the MoU with foreign university/institution, has beed designated as a Co-ordinator for the Mou and conducted the below mentioned activities.
                </li>
                
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="7"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(6);
            }}
          >
            <span>{accKey === 6 ? "-" : "+"}</span>&nbsp;&nbsp;Instructions
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              <ul>
                <li>General Details of the Applicant: Provide your general information.</li>
                <li>MoU Details: Provide information about your memorandum of Understanding (MoUs) and related activities. Each MoU requires a separate section.</li>
                <li>Mandatory MoU Information: You must provide details for at least one MoU.</li>
                <li>Multiple MoUs: If you have multiple MoUs, provide activity details separately.</li>
                <li>Mandatory Sections: Complete Sections related to your information and program schedule.</li>
                <li>Document Upload: Upload a document for each MoU detailing activities conducted, including commencement dates, impact on the institute, and relevant images/photos.</li>
                <li>Types of Activities: Specify the types of activities organized under the MoU, such as research grants, interactive sessions, exchanges, webinars, conferences, joint PhD supervision, or others.</li>
                <li>Declaration and Schedule: Include a declaration and tentative program schedule, along with guidelines for utilizing the mobility grant for the 2024-25 period.</li>
                <li>Evaluation Process: The committee will evaluate applications based on merit for the award of the Mobility Grant.</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="2"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(1);
            }}
          >
            <span>{accKey === 1 ? "-" : "+"}</span>&nbsp;&nbsp;Financial Support
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <ul>
                <li>Number of Mobility Grant - 03</li>
                <li>
                  Mobility grant of INR 1.5 Lakhs each will be awarded to MoU Co-ordinator
                </li>
                <li>This grant is intended to cover expenses related to participating in international conference and workshops, presenting poster, or visiting foreign laboratories to foster collaborative partnerships,</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="4"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(3);
            }}
          >
            <span>{accKey === 3 ? "-" : "+"}</span>&nbsp;&nbsp;What is Expected?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <ul>
                <li>It is excepted that the Co-ordinator will take a lead in enhancing the proposed activities while utilizing the mobility grant</li>
                <li>Promote bilateral research: Encourage IIT Indore faculty members to apply for bilateral research grants</li>
                <li>Organize interactive sessions with the faculty members from both the institutions to promote cooperation between partner institutes</li>
                <li>Facilitate the exchange of UG/PG/PhD students and faculty members</li>
                <li>Organize online/offline talks and webinars</li>
                <li>Organize conferences and workshops</li>
                <li>Joint Co-supervision of PhD students</li>
                <li>Any other activity to increase international outreach of the institute at the global level</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="5"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(4);
            }}
          >
            <span>{accKey === 4 ? "-" : "+"}</span>&nbsp;&nbsp;Selection
            Criteria
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              <ul>
                <li>The committee will evaluate the applications and recommend on a merit basis for the award of Mobility Grant.</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="6"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(5);
            }}
          >
            <span>{accKey === 5 ? "-" : "+"}</span>&nbsp;&nbsp;Application Dates
            and Online Portal for applying
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              <p style={{ color: 'Red', fontWeight: 'bold' }}>Please fill up the Application by 15th of December 2024</p>
              <a href='https://irglobal.iiti.ac.in/' target="_blank" rel="noreferrer">
                <div className="appPortalBtn">
                  <FiExternalLink /> Application Form
                </div>
              </a>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}


export default MobilityGrant;
