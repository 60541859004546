export const data = [
    {
        "Name of the Faculty": "Prof. Kapil Ahuja",
        Department: "CSE",
        "Author(s)":
            "R. Agrawal , K. Ahuja, D. Maheshwari, M. U. Shaikh, M. Bouaziz, A. Kumar.",
        Title: "Parallel FPGA Routers with Lagrange Relaxation",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Access",
        "Volume/ Page": "Accepted",
        Year: "2023",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematicss",
        "Author(s)":
            "M Tanveer, MA Ganaie, Iman Beheshti, Tripti Goel, Nehal Ahmad, Kuan-Ting Lai, Kaizhu Huang, Yu-Dong Zhang, Javier Del Ser, Chin-Teng Lin",
        Title: "Deep Learning for Brain Age Estimation: A Systematic Review ",
        "Book/ Journal/ Book chapter/ Conference Name": "Information Fusion",
        "Volume/ Page": "96/130-143",
        Year: "2023",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematicss",
        "Author(s)":
            "M.A. Ganaie, Jha Rohan, Krish Agrawal, Rupal Shah, Anouck Girard, Josephine Kasa-Vubu, M. Tanveer",
        Title: "Convolutional and l21-norm neural network for bone age estimation ",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Intelligent Systems",
        "Volume/ Page": "-",
        Year: "2023",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematicss",
        "Author(s)":
            "M Tanveer, A Rastogi, V Paliwal, MA Ganaie, AK Malik, J Del Ser, CT Lin",
        Title: "Ensemble deep learning in speech signal tasks: a review ",
        "Book/ Journal/ Book chapter/ Conference Name": "Neurocomputing",
        "Volume/ Page": "550/126436",
        Year: "2023",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematicss",
        "Author(s)":
            "AK Malik, R Gao, MA Ganaie, M Tanveer, PN Suganthan",
        Title: "Random vector functional link network: recent developments, applications, and future directions ",
        "Book/ Journal/ Book chapter/ Conference Name": "Applied Soft Computing",
        "Volume/ Page": "143/110377",
        Year: "2023",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematicss",
        "Author(s)":
            "H Moosaei, MA Ganaie, M Hladík, M Tanveer",
        Title: "Inverse free reduced universum twin support vector machine for imbalanced data classification",
        "Book/ Journal/ Book chapter/ Conference Name": "Neural Networks",
        "Volume/ Page": "157/125-135",
        Year: "2023",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematicss",
        "Author(s)":
            "M.A. Ganaie, Jha Rohan, Krish Agrawal, Rupal Shah, Anouck Girard, Josephine Kasa-Vubu, M. Tanveer",
        Title: "Convolutional and l21-norm neural network for bone age estimation",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Intelligent Systems",
        "Volume/ Page": "-",
        Year: "2023",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematicss",
        "Author(s)":
            "M Tanveer, MA Ganaie, Iman Beheshti, Tripti Goel, Nehal Ahmad, Kuan-Ting Lai, Kaizhu Huang, Yu-Dong Zhang, Javier Del Ser, Chin-Teng Lin",
        Title: "Deep Learning for Brain Age Estimation: A Systematic Review ",
        "Book/ Journal/ Book chapter/ Conference Name": "Information Fusion ",
        "Volume/ Page": "96/130-143",
        Year: "2023",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematicss",
        "Author(s)":
            "MA Ganaie, M Tanveer, I Beheshti",
        Title: "Brain age prediction with improved least squares twin SVR ",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Journal of Biomedical and Health Informatics ",
        "Volume/ Page": "27/1661-1669",
        Year: "2023",
    },
    {
        "Name of the Faculty": "Prof. Sarika Jalan",
        Department: "Physics",
        "Author(s)":
            "VV Semenov, S Jalan, A Zakharova",
        Title: "Multiplexing-based control of wavefront propagation: the interplay of inter-layer coupling, asymmetry and noise",
        "Book/ Journal/ Book chapter/ Conference Name": "Chaos, Solitons & Fractals",
        "Volume/ Page": "173,/113656",
        Year: "2023",
    },
    {
        "Name of the Faculty": "Prof. Sarika Jalan",
        Department: "Physics",
        "Author(s)":
            "Stefano Boccaletti, Pietro De Lellis, CI del Genio, Karin Alfaro-Bittner, Regino Criado, Sarika Jalan, Miguel Romance",
        Title: "The structure and dynamics of networks with higher order interactions",
        "Book/ Journal/ Book chapter/ Conference Name": "Physics Reports",
        "Volume/ Page": "1018/ 1-64",
        Year: "2023",
    },
    {
        "Name of the Faculty": "Prof. Sarika Jalan",
        Department: "Physics",
        "Author(s)":
            "Benoît Mansoz, Luciana Maria Bortoluci Ormastroni, Jérémy Rame, Caspar Schwalbe, K.V. Vamsi, Pierre Caron, Jonathan Cormier, Florence Pettinari-Sturmel",
        Title: "Tensile behavior of single crystal nickel-based superalloys at 650°C",
        "Book/ Journal/ Book chapter/ Conference Name": "Intermetallics",
        "Volume/ Page": "161/107976",
        Year: "2023",
    },
    {
        "Name of the Faculty": "Prof. Kapil Ahuja",
        Department: "CSE",
        "Author(s)":
            "K. Ahuja, B. Endtmayer, M. C. Steinbach, T. Wick",
        Title: "Multigoal-oriented Error Estimation and Mesh Adaptivity for Fluid–Structure Interaction ",
        "Book/ Journal/ Book chapter/ Conference Name": "Journal of Computational and Applied Mathematics",
        "Volume/ Page": "vol. 412, article no. 114315.",
        Year: "2022",
    },
    {
        "Name of the Faculty": "Prof. Kapil Ahuja",
        Department: "CSE",
        "Author(s)":
            "R. Agrawal, K. Ahuja, M.C. Steinbach, T. Wick",
        Title: "SABMIS: sparse approximation based blind multi-image steganography scheme",
        "Book/ Journal/ Book chapter/ Conference Name": "PeerJ Computer Science",
        "Volume/ Page": "vol. 8, e1080",
        Year: "2022",
    },
    {
        "Name of the Faculty": "Prof. Sarika Jalan",
        Department: "Physics",
        "Author(s)":
            "S Dutta, O Alamoudi, YS Vakilna, S Pati, S Jalan",
        Title: "Oscillation quenching in Stuart-Landau oscillators via dissimilar repulsive coupling",
        "Book/ Journal/ Book chapter/ Conference Name": "Physical Review Research",
        "Volume/ Page": "5 (1), 013074",
        Year: "2022",
    },
    {
        "Name of the Faculty": "Prof. Sarika Jalan",
        Department: "Physics",
        "Author(s)":
            "Rahul K Verma, Alena Kalyakulina, Ankit Mishra, Mikhail Ivanchenko, Sarika Jalan",
        Title: "Role of mitochondrial genetic interactions in determining adaptation to high altitude human population",
        "Book/ Journal/ Book chapter/ Conference Name": "Scientific Reports",
        "Volume/ Page": "01-12-2046",
        Year: "2022",
    },
    {
        "Name of the Faculty": "Prof. Kapil Ahuja",
        Department: "CSE",
        "Author(s)":
            "A. A. Shastri, K. Ahuja, M. B. Ratnaparkhe, and Y.Busnel",
        Title: "Probabilistically Sampled and Spectrally Clustered Plant Genotypes using Phenotypic Characteristics",
        "Book/ Journal/ Book chapter/ Conference Name": "PeerJ",
        "Volume/ Page": "vol. 9, e11927",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Prof. Kapil Ahuja",
        Department: "CSE",
        "Author(s)":
            "S. Jain, A. A. Shastri, K. Ahuja, Y. Busnel, N. P. Singh",
        Title: "Cube Sampled K-Prototype Clustering for Featured Data",
        "Book/ Journal/ Book chapter/ Conference Name": "In INDICON’21: Proc. of Flagship International Conference of the IEEE India Council",
        "Volume/ Page": "INSPEC Accession Number: 21704076",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Prof. Kapil Ahuja",
        Department: "CSE",
        "Author(s)":
            "Y. Peruvemba, S. Rai, K. Ahuja and A. Kumar",
        Title: "RL-Guided Runtime-Constrained Heuristic Exploration for Logic Synthesis",
        "Book/ Journal/ Book chapter/ Conference Name": "In ICCAD'21: Proc. of 40th International Conference On Computer Aided Design",
        "Volume/ Page": "INSPEC Accession Number: 21529538",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Prof. Sarika Jalan",
        Department: "Physics",
        "Author(s)":
            "S Makovkin, T Laptyeva, S Jalan, M Ivanchenko",
        Title: "Synchronization in multiplex models of neuron–glial systems: Small-world topology and inhibitory coupling",
        "Book/ Journal/ Book chapter/ Conference Name": "Chaos: An Interdisciplinary Journal of Nonlinear Science",
        "Volume/ Page": "31/11",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Prof. Sarika Jalan",
        Department: "Physics",
        "Author(s)":
            "A Panday, WS Lee, S Dutta, S Jalan",
        Title: "Machine learning assisted network classification from symbolic time-series",
        "Book/ Journal/ Book chapter/ Conference Name": "Chaos: An Interdisciplinary Journal of Nonlinear Science",
        "Volume/ Page": "31/031106",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Prof. Sarika Jalan",
        Department: "Physics",
        "Author(s)":
            "Rahul K Verma, Alena Kalyakulina, Cristina Giuliani, Pramod Shinde, Ajay Deep Kachhvah, Mikhail Ivanchenko, Sarika Jalan",
        Title: "Analysis of human mitochondrial genome co‑occurrence networks of Asian population at varying altitudes",
        "Book/ Journal/ Book chapter/ Conference Name": "Scientific Reports",
        "Volume/ Page": "11/133",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Prof. Sarika Jalan",
        Department: "Physics",
        "Author(s)":
            "P Shinde, HJ Whitwell, RK Verma, M Ivanchenko, A Zaikin, S Jalan",
        Title: "Impact of modular mitochondrial epistatic interactions on the evolution of human subpopulations",
        "Book/ Journal/ Book chapter/ Conference Name": "Mitochondrion",
        "Volume/ Page": "58/505818",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Anil Kumar",
        Department: "Physics",
        "Author(s)":
            "Anil Kumar, M. Kamal Warshi, Archna Sagdeo, Matthew Krzystyniak, Svemir Rudi, T. Adroja, Ivan da Silva, P. R. Sagdeo",
        Title: "Origin of natural and magnetic field induced polar order in orthorhombic PrFe<sub>&frac12;</sub>Cr<sub>&frac12;</sub>O<sub>3</sub>",
        "Book/ Journal/ Book chapter/ Conference Name": "Physical Review B",
        "Volume/ Page": "104 (3) / 035101",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Ananya Ghoshal",
        Department: "School of HSS",
        "Author(s)":
            "Jason M. Kelly, Fiona P. McDonald, Alejandro Camargo, Amelia Moore, Mark Kesling, Ananya Ghoshal,George Marcus, Paul Stoller,Dominic Boyer, Serenella Iovino, Rebecca Ballestra, Jim Enote, Eduardo S. Brondizio, Ignatius Gutsa, Cymene Howe , Sue Jackson and Phil Scarpino. The book is edited by Jason M. Kelly and Fiona P. McDonald.",
        Title: "Forthcoming Book Chapter - in 'Scenes from the Anthropocene' to be published by University of California Press as a Hard Copy Volume of Critical Essays",
        "Book/ Journal/ Book chapter/ Conference Name": "-",
        "Volume/ Page": "-",
        Year: "Expected by 2021",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)":
            "Minu Treesa Abraham, Neelima Satyam , Sai Kumar Peddholla Reddy, Biswajeet Pradhan",
        Title: "Runout Modelling and Calibration of Friction Parameters of Kurichermala Debris Flow, India",
        "Book/ Journal/ Book chapter/ Conference Name": "Landslides, Springer",
        "Volume/ Page": "18",
        Year: "-",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)": "Klionsky Daniel et al.,",
        Title: "Guidelines for the Use and Interpretation of Assays for Monitoring Autophagy (4th edition)",
        "Book/ Journal/ Book chapter/ Conference Name": "Autophagy",
        "Volume/ Page": "1-382",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)":
            "Srikanth Karnati, Michael Seimetz, Florian Kleefeldt, Avinash Sonawane, Madhusudhan Thati, Djuro Kosanovic, Norbert Weissmann, Karsten Krüger, Süleyman Ergün",
        Title: "Chronic Obstructive Pulmonary Disease (COPD) and the Cardiovascular System: Vascular Repair and Regeneration as a Therapeutic Target.",
        "Book/ Journal/ Book chapter/ Conference Name": "Frontiers in Cardiovascular Medicine",
        "Volume/ Page": "NA\n(In Press)",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)":
            "Natalia Mikhailovna Yudintceva, Natalia Arkadievna Mikhailova, Danila Evhenjevich Bobkov, Liudmila Yakovleva, Boris Nikolaev, Alexandr Muraviov, Tatiana Vinogradova, Petr Yablonskiy, Igor Samusenko, Vyacheslav Ryzhov, Vladimir Deriglazov, Gabriele Multhoff, Alexander Klapproth, Wei Bo Li, Barsha Nayak, Avinash Sonawane, Maxim Shevtsov",
        Title: "Evaluation of the Biodistribution of Mesenchymal Stem Cells in a Preclinical Renal Tuberculosis Model by Nonlinear Magnetic Response Measurements.",
        "Book/ Journal/ Book chapter/ Conference Name": "Frontiers in Physics",
        "Volume/ Page": "NA\n(In Press)",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Prof. Sanjay Kumar Singh",
        Department: "Chemistry",
        "Author(s)":
            "Rohit K. Rai, Mahendra K. Awasthi, Vipin K. Singh, Sudipta R. Barman, Silke Behrens and Sanjay K. Singh*",
        Title: "Aqueous Phase Semihydrogenation of Alkynes over Ni-Fe Bimetallic Catalysts",
        "Book/ Journal/ Book chapter/ Conference Name": "Catalysis Science and Technology",
        "Volume/ Page": "10, 4968-4980",
        Year: "2021",
    },
    {
        "Author(s)":
            "Vanitha R. Naina, Sheng Wang, Dmitry I. Sharapa, Michael Zimmermann, Martin Hahsler, Lukas Niebl-Eibenstein, Junjun Wang, Christof Woll, Yuemin Wang, Sanjay K. Singh, Felix Studt, and Silke Behrens*",
        Title: "Shape-Selective Synthesis of Intermetallic Pd3Pb Nanocrystals and Enhanced Catalytic Properties in the Direct Synthesis of Hydrogen Peroxide",
        "Book/ Journal/ Book chapter/ Conference Name": "ACS Catalysis",
        "Volume/ Page": "11, 2288–2301",
        Year: "2021",
        Department: "Chemistry",
        "Name of the Faculty": "Prof. Sanjay Kumar Singh",
    },
    {
        "Author(s)": "Mahendra K. Awasthi, Rohit K. Rai, Silke Behrens and Sanjay K. Singh*",
        Title: "Low-temperature hydrogen production from methanol over ruthenium catalyst in water",
        "Book/ Journal/ Book chapter/ Conference Name": "Catalysis Science and Technology",
        "Volume/ Page": "11, 136-142",
        Year: "2021",
        Department: "Chemistry",
        "Name of the Faculty": "Prof. Sanjay Kumar Singh",
    },
    {
        "Name of the Faculty": "Prof. Suman Mukhopadhyay",
        Department: "Chemistry",
        "Author(s)":
            "Komal Vyas, Deepu Sharma, Sri Krishna Jayadev Magani, Shaikh M Mobin, Suman Mukhopadhyay",
        Title: "In Vitro Evaluation of Cytotoxicity and Antimetastatic Properties of Novel Arene Ruthenium(II)‐Tetrazolato Compounds on Human Cancer Cell Lines",
        "Book/ Journal/ Book chapter/ Conference Name": "Appl. Organomet. Chem",
        "Volume/ Page": "in press",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Author(s)": "Venkatesh, C., Doorneweerd, D. D., Xia, W., Shen, J., Putt, K. S., Low, P.S.",
        Title: "Folate-Targeted Verrucarin a Reduces The Number of Activated Macrophages in a Mouse Model of Acute Peritonitis",
        "Book/ Journal/ Book chapter/ Conference Name": "Bioorg. Med. Chem. Lett.",
        "Volume/ Page": "0",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "BSBE",
        "Author(s)": "Krishnan, M. A., Yadav, K., Roach, P., Venkatesh, C.",
        Title: "Targeted Near-Infrared Nanoprobe for Deep-Tissue Penetration and Imaging of Prostate Cancer",
        "Book/ Journal/ Book chapter/ Conference Name": "Biomaterials Science",
        "Volume/ Page": "0",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Nagendra Kumar",
        Department: "CSE",
        "Author(s)": "Nagendra Kumar, Eshwanth Baskaran, Anand Konjengbam & Manish Singh",
        Title: "Hashtag Recommendation for Short Social Media Texts Using Word-Embeddings and External Knowledge",
        "Book/ Journal/ Book chapter/ Conference Name": "Knowledge and Information Systems",
        "Volume/ Page": "63",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Ruchi Sharma",
        Department: "School of HSS",
        "Author(s)": "Ivus, Olena, Manu Jose and Ruchi Sharma",
        Title: "R&D Tax Credit and Innovation: Evidence from Private Firms in India",
        "Book/ Journal/ Book chapter/ Conference Name": "Research Policy",
        "Volume/ Page": "50 (1)",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Mohd. Arshad",
        Department: "Mathematics",
        "Author(s)": "Jodra, P., and Arshad, M.",
        Title: "An Intermediate Muth Distribution with Increasing Failure Rate",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Communications in Statistics- Theory and Methods",
        "Volume/ Page": "Accepted",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Sanjeev Singh",
        Department: "Mathematics",
        "Author(s)": "A. Baricz, N. Bisht, S. Singh, V. A. Vijesh",
        Title: "The Generalized Marcum Function of the Second Kind: Monotonicity Patterns and Tight Bounds",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Journal of Computational and Applied Mathematics",
        "Volume/ Page": "382, Art. 113093",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Sanjeev Singh",
        Department: "Mathematics",
        "Author(s)": "A. Baricz, N. Bisht, S. Singh, V. A. Vijesh",
        Title: "Functional Inequalities and Bounds for the Generalized Marcum Function of the Second Kind",
        "Book/ Journal/ Book chapter/ Conference Name": "Results in Mathematics",
        "Volume/ Page": "76, Art. 35",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "M Khan, MS Obaidat, T Hussain, J Del Ser, N Kumar, M Tanveer, F Doctor",
        Title: "Fuzzy Logic in Surveillance Big Video Data: Comprehensive Review, Challenges and Research Directions",
        "Book/ Journal/ Book chapter/ Conference Name": "ACM Computing Surveys.",
        "Volume/ Page": "-",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "Hossein Moosaei, Saeed Ketabchi, Mohamad Razzaghi, M Tanveer",
        Title: "Generalized Twin Support Vector Machines",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Neural Processing Letters (NEPL), Springer",
        "Volume/ Page": "-",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Sk. Safique Ahmad",
        Department: "Mathematics",
        "Author(s)": "SS Ahmad, I Ali, and I. Slapnicar",
        Title: "Perturbation Analysis of Matrices over a Quaternion Division Algebra",
        "Book/ Journal/ Book chapter/ Conference Name": "Electron. Tans. Mumer. Anal.(ETNA)",
        "Volume/ Page": "54",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. V. Antony Vijesh",
        Department: "Mathematics",
        "Author(s)": "A. Baricz, N. Bisht, S. Singh, V. A. Vijesh",
        Title: "The Generalized Marcum Function of the Second Kind: Monotonicity Patterns and Tight Bounds",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Journal of Computational and Applied Mathematics",
        "Volume/ Page": "382, Art. 113093",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. V. Antony Vijesh",
        Department: "Mathematics",
        "Author(s)": "A. Baricz, N. Bisht, S. Singh, V. A. Vijesh",
        Title: "Functional Inequalities and Bounds for the Generalized Marcum Function of the Second Kind",
        "Book/ Journal/ Book chapter/ Conference Name": "Results in Mathematics",
        "Volume/ Page": "76, Art. 35",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr.I.A.Palani",
        Department: "Mechanical Engineering",
        "Author(s)":
            "N Resnina, IA Palani, S Belyaev, SS Mani Prabu, P Liulchak, U Karaseva, M Manikandan, S Jayachandran, V Bryukhanova, Anshu Sahu, R Bikbaev",
        Title: "Structure, Martensitic Transformations and Mechanical Behaviour of NiTi Shape Memory Alloy Produced by Wire Arc Additive Manufacturing",
        "Book/ Journal/ Book chapter/ Conference Name": "Journal of Alloys and Compounds",
        "Volume/ Page": "851",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Rupesh S. Devan",
        Department: "MEMS",
        "Author(s)":
            "P. R. Chikate, A. Sharma, S. R. Rondiya, R. W. Cross, N. Y. Dzade, P. M. Shirage, and R. S. Devan*",
        Title: "Hierarchically Interconnected ZnO Nanowires for Low Temperature Operated Reducing Gas Sensors: Experimental and DFT Studies",
        "Book/ Journal/ Book chapter/ Conference Name": "New J. of Chemistry",
        "Volume/ Page": "45/1403-1414",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Rupesh S. Devan",
        Department: "MEMS",
        "Author(s)":
            "N. Kitchamsetti, M. S. Ramteke, S. R. Rondiya, S. R. Mulani, M. S. Patil, R. W. Cross, N. Y. Dzade, and R. S. Devan",
        Title: "DFT and Experimental Investigations on the Photocatalytic Activities of NiO Nanobelts for Removal of Organic Pollutants",
        "Book/ Journal/ Book chapter/ Conference Name": "J. of Alloys and Compounds",
        "Volume/ Page": "855/157337",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Preeti Anand Bhobe",
        Department: "Physics",
        "Author(s)": "S. Chaudhuri, D. Salas, V. Srihari, E. Welter, I. Karaman, and P. A. Bhobe",
        Title: "Half Metallicity in Cr Substituted Fe2TiSn",
        "Book/ Journal/ Book chapter/ Conference Name": "Nature Scientific Reports",
        "Volume/ Page": "Vol 11, Pg. 524",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Rajesh Kumar",
        Department: "Physics",
        "Author(s)":
            "Manushree Tanwar, Devesh K. Pathak, Anjali Chaudhary, Alexander S. Krylov, Herbert Pfnur, Ashutosh Sharma, Byungmin Ahn, Sangyeob Lee and Rajesh Kumar",
        Title: "Pseudo-Anomalous Size Dependent Electron-Phonon Interaction in Graded Energy Band: Solving the Fano Paradox",
        "Book/ Journal/ Book chapter/ Conference Name": "J. Phys. Chem. Lett.,",
        "Volume/ Page": "12, 2044",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)": "Nitin Tiwari, Neelima Satyam and Anand J Puppala",
        Title: "Effect of Synthetic Geotextile on Stabilization of Expansive Subgrades: An Experimental Study",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Journal of Materials in Civil Engineering, ASCE",
        "Volume/ Page": "In Press",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)": "Minu Treesa Abraham, Neelima Satyam and Biswajeet Pradhan",
        Title: "Forecasting Landslides using Mobility Functions: A Case Study from Idukki District, India",
        "Book/ Journal/ Book chapter/ Conference Name": "Indian Geotechnical Journal, Springer",
        "Volume/ Page": "In Press",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)": "Meghna Sharma, Neelima Satyam and Krishna R. Reddy",
        Title: "Effect of Freeze-Thaw Cycles on Engineering Properties of Biocemented Sand under Different Treatment Conditions",
        "Book/ Journal/ Book chapter/ Conference Name": "Engineering Geology, Elsevier",
        "Volume/ Page": "284 (106022)",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)":
            "Minu Treesa Abraham, Neelima Satyam, Nakshatram Shreyas, Biswajeet Pradhan, Samuele Segoni, Khairul Nizam Abdul Maulud, Abdullah M. Alamri",
        Title: "Forecasting Landslides using SIGMA Model: a Case Study from Idukki, India",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Geomatics, Natural Hazards and Risk,Taylor & Francis",
        "Volume/ Page": "In Press",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)": "Meghna Sharma, Neelima Satyam and Krishna R. Reddy",
        Title: "Hybrid Bacteria Mediated Cemented Sand: Microcharacterization, Permeability, Strength, Shear Wave Velocity, Stress-Strain, and Durability",
        "Book/ Journal/ Book chapter/ Conference Name":
            "International Journal of Damage Mechanics, SAGE Publication",
        "Volume/ Page": "In Press",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)":
            "Minu Treesa Abraham, Neelima Satyam , Ascanio Rosi, Biswajeet Pradhan and Samuele Segoni",
        Title: "Usage of Antecedent Soil Moisture for Improving the Performance of Rainfall Thresholds for Landslide Early Warning",
        "Book/ Journal/ Book chapter/ Conference Name": "Catena, Elsevier",
        "Volume/ Page": "In Press",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)": "Meghna Sharma, Neelima Satyam and Krishna R. Reddy",
        Title: "Rock-like Behavior of Biocemented Sand Treated under Non-Sterile Environment and Various Treatment Conditions",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Journal of Rock Mechanics and Geotechnical Engineering, Elsevier",
        "Volume/ Page": "In Press",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)": "Meghna Sharma, Neelima Satyam and Krishna R. Reddy",
        Title: "Comparison of Improved Shear Strength of Biotreated Sand using Different Ureolytic Strains and Sterile Conditions",
        "Book/ Journal/ Book chapter/ Conference Name": "Soil Use and Management, Wiley",
        "Volume/ Page": "In Press",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)":
            "Binh Thai P, Abolfazl J., T. Nguyen-Thoi, T.V. Phong, H. D. Nguyen, Neelima Satyam , Md Masroor, S. Rehman, H. Sajjad, M. Sahana, H. V. Le & I. Prakash",
        Title: "Ensemble Machine Learning Models based on Reduced Error Pruning Tree for Prediction of Rainfall-Induced Landslides",
        "Book/ Journal/ Book chapter/ Conference Name":
            "International Journal of Digital Earth, Taylor & Francis",
        "Volume/ Page": "In Press",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Guru Prakash",
        Department: "Civil Engineering",
        "Author(s)": "Dr. Arnold Yuan",
        Title: "Toward a Big Data-based Approach: A Review on Degradation Models for Prognosis of Critical Infrastructure",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Journal of Nondestructive Evaluation, Diagnostics and Prognostics of Engineering Systems",
        "Volume/ Page": "4",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Guru Prakash",
        Department: "Civil Engineering",
        "Author(s)": "Daijiro Mizutani",
        Title: "Toward a Big Data-based Approach: A Review on Degradation Models for Prognosis of Critical Infrastructure",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Journal of Nondestructive Evaluation, Diagnostics and Prognostics of Engineering Systems",
        "Volume/ Page": "4",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Dr. Saikat Sarkar",
        Department: "Civil Engineering",
        "Author(s)": "TV Varma, B Ungureanu, S Sarkar, R Craster, S Guenneau, S Brûlé",
        Title: "The Influence of Structure Geometry and Material on Seismic Metamaterial Performance",
        "Book/ Journal/ Book chapter/ Conference Name": "Frontiers in materials (to be published)",
        "Volume/ Page": "-",
        Year: "2021",
    },
    {
        "Author(s)":
            "Uttpal Ananda, Bashir Adelodunb,c, Alberto Pivatod, S. Sureshe, Omkar Indarif, Shweta Jakhmolaf, Hem Chandra Jhaf, Pawan Kumar Jhag, Vijay Tripathih,*, Francesco Di Maria",
        Title: "A review of the presence of SARS-CoV-2 RNA in wastewater and airborne particulates and its use for virus spreading surveillance",
        "Book/ Journal/ Book chapter/ Conference Name": "Environmental Research",
        "Volume/ Page": "oi.org/10.1016/j.envres.2021.110929",
        Year: "2021",
        "Name of the Faculty": "Dr. Hem Chandra Jha",
        Department: "BSBE",
    },
    {
        "Author(s)": "Omkar Indari, Rajesh Chandramohanadas, Hem Chandra Jha",
        Title: "Epstein–Barr virus infection modulates blood–brain barrier cells and its co-infection with Plasmodium falciparum induces RBC adhesion",
        "Book/ Journal/ Book chapter/ Conference Name": "Pathogens and Disease",
        "Volume/ Page": "doi.org/10.1093/femspd/ftaa080",
        Year: "2021",
        "Name of the Faculty": "Dr. Hem Chandra Jha",
        Department: "BSBE",
    },
    {
        "Name of the Faculty": "Prof. Bhargav Vaidya",
        Department: "Discipline of Astronomy, Astrophysics and Space Engineering (DAASE)",
        "Author(s)":
            "Dominguez-Fernandez, P., Bruggen, M., Vazza, F., Banda-Barragan, W. E., Rajpurohit, K., Mignone, A., Mukherjee, D., & Vaidya, B.",
        Title: "Morphology of radio relics - I. What causes the substructure of synchrotron emission?",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Monthly Notices of the Royal Astronomical Society",
        "Volume/ Page": "500; 795-816",
        Year: "2021",
    },
    {
        "Name of the Faculty": "Prof. Bhargav Vaidya",
        Department: "Discipline of Astronomy, Astrophysics and Space Engineering (DAASE)",
        "Author(s)":
            "Mukherjee, Dipanjan, Bodo, Gianluigi, Mignone, Andrea, Rossi, Paola, & Vaidya, Bhargav",
        Title: "Simulating the dynamics and non-thermal emission of relativistic magnetized jets I. Dynamics",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Monthly Notices of the Royal Astronomical Society",
        "Volume/ Page": "499; 681-701",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Prof. Bhargav Vaidya",
        Department: "Discipline of Astronomy, Astrophysics and Space Engineering (DAASE)",
        "Author(s)": "Kumar, Sandeep, Paul, Arghyadeep, & Vaidya, Bhargav",
        Title: "A comparison study of extrapolation models and empirical relations in forecasting solar wind",
        "Book/ Journal/ Book chapter/ Conference Name": "rontiers in Astronomy and Space Sciences",
        "Volume/ Page": "7; 92",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Prof. Bhargav Vaidya",
        Department: "Discipline of Astronomy, Astrophysics and Space Engineering (DAASE)",
        "Author(s)":
            "Dabhade, P., Mahato, M., Bagchi, J., Saikia, D. J., Combes, F., Sankhyayan, S., Röttgering, H. J. A., Ho, L. C., Gaikwad, M., Raychaudhury, S., Vaidya, B., & Guiderdoni, B.",
        Title: "Search and analysis of giant radio galaxies with associated nuclei (SAGAN). I. New sample and multi-wavelength studies",
        "Book/ Journal/ Book chapter/ Conference Name": "Astronomy and Astrophysics",
        "Volume/ Page": "642; A153",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Prof. Bhargav Vaidya",
        Department: "Discipline of Astronomy, Astrophysics and Space Engineering (DAASE)",
        "Author(s)":
            "Dabhade, P., Mahato, M., Bagchi, J., Saikia, D. J., Combes, F., Sankhyayan, S., Röttgering, H. J. A., Ho, L. C., Gaikwad, M., Raychaudhury, S., Vaidya, B., & Guiderdoni, B.",
        Title: "Search and analysis of giant radio galaxies with associated nuclei (SAGAN). I. New sample and multi-wavelength studies",
        "Book/ Journal/ Book chapter/ Conference Name": "Astronomy and Astrophysics",
        "Volume/ Page": "642; A153",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Prof. Bhargav Vaidya",
        Department: "Discipline of Astronomy, Astrophysics and Space Engineering (DAASE)",
        "Author(s)": "Mignone, A., Vaidya, B., Puzzoni, E., Mukherjee, D., Bodo, G., & Flock, M.",
        Title: "Particle-Gas Hybrid Schemes in the PLUTO Code",
        "Book/ Journal/ Book chapter/ Conference Name": "Journal of Physics Conference Series",
        "Volume/ Page": "1623; 012007",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)":
            "Sumanta Kumar Naik, Kaliprasad Pattanaik, Jacqueline Eich, Vivien Sparr, Matthias Hauptmann, Barbara Kalsdorf, Nobert Reiling, Wolfgang Liedtke, Wolfgang M. Kuebler, Ulrich E. Schaible and Avinash Sonawane.",
        Title: "Differential roles of the ion channel TRPV4 in host responses to Mycobacterium tuberculosis early and late in infection.",
        "Book/ Journal/ Book chapter/ Conference Name": "iScience",
        "Volume/ Page": "23 (6), p.101206",
        Year: "2020",
    },
    {
        "Author(s)":
            "Yonggang Pei, Nicky Hwang, Fengchao Lang, Fengchao Lang, Lanlan Zhou, Josiah Hiu-yuen Wong, Rajnish Kumar Singh,Hem Chandra Jha, Wafik S. El-Deiry, Yanming Du, Erle S. Robertson",
        Title: "Quassinoid analogs with enhanced efficacy for treatment of hematologic malignancies target the PI3Kγ isoform",
        "Book/ Journal/ Book chapter/ Conference Name": "Communication Biology",
        "Volume/ Page": "ttps://doi.org/10.1038/s42003-020-099",
        Year: "2020",
        "Name of the Faculty": "Dr. Hem Chandra Jha",
        Department: "BSBE",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Author(s)": "Venkatesh, C., Shen, J., Putt, K. S., Low, P.S.",
        Title: "Evaluation of the Reducing Potential of PSMA-containing Endosomes by FRET Imaging",
        "Book/ Journal/ Book chapter/ Conference Name": "Cancer Drug Resistance",
        "Volume/ Page": "3",
        Year: "2020",
    },
    {
        "Author(s)":
            "Yonggang Pei, Josiah Hiu-yuen Wong, Hem Chandra Jha, Tian Tian, Zhi Wei, Erle S. Robertson",
        Title: "Epstein-Barr Virus Facilitates Expression of KLF14 by Regulating the Cooperative Binding of the E2F-Rb-HDAC Complex in Latent Infection",
        "Book/ Journal/ Book chapter/ Conference Name": "Journal of Virology",
        "Volume/ Page": "10.1128/JVI.01209-20",
        Year: "2020",
        "Name of the Faculty": "Dr. Hem Chandra Jha",
        Department: "BSBE",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "W Hu, CH Chang, Anirban Sengupta, S Bhunia, R Castner, H Li",
        Title: "An Overview of Hardware Oriented Security and Trust: Threats, Countermeasures and Design Tools",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Transactions on CAD",
        "Volume/ Page": "doi: 10.1109/\nTCAD.2020.3047976",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "W Hu, CH Chang, Anirban Sengupta, S Bhunia, R Castner, H Li",
        Title: "Hardware Oriented Security and Trust",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Transactions on CAD",
        "Volume/ Page": "Special issue",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Pritee Sharma",
        Department: "School of HSS",
        "Author(s)": "Sharma, Pritee and S.N. Kumar",
        Title: "The Global Governance of Water, Energy and Food Nexus: Allocation and Access for Competing Demands",
        "Book/ Journal/ Book chapter/ Conference Name":
            "International Environmental Agreements (Springer Nature)",
        "Volume/ Page": "Vol 20",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Ruchi Sharma",
        Department: "School of HSS",
        "Author(s)": "Panda, S., Ruchi Sharma and W.G.Park.",
        Title: "Patent Protection, Technological Efforts, and Exports: An Empirical Investigation.",
        "Book/ Journal/ Book chapter/ Conference Name": "The Journal of Developing Areas",
        "Volume/ Page": "54(2)",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Sanjeev Singh",
        Department: "Mathematics",
        "Author(s)": "I. Aktas, A. Baricz, S. Singh",
        Title: "Geometric and Monotonic Properties of Hyper-Bessel Functions",
        "Book/ Journal/ Book chapter/ Conference Name": "Ramanujan Journal",
        "Volume/ Page": "51. 275-295",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Md. Aquil Khan",
        Department: "Mathematics",
        "Author(s)": "S. Manna, BN Datta, S. Ahmad (Eds)",
        Title: "Mathematrical Modelling and Scientific Computing with Applications",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Springer Proceedings in Matheamtics & Statistics",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "J.J. Virtusio, D.S. Tan, W.H. Cheng, M. Tanveer, and K.L. Hua",
        Title: "Enabling Artistic Control Over Pattern Density and Stroke Strength",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Transactions on Multimedia",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "L. Fang, C. Yin, J. Zhu, C. Ge, M. Tanveer, A. Jolfaei, Z. Cao",
        Title: "Privacy Protection for Medical Data Sharing in Smart Healthcare",
        "Book/ Journal/ Book chapter/ Conference Name":
            "ACM Transactions on Multimedia Computing, Communications, and Applications (TOMM)",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "M. Tanveer, S. Sharma, K. Muhammad",
        Title: "Large Scale Least Squares Twin SVMs",
        "Book/ Journal/ Book chapter/ Conference Name": "ACM Transactions on Internet Technology",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "B. Cao, S. Fan, J. Zhao, P. Yang, K. Muhammad, M. Tanveer",
        Title: "Quantum-enhanced Multi Objective Large-Scale Optimization via Parallelism",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Swarm and Evolutionary Computation, Elsevier",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "M. Tanveer, P. Khanna, M. Prasad, C.T. Lin",
        Title: "Introduction to the Special Issue on Computational Intelligence for Biomedical Data and Imaging",
        "Book/ Journal/ Book chapter/ Conference Name":
            "ACM Transactions on Multimedia Computing, Communications, and Applications (TOMM)",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "M. Tanveer, A. Sharma, P.N. Suganthan",
        Title: "Least Squares K-nearest Neighbor-based Weighted Multi-class Twin SVM",
        "Book/ Journal/ Book chapter/ Conference Name": "Neurocomputing, Elsevier",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "Z. Yue, S. Ding, L. Zhao, Y. Zhang, Z. Cao, M. Tanveer, A. Jolfaei, X.Zheng",
        Title: "Privacy-preserving Time Series Medical Images Analysis Using a Hybrid Deep Learning Frame-work",
        "Book/ Journal/ Book chapter/ Conference Name":
            "ACM Transactions on Internet Technology (TOIT)",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)":
            "C. Gautam, P.K. Mishra, A. Tiwari, B. Richhariya, H.M. Pandey, S. Wang, M. Tanveer",
        Title: "Minimum Variance-Embedded Deep Kernel Regularized Least Square Method for One-class Classification and its Applications to Biomedical Data",
        "Book/ Journal/ Book chapter/ Conference Name": "Neural Networks, Elsevier",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "M.A. Ganaie, M. Tanveer, P.N. Suganthan",
        Title: "Oblique Decision Tree Ensemble via Twin Bounded SVM",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Expert Systems with Applications, Elsevier",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "Qi Li, Z. Cao, M. Tanveer, H.M. Pandey, C. Wang",
        Title: "A Semantic Collaboration Method Based on Uniform Knowledge Graph",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Internet of Things Journal",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "Q Li, Z Cao, M Tanveer, HM Pandey, C Wang",
        Title: "An Effective Reliability Evaluation Method for Power Communication Network Based on Community Structure",
        "Book/ Journal/ Book chapter/ Conference Name":
            "IEEE Transactions on Industry Applications",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "M Khan, T Hussain, M Tanveer, G Sannino, V Albuquerque",
        Title: "Cost-Effective Video Summarization using Deep CNN with Hierarchical Weighted Fusion for IoT Surveillance Networks",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Internet of Things Journal",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "L Fang, Y Li, X Yun, Z Wen, S Ji, W Meng, Z Cao, M Tanveer",
        Title: "THP: An SDN-based Authentication Scheme Resistant to Multiple Attacks in IoT",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Internet of Things Journal",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)":
            "M. Tanveer, B. Richhariya, R.U. Khan, A.H. Rashid, M. Prasad, P. Khanna, C.T. Lin",
        Title: "Machine Learning Techniques for the Diagnosis of Alzheimer's Disease: A Review",
        "Book/ Journal/ Book chapter/ Conference Name":
            "ACM Transactions on Multimedia Computing, Communications, and Applications (TOMM)",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "Minghui Hu, Qiushi Shi, P.N. Suganthan, M. Tanveer",
        Title: "Adaptive Ensemble Variants of Random Vector Functional Link Networks",
        "Book/ Journal/ Book chapter/ Conference Name":
            "27th International Conference on Neural Information Processing (ICONIP 2020) (Core Rank A)",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "M.A. Ganaie, M. Tanveer, P.N. Suganthan",
        Title: "Minimum Variance Embedded Random Vector Functional Link Network",
        "Book/ Journal/ Book chapter/ Conference Name":
            "27th International Conference on Neural Information Processing (ICONIP 2020) (Core Rank A).",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "A.H. Rashid, I. Razzak, M. Tanveer",
        Title: "RipNet: A Lightweight One-class Deep Neural Network for the Identification of RIP Currents",
        "Book/ Journal/ Book chapter/ Conference Name":
            "27th International Conference on Neural Information Processing (ICONIP 2020) (Core Rank A).",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. M. Tanveer",
        Department: "Mathematics",
        "Author(s)": "M.A. Ganaie, M. Tanveer and P.N. Suganthan",
        Title: "Regularized Robust Fuzzy Least Squares Twin Support Vector Machine for Class Imbalance Learning",
        "Book/ Journal/ Book chapter/ Conference Name": "WCCI (IJCNN) 2020 (Core Rank A).",
        "Volume/ Page": "-",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Dan Sathiaraj",
        Department: "Mechanical Engineering",
        "Author(s)": "G Dan Sathiaraj, Aurimas Pukenas, Werner Skrotzki",
        Title: "Texture Formation in Face-centered Cubic High-entropy Alloys",
        "Book/ Journal/ Book chapter/ Conference Name": "Journal of Alloys and Compounds",
        "Volume/ Page": "826",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Dan Sathiaraj",
        Department: "Mechanical Engineering",
        "Author(s)": "G Dan Sathiaraj, Rajib Kalsar, Satyam Suwas, Werner Skrotzki",
        Title: "Effect of Stacking Fault Energy on Microstructure and Texture Evolution during the Rolling of Non-Equiatomic CrMnFeCoNi High-Entropy Alloys",
        "Book/ Journal/ Book chapter/ Conference Name": "Crystals",
        "Volume/ Page": "10",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Rupesh S. Devan",
        Department: "MEMS",
        "Author(s)": "N. Kitchamsetti, Y. R. Ma, P. M. Shirage, and R. S. Devan*,",
        Title: "Mesoporous Perovskite of Interlocked Nickel Titanate Nanoparticles for Efficient Electrochemical Supercapacitor Electrode",
        "Book/ Journal/ Book chapter/ Conference Name": "J. Alloys and Compounds",
        "Volume/ Page": "833/155134",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Rupesh S. Devan",
        Department: "MEMS",
        "Author(s)":
            "S. M. Ansari, K. C. Ghosh, R. S. Devan, D. Sen, P. U. Sastry, Y. D. Kolekar, and C. V. Ramana,",
        Title: "Eco-Friendly Synthesis, Crystal Chemistry, and Magnetic Properties of Manganese-Substituted CoFe2O4 Nanoparticles",
        "Book/ Journal/ Book chapter/ Conference Name": "ACS Omega",
        "Volume/ Page": "5/19315-19330",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Rupesh S. Devan",
        Department: "MEMS",
        "Author(s)":
            "S. Khirid, R. Biswas, S. Meena, R. A. Patil, Y. R. Ma, R. S. Devan, R. S. Dhayal, and K. K. Haldar",
        Title: "Designing of Ag2S Nanowires from a Single-Source Molecular Precursor [(PPh3)2AgS2P(OiPr)2] for Hydrogen Evolution Reaction",
        "Book/ Journal/ Book chapter/ Conference Name": "ChemistrySelect",
        "Volume/ Page": "5/10593-10598",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Dudekula Althaf Basha",
        Department: "MEMS",
        "Author(s)": "R Mahjoub, DA Basha, A Singh, M Ferry, N Stanford",
        Title: "The Contrasting Fracture Behaviour of Twin Boundaries and General Boundaries–A First Principles Study Based on Experimental Observation.",
        "Book/ Journal/ Book chapter/ Conference Name": "Materials Science and Engineering A",
        "Volume/ Page": "781",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Dudekula Althaf Basha",
        Department: "MEMS",
        "Author(s)": "D. Singh, DA Basha, A Singh, R. Devan, S.S. Hosmani",
        Title: "Microstructural and Passivation Response of Severely Deformed AISI 304 Steel Surface: The Role of Surface Mechanical Attrition Treatment",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Journal of Materials Engineering and Performance",
        "Volume/ Page": "29",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Debajyoti Sarkar",
        Department: "Physics",
        "Author(s)": "Daniel Kabat, Gilad Lifschytz, Phuc Nguyen and Debajyoti Sarkar",
        Title: "Endpoint Contributions to Excited State Modular Hamiltonians",
        "Book/ Journal/ Book chapter/ Conference Name": "JHEP",
        "Volume/ Page": "12, 128",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Debajyoti Sarkar",
        Department: "Physics",
        "Author(s)": "Manus Visser and Debajyoti Sarkar",
        Title: "The First Law of Differential Entropy and Holographic Complexity",
        "Book/ Journal/ Book chapter/ Conference Name": "JHEP",
        "Volume/ Page": "11, 004",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Preeti Anand Bhobe",
        Department: "Physics",
        "Author(s)": "Tamalika Samanta, D. Salas, V. Srihari, I. Karaman, and P.A. Bhobe",
        Title: "Emergent Properties in the Natural Composite Ni2MnSb0.5Al0.5",
        "Book/ Journal/ Book chapter/ Conference Name": "J. Phy. D: Appl. Phys.",
        "Volume/ Page": "Vol. 53 225302",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Rajesh Kumar",
        Department: "Physics",
        "Author(s)":
            "Devesh K. Pathak Anjali Chaudhary Manushree Tanwar Uttam K. Goutam Herbert Pfnur Rajesh Kumar",
        Title: "Chronopotentiometric Deposition of Nanocobalt Oxide for Elec-trochromic Auxiliary Active Electrode Application",
        "Book/ Journal/ Book chapter/ Conference Name": "Physica Status Solidi-A",
        "Volume/ Page": "217, 2000173",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Rajesh Kumar",
        Department: "Physics",
        "Author(s)":
            "Anjali Chaudhary, Devesh Kumar Pathak, M Tanwar, Julian Koch, Herbert Pfnur and Rajesh Kum",
        Title: "Polythiophene-nanoWO3 Bilayer as Electrochromic Infrared Filter: A Transparent Heat Shield",
        "Book/ Journal/ Book chapter/ Conference Name": "J. Mater. Chem. C.",
        "Volume/ Page": "08, 1773",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)":
            "Minu Treesa Abraham, Neelima Satyam, Maria Alexandra Bulzinetti, Biswajeet Pradhan, Binh Thai Pham and Samuele Segoni",
        Title: "Using Field-Based Monitoring to Enhance the Performance of Rainfall Thresholds for Landslide Warning",
        "Book/ Journal/ Book chapter/ Conference Name": "Water, MDPI",
        "Volume/ Page": "In Press",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)":
            "Mehebub S, Binh Thai P, Manas S, Romulus C, Do Xuan T, Rabin C, Neelima Satyam , Huu Duy N, Tran Van P, Hiep Van L, Subodh C, G Areendran, Kashif I and Indra P.",
        Title: "Rainfall Induced Landslide Susceptibility Mapping Using Novel Hybrid Soft Computing Methods Based on Multi-layer Perceptron Neural Network Classifie",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Geocarto International, Taylor and Francis",
        "Volume/ Page": "In Press",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)": "Meghna Sharma, Neelima Satyam , and Krishna R. Reddy",
        Title: "State-of-the-Art Review of Emerging and Biogeotechnical Methods for LiquefactionMitigation in Sands",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Journal of Hazardous, Toxic, and Radioactive Waste, ASCE",
        "Volume/ Page": "25 (03120001)",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)":
            "Minu Treesa Abraham, Neelima Satyam , Biswajeet Pradhan, and Abdullah M. Alamri",
        Title: "IoT-Based Geotechnical Monitoring of Unstable Slopes for Landslide Early Warning in the Darjeeling Himalayas",
        "Book/ Journal/ Book chapter/ Conference Name": "Sensors, MDPI",
        "Volume/ Page": "20(9)",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)": "Meghna Sharma, Neelima Satyam and Krishna R. Reddy",
        Title: "Strength Enhancement and Lead Immobilization of Sand using Consortia of Bacteria and Blue-Green Algae",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Journal of Hazardous, Toxic, and Radioactive Waste, ASCE",
        "Volume/ Page": "24(04020049)",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)":
            "Minu Treesa Abraham, Neelima Satyam, Sai Kushal, Ascanio Rosi, Biswajeet Pradhan and Samuele Segoni",
        Title: "Rainfall Threshold Estimationand Landslide Forecasting for Kalimpong, India Using SIGMA Model",
        "Book/ Journal/ Book chapter/ Conference Name": "Water, MDPI",
        "Volume/ Page": "12 (4)",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)":
            "Minu Treesa Abraham , Neelima Satyam, Ascanio Rosi Biswajeet Pradhan and Samuele Segoni",
        Title: "The Selection of Rain Gauges and Rainfall Parameters in Estimating Intensity-Duration Thresholds for Landslide Occurrence: Case Study from Wayanad (India)",
        "Book/ Journal/ Book chapter/ Conference Name": "Water, MDPI",
        "Volume/ Page": "12",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)": "Nitin Tiwari, Neelima Satyam and Sanjay Kumar Shukla",
        Title: "An Experimental Study on Micro-structural and Geotechnical Characteristics of Expansive Clay Mixed with EPS Granules",
        "Book/ Journal/ Book chapter/ Conference Name": "Soils and Foundations, Elsevier",
        "Volume/ Page": "60(3)",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)":
            "Minu Treesa Abraham, Neelima Satyam, Biswajeet Pradhan and Abdullah M. Alamri",
        Title: "Forecasting of Landslides Using Rainfall Severity and Soil Wetness: A Probabilistic Approach for Darjeeling Himalayas",
        "Book/ Journal/ Book chapter/ Conference Name": "Water, MDPI",
        "Volume/ Page": "12 (3)",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)": "Meghna Sharma, Neelima Satyam and Krishna R. Reddy",
        Title: "Investigation of various Gram-positive Bacteria for MICP in Narmada Sand, India",
        "Book/ Journal/ Book chapter/ Conference Name":
            "International Journal of Geotechnical Engineering",
        "Volume/ Page": "15(2)",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)": "Abhirup Dikshit, Neelima Satyam, Biswajeet Pradhan and Sai Kushal",
        Title: "Estimating Rainfall Threshold and Temporal Probability for Landslide Occurrences in Darjeeling Himalayas",
        "Book/ Journal/ Book chapter/ Conference Name": "Geosciences, Springer",
        "Volume/ Page": "In Press",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Munir Ahmad Nayak",
        Department: "Civil Engineering",
        "Author(s)": "Munir Ahmad Nayak; Gabriele Villarini, Kate Cowles, Burhan Ul Wafa",
        Title: "Bayesian Hierarchical Models for the Frequency of Winter Heavy Precipitation Events over the Central United States: The Role of Atmospheric Rivers",
        "Book/ Journal/ Book chapter/ Conference Name": "Water Resources Research",
        "Volume/ Page": "56",
        Year: "2020",
    },
    {
        "Name of the Faculty": "Dr. Abhishek Rajput IIT Indore",
        Department: "Civil Engineering",
        "Author(s)": "A Rajput, JH Park, S Hwan Noh, J Kee Paik",
        Title: "Fresh and Sea Water Immersion Corrosion Testing on Marine Structural Steel at Low Temperature",
        "Book/ Journal/ Book chapter/ Conference Name": "Ships and Offshore Structures",
        "Volume/ Page": "15",
        Year: "2020",
    },
    {
        "Author(s)":
            "SK Nazmul Hasan MD Dostagir, Mahendra Kumar Awasthi, Ankit Kumar, Kavita Gupta, Silke Behrens, Abhijit Shrotri, and Sanjay K. Singh*",
        Title: "Selective Catalysis for Room-temperature Hydrogenation of Biomass-derived Compounds over Supported NiPd Catalysts in Water",
        "Book/ Journal/ Book chapter/ Conference Name": "ACS Sustainable Chem. Eng.,",
        "Volume/ Page": "7, 9352-9359",
        Year: "2019",
        Department: "Chemistry",
        "Name of the Faculty": "Prof. Sanjay Kumar Singh",
    },
    {
        "Name of the Faculty": "Dr. Shaikh M. Mobin",
        Department: "Chemistry",
        "Author(s)":
            "Ghann, William; Sharma, Vinay; Kang, Hyeonggon; Karim, Fahim; Richards, Balvin; Shaikh M. Mobin; Uddin, Jamal; Rahman, Mohammed M.; Hossain, Faruque; Kabir, Humayun",
        Title: "The Synthesis and Characterization of Carbon Dots and their Application in Dye Sensitized Solar Cell",
        "Book/ Journal/ Book chapter/ Conference Name": "INT. J. HYD. ENERG",
        "Volume/ Page": "44, 14580- 14587",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr. Shaikh M. Mobin",
        Department: "Chemistry",
        "Author(s)":
            "Tiwari, Pranav; Kaur, Navpreet; Sharma, Vinay; Kang, Hyeonggon; Uddin, Jamal; Shaikh M. Mobin",
        Title: "Cannabis sativa-Derived Carbon Dots Co-Doped with N-S: Highly Efficient Nanosensors for Temperature and Vitamin B12",
        "Book/ Journal/ Book chapter/ Conference Name": "New J. Chem.",
        "Volume/ Page": "43, 17058-17068",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr. Shaikh M. Mobin",
        Department: "Chemistry",
        "Author(s)":
            "Gupta, Gajendra; Kumari, Pratibha; Ryu, Ji Yeon; Lee, Junseong; Shaikh M. Mobin; Lee, Chang Yeon",
        Title: "Mitochondrial Localization of Highly Fluorescent and Photostable BODIPY-Based Ruthenium(II), Rhodium(III), and Iridium(III) Metal Complexes",
        "Book/ Journal/ Book chapter/ Conference Name": "Inorg. Chem.",
        "Volume/ Page": "58, 8587-8595",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "IP Core Protection and Hardware-Assisted Security for Consumer Electronics",
        "Book/ Journal/ Book chapter/ Conference Name": "IET Book",
        "Volume/ Page": "ISBN\n978-1-78561-799-7",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "Introduction to IP Core Protection and Hardware-Assisted Security of Consumer Electronics",
        "Book/ Journal/ Book chapter/ Conference Name": "Book chapter, IET Book",
        "Volume/ Page": "e-ISBN\n978-1-78561-800-0",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Saraju P. Mohanty, Anirban Sengupta,",
        Title: "Security in Consumer Electronics and Internet of Things (IoT)",
        "Book/ Journal/ Book chapter/ Conference Name": "Book chapter, IET Book",
        "Volume/ Page": "e-ISBN\n978-1-78561-800-0",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "Trojan Security Aware DSP IP Core and Integrated Circuits",
        "Book/ Journal/ Book chapter/ Conference Name": "Book chapter, IET Book",
        "Volume/ Page": "e-ISBN\n978-1-78561-800-0",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "IP Core and Integrated Circuit Protection using Robust Watermarking",
        "Book/ Journal/ Book chapter/ Conference Name": "Book chapter, IET Book",
        "Volume/ Page": "e-ISBN\n978-1-78561-800-0",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "Symmetrical Protection of DSP IP Core and Integrated Circuits using Fingerprinting and Watermarking",
        "Book/ Journal/ Book chapter/ Conference Name": "Book chapter, IET Book",
        "Volume/ Page": "e-ISBN\n978-1-78561-800-0",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "Obfuscation of JPEG CODEC IP Core for CE Devices",
        "Book/ Journal/ Book chapter/ Conference Name": "Book chapter, IET Book",
        "Volume/ Page": "e-ISBN\n978-1-78561-800-0",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "Computational Forensic Engineering for Resolving Ownership Conflict of DSP IP Core",
        "Book/ Journal/ Book chapter/ Conference Name": "Book chapter, IET Book",
        "Volume/ Page": "e-ISBN\n978-1-78561-800-0",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "Structural Obfuscation of DSP Cores used in CE Devices",
        "Book/ Journal/ Book chapter/ Conference Name": "Book chapter, IET Book",
        "Volume/ Page": "e-ISBN\n978-1-78561-800-0",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "Functional Obfuscation of DSP Cores used in CE Devices",
        "Book/ Journal/ Book chapter/ Conference Name": "Book chapter, IET Book",
        "Volume/ Page": "e-ISBN\n978-1-78561-800-0",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "Advanced Encryption Standard (AES) and its Hardware Watermarking for Ownership Protection",
        "Book/ Journal/ Book chapter/ Conference Name": "Book chapter, IET Book",
        "Volume/ Page": "e-ISBN\n978-1-78561-800-0",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr. Pritee Sharma",
        Department: "School of HSS",
        "Author(s)": "Gerlak, A., et al.",
        Title: "Issues and Geography in Earth System Governance",
        "Book/ Journal/ Book chapter/ Conference Name":
            'In: M.Betsill, T.Benney and A. Gerlak "Agency in Earth System Governance Book chapter',
        "Volume/ Page": "Book Chapter, Published by Cambridge University Press.",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr. Swadesh Kumar Sahoo",
        Department: "Mathematics",
        "Author(s)": "S. Ponnusamy, S. K. Sahoo, and T. Sugawa,",
        Title: "Hornich Operations on Functions of Bounded Boundary Rotations and Order Alpha",
        "Book/ Journal/ Book chapter/ Conference Name": "Comput. Methods Funct. Theory",
        "Volume/ Page": "19 (3), 455 - 472.",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr.I.A.Palani",
        Department: "Mechanical Engineering",
        "Author(s)":
            "Nandini Patra, Siva Krishna Karuturi, Nilesh J Vasa, D Nakamura, M Higashihata, Vipul Singh, IA Palani",
        Title: "Influence of Ni, Ti and NiTi Alloy Nanoparticles on Hydrothermally Grown ZnO Nanowires for Photoluminescence Enhancement",
        "Book/ Journal/ Book chapter/ Conference Name": "Journal of Alloys and Compounds",
        "Volume/ Page": "770",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr. Rupesh S. Devan",
        Department: "MEMS",
        "Author(s)":
            "N. Kitchamsetti, P. R. Chikate, R. A. Patil, Y. R. Ma, P. M. Shirage, and R. S. Devan",
        Title: "Perforated Mesoporous NiO Nanostructures for Enhanced Pseudocapacitive performance with Ultra-High Rate Capability and High Energy Density",
        "Book/ Journal/ Book chapter/ Conference Name": "CrystEngComm",
        "Volume/ Page": "21/7130-7140",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr. Rupesh S. Devan",
        Department: "MEMS",
        "Author(s)":
            "N. Kitchamsetti, R. S. Kalubarme, P. R. Chikate, C. J. Park, Y. R. Ma, P. M. Shirage, and R. S. Devan",
        Title: "An Investigation on the Effect of Li-ion Cycling on the Vertically Aligned Brookite TiO2 Nanostructures",
        "Book/ Journal/ Book chapter/ Conference Name": "Chemistry Select",
        "Volume/ Page": "4/6620-6626",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Rajesh Kumar",
        Department: "Physics",
        "Author(s)":
            "Suryakant Mishra, P Yogi, A Chaudhary, D Pathak, S Saxena, A Krylov, P.R. Sagdeo & Rajesh Kumar Ind. J. Phys., 93, 927 (2019)",
        Title: "Understanding Perceived-Color through Gradual Spectroscopic Variations in Electrochromism",
        "Book/ Journal/ Book chapter/ Conference Name": "Ind. J. Phys.",
        "Volume/ Page": "93, 927",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr. Abhishek Rajput IIT Indore",
        Department: "Civil Engineering",
        "Author(s)": "Abhishek Rajput, Meral Ak, Sang Jin Kim , Sung Hwan Noh, J Kee Paik",
        Title: "Effects of the Surface Preparation on the Life of Epoxy Coating in Steel Ship Plates: An Experimental Study",
        "Book/ Journal/ Book chapter/ Conference Name": "Ships and Offshore Structures",
        "Volume/ Page": "14",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Dr. Saikat Sarkar",
        Department: "Civil Engineering",
        "Author(s)": "M Nowruzpour, S Sarkar, JN Reddy, D Roy",
        Title: "A Derivative-free Upscaled Theory for Analysis of Defects",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Journal of the Mechanics and Physics of Solids",
        "Volume/ Page": "122, 489-501",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Prof. Bhargav Vaidya",
        Department: "Discipline of Astronomy, Astrophysics and Space Engineering (DAASE)",
        "Author(s)": "Mignone, A., Flock, M., & Vaidya, B.",
        Title: "A Particle Module for the PLUTO Code. III. Dust",
        "Book/ Journal/ Book chapter/ Conference Name":
            "The Astrophysical Journal Supplement Series",
        "Volume/ Page": "244; 38;",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Prof. Bhargav Vaidya",
        Department: "Discipline of Astronomy, Astrophysics and Space Engineering (DAASE)",
        "Author(s)":
            "Singh, K. K., Meintjes, P. J., van Soelen, B., Ramamonjisoa, F. A., & Vaidya, B.",
        Title: "Optical polarization properties of February 2010 outburst of the blazar Mrk 421",
        "Book/ Journal/ Book chapter/ Conference Name": "Astrophysics and Space Science",
        "Volume/ Page": "364; 88",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Prof. Bhargav Vaidya",
        Department: "Discipline of Astronomy, Astrophysics and Space Engineering (DAASE)",
        "Author(s)":
            "Vaidya Bhargav, Mignone, Andrea, Bodo, Gianluigi, Rossi, Paola, & Massaglia, Silvano",
        Title: "A Particle Module for the PLUTO Code. II. Hybrid Framework for Modeling Nonthermal Emission from Relativistic Magnetized Flows",
        "Book/ Journal/ Book chapter/ Conference Name": "The Astrophysical Journal",
        "Volume/ Page": "865; 144",
        Year: "2019",
    },
    {
        "Name of the Faculty": "Prof. Bhargav Vaidya",
        Department: "Discipline of Astronomy, Astrophysics and Space Engineering (DAASE)",
        "Author(s)": "Mignone, A., Bodo, G., Vaidya, B., & Mattia, G.",
        Title: "A Particle Module for the PLUTO Code. I. An Implementation of the MHD-PIC Equations",
        "Book/ Journal/ Book chapter/ Conference Name": "The Astrophysical Journal",
        "Volume/ Page": "859; 13;",
        Year: "2018",
    },
    {
        "Author(s)":
            "Rajnish Kumar Singh, Fengchao Lang, Yonggang Pei, Hem Chandra Jha, Erle S. Robertson",
        Title: "Metabolic reprogramming of Kaposi’s sarcoma associated herpes virus infected B-cells in hypoxia",
        "Book/ Journal/ Book chapter/ Conference Name": "Plos Pathogens",
        "Volume/ Page": "doi.org/10.1371/journal.ppat.1007062",
        Year: "2018",
        "Name of the Faculty": "Dr. Hem Chandra Jha",
        Department: "BSBE",
    },
    {
        "Author(s)":
            "Saurabh Pandey, Hem Chandra Jha, Sanket Kumar Shukla, Meghan K. Shirley, Erle S. Robertson",
        Title: "Epigenetic Regulation of Tumor Suppressors by Helicobacter pylori Enhances EBV-Induced Proliferation of Gastric Epithelial Cells",
        "Book/ Journal/ Book chapter/ Conference Name": "mBio",
        "Volume/ Page": "10.1128/mBio.00649-18 ",
        Year: "2018",
        "Name of the Faculty": "Dr. Hem Chandra Jha",
        Department: "BSBE",
    },
    {
        "Author(s)":
            "Fengchao Lang, Zhiguo Sun, Yonggang Pei, Rajnish Kumar Singh, Hem Chandra Jha, Erle S. Robertson",
        Title: "Shugoshin 1 is dislocated by KSHV-encoded LANA inducing aneuploidy",
        "Book/ Journal/ Book chapter/ Conference Name": "Plos Pathogens",
        "Volume/ Page": "doi.org/10.1371/journal.ppat.1007253",
        Year: "2018",
        "Name of the Faculty": "Dr. Hem Chandra Jha",
        Department: "BSBE",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)": "Pati R, Shevtsov M., and Sonawane A.",
        Title: "Nanoparticle Vaccines Against Infectious Diseases.",
        "Book/ Journal/ Book chapter/ Conference Name": "Frontiers in Immunology",
        "Volume/ Page": "9, p.2224",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Prof. Suman Mukhopadhyay",
        Department: "Chemistry",
        "Author(s)":
            "Bidyut Kumar Kundu, Vaishali Chhabra, Novina Malviya, Rakesh Ganguly, Gopal S Mishra, Suman Mukhopadhyay",
        Title: "Zeolite Encapsulated Host-guest Cu(II) Schiff Base Complexes: Superior Activity Towards Oxidation Reactions over Homogenous Catalytic Systems,",
        "Book/ Journal/ Book chapter/ Conference Name": "Microporous Mesoporous Mater",
        "Volume/ Page": "271/100",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Author(s)": "Poh, S., Venkatesh, C., Ayala-Lopez, W., Putt, K. S., Low, P. S.,",
        Title: "Selective Liposome Targeting of Folate Receptor Positive Immune Cells in Inflammatory Diseases",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Nanomedicine: Nanotechnology, Biology, and Medicine",
        "Volume/ Page": "14, 1033–1043",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "BSBE",
        "Author(s)":
            "Rangasamy, L., Venkatesh C., Kanduluru, A. K., Srinivasarao, M., Bandara, N. A., You, F., Orellana, E. A., Kasinski, A. L., Low, P. S.",
        Title: "New Mechanism for Release of Endosomal Contents: Osmotic Lysis Via Nigericin-Mediated K+/H+ Exchange",
        "Book/ Journal/ Book chapter/ Conference Name": "Bioconjugate Chem.,",
        "Volume/ Page": "29, 1047–1059",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Dipanjan Roy, Saraju P Mohanty, Peter Corcoran",
        Title: "Low-Cost Obfuscated JPEG CODEC IP Core for Secure CE Hardware",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Transactions on Consumer Electronics",
        "Volume/ Page": "vol. 64, pp. 365-374",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Dipanjan Roy, Saraju P Mohanty",
        Title: "Triple-Phase Watermarking for Reusable IP Core Protection during Architecture Synthesis",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Transactions on CAD",
        "Volume/ Page": "vol. 37, pp. 742 - 755",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P Mohanty, Fernando Pescador, Peter Corcoran",
        Title: "Multi-Phase Obfuscation of Fault Secured DSP Designs with Enhanced Security Feature",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Transactions on Consumer Electronics",
        "Volume/ Page": "vol. 64, pp. 356-364",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Dipanjan Roy, Saraju Mohanty, Peter Corcoran",
        Title: "A Framework for Hardware Efficient Reusable IP Core for Grayscale Image CODEC",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Access Journal",
        "Volume/ Page": "vol. 6, 871 - 882",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, SP Mohanty, Garrett Rose",
        Title: "Hardware - Assisted Design for Security and Protection of Consumer Electronics",
        "Book/ Journal/ Book chapter/ Conference Name": "IET Computers and Digital Techniques",
        "Volume/ Page": "vol. 12, pp. 249-250",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "Editorial: From the Editors Desk",
        "Book/ Journal/ Book chapter/ Conference Name": "EEE VLSI Circuits & Systems Letter",
        "Volume/ Page": "vol. 4, issue. 1, pp. 1",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "Editorial: From the Editors Desk",
        "Book/ Journal/ Book chapter/ Conference Name": "EEE VLSI Circuits & Systems Letter",
        "Volume/ Page": "vol. 4, issue. 2, pp. 1",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "Editorial: From the Editors Desk",
        "Book/ Journal/ Book chapter/ Conference Name": "EEE VLSI Circuits & Systems Letter",
        "Volume/ Page": "vol. 4, issue. 3, pp. 1",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "Editorial: From the Editors Desk",
        "Book/ Journal/ Book chapter/ Conference Name": "EEE VLSI Circuits & Systems Letter",
        "Volume/ Page": "vol. 4, issue. 4, pp. 1",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)":
            "Anirban Sengupta, Shubha Neema, Pallabi Sarkar, Sri Harsha, Saraju P Mohanty, Mrinal Kanti Naskar",
        Title: "Obfuscation of Fault Secured DSP Design through Hybrid Transformation",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Proceedings of 17th IEEE Computer Society Annual Symposium on VLSI (ISVLSI), Hongkong",
        "Volume/ Page": "pp. 732 - 737",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta,, Saraju P. Mohanty",
        Title: "Functional Obfuscation of DSP cores using Robust Logic Locking and Encryption",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Proceedings of 17th IEEE Computer Society Annual Symposium on VLSI (ISVLSI), Hongkong",
        "Volume/ Page": "pp. 709 - 713",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr. Vinay Kumar Gupta",
        Department: "Mathematics",
        "Author(s)": "Rana, A. S., Gupta, V. K. and Struchtrup, H.",
        Title: "Coupled Constitutive Relations: a Second Law Based Higher Order Closure for Hydrodynamics",
        "Book/ Journal/ Book chapter/ Conference Name": "Proceedings of the Royal Society A",
        "Volume/ Page": "474, 20180323",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr. Vinay Kumar Gupta",
        Department: "Mathematics",
        "Author(s)": "Gupta, V. K., Shukla, P. and Torrilhon, M.",
        Title: "Higher-order Moment Theories for Dilute Granular Gases of Smooth hard Spheres",
        "Book/ Journal/ Book chapter/ Conference Name": "Journal of Fluid Mechanics",
        "Volume/ Page": "836, 451-501",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr. Vinay Kumar Gupta",
        Department: "Mathematics",
        "Author(s)": "Gupta, V. K. and Torrilhon, M.",
        Title: "Heat Flux in Binary Gas Mixtures Confined Between Two Parallel Plates via Moment Equations",
        "Book/ Journal/ Book chapter/ Conference Name": "AIP Conference Proceedings",
        "Volume/ Page": "2132, 120003",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr.I.A.Palani",
        Department: "Mechanical Engineering",
        "Author(s)":
            "K Akash, SS Mani Prabu, Tobias Gustmann, S Jayachandran, Simon Pauly, IA Palani",
        Title: "Enhancing the Life Cycle Behaviour of Cu-Al-Ni Shape Memory Alloy Bimorph by Mn Addition",
        "Book/ Journal/ Book chapter/ Conference Name": "Materials Letters",
        "Volume/ Page": "226",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr. Rupesh S. Devan",
        Department: "MEMS",
        "Author(s)":
            "A. K. Sharma, R. S. Devan, M. Arora, R. Kumar, Y. R. Ma, and J. Nagendra Babu",
        Title: "Reductive-co-precipitated Cellulose Immobilized Zerovalent Iron Nanoparticles in Ionic Liquid/Water for Cr(VI) Adsorption",
        "Book/ Journal/ Book chapter/ Conference Name": "Cellulose",
        "Volume/ Page": "25/5259-5275",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr. Rupesh S. Devan",
        Department: "MEMS",
        "Author(s)":
            "P. R. Chikate, K. D. Daware, D. Gavhane, Y. R. Ma, R. J. Choudhary, S. I. Patil, M. A. More, D. M. Phase, S. W. Gosavi, P. M. Shirage, and R. S. Devan",
        Title: "Controlled Hetero-architectures of Au Nanoparticles Decorated ZnO Nanowires for Enhanced Field Electron Emission Displays",
        "Book/ Journal/ Book chapter/ Conference Name": "Chemistry Select",
        "Volume/ Page": "3/) 7891-7899",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr. Rupesh S. Devan",
        Department: "MEMS",
        "Author(s)":
            "P. R. Chikate, P. K. Bankar, R. J. Choudhary, Y. R. Ma, S. I. Patil, M. A. More, D. M. Phase, P. M. Shirage, and R. S. Devan",
        Title: "Spitzer Shaped ZnO Nanostructures for Enhancement of Field Electron Emission Behaviors",
        "Book/ Journal/ Book chapter/ Conference Name": "RSC Advances",
        "Volume/ Page": "8/21664-21670",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr. Rupesh S. Devan",
        Department: "MEMS",
        "Author(s)": "P. Dubey, N. Kaurav, R. S. Devan, G. S. Okram, and Y. K. Kuo",
        Title: "The Effect of Stoichiometry on the Structural, Thermal and Electronic Properties of Thermally Decomposed Nickel Oxide",
        "Book/ Journal/ Book chapter/ Conference Name": "RSC Advances",
        "Volume/ Page": "8/5882-5890",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr. Rupesh S. Devan",
        Department: "MEMS",
        "Author(s)":
            "T. S. Bhat, A. V. Shinde, R. S. Devan, A. M. Teli, Y. R. Ma, J. H. Kim, and P. S. Patil",
        Title: "Structural and Electrochemical Analysis of Chemically Synthesized Microcubic Architectured lead Selenide Thin Films",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Applied Physics A: Materials Science & Processing",
        "Volume/ Page": "Dec-34",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Rajesh Kumar",
        Department: "Physics",
        "Author(s)":
            "A. S. Krylov, S. N. Sofronova, I. A. Gudim, S. N. Krylova, Rajesh Kumar and A. N. Vtyurin",
        Title: "Manifestation of Magnetoelastic Interactions in Raman Spectra Of HoxNd1􀀀xFe3(BO3)4 Crystals",
        "Book/ Journal/ Book chapter/ Conference Name": "J. Adv. Dielect.",
        "Volume/ Page": "08, 1850011",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Rajesh Kumar",
        Department: "Physics",
        "Author(s)":
            "Manushree Tanwar, Priyanka Yogi, Simran Lambora, Suryakant Mishra, Shailendra K. Saxena, Pankaj R Sagdeo, lexander S. Krylov, Rajesh Kumar",
        Title: "Generalization of Phonon Connement Model for Interpretation of Raman Line-Shape from Nano- Silicon",
        "Book/ Journal/ Book chapter/ Conference Name": "Adv. Mater. Proc. Technol.",
        "Volume/ Page": "04, 227",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr. Neelima Satyam",
        Department: "Civil Engineering",
        "Author(s)": "Abhirup Dikshit, Neelima Satyam, Ikuo Towhata",
        Title: "Early Warning System using Tilt Sensors in Chibo Kalimpong, Darjeeling Himalayas, India",
        "Book/ Journal/ Book chapter/ Conference Name": "Natural Hazards",
        "Volume/ Page": "94(2)",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Munir Ahmad Nayak",
        Department: "Civil Engineering",
        "Author(s)": "Munir Ahmad Nayak; Jonathan D. Herman; Scott Steinschneider",
        Title: "Balancing Flood Risk and Water Supply in California: Policy Search Integrating Short‐term Forecast Ensembles with Conjunctive Use",
        "Book/ Journal/ Book chapter/ Conference Name": "Water Resources Research",
        "Volume/ Page": "54",
        Year: "2018",
    },
    {
        "Name of the Faculty": "Dr. Ananya Ghoshal",
        Department: "School of HSS",
        "Author(s)":
            "Jason M. Kelly, Fiona P. McDonald, Alejandro Camargo, Amelia Moore, Mark Kesling, Ananya Ghoshal,George Marcus, Paul Stoller,Dominic Boyer, Serenella Iovino, Rebecca Ballestra, Jim Enote, Eduardo S. Brondizio, Ignatius Gutsa, Cymene Howe , Sue Jackson and Phil Scarpino. The book is edited by Jason M. Kelly and Fiona P. McDonald.",
        Title: "An Anthropocene Primer (Anthropoceneprimer.Org) a Born-Digital, Open Access, Open Peer Review Book Hosted Online by IUPUI. ",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Version 2.0 of the Book is in process and a Hard Copy of the Book with Indiana University Press is also forthcoming.",
        "Volume/ Page": "Version 1.0 published,\nVersion 2.0 forthcoming",
        Year: "2017 onwards (Cont.)",
    },
    {
        "Name of the Faculty": "Prof. Bhargav Vaidya",
        Department: "Discipline of Astronomy, Astrophysics and Space Engineering (DAASE)",
        "Author(s)":
            "Vaidya, Bhargav, Prasad, Deovrat, Mignone, Andrea, Sharma, Prateek, & Rickler, Luca",
        Title: "Scalable explicit implementation of anisotropic diffusion with Runge-Kutta-Legendre super-time stepping",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Monthly Notices of the Royal Astronomical Society",
        "Volume/ Page": "472; 3147-3160",
        Year: "2017",
    },
    {
        "Author(s)":
            "Yonggang Pei, Shuvomoy Banerjee, Hem Chandra Jha, Zhiguo Sun, Erle S. Robertson",
        Title: "An essential EBV latent antigen 3C binds Bcl6 for targeted degradation and cell proliferation",
        "Book/ Journal/ Book chapter/ Conference Name": "Plos Pathogens",
        "Volume/ Page": "doi.org/10.1371/journal.ppat.1006500",
        Year: "2017",
        "Name of the Faculty": "Dr. Hem Chandra Jha",
        Department: "BSBE",
    },
    {
        "Name of the Faculty": "Prof. Suman Mukhopadhyay",
        Department: "Chemistry",
        "Author(s)":
            "Manideepa Saha, Komal M. Vyas, Luisa, M. D. R. S. Martins, Nuno M. R. Martins, Armando J. L. Pombeiro, Shaikh M. Mobin, D, Bhattacherjee, Krishna, P. Bhabak, Suman Mukhopadhyay",
        Title: "Copper(II) Tetrazolato Complexes : Role in Oxidation Catalysis and Binding",
        "Book/ Journal/ Book chapter/ Conference Name": "Polyhedron",
        "Volume/ Page": "132/53",
        Year: "2017",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Author(s)":
            "Poh, S., Venkatesh, C., Lindsay, K., Ayala-Lopez, W., Balasubramanian, V., Putt, K. S., Low, P. S.,",
        Title: "Folate-conjugated Liposomes Target and Deliver Therapeutics to Immune Cells in a Rat Model of Rheumatoid Arthritis",
        "Book/ Journal/ Book chapter/ Conference Name": "Nanomedicine(Lond.),",
        "Volume/ Page": "12, 2441–2451",
        Year: "2017",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Dipanjan Roy, Saraju Mohanty, Peter Corcoran",
        Title: "DSP Design Protection in CE through Algorithmic Transformation Based Structural Obfuscation",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Transactions on Consumer Electronics",
        "Volume/ Page": "vol. 63, pp. 467 - 476",
        Year: "2017",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Sandip Kundu",
        Title: "Securing IoT Hardware: Threat Models and Reliable, Low-power Design Solutions",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Transactions on VLSI",
        "Volume/ Page": "vol. 25, pp.3265 - 3267",
        Year: "2017",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "S. P. Mohanty, A. Sengupta, P. Guturu, and E. Kougianos",
        Title: "Everything You Want to Know About Watermarking: From Paper Marks to Hardware Protection",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Transactions on Consumer Electronics",
        "Volume/ Page": "vol. 7, pp. 83--91",
        Year: "2017",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "A. Sengupta, S. Bhadauria, S. P. Mohanty",
        Title: "TL-HLS: Methodology for Low Cost Hardware Trojan Security Aware Scheduling with Optimal Loop Unrolling Factor during High Level Synthesis",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Transactions on CAD",
        "Volume/ Page": "vol. 36, pp. 655 – 668",
        Year: "2017",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saumya Bhadauria, and Saraju P. Mohanty",
        Title: "Low Cost Security Aware High Level Synthesis Methodology",
        "Book/ Journal/ Book chapter/ Conference Name": "IET Computers & Digital Techniques",
        "Volume/ Page": "vpl. 11, pp. 68 - 79",
        Year: "2017",
    },
    {
        "Name of the Faculty": "Dr. Pritee Sharma",
        Department: "School of HSS",
        "Author(s)": "Narsimulu, A. et.al.",
        Title: "Technology Transfer for Clean Energy Access: Opportunities and Challenges",
        "Book/ Journal/ Book chapter/ Conference Name": "EPFL Workshop Monitor",
        "Volume/ Page":
            "lk https://infoscience.epfl.ch/record/229950/files/170718-TTM_Workshop_Takeaways.pdf",
        Year: "2017",
    },
    {
        "Name of the Faculty": "Dr. Charitha Cherugondi",
        Department: "Mathematics",
        "Author(s)": "C. Charitha, Joydeep Dutta and D. Russell Luke",
        Title: "Lagrange Multipliers, (exact) Regularization and Error Bounds for Monotone Variational Inequalities",
        "Book/ Journal/ Book chapter/ Conference Name": "Mathematical Programming",
        "Volume/ Page": "-",
        Year: "2017",
    },
    {
        "Name of the Faculty": "Dr. Swadesh Kumar Sahoo",
        Department: "Mathematics",
        "Author(s)": "Z. Ibragimov, M. R. Mohapatra, S. K. Sahoo, and X.-H. Zhang,",
        Title: "Geometry of the Cassinian Metric and its Inner Metric,",
        "Book/ Journal/ Book chapter/ Conference Name": "Bull. Malaysian Math. Sci. Soc.",
        "Volume/ Page": "40 (1), 361 - 372",
        Year: "2017",
    },
    {
        "Name of the Faculty": "Dr. Swadesh Kumar Sahoo",
        Department: "Mathematics",
        "Author(s)": "Jiaolong Chen, Peijin Li, Swadesh Kumar Sahoo, and Xiantao Wang,",
        Title: "On the Lipschitz Continuity of Certain Quasiregular Mappings between Smooth Jordan Domains",
        "Book/ Journal/ Book chapter/ Conference Name": "Israel J. Math.",
        "Volume/ Page": "220, 453 - 478",
        Year: "2017",
    },
    {
        "Name of the Faculty": "Dr. Swadesh Kumar Sahoo",
        Department: "Mathematics",
        "Author(s)": "R. Klén, M. R. Mohapatra, and S. K. Sahoo,",
        Title: "Geometric Properties of the Cassinian Metric",
        "Book/ Journal/ Book chapter/ Conference Name": "Mathematische Nachrichten ",
        "Volume/ Page": "290, 1531 - 1543",
        Year: "2017",
    },
    {
        "Name of the Faculty": "Dr. Rupesh S. Devan",
        Department: "MEMS",
        "Author(s)":
            "R. S. Devan*, V. P. Thakare, V. V. Antad, P. R. Chikate, R. T. Khare, M. A. More, R. S. Dhayal, S. I. Patil, Y. R. Ma, and L. Schmidt-Mende",
        Title: "Nano-hetero-architectures of Two-dimensional MoS2@one-dimensional Brookite TiO2 Nanorods: Prominent Electron Field Emission for Displays",
        "Book/ Journal/ Book chapter/ Conference Name": "ACS Omega",
        "Volume/ Page": "2/2925-2934",
        Year: "2017",
    },
    {
        "Name of the Faculty": "Dr. Saikat Sarkar",
        Department: "Civil Engineering",
        "Author(s)": "S Sarkar, M Nowruzpour, JN Reddy, AR Srinivasa",
        Title: "A Discrete Lagrangian based Direct Approach to Macroscopic Modelling",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Journal of the Mechanics and Physics of Solids",
        "Volume/ Page": "98, 172-180",
        Year: "2017",
    },
    {
        "Author(s)":
            "Gurpreet Kaur, Rohit K. Rai., Deepika Tyagi, Xin Yao, Pei-Zhou Li, Xin-Chun Yang, Yanli Zhao, Qiang Xu, and Sanjay K. Singh*",
        Title: "Room-temperature synthesis of bimetallic Co-Zn based Zeolitic Imidazolate Frameworks in water for enhanced CO2 and H2 uptakes",
        "Book/ Journal/ Book chapter/ Conference Name": "J. Mater. Chem. A",
        "Volume/ Page": "4, 14887-15248",
        Year: "2016",
        Department: "Chemistry",
        "Name of the Faculty": "Prof. Sanjay Kumar Singh",
    },
    {
        "Author(s)": "Ajay Dhankhar, Rohit K. Rai, Deepika Tyagi, Xin Yao, and Sanjay K. Singh*",
        Title: "Synergistic Catalysis with MIL-101 Stabilized Highly Active Bimetallic NiPd and CuPd Alloy Nanoparticle Catalysts for C-C Coupling Reactions",
        "Book/ Journal/ Book chapter/ Conference Name": "Chemistry Select",
        "Volume/ Page": "1, 3223-3227",
        Year: "2016",
        Department: "Chemistry",
        "Name of the Faculty": "Prof. Sanjay Kumar Singh",
    },
    {
        "Author(s)":
            "Ambikesh D. Dwivedi, Chinky Binnani, Deepika Tyagi, Kuber S. Rawat, Pei-Zhou Li, Yanli Zhao, Shaikh M. Mobin, Biswarup Pathak, and Sanjay K. Singh*",
        Title: "Troponate-/Aminotroponate Ruthenium-Arene complexes: Synthesis, Structure and Ligand Tuned Mechanistic Pathway for Direct C-H bond Arylation with Arylchlorides in water,",
        "Book/ Journal/ Book chapter/ Conference Name": "Inorganic Chemistry",
        "Volume/ Page": "55, 6739-6749",
        Year: "2016",
        Department: "Chemistry",
        "Name of the Faculty": "Prof. Sanjay Kumar Singh",
    },
    {
        "Author(s)":
            "Deepika Tyagi, Chinky Binnani, Rohit K. Rai, Ambikesh D. Dwivedi, Kavita Gupta, Pei-Zhou Li, Yanli Zhao and Sanjay K. Singh*",
        Title: "Ruthenium Catalysed Oxidative Homocoupling of Arylboronic Acids in Water: Ligand Tuned Reactivity and Mechanistic Study,",
        "Book/ Journal/ Book chapter/ Conference Name": "Inorganic Chemistry",
        "Volume/ Page": "55, 6332-6343",
        Year: "2016",
        Department: "Chemistry",
        "Name of the Faculty": "Prof. Sanjay Kumar Singh",
    },
    {
        "Author(s)":
            "Rohit K. Rai, Kavita Gupta, Deepika Tyagi, Arup Mahata, Silke Behrens, Xinchun Yang, Qiang Xu, Biswarup Pathak and Sanjay K. Singh*",
        Title: "Access to highly active Ni-Pd bimetallic nanoparticle catalysts for C-C coupling reactions,",
        "Book/ Journal/ Book chapter/ Conference Name": "Catalysis Science and Technology",
        "Volume/ Page": "6, 5567-5579",
        Year: "2016",
        Department: "Chemistry",
        "Name of the Faculty": "Prof. Sanjay Kumar Singh",
    },
    {
        "Author(s)": "Hem C. Jha, Yonggang Pei and Erle S. Robertson",
        Title: "Epstein–Barr Virus: Diseases Linked to Infection and Transformation",
        "Book/ Journal/ Book chapter/ Conference Name": "Front. Microbiol",
        "Volume/ Page": "doi.org/10.3389/fmicb.2016.01602",
        Year: "2016",
        "Name of the Faculty": "Dr. Hem Chandra Jha",
        Department: "BSBE",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)":
            "Mohanty S, Dal Molin M, Ganguli G, Padhi A, JenaP, Selchow P, SenguptaS, Meuli M, Sander P and Sonawane A",
        Title: "Mycobacterium tuberculosis EsxO (Rv2346c) promotes bacillary survival by inducing oxidative stress mediated genomic instability in macrophages.",
        "Book/ Journal/ Book chapter/ Conference Name": "Tuberculosis (Edinb)",
        "Volume/ Page": "96:44-57",
        Year: "2016",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)":
            "Klionsky DJ, Abdelmohsen K, Abe A, Abedin MJ, Abeliovich H, Acevedo Arozena A. Sonawane A et al.,",
        Title: "Guidelines for the use and interpretation of assays for monitoring autophagy (3rd edition)",
        "Book/ Journal/ Book chapter/ Conference Name": "Autophagy",
        "Volume/ Page": "12(1):1-222",
        Year: "2016",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Author(s)":
            "Kumar, A., Venkatesh, C., Sakkarapalayam, M., Li, G., Sanchez-Cruz, P., Pinero, N. S., Low, P. S., Alegria, A. E.,",
        Title: "Synthesis and Evaluation of Folate-Conjugated Phenanthraquinones for Tumor-Targeted Oxidative Chemotherapy",
        "Book/ Journal/ Book chapter/ Conference Name": "O J. Med. Chem.",
        "Volume/ Page": "6,1–17",
        Year: "2016",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, F. Lombardi, S.P Mohanty, M. Zwolinski",
        Title: "Security and Reliability Aware System Design for Mobile Computing Systems",
        "Book/ Journal/ Book chapter/ Conference Name": "IEEE Access Journal",
        "Volume/ Page": "vol. 4, pp. 2976 - 2980",
        Year: "2016",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saraju P. Mohanty",
        Title: "High-Level Synthesis of Digital Circuits in the Nanoscale, Mobile Electronics Era",
        "Book/ Journal/ Book chapter/ Conference Name": "Book chapter, IET Book",
        "Volume/ Page": "e-ISBN\n9781785610004",
        Year: "2016",
    },
    {
        "Name of the Faculty": "Dr Anirban Sengupta",
        Department: "CSE",
        "Author(s)": "Anirban Sengupta, Saumya Bhadauria, Saraju Mohanty",
        Title: "Embedding Low Cost Optimal Watermark During High Level Synthesis for Reusable IP Core Protection",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Proc. of 48th IEEE Int'l Symposium on Circuits & Systems (ISCAS), Montreal",
        "Volume/ Page": "pp. 974 - 977",
        Year: "2016",
    },
    {
        "Name of the Faculty": "Dr. Pritee Sharma",
        Department: "School of HSS",
        "Author(s)": "Sharma, Pritee, Karanth, A., Burvery, M. and Dubey A.",
        Title: "The Economic Impact of Floods and Waterlogging on Low-Income Households: Lessons from Indore, India",
        "Book/ Journal/ Book chapter/ Conference Name": "IIED Working Paper",
        "Volume/ Page": "lk https://pubs.iied.org/10789IIED",
        Year: "2016",
    },
    {
        "Name of the Faculty": "Dr. Vinay Kumar Gupta",
        Department: "Mathematics",
        "Author(s)": "Gupta, V. K., Struchtrup, H. and Torrilhon, M.",
        Title: "Regularized Moment Equations for Binary Gas Mixtures: Derivation and Linear Analysis",
        "Book/ Journal/ Book chapter/ Conference Name": "Physics of Fluids",
        "Volume/ Page": "28, 042003",
        Year: "2016",
    },
    {
        "Name of the Faculty": "Dr. Charitha Cherugondi",
        Department: "Mathematics",
        "Author(s)": "Timo Aspelmeier, C. Charitha and D. Russell Luke",
        Title: "Local Linear Convergence of the ADMM/Douglas–Rachford Algorithms without Strong Convexity and Application to Statistical Imaging",
        "Book/ Journal/ Book chapter/ Conference Name": "SIAM Journal on Imaging Sciences",
        "Volume/ Page": "-",
        Year: "2016",
    },
    {
        "Name of the Faculty": "Dr. Charitha Cherugondi",
        Department: "Mathematics",
        "Author(s)": "Timo Aspelmeier, C. Charitha and D. Russell Luke",
        Title: "Local Linear Convergence of the ADMM/Douglas–Rachford Algorithms without Strong Convexity and Application to Statistical Imaging",
        "Book/ Journal/ Book chapter/ Conference Name": "SIAM Journal on Imaging Sciences",
        "Volume/ Page": "-",
        Year: "2016",
    },
    {
        "Name of the Faculty": "Dr. Swadesh Kumar Sahoo",
        Department: "Mathematics",
        "Author(s)": "P. Hästö, R. Klén, S. K. Sahoo, and M. Vuorinen,",
        Title: "Geometric Properties of φ-uniform Domains",
        "Book/ Journal/ Book chapter/ Conference Name": "The Journal of Analysis, ",
        "Volume/ Page": "24 (1), 57 - 66",
        Year: "2016",
    },
    {
        "Name of the Faculty": "Dr. Saikat Sarkar",
        Department: "Civil Engineering",
        "Author(s)": "S Sarkar, JN Reddy",
        Title: "Exploring the Source of Non-locality in the Euler–Bernoulli and Timoshenko Beam Models",
        "Book/ Journal/ Book chapter/ Conference Name":
            "International Journal of Engineering Science",
        "Volume/ Page": "104, 110-115",
        Year: "2016",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)":
            "Mohanty, S., Jagannathan, L., Ganguli G., Padhi, A., Alaridah, A., Saha, P., Godaly, G.,Banerjee, S., Sonawane, A.",
        Title: "Mycobacterium tuberculosis phosphoribosyltransferase modulates antibacterial effector functions leading to evasion of innate immunity in macrophages and Zebra fish.",
        "Book/ Journal/ Book chapter/ Conference Name": "J. Biol. Chem",
        "Volume/ Page": "290(21):13321-43",
        Year: "2015",
    },
    {
        "Name of the Faculty": "Dr. Shaikh M. Mobin",
        Department: "Chemistry",
        "Author(s)":
            "Das, Ankita; Ghosh, Prabir; Plebst, Sebastian; Schwederski, Brigitte; Shaikh M. Mobin; Kaim, Wolfgang; Lahiri, Goutam Kumar",
        Title: "Ancillary Ligand Control of 1 Electronic Structure in oBenzoquinonediimine-Ruthenium Complex Redox Series: Structures, Electron Paramagnetic Resonance (EPR), and Ultraviolet-Visible-Near-Infrared (UV-vis-NIR) Spectroelectrochemistry",
        "Book/ Journal/ Book chapter/ Conference Name": "Inorg. Chem.",
        "Volume/ Page": "54, 3376-3386",
        Year: "2015",
    },
    {
        "Name of the Faculty": "Prof. Suman Mukhopadhyay",
        Department: "Chemistry",
        "Author(s)": "Kelly Machadoa, Suman Mukhopadhyay, Gopal S Mishra.,",
        Title: "Silica Nano-particle Supported Copper and Vanadium Complexes Have Been Utilized for Study of Cyclohexane Oxidation Reaction",
        "Book/ Journal/ Book chapter/ Conference Name": "J Mol Catalysis A : Chemical",
        "Volume/ Page": "139/400",
        Year: "2015",
    },
    {
        "Name of the Faculty": "Prof. Suman Mukhopadhyay",
        Department: "Chemistry",
        "Author(s)": "Kelly Machadoa, Suman Mukhopadhyay, Gopal S Mishra.,",
        Title: "Silica Nano-particle Supported Copper and Vanadium Complexes Have Been Utilized for Study of Cyclohexane Oxidation Reaction",
        "Book/ Journal/ Book chapter/ Conference Name": "RSC Adv.",
        "Volume/ Page": "5/35675",
        Year: "2015",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Author(s)":
            "Roy, J., Nguygen, T. X., Kandaluru, A. K., Venkatesh, C., Lv, W., Reddy, P.V.N., Low, P. S., Cushman, M.",
        Title: "Dupa Conjugation of a Cytotoxic Indenoisoquinoline Topoisomerase I Inhibitor for Selective Prostate Cancer Cell Targeting",
        "Book/ Journal/ Book chapter/ Conference Name": "J. Med. Chem.",
        "Volume/ Page": "3094–3103",
        Year: "2015",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "BSBE",
        "Author(s)": "Poh, S., Venkatesh, C., Low, P. S.",
        Title: "Comparison of Nanoparticle Penetration into Solid Tumors and Sites of Inflammation: Studies Using Targeted and Non-Targeted Liposomes",
        "Book/ Journal/ Book chapter/ Conference Name": "Nanomedicine",
        "Volume/ Page": "10, 1439–1449",
        Year: "2015",
    },
    {
        "Name of the Faculty": "Dr. Vinay Kumar Gupta",
        Department: "Mathematics",
        "Author(s)": "Gupta, V. K. and Torrilhon, M.",
        Title: "Comparison of Relaxation Phenomena in Binary Gas-Mixtures of Maxwell Molecules and Hard Spheres",
        "Book/ Journal/ Book chapter/ Conference Name":
            "Computers and Mathematics with Applications",
        "Volume/ Page": "70, 73-88",
        Year: "2015",
    },
    {
        "Name of the Faculty": "Dr. Vinay Kumar Gupta",
        Department: "Mathematics",
        "Author(s)": "Gupta, V. K. and Torrilhon, M.",
        Title: "Higher Order Moment Equations for Rarefied Gas Mixtures",
        "Book/ Journal/ Book chapter/ Conference Name": "Proceedings of the Royal Society A",
        "Volume/ Page": "471, 20140754",
        Year: "2015",
    },
    {
        "Name of the Faculty": "Dr. Vinay Kumar Gupta",
        Department: "Mathematics",
        "Author(s)": "Gupta, V. K. and Torrilhon, M.",
        Title: "Heat Transfer in Binary Gas Mixtures Confined in a Lid-Driven Square Cavity",
        "Book/ Journal/ Book chapter/ Conference Name": "Procedia Engineering",
        "Volume/ Page": "127, 10-17",
        Year: "2015",
    },
    {
        "Name of the Faculty": "Dr.I.A.Palani",
        Department: "Mechanical Engineering",
        "Author(s)":
            "Tejendra Dixit, Anubha Bilgaiyan, IA Palani, Daisuke Nakamura, T Okada, Vipul Singh",
        Title: "Influence of Potassium Permanganate on the Anisotropic Growth and Enhanced UV Emission of ZnO Nanostructures using Hydrothermal Process for Optoelectronic Applications",
        "Book/ Journal/ Book chapter/ Conference Name": "Journal of Sol-Gel Science and Technology",
        "Volume/ Page": "75",
        Year: "2015",
    },
    {
        "Name of the Faculty": "Dr. Saikat Sarkar",
        Department: "Civil Engineering",
        "Author(s)": "N Pimprikar, S Sarkar, G Devaraj, D Roy, SR Reid",
        Title: "A Stabilization Approach for Mesh-free Simulations of Systems Developing Shocks or Extreme Strain Localizations",
        "Book/ Journal/ Book chapter/ Conference Name":
            "International Journal of Mechanical Sciences",
        "Volume/ Page": "91, 18-32",
        Year: "2015",
    },
    {
        "Author(s)":
            "Rohit Kumar Rai, Kavita Gupta, Silke Behrens, Jun Li, Qiang Xu, and Sanjay Kumar Singh*",
        Title: "Highly Active Bimetallic Ni-Pd Alloy Nanoparticles Catalyzed Suzuki-Miyaura Reactions",
        "Book/ Journal/ Book chapter/ Conference Name": "ChemCatChem",
        "Volume/ Page": "7, 1806-1812",
        Year: "2015",
        Department: "Chemistry",
        "Name of the Faculty": "Prof. Sanjay Kumar Singh",
    },
    {
        "Author(s)":
            "Rohit K. Rai, Arup Mahata, Sushobhan Mukhopadhyay, Sampa Gupta, Pei-Zhou Li, Kim T. Nguyen, Yanli Zhao, Biswarup Pathak, and Sanjay K. Singh*",
        Title: "Room-temperature Chemoselective Reduction of Nitro groups using Non-noble Metal Nanocatalysts in water,",
        "Book/ Journal/ Book chapter/ Conference Name": "Inorganic Chemistry",
        "Volume/ Page": "53, 2904-2909",
        Year: "2014",
        Department: "Chemistry",
        "Name of the Faculty": "Prof. Sanjay Kumar Singh",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)": "Padhi, A., Sengupta, M., Sengupta, S., Roehm, K., Sonawane, A.",
        Title: "Antimicrobial peptides and proteins in mycobacterial therapy: current status and future prospects.",
        "Book/ Journal/ Book chapter/ Conference Name": "Tuberculosis (Edinb)",
        "Volume/ Page": "94(4), 363- 73",
        Year: "2014",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)":
            "Mehta, R., Verma, S., Sengupta, M., Pati, R., Khatua, B., Jena, R., Sethy, S., Rohm, K., Mandal, C., Sonawane, A.",
        Title: "Mutations in subunit interfaces and B-cell epitopes improve antileukemic activities of Escherichia coli L-asparaginase-II: Evaluation of immunogenicity in mice.",
        "Book/ Journal/ Book chapter/ Conference Name": "J. Biol. Chem",
        "Volume/ Page": "289(6), 3555-70",
        Year: "2014",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)": "Verma, S., Mehta, R., Maiti, P., Roehm, K., Sonawane, A.",
        Title: "Improvement of stability and enzymatic activity by site-directed mutagenesis of E. coli asparaginase II",
        "Book/ Journal/ Book chapter/ Conference Name": "Biochim Biophys Acta.",
        "Volume/ Page": "1844(7), 1219-30",
        Year: "2014",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Author(s)":
            "Tsai, E. H. R., Bentz, B. Z., Venkatesh, C., Gaind, V., Webb, K. J., Low, P. S.",
        Title: "In Vivo Mouse Fluorescence Imaging for Folate-Targeted Delivery and Release Kinetics",
        "Book/ Journal/ Book chapter/ Conference Name": "Optical Imaging",
        "Volume/ Page": "5, 2662–2678",
        Year: "2014",
    },
    {
        "Name of the Faculty": "Dr. Md. Aquil Khan",
        Department: "Mathematics",
        "Author(s)": "Khan, M. A., Banerjee, M. and Rieke, R",
        Title: "An Update Logic for Information Systems",
        "Book/ Journal/ Book chapter/ Conference Name":
            "International Journal of Approximate Reasoning",
        "Volume/ Page": "55, 436-456",
        Year: "2014",
    },
    {
        "Name of the Faculty": "Dr. Swadesh Kumar Sahoo",
        Department: "Mathematics",
        "Author(s)": "R. Klén, Y. Li, S. K. Sahoo, and M. Vuorinen",
        Title: "On the Stability of φ-Uniform Domains,",
        "Book/ Journal/ Book chapter/ Conference Name": "Monatshefte für Mathematik",
        "Volume/ Page": "174(2), 231 - 258",
        Year: "2014",
    },
    {
        "Name of the Faculty": "Dr. Swadesh Kumar Sahoo",
        Department: "Mathematics",
        "Author(s)": "S. Ponnusamy, S. K. Sahoo, and H. Yanagihara,",
        Title: "Radius of Convexity of Partial Sums of Functions in the Close-to-convex Family,",
        "Book/ Journal/ Book chapter/ Conference Name": "Non-linear Analysis",
        "Volume/ Page": "95, 219 - 228",
        Year: "2014",
    },
    {
        "Name of the Faculty": "Dr. Swadesh Kumar Sahoo",
        Department: "Mathematics",
        "Author(s)": "S. Ponnusamy, S. K. Sahoo, and T. Sugawa,",
        Title: "Radius Problems Associated with Pre-Schwarzian and Schwarzian Derivatives,",
        "Book/ Journal/ Book chapter/ Conference Name": "Analysis (Berlin)",
        "Volume/ Page": "34(2), 163 - 171",
        Year: "2014",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Author(s)": "Shen, J., Venkatesh, C., Cresswell, G., Low, P. S.",
        Title: "Use of Folate-Conjugated Imaging Agents to Target Alternatively Activated Macrophages in a Murine Model of Asthma",
        "Book/ Journal/ Book chapter/ Conference Name": "Mol. Pharm.",
        "Volume/ Page": "10, 1918–1927",
        Year: "2013",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Author(s)":
            "Lindsay, K., Venkatesh, C., Charity, W., Mahalingam, S., Scott, P., Sumith, K., Low, P. S.,",
        Title: "Development of Tumor-Targeted Near Infrared Probes for Fluorescence Guided Surgery",
        "Book/ Journal/ Book chapter/ Conference Name": "Bioconjug. Chem.",
        "Volume/ Page": "24, 1075–1080",
        Year: "2013",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Author(s)": "Gaind, V., Tsai, H.-R., Webb, K. J., Venkatesh, C., Low, P. S.",
        Title: "Small Animal Optical Diffusion Tomography with Targeted Fluorescence,",
        "Book/ Journal/ Book chapter/ Conference Name": "J. Opt. Soc. Am. A.",
        "Volume/ Page": "30, 1146¬–1154",
        Year: "2013",
    },
    {
        "Name of the Faculty": "Dr. Venkatesh Chelvam",
        Department: "Chemistry",
        "Author(s)": "Tsai, H.-R., Bentz, B. Z., Venkatesh, C., Gaind, V., Webb, K. J., Low, P. S.",
        Title: "In Vivo Optical Imaging of Kinetics in a Small Animal for Folate-Targeted Drug Development",
        "Book/ Journal/ Book chapter/ Conference Name": "Opt. Life Sci.,",
        "Volume/ Page": "2013",
        Year: "2013",
    },
    {
        "Name of the Faculty": "Dr. Swadesh Kumar Sahoo",
        Department: "Mathematics",
        "Author(s)": "G. Bharali, D. Minda, S. Ponnusamy, and S. K. Sahoo (Editors)",
        Title: "The Proceedings of the International Symposium on Complex Analysis and Conformal Geometry",
        "Book/ Journal/ Book chapter/ Conference Name": "J. Analysis",
        "Volume/ Page": "-",
        Year: "2013",
    },
    {
        "Name of the Faculty": "Sk. Safique Ahmad",
        Department: "Mathematics",
        "Author(s)": "S. S. Ahmad and V. Mehrmann",
        Title: "Backward Errors for Eigenvalues and Eigenvectors of Hermitian, Skew-Hermitian, H-even and H-odd Matrix Polynomials,",
        "Book/ Journal/ Book chapter/ Conference Name": "Linear and multilinear Algebra",
        "Volume/ Page": "61",
        Year: "2013",
    },
    {
        "Name of the Faculty": "Dr. Vinay Kumar Gupta",
        Department: "Mathematics",
        "Author(s)": "Gupta, V. K. and Torrilhon, M.",
        Title: "Automated Boltzmann Collision Integrals for Moment Equations",
        "Book/ Journal/ Book chapter/ Conference Name": "AIP Conference Proceedings",
        "Volume/ Page": "1501, 67-74",
        Year: "2012",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)":
            "Jena, P., Mohanty, S., Mohanty, T., Kallert, S., Morgelin, M., Lindstrom,T., Niels, B., Stenger, S., Sonawane, A.,* Sorensen, O.E.*",
        Title: "Azurophil granule proteins constitute the major mycobactericidal proteins in human neutrophils and enhance the intracellular killing of mycobacteria in macrophages.",
        "Book/ Journal/ Book chapter/ Conference Name": "PLoS ONE",
        "Volume/ Page": "7(12), e50345",
        Year: "2012",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)": "Sonawane, A.,* Mohanty, S., Jagannathan, L., Bekolay, A., Banerjee, S.,",
        Title: "Role of glycans and glycoproteins in disease development by Mycobacterium tuberculosis.",
        "Book/ Journal/ Book chapter/ Conference Name": "Critical Review in Microbiology",
        "Volume/ Page": "38(3), 250-266",
        Year: "2012",
    },
    {
        "Name of the Faculty": "Sk. Safique Ahmad",
        Department: "Mathematics",
        "Author(s)": "S. S. Ahmad and V. Mehrmann",
        Title: "Perturbation Analysis for Complex Symmetric, Skew Symmetric, Even, and Odd Matrix Polynomials",
        "Book/ Journal/ Book chapter/ Conference Name": "Electron. Tans. Mumer. Anal.(ETNA)",
        "Volume/ Page": "38",
        Year: "2011",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)":
            "Sonawane, A.*, Santos, J., Mishra, B., Jena, P., Progida, C., Sorensen, O.E., Gallo, R., Appelberg, R., Griffiths, G.",
        Title: "Cathelicidin is involved in the intra-cellular killing of mycobacteria in macrophages.",
        "Book/ Journal/ Book chapter/ Conference Name": "Cellular Microbiology",
        "Volume/ Page": "13(10),1601-1617",
        Year: "2011",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)": "Jena, P., Mishra. B., Leippe. M., Hasilik, A., Griffiths, G., Sonawane, A.",
        Title: "Membrane-active antimicrobial peptides and human placental lysosomal extracts are highly active against mycobacteria.",
        "Book/ Journal/ Book chapter/ Conference Name": "Peptides",
        "Volume/ Page": "32, 881-7",
        Year: "2011",
    },
    {
        "Name of the Faculty": "Prof. Avinash Sonawane",
        Department: "Biosciences and Biomedical Engineering (BSBE)",
        "Author(s)":
            "Mishra, B.B., Moura-Alves, P., Sonawane, A., Hacohen, N., Griffiths, G., Moita, L.F., Anes, E.",
        Title: "Mycobacterium tuberculosis Protein ESAT-6 is a Potent Activator of the NLRP3/ASC Inflammasome.",
        "Book/ Journal/ Book chapter/ Conference Name": "Cellular Microbiology",
        "Volume/ Page": "12:1046-63",
        Year: "2010",
    },
];
