import React from "react";
import { Link } from "react-router-dom";

export const AboutIOA = () => {
  return (
    <div className="container pb-5 text-justify">
      <h2 className="mainTitle text-center m-3 mb-4">About Us</h2>
      {/* <h3 className="mainTitle mt-5">International Affairs and Outreach</h3> */}
      <h3 className="mainTitle mt-5">International Relations</h3>
      <p>
        IIT Indore is rapidly expanding globally with an aim to internationalize
        its teaching and research portfolio. Currently, we are collaborating
        intensely with counties across North America, Europe, Asia, and Oceania.
        In continuation to the previous year, this year we had notable progress
        on three fronts. First, we were successful in recruiting and hosting
        many international students in our degree programs (from ASEAN and SAARC
        regions). Second, we secured multiple grants from our Ministry of
        Education, which is feeding well into our internationalization
        endeavors. Third, we expanded our footprint further in Europe.
      </p>
      <h3 className="mainTitle mt-4">Recruitment of Foreign Students</h3>
      <p>
        Continuing the upward trajectory in enrolling foreign students in our
        degree programs, since 2019, we have hosted 04 international students
        who were from Bangladesh, Nepal and Nigeria and joined us through the
        Study in India (SII) program. Currently we have 05 MTech students and 02
        PhD research scholars at our campus native from Nepal, Nigeria, Ethiopia
        and Bangladesh. Additionally, IIT Indore has been hosting foreign
        students as interns since its inception, till date we have hosted 08
        Thai students, 01 from Taiwan and 01 student from USA . This has helped
        us establish our brand in ASEAN countries, and specifically Thailand.
        For more details visit:{" "}
        <a href="https://ir.iiti.ac.in/#/int/students">
          https://ir.iiti.ac.in/#/int/students
        </a>
      </p>
      <h3 className="mainTitle mt-4">MoE Initiatives</h3>
      <p>
        GIAN, VAJRA, SPARC and ASEM-DUO are the recent Ministry of Education
        initiatives that aim to improve the research and teaching ecosystem of
        India’s higher education. In the year 2019-20, a substantial number of
        such Projects were awarded to IIT Indore.
      </p>
      <ol>
        <li>
          <Link to="/mhrd/gian">GIAN Courses - 07</Link>
        </li>
        <li>
          <Link to="/mhrd/vajra">VAJRA Fellowships - 03</Link>
        </li>
        <li>
          <Link to="/mhrd/sparc">SPARC Projects – 09</Link>
        </li>
        <li>
          <Link to="/mhrd/asem">ASEM-DUO Fellowships – 06</Link>
        </li>
      </ol>
    </div>
  );
};
