import React from "react";
import { Table } from "react-bootstrap";
import "./IntVisitors.css";

export const IntVisitors = () => {
	return (
		<div className="container pb-5 text-left">
			<h2 className="mainTitle text-center m-3 mb-4">International Visitors</h2>
			<h4 className="font-ubuntu text-center">
			Foreign faculty visit to IIT Indore (01.08.2021 to till date).
			</h4>
			<Table striped bordered hover variant="primary" responsive className="mb-3">
                <thead>
                    <tr>
                        <th>Faculty Name</th>
                        <th>Academic Designation/Scheme</th>
                        <th>Date of joining IIT Indore(latest joining)</th>
                        <th>Department</th>
                        <th>Highest University Degree</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Dr. Yogeshwarnath Mishra</td>
                        <td>Visiting Professor under SPARC Project</td>
                        <td>31-Jan-22</td>
                        <td>Mechanical Engineering</td>
                        <td>PhD</td>
                    </tr>
                    <tr>
                        <td>DR. INDRAJITH D. NISSANKA</td>
                        <td>Visiting Professor under ISRF</td>
                        <td>02-Feb-22</td>
                        <td>Mechanical Engineering</td>
                        <td>PhD</td>
                    </tr>
                    <tr>
                        <td>Mr. Khagendra Adhikari</td>
                        <td>CIMPA scheme</td>
                        <td>21-Sep-22</td>
                        <td>Mathematics</td>
                        <td>M.Phil.</td>
                    </tr>
                    <tr>
                        <td>Mr. Ramesh Gautam</td>
                        <td>CIMPA scheme</td>
                        <td>18-Sep-22</td>
                        <td>Mathematics</td>
                        <td>M.Phil.</td>
                    </tr>
                    <tr>
                        <td>Prof. Narasimhan Sundararajan</td>
                        <td>Visiting Professor (Retd.)</td>
                        <td>08-Sep-22</td>
                        <td>Mathematics</td>
                        <td>PhD</td>
                    </tr>
					<tr>
						<td>Dr. Stefano Boccaletti</td>
						<td>Visiting Professor under VAJRA</td>
						<td>06-Oct-22</td>
						<td>Physics</td>
						<td>PhD</td>
					</tr>
					<tr>
						<td>Prof. Herbert Pfnür</td>
						<td>Visiting Professor under DAAD (LUH-IITI)</td>
						<td>27-Nov-22</td>
						<td>Physics</td>
						<td>PhD</td>
					</tr>
					<tr>
						<td>Prof. Yann BUSNEL</td>
						<td>Visiting Professor</td>
						<td>09-Sep-22</td>
						<td>Computer Science and Engineering</td>
						<td>PhD</td>
					</tr>
					<tr>
						<td>Dr. Krishna R Reddy</td>
						<td>Visiting Professor under VAJRA</td>
						<td>Yet to join</td>
						<td>Civil</td>
						<td>PhD</td>
					</tr>
					<tr>
						<td>Dr. Erik Zackrisson</td>
						<td>Visiting Professor under SPARC Project</td>
						<td>07-Sep-2022</td>
						<td>Astronomy</td>
						<td>PhD</td>
					</tr>
                </tbody>
            </Table>
			{/* <div className="visitorCardDiv">
				{data.map((dat) => {
					return (
						<div className="visitorCard">
							<img src={dat.img} alt={dat.name} />
							<h4 className="mt-3 font-acme" style={{ color: "#007bff", textDecoration: "underline" }}>
								{dat.name}
							</h4>
							<p className="mb-2 font-ubuntu">{dat.designation}</p>
							<p className="mb-2 font-ubuntu">{dat.institute}</p>
							{dat.title.map((e) => { 
								return (
								<p className="mb-2 font-ubuntu" style={{ fontWeight: "bold" }}>{e}</p>
								);
								})}
							<p className="mb-2 font-ubuntu">{dat.host}</p>
						</div>
					);
				})}
			</div> */}
		</div>
	);
};
