import iaoBrochure from "../../../assets/Institute Brochure-IAO.pdf";

export const searchData = [
  {
    keywords: ["home"],
    name: "Home",
    link: "/",
    value: 0,
  },
  { name: "About IR", link: "/Aboutioa", keywords: ["About Us"], value: 1 },
  { name: "About IITI", link: "/Aboutiiti", keywords: ["About Us"], value: 1 },
  { name: "Campus Life", link: "/LifeIITI", keywords: ["About Us"], value: 1 },
  { name: "Places To Visit", link: "/Pros/places", keywords: [], value: 1 },
  { name: "IR Brochure", link: iaoBrochure, document: true, keywords: ["About Us"], value: 1 },
  {
    name: "International Affairs",
    link: "/people/oia",
    keywords: ["People", "About Us"],
    value: 1,
  },
  { name: "SIA Cell", link: "/people/sc", keywords: ["People", "About Us"], value: 1 },
  { name: "Outreach Committee", link: "/committee/oc", keywords: ["People", "About Us"], value: 1 },
  { name: "ISMAE Committee", link: "/committee/mec", keywords: ["People", "About Us"], value: 1 },
  {
    name: "Hostels",
    link: "http://hostel.iiti.ac.in/",
    keywords: ["facilities", "About Us"],
    value: 1,
  },
  { name: "Guest House", link: "/guestHouse", keywords: ["facilities", "About Us"], value: 1 },
  {
    name: "Eateries",
    link: "http://eateries.iiti.ac.in/",
    keywords: ["facilities", "About Us"],
    value: 1,
  },
  {
    name: "Health Centre",
    link: "http://people.iiti.ac.in/~medical/",
    keywords: ["facilities", "About Us"],
    value: 1,
  },
  {
    name: "Counselling Cell",
    link: "http://iiti.ac.in/page/counselling-cell",
    keywords: ["facilities", "About Us"],
    value: 1,
  },
  {
    name: "Training and Placement",
    link: "http://placement.iiti.ac.in/",
    keywords: ["facilities", "About Us"],
    value: 1,
  },
  {
    name: "Computer and IT Center",
    link: "http://cc.iiti.ac.in/",
    keywords: ["facilities", "About Us"],
    value: 1,
  },
  {
    name: "Nursery School",
    link: "http://people.iiti.ac.in/~creche/",
    keywords: ["facilities", "About Us"],
    value: 1,
  },
  {
    name: "E-Payments",
    link: "http://iiti.ac.in/page/e-payments",
    keywords: ["facilities", "About Us"],
    value: 1,
  },
  {
    name: "Transport Booking",
    link: "http://vbs.iiti.ac.in/",
    keywords: ["facilities", "About Us"],
    value: 1,
  },
  {
    name: "Green vehicle schedule",
    link: "https://www.iiti.ac.in/public/storage/Green%20vehicle%20schedule-%202021.pdf",
    keywords: ["facilities", "About Us"],
    value: 1,
  },
  {
    name: "KV IIT Indore",
    link: "https://www.iitindore.kvs.ac.in/",
    keywords: ["facilities", "About Us"],
    value: 1,
  },
  {
    name: "Research and Development",
    link: "http://rnd.iiti.ac.in/",
    keywords: ["Research", "rnd", "About Us"],
    value: 1,
  },
  {
    name: "Academic Programs",
    link: "https://academic.iiti.ac.in/",
    keywords: ["academic", "programs", "About Us"],
    value: 1,
  },
  {
    name: "News and Activities",
    link: "/outreach/news",
    keywords: ["news", "About Us"],
    value: 1,
  },
  {
    keywords: ["Visitors", "Inbound"],
    name: "Visitors' Experience",
    link: "/Pros/visitors_experience",
    value: 2,
  },
  {
    keywords: ["Visitors", "Inbound"],
    name: "Places To Visit",
    link: "/Pros/places",
    value: 2,
  },
  {
    keywords: ["Visitors", "Inbound"],
    name: "International Students' List",
    link: "/int/students",
    value: 2,
  },
  {
    keywords: ["Visitors", "Inbound"],
    name: "International Visitors",
    link: "/int/visitors",
    value: 2,
  },
  { keywords: ["Visitors", "Inbound"], name: "Campus Life", link: "/people/sc", value: 2 },
  {
    keywords: ["Students", "Inbound"],
    name: "Admission",
    link: "/admission",
    value: 2,
  },
  {
    keywords: ["Students", "Inbound"],
    name: "Programs",
    link: "https://academic.iiti.ac.in/academic_program.php",
    value: 2,
  },
  {
    keywords: ["Students", "Inbound"],
    name: "Visa Process",
    link: "/visa",
    value: 2,
  },
  {
    keywords: ["Researchers", "Inbound"],
    name: "Visa Process",
    link: "/visa",
    value: 2,
  },
  {
    keywords: ["Researchers", "Inbound"],
    name: "IR Brochure",
    link: iaoBrochure,
    document: true,
    value: 2,
  },
  {
    keywords: ["Opportunities", "Inbound"],
    name: "Study In India",
    link: "https://studyinindia.gov.in/",
    value: 2,
  },
  {
    keywords: ["Opportunities", "Inbound"],
    name: "ICCR",
    link: "https://www.iccr.gov.in/",
    value: 2,
  },
  {
    keywords: ["Opportunities", "Inbound"],
    name: "ASEAN India STIC",
    link: "https://asean-iit.in/",
    value: 2,
  },
  {
    keywords: ["Opportunities", "Inbound"],
    name: "UKIERI Mobility Programme",
    link: "https://www.britishcouncil.in/programmes/higher-education/ukieri/ukieri-mobility-programme-study-india",
    value: 2,
  },
  {
    keywords: ["MHRD Schemes", "Inbound"],
    name: "GIAN",
    link: "/mhrd/gian",
    value: 2,
  },
  {
    keywords: ["MHRD Schemes", "Inbound"],
    name: "VAJRA",
    link: "/mhrd/vajra",
    value: 2,
  },
  {
    keywords: ["MHRD Schemes", "Inbound"],
    name: "SPARC",
    link: "/mhrd/sparc",
    value: 2,
  },
  {
    keywords: ["MHRD Schemes", "Inbound"],
    name: "ASEM - DUO",
    link: "/mhrd/asem",
    value: 2,
  },
  {
    keywords: ["French Scholarships", "Students", "Outbound"],
    name: "Raman-Charpak Fellowship",
    link: "http://www.cefipra.org/Raman_Charpak.aspx",
    value: 3,
  },
  {
    keywords: ["French Scholarships", "Students", "Outbound"],
    name: "CEFIPRA-ESONN Fellowship",
    link: "http://www.cefipra.org/Cefipra_ESONN.aspx",
    value: 3,
  },
  {
    keywords: ["French Scholarships", "Students", "Outbound"],
    name: "ENS Internship program",
    link: "http://www.ens-lyon.fr/en/studies/admissions/application-research-internship",
    value: 3,
  },
  {
    keywords: ["Japanese Scholarships", "Students", "Outbound"],
    name: "JSPS",
    link: "https://www.jsps.go.jp/english/",
    value: 3,
  },
  {
    keywords: ["Japanese Scholarships", "Students", "Outbound"],
    name: "MEXT Scholarship",
    link: "https://www.in.emb-japan.go.jp/Education/japanese_government_scholarships.html",
    value: 3,
  },
  {
    keywords: ["German Scholarships", "Students", "Outbound"],
    name: "Alexander von Humboldt Foundation",
    link: "https://www.humboldt-foundation.de/en/",
    value: 3,
  },
  {
    keywords: ["German Scholarships", "Students", "Outbound"],
    name: "DAAD",
    link: "https://www.daad.in/en/study-research-in-germany/",
    value: 3,
  },
  {
    keywords: ["German Scholarships", "Students", "Outbound"],
    name: "Indo-German Science & Technology Center",
    link: "https://www.igstc.org",
    value: 3,
  },
  {
    keywords: ["German Scholarships", "Students", "Outbound"],
    name: "German Language",
    link: "https://www.goethe.de/ins/in/en/sta/new.html",
    value: 3,
  },
  {
    keywords: ["German Scholarships", "Students", "Outbound"],
    name: "Indo-German Training Centre",
    link: "https://indien.ahk.de/education/indo-german-training-centre",
    value: 3,
  },
  {
    keywords: ["German Scholarships", "Students", "Outbound"],
    name: "ISG",
    link: "https://www.indianstudentsgermany.org/",
    value: 3,
  },
  {
    keywords: ["Canadian Scholarships", "Students", "Outbound"],
    name: "Indo-Shastri Canadian Institute",
    link: "https://www.shastriinstitute.org/",
    value: 3,
  },
  {
    keywords: ["Canadian Scholarships", "Students", "Outbound"],
    name: "Indo-Canada Education Council",
    link: "https://indocanadaeducation.org/",
    value: 3,
  },
  {
    keywords: ["Canadian Scholarships", "Students", "Outbound"],
    name: "MITACS",
    link: "https://www.mitacs.ca/en/programs/globalink/come-to-canada",
    value: 3,
  },
  {
    keywords: ["UK Scholarships", "Students", "Outbound"],
    name: "British Council India",
    link: "https://www.britishcouncil.in/study-uk/scholarships",
    value: 3,
  },
  {
    keywords: ["UK Scholarships", "Students", "Outbound"],
    name: "UKRI India",
    link: "https://www.ukri.org/our-work/collaborating-internationally/our-international-offices/ukri-india/",
    value: 3,
  },
  {
    keywords: ["UK Scholarships", "Students", "Outbound"],
    name: "Charles Wallace India Trust",
    link: "https://www.charleswallaceindiatrust.com/",
    value: 3,
  },
  {
    keywords: ["UK Scholarships", "Students", "Outbound"],
    name: "Commonwealth Scholarships",
    link: "https://www.britishcouncil.in/study-uk/scholarships/commonwealth-scholarships",
    value: 3,
  },
  {
    keywords: ["UK Scholarships", "Students", "Outbound"],
    name: "GREAT Scholarships India",
    link: "https://study-uk.britishcouncil.org/scholarships/great-scholarships/india",
    value: 3,
  },
  {
    keywords: ["UK Scholarships", "Students", "Outbound"],
    name: "Newton Bhabha Fund",
    link: "https://www.britishcouncil.in/programmes/higher-education/newton-fund",
    value: 3,
  },
  {
    keywords: ["Other Scholarships", "Students", "Outbound"],
    name: "USA",
    link: "https://www.iusstf.org/",
    value: 3,
  },
  {
    keywords: ["Other Scholarships", "Students", "Outbound"],
    name: "Asean",
    link: "https://aistic.gov.in/ASEAN/HomePage",
    value: 3,
  },
  {
    keywords: ["Other Scholarships", "Students", "Outbound"],
    name: "China",
    link: "http://www.anso.org.cn/programmes/talent/scholarship/",
    value: 3,
  },
  {
    keywords: ["Other Scholarships", "Students", "Outbound"],
    name: "Taiwan",
    link: "https://www.icdf.org.tw/ct.asp?xItem=12505&CtNode=30316&mp=2",
    value: 3,
  },
  {
    keywords: ["Other Scholarships", "Students", "Outbound"],
    name: "Spain",
    link: "https://www.crg.eu/en/content/training/undergraduates-and-masters",
    value: 3,
  },
  {
    keywords: ["Other Scholarships", "Students", "Outbound"],
    name: "Austria",
    link: "https://www.scholarships.at/default.aspx",
    value: 3,
  },
  {
    keywords: ["Other Scholarships", "Students", "Outbound"],
    name: "New Zealand",
    link: "https://www.nzscholarships.govt.nz/",
    value: 3,
  },
  {
    keywords: ["Other Scholarships", "Students", "Outbound"],
    name: "Thailand",
    link: "https://www.ait.ac.th/admissions/scholarships/bangchak-master-scholarships/",
    value: 3,
  },
  {
    keywords: ["Other Important Opportunities", "Students", "Outbound"],
    name: "Internships",
    link: "/opportunities/internships",
    level: "4",
    value: 3,
  },
  {
    keywords: ["Other Important Opportunities", "Students", "Outbound"],
    name: "PG / Ph.D. / Post-Doc",
    link: "/opportunities/pg_phd_post_doc",
    value: 3,
  },
  {
    keywords: ["Indo-Germany", "Researchers", "Outbound"],
    name: "Alexander von Humboldt Foundation",
    link: "https://www.humboldt-foundation.de/en/",
    value: 3,
  },
  {
    keywords: ["Indo-Germany", "Researchers", "Outbound"],
    name: "DAAD",
    link: "https://www.daad.in/en/study-research-in-germany/",
    value: 3,
  },
  {
    keywords: ["Indo-Germany", "Researchers", "Outbound"],
    name: "Indo-German Science & Technology Center",
    link: "https://www.igstc.org/",
    value: 3,
  },
  {
    keywords: ["Others", "Researchers", "Outbound"],
    name: "Indo-French",
    link: "http://www.cefipra.org/",
    value: 3,
  },
  {
    keywords: ["Others", "Researchers", "Outbound"],
    name: "Indo-Japan",
    link: "https://www.jsps.go.jp/english/",
    value: 3,
  },
  {
    keywords: ["Others", "Researchers", "Outbound"],
    name: "Indo-USA",
    link: "https://www.iusstf.org/",
    value: 3,
  },
  {
    keywords: ["Others", "Researchers", "Outbound"],
    name: "Indo-UK",
    link: "https://www.ukri.org/our-work/collaborating-internationally/our-international-offices/ukri-india/",
    value: 3,
  },
  {
    keywords: ["Others", "Researchers", "Outbound"],
    name: "Indo-ASEAN",
    link: "https://aistic.gov.in/ASEAN/HomePage",
    value: 3,
  },
  {
    keywords: ["Others", "Researchers", "Outbound"],
    name: "Indo-Canada",
    link: "https://www.shastriinstitute.org/",
    value: 3,
  },
  {
    keywords: ["MoUs", "Cooperation and Engagements"],
    name: "Asian Universities",
    link: "/mou/asia",
    value: 4,
  },
  {
    keywords: ["MoUs", "Cooperation and Engagements"],
    name: "Australian Universities",
    link: "/mou/australia",
    value: 4,
  },
  {
    keywords: ["MoUs", "Cooperation and Engagements"],
    name: "American Universities",
    link: "/mou/america",
    value: 4,
  },
  {
    keywords: ["MoUs", "Cooperation and Engagements"],
    name: "African Universities",
    link: "/mou/africa",
    value: 4,
  },
  {
    keywords: ["MoUs", "Cooperation and Engagements"],
    name: "European Universities",
    link: "/mou/europe",
    value: 4,
  },
  {
    keywords: ["Student Visits", "Cooperation and Engagements"],
    name: "America",
    link: "/mobility/student/america",
    value: 4,
  },
  {
    keywords: ["Student Visits", "Cooperation and Engagements"],
    name: "Europe",
    link: "/mobility/student/europe",
    value: 4,
  },
  {
    keywords: ["Faculty Visits", "Cooperation and Engagements"],
    name: "Africa",
    link: "/mobility/faculty/africa",
    value: 4,
  },
  {
    keywords: ["Faculty Visits", "Cooperation and Engagements"],
    name: "America",
    link: "/mobility/faculty/america",
    value: 4,
  },
  {
    keywords: ["Faculty Visits", "Cooperation and Engagements"],
    name: "Asia",
    link: "/mobility/faculty/asia",
    value: 4,
  },
  {
    keywords: ["Faculty Visits", "Cooperation and Engagements"],
    name: "Australia",
    link: "/mobility/faculty/australia",
    value: 4,
  },
  {
    keywords: ["Faculty Visits", "Cooperation and Engagements"],
    name: "Europe",
    link: "/mobility/faculty/europe",
    value: 4,
  },
  {
    keywords: ["Faculty Visits", "Cooperation and Engagements"],
    name: "Other Countries",
    link: "/mobility/faculty/others",
    value: 4,
  },
  {
    keywords: ["International Grants", "Cooperation and Engagements"],
    name: "Bilateral Intl. Grants",
    link: "/grants",
    value: 4,
  },
  {
    keywords: ["MHRD Schemes", "Cooperation and Engagements"],
    name: "GIAN",
    link: "/mhrd/gian",
    value: 4,
  },
  {
    keywords: ["MHRD Schemes", "Cooperation and Engagements"],
    name: "VAJRA",
    link: "/mhrd/vajra",
    value: 4,
  },
  {
    keywords: ["MHRD Schemes", "Cooperation and Engagements"],
    name: "SPARC",
    link: "/mhrd/sparc",
    value: 4,
  },
  {
    keywords: ["MHRD Schemes", "Cooperation and Engagements"],
    name: "ASEM - DUO",
    link: "/mhrd/asem",
    value: 4,
  },
  {
    keywords: ["Cooperation and Engagements"],
    name: "Publications",
    link: "/publications",
    value: 4,
  },
  {
    keywords: ["Lecture Series", "Outreach"],
    name: "Nobel Laureate Lectures",
    link: "/outreach/nobel",
    value: 5,
  },
  {
    keywords: ["Lecture Series", "Outreach"],
    name: "Frontiers in Physics",
    link: "/outreach/events/frontiers",
    value: 5,
  },
  {
    keywords: ["Lecture Series", "Outreach"],
    name: "Hardy Ramanujan Lecture Series",
    link: "/outreach/events/bruce-berndt",
    value: 5,
  },
  {
    keywords: ["Public Lectures", "Outreach"],
    name: "Automotive Light-weighting Technologies",
    link: "/outreach/events/automotive",
    value: 5,
  },
  {
    keywords: ["Public Lectures", "Outreach"],
    name: "Prosperity Through Science Technology",
    link: "/outreach/events/prosperity",
    value: 5,
  },
  {
    keywords: ["Public Lectures", "Outreach"],
    name: "Role of Science in Nation Building during Global Health Crisis",
    link: "/outreach/events/health",
    value: 5,
  },
  {
    keywords: ["Public Lectures", "Outreach"],
    name: "Forecasting Crude Oil Futures Prices Using the Kalman Filter and News",
    link: "/outreach/events/paresh-date",
    value: 5,
  },
  {
    keywords: ["Conferences and Seminars"],
    name: "Chemistry Talks",
    link: "/chemTalks",
    value: 5,
  },
  {
    keywords: ["Conferences and Seminars"],
    name: "Nobel Prizes 2020",
    link: "/outreach/events/nobelPrizes",
    value: 5,
  },
  {
    keywords: ["Events and Activities"],
    name: "National Science Day",
    link: "/outreach/nsd",
    value: 5,
  },
  {
    keywords: ["contact"],
    name: "Reach Us",
    link: "/contact",
    value: 1,
  },
  {
    name: "IIT Indore",
    link: "https://iiti.ac.in/",
    value: 0,
    keywords: ["IITI official website"],
  },
];
