import React from "react";
import { Link } from "react-router-dom";

export const Newscard = ({ title, text, date, img, link }) => {
  return (
    <div
      className="indiEvent font-ubuntu container-fluid"
      style={{
        // minHeight: "50vh",
        padding: "20px 0",
        borderBottom: "1px solid rgba(0,0,0,0.1)",
        margin: "0 10px",
      }}
    >
      <div className="eventPart1" style={{ display: "flex", alignItems: "flex-start" }}>
        <img
          alt="News"
          src={img}
          style={{ width: "min(90vw, 400px)", minWidth: "min(90vw, 400px)" }}
        />
        <div className="eventPart2">
          <h4 className="font-acme">{title}</h4>
          <p className="text-muted">{date}</p>
          <p style={{ whiteSpace: "pre-wrap" }}>{typeof text === "function" ? text() : text}</p>
          {link !== "" && link !== undefined && (
            <p style={{ whiteSpace: "pre-wrap" }}>
              To know more,{" "}
              {link[0] === "h" ? (
                <a href={link} target="_blank" rel="noreferrer">
                  Click Here
                </a>
              ) : (
                <Link to={link}>Click Here</Link>
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
