import React, { useState } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Gelp from "./sections/Inbound/E-LearningPrograms/GlobalElearningProgram/gelp.js"
import { 
    Home
} from "./sections/Home/Home";
import { 
    People,
     CommitteesPage, 
     AboutIITI, 
     AboutIOA, 
     LifeIITI, 
     Ghouse 
} from "./sections/AboutUs";
import {
    Places,
    Visa,
    Admission,
    Programs,
    IntStudents,
    Testimonials,
    IntVisitors,
    StudentStrp,
    ResearchersStrp,
    Riisshe,
    VidTestimonials,
    UgPgFellowshipInbound
    // GlobalElearning,
} from "./sections/Inbound";
import {
    Scholarship,
    Internships,
    OutboundStudentStrp,
    OutboundResearcherStrp,
    UgPgFellowshipOutbound,
    MobilityGrant,
    InternationalGrant
} from "./sections/Outbound";
import {
    Mou2,
    Sparc,
    Asem,
    Vajra,
    Gian,
    MobilityExchange,
    Publications,
    Grants,
} from "./sections/CoopNEngage";
import { 
    NewsPage, 
    EventsPage, 
    IndiEvent, 
    IndiNews, 
    Outreach 
} from "./sections/Outreach";
import { 
    Dean, 
    Director, 
    NotFound 
} from "./sections/Others";
import ReachUs from "./sections/ReachUs/ReachUs";
import { 
    Title, 
    Footer, 
    ScrollToTop, 
    CustomNavbar 
} from "./components";

export const NoAuth = () => {
    const [val, setVal] = useState(0);

    return (
        <Router basename="/">
            <ScrollToTop />
            <div style={{ position: "sticky", top: "0", zIndex: "200" }}>
                <Title setVal={setVal} />
                <div className="mainNav">
                    <CustomNavbar val={val} setVal={setVal} />
                </div>
            </div>
            <div className="mainScreen">
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/people/oia" exact>
                        <People index={1} />
                    </Route>
                    <Route path="/people/sc" exact>
                        <People index={2} />
                    </Route>
                    <Route path="/int/students" exact component={IntStudents} />
                    <Route path="/int/vidtests" exact component={VidTestimonials} />
                    <Route path="/int/visitors" exact component={IntVisitors} />
                    <Route path="/committee/oc" exact>
                        <CommitteesPage index={1} />
                    </Route>
                    <Route path="/committee/mec" exact>
                        <CommitteesPage index={2} />
                    </Route>
                    <Route path="/committee/ifs" exact>
                        <CommitteesPage index={3} />
                    </Route>
                    <Route path="/Aboutiiti" exact component={AboutIITI} />
                    <Route path="/Aboutioa" exact component={AboutIOA} />
                    <Route path="/LifeIITI" exact component={LifeIITI} />
                    <Route path="/guestHouse" exact component={Ghouse} />
                    <Route path="/visa" exact component={Visa} />
                    <Route path="/admission" exact component={Admission} />
                    <Route path="/programs" exact component={Programs} />
                    <Route path="/Pros/places" exact component={Places} />
                    <Route path="/Pros/visitors_experience" exact component={Testimonials} />
                    <Route path="/ProsFaculty/visa" exact component={Visa} />
                    <Route path="/opportunities/internships" exact component={Internships} />
                    <Route path="/opportunities/scholarship" exact component={Scholarship} />
                    <Route
                        path="/outbound/students/srp-iitis"
                        exact
                        component={OutboundStudentStrp}
                    />
                    <Route
                        path="/outbound/Faculty/mobility_Grant"
                        exact
                        component={MobilityGrant}
                    />
                    <Route
                        path="/outbound/Faculty/internationalgrant"
                        exact
                        component={InternationalGrant}
                    />
                    <Route
                        path="/outbound/ug_pg_fellowship"
                        exact
                        component={UgPgFellowshipOutbound}
                    />
                    <Route path="/inbound/students/srp-is" component={StudentStrp} />
                    <Route path="/inbound/students/srp-ugpg" component={UgPgFellowshipInbound} />
                    <Route path="/inbound/researchers/srp-ias" component={ResearchersStrp} />
                    {/* <Route path="/inbound/students/e-learning" component={GlobalElearning} /> */}
                    <Route path="/inbound/students/gelp" component={Gelp} />
                    <Route path="/inbound/students/riisshe" component={Riisshe} />
                    <Route path="/outreach/news" exact component={NewsPage} />
                    <Route path="/outreach/news/:id" exact component={IndiNews} />
                    <Route path="/outreach/events" exact component={EventsPage} />
                    <Route path="/outreach/events/:name" exact component={IndiEvent} />
                    <Route path="/outreach/:name" exact component={Outreach} />
                    <Route path="/contact" exact component={ReachUs} />
                    <Route path="/mou/asia" exact>
                        <Mou2 name={"Asia"} />
                        <Mou2 name={"Europe"} />
                        <Mou2 name={"Americas"} />
                        <Mou2 name={"Australia"} />
                        <Mou2 name={"Africa"} />
                    </Route>
                    <Route path="/mou/europe" exact>
                        <Mou2 name={"Europe"} />
                        <Mou2 name={"Asia"} />
                        <Mou2 name={"Americas"} />
                        <Mou2 name={"Australia"} />
                        <Mou2 name={"Africa"} />
                    </Route>
                    <Route path="/mou/america" exact>
                        <Mou2 name={"Americas"} />
                        <Mou2 name={"Asia"} />
                        <Mou2 name={"Europe"} />
                        <Mou2 name={"Australia"} />
                        <Mou2 name={"Africa"} />
                    </Route>
                    <Route path="/mou/australia" exact>
                        <Mou2 name={"Australia"} />
                        <Mou2 name={"Asia"} />
                        <Mou2 name={"Europe"} />
                        <Mou2 name={"Americas"} />
                        <Mou2 name={"Africa"} />
                    </Route>
                    <Route path="/mou/africa" exact>
                        <Mou2 name={"Africa"} />
                        <Mou2 name={"Asia"} />
                        <Mou2 name={"Europe"} />
                        <Mou2 name={"Americas"} />
                        <Mou2 name={"Australia"} />
                    </Route>
                    <Route path="/grants" exact component={Grants} />
                    <Route path="/publications" exact component={Publications} />
                    <Route path="/mhrd/sparc" exact component={Sparc} />
                    <Route path="/mhrd/asem" exact component={Asem} />
                    <Route path="/mhrd/vajra" exact component={Vajra} />
                    <Route path="/mhrd/gian" exact component={Gian} />
                    <Route path="/mobility/student/america" exact>
                        <MobilityExchange type="student" category="America" />
                    </Route>
                    <Route path="/mobility/student/europe" exact>
                        <MobilityExchange type="student" category="Europe" />
                    </Route>
                    <Route path="/mobility/faculty/asia" exact>
                        <MobilityExchange type="faculty" category="Asia" />
                    </Route>
                    <Route path="/mobility/faculty/america" exact>
                        <MobilityExchange type="faculty" category="America" />
                    </Route>
                    <Route path="/mobility/faculty/africa" exact>
                        <MobilityExchange type="faculty" category="Africa" />
                    </Route>
                    <Route path="/mobility/faculty/australia" exact>
                        <MobilityExchange type="faculty" category="Australia" />
                    </Route>
                    <Route path="/mobility/faculty/europe" exact>
                        <MobilityExchange type="faculty" category="Europe" />
                    </Route>
                    <Route path="/mobility/faculty/others" exact>
                        <MobilityExchange type="faculty" category="OtherCountries" />
                    </Route>
                    <Route path="/dean" component={Dean} />
                    <Route path="/director" component={Director} />
                    <Route component={NotFound} />
                </Switch>
            </div>
            <Footer />
        </Router>
    );
};
