import { React, useState } from "react"
import { Accordion, Card } from "react-bootstrap";
import { AiFillFileText } from "react-icons/ai";
import { fl1, fl3, geo_tech, electrmetric, Appled_Econometrics_Basic } from "../../../../../assets";
import { MdFiberNew } from "react-icons/md";

function Gelp() {
  const [accKey, setAccKey] = useState(-1);

  const handleAccClick = (i) => {
    console.log("Clicked");
    if (i === accKey) setAccKey(-1);
    else setAccKey(i);
  };
  return (
    <div className="container pb-5 pt-4">
      <h2 className="font-acme text-center mb-5">
        Global e-Learning Program
      </h2>
      <div className="font-ubuntu text-justify mb-5 strp-intro">
        <b>About the Program:</b> International Relations Office, IIT Indore has launched a Global e-Learning Program for the International Students (UG/PG/PhD) and early career faculties from all over the world. This program shall provide training in new and advanced technology of Futuristic and Globally relevant areas. More details are on the flyers of each course.

        <br />

        <Accordion>
          <Card className="accordionCard">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="4"
              className="accordionHeader"
              onClick={() => {
                handleAccClick(4);
              }}
            >
              <span>{accKey === 4 ? "-" : "+"}</span>&nbsp;&nbsp;Applied Econometrics(Basic) <MdFiberNew size={25} color={"red"} />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <a href={Appled_Econometrics_Basic} target="_blank" rel="noreferrer">
                  <div className="appPortalBtn" style={{ width: 210 }}>
                    <AiFillFileText /> Flyer of the program
                  </div>
                </a>
                <br />
                <br />
                <p>To Register for the program <a href="https://docs.google.com/forms/d/e/1FAIpQLSd_lYrp34-mW22bqdw1itpIXFuRAowBuoYybsIwLanBFL-xUA/viewform">click here</a></p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              className="accordionHeader"
              onClick={() => {
                handleAccClick(0);
              }}
            >
              <span>{accKey === 0 ? "-" : "+"}</span>&nbsp;&nbsp;Advanced Applied Econometrics in Data Analysis <MdFiberNew size={25} color={"red"} />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <a href={electrmetric} target="_blank" rel="noreferrer">
                  <div className="appPortalBtn" style={{ width: 210 }}>
                    <AiFillFileText /> Flyer of the program
                  </div>
                </a>
                <br />
                <br />
                <p>To Register for the program <a href="https://docs.google.com/forms/d/e/1FAIpQLSdA_UjB7f2rhOmyrHgFL0LOTc0l0OOw2HPqBbSSRkyl2K4U9w/viewform">click here</a></p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="1"
              className="accordionHeader"
              onClick={() => {
                handleAccClick(1);
              }}
            >
              <span>{accKey === 1 ? "-" : "+"}</span>&nbsp;&nbsp;Geotechnical Aspects of Earthquake
              Engineering <MdFiberNew size={25} color={"red"} />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <a href={geo_tech} target="_blank" rel="noreferrer">
                  <div className="appPortalBtn" style={{ width: 210 }}>
                    <AiFillFileText /> Flyer of the program
                  </div>
                </a>
                <br />
                <br />
                <p>To Register for the program <a href="https://docs.google.com/forms/d/e/1FAIpQLSd2BcdtJmZQXv4IsnaD0kCCBW680RvoKogFx2GCCkVy0AcBuA/viewform">click here</a></p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="2"
              className="accordionHeader"
              onClick={() => {
                handleAccClick(2);
              }}
            >
              <span>{accKey === 2 ? "-" : "+"}</span>&nbsp;&nbsp;Artificial Intelligence for Next-Generation IoT <MdFiberNew size={25} color={"red"} />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <a href={fl1} target="_blank" rel="noreferrer">
                  <div className="appPortalBtn" style={{ width: 210 }}>
                    <AiFillFileText /> Flyer of the program
                  </div>
                </a>
                <br />
                <br />
                <p>To Register for the program <a href="https://docs.google.com/forms/d/e/1FAIpQLSe5EhmIBN-a0Ayk7LSq7GKaYVoDJzpTfpQuBrcZqgYEDqB-hw/viewform">click here</a></p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="accordionCard">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="3"
              className="accordionHeader"
              onClick={() => {
                handleAccClick(3);
              }}
            >
              <span>{accKey === 3 ? "-" : "+"}</span>&nbsp;&nbsp;Artificial Intelligence assisted Sustainable Environment Development(AISED) <MdFiberNew size={25} color={"red"} />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <a href={fl3} target="_blank" rel="noreferrer">
                  <div className="appPortalBtn" style={{ width: 210 }}>
                    <AiFillFileText /> Flyer of the program
                  </div>
                </a>
                <br />
                <br />
                <p>To Register for the program <a href="https://docs.google.com/forms/d/e/1FAIpQLSeb-h_qgOiHPS7f5Au5rmZyEEtA3iVmSjx3mJPVzGE08o8xQg/viewform">click here</a></p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  )
}

export default Gelp;