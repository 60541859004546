import { profholger, profjean, profmalte, profsilke } from "../../../../assets";

export const data = [
	{
		name: "Prof. Holger Braunschweig",
		img: profholger,
		university: "University of Würzburg, Germany",
		topic: "Borametallomimetics - Activation of Small Molecules by Low-valent Boron Species ",
		datetime: "February 4, 2021. (Time 04:00 PM)",
	},
	{
		name: "Prof. Malte Brasholz",
		img: profmalte,
		university: "University of Rostock, Germany",
		topic: "New photocatalytic reactions: Updates on radical cascades and photooxygenations",
		datetime: "February 11, 2021. (Time 04:00 PM)",
	},
	{
		name: "Prof. Jean-François Halet",
		img: profjean,
		university: "Universitéde Rennes, CNRS, ISCR (Institut des Sciences Chimiques de Rennes), France",
		topic: "Organometallic Wires for Electronics ",
		datetime: "February 16, 2021. (Time 04:00 PM)",
	},
	{
		name: "Prof. Silke Behrens",
		img: profsilke,
		university: "Karlsruhe Institute of Technology, Germany",
		topic: "Nanoparticle-Derived Functional Materials and Model Catalysts ",
		datetime: "February 26, 2021. (Time 04:00 PM)",
	},
];
