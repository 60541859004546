export const data = [
    {
        name: "Ms. Brigita De Vega",
        country: "United Kingdom",
        program: "Short-term Research Fellowship PhD",
        text: "Ms. Brigita De Vega is an International Short-term Research Fellowship PhD Student at the Department of Chemistry of Indian Institute of Technology Indore. She is native to Indonesia and has joined IITI for a period of 3 months. She is currently pursuing her PhD from University College London, UK.",
        VidLink: "https://youtube.com/embed/FaXrrZsYtdc"
    },
    {
        name: "Ms. Halima Benali",
        country: "Morocco",
        program: "Short-term Research Fellowship PhD",
        text: "Ms. Halima Benali is an International Short-term Research Fellowship PhD Student at the Department of Mathematics of Indian Institute of Technology Indore. She is native to Morocco and has joined IITI for a period of 3 months. She is currently pursuing her PhD from Sultan Moulay Slimane University, Morocco.",
        VidLink: "https://www.youtube.com/embed/GfjY2ouPGrw"
    },
    {
        name: "Ms. Dibyashree Poudyal",
        country: "Nepal",
        program: "Short-term Research Fellowship PhD",
        text: "Ms. Dibyashree Poudyal is an International Short-term Research Fellowship PhD Student at the Department of Civil Engineering of Indian Institute of Technology Indore. She is native to Nepal and have joined IITI for a period of 3 months. She is currently pursuing her PhD from Infrastructure University Kuala Lumpur, Malaysia.",
        VidLink: "https://www.youtube.com/embed/sXmabrXyPHc"
    },
    {
        name: "Mr. Raghu Nath Prajapati",
        country: "Nepal",
        program: "Short-term Research Fellowship PhD",
        text: "Mr. Raghu Nath Prajapati is an International Short-term Research Fellowship PhD Student at the Department of Civil Engineering of Indian Institute of Technology Indore. He is native to Nepal and have joined IITI for a period of 3 months. He is currently pursuing his PhD from Infrastructure University Kuala Lumpur, Malaysia.",
        VidLink: "https://www.youtube.com/embed/tlhDO6AmTeM"
    },
    {
        name: "Mr. Johirul Islam",
        country: "Finland",
        program: "PhD",
        text: "Mr. Johirul Islam is a PhD exchange student at the Department of Computer Science and Engineering of Indian Institute of Technology Indore. He is native to Finland and have joined IITI for a period of 3 months.",
        VidLink: "https://www.youtube.com/embed/vqlAqgq4fFI"
    },
    {
        name: "Mr. Bimal Bashyal",
        country: "Nepal",
        program: "M.Tech. (MSD)",
        text: "Mr. Bimal Bashyal is an MTech Self-funded student of MSD Department of the Indian Institute of Technology Indore. He is native to Nepal and have joined the program for the academic year 2022-24.",
        VidLink: "https://www.youtube.com/embed/Pp9YeQWaU5Q"
    },
    {
        name: "Ms. Lidiya Kumssa Fekadu",
        country: "Ethiopia",
        program: "MTech (Space Engineering)",
        text: "Ms. Lidiya Kumssa Fekadu is enrolled in MTech program (Space Engineering) for the Academic year 2022-24. She is native to Ethiopia and have joined the Indian Institute of Technology Indore through Study in India Fellowship.",
        VidLink: "https://www.youtube.com/embed/6vjxD6krwIA"
    },
    {
        name: "Mr. Arifuzzaman Khan",
        country: "Bangladesh",
        program: "Ph.D. (HSS)",
        text: "Mr. Arifuzzaman Khan is a Ph.D. scholar of HSS Discipline at Indian Institute of Technology Indore. He is native to Bangladesh and have enrolled himself into the program in 2022 through SAARC Finance - RBI Scholarship.",
        VidLink: "https://www.youtube.com/embed/neyL0PauNog"
    },
    {
        name: "Mr. Tim Seedorf",
        country: "Germany",
        program: "PhD (Chemistry)",
        text: "Mr. Tim Seedorf is a PhD Exchange Student at the Department of Chemistry, Indian Institute of Technology Indore. He is native to Germany and have come for a period of 6 Weeks (2022-23) to our institute.",
        VidLink: "https://www.youtube.com/embed/Iea62B9NjnM"
    },
    {
        name: "Mr. Akash Yadav",
        country: "Nepal",
        program: "MTech (PIE)",
        text: "Mr. Akash Yadav is an MTech student of PIE Department of the Indian Institute of Technology Indore. He is native to Nepal and have joined the program for the academic year 2021-23 through Nepal Scholarship.",
        VidLink: "https://www.youtube.com/embed/iHpWzXqU3hg"
    },
    {
        name: "Mr. Nehal Ahmad",
        country: "Taiwan",
        program: "PhD (Mathematics)",
        text: "Mr. Nehal Ahmad is a PhD Exchange Student at the Department of Mathematics, Indian Institute of Technology Indore. He is native to Taiwan and have come for a period of 9 Months (2021-22) to our institute.",
        VidLink: "https://www.youtube.com/embed/JLxoWHLq61U"
    },
    {
        name: "Mr. Akah Precious Chiemena",
        country: "Nigeria",
        program: "MTech (CSP)",
        text: "Mr. Akah Precious Chiemena is an MTech student at Indian Institute of Technology Indore enrolled for the academic year 2021-23 in CSP branch. He is native to Nigeria and has come through the ICCR Fellowship.",
        VidLink: "https://www.youtube.com/embed/2Ur38lintdE"
    },
    {
        name: "Mr. Pawan Singh",
        country: "Nepal",
        program: "Ph.D. (HSS)",
        text: "Mr. Pawan Singh is a Ph.D. scholar of HSS Discipline at Indian Institute of Technology Indore. He is native to Nepal and have enrolled himself into the program in 2021 through Nepal Scholarship.",
        VidLink: "https://www.youtube.com/embed/MlLchXnXRQA"
    },

]