// Marquee.js
import React from 'react';
import { MdFiberNew } from "react-icons/md";
import { Link } from 'react-router-dom';
import styled, { keyframes } from "styled-components";
import './Marquee.css'; // Create this CSS file for styling

function Marquee() {
    return (
      <div class="Marquee">
        <Marquee_stop className='Marquee_stop'>
          <span style={{ display: 'inline-block' }} className="marquee-links">
          
          <Link to='/outreach/news'className="marquee_a">
            <MdFiberNew size={25} color="red" />
            <h5 style={{ 
              display: 'inline', 
              marginRight: 20,
              marginLeft: 5, 
              verticalAlign: 'middle' }}>Fellowships Selected Candidates</h5>
          </Link>
            <Link to='/outbound/Faculty/internationalgrant' className="marquee_a">
              <MdFiberNew size={25} color="red" />
              <h5 style={{ 
                display: 'inline', 
                marginRight: 20,
                marginLeft: 5, 
                verticalAlign: 'middle'
                }}>International Travel Grant 2024-25 [Deadline Extended]</h5>
            </Link>
            <Link to='/outbound/Faculty/mobility_Grant' className="marquee_a">
              <MdFiberNew size={25} color="red" />
              <h5 style={{ 
                display: 'inline', 
                marginRight: 20,
                marginLeft: 5, 
                verticalAlign: 'middle'
                , }}>Mobility Grant 2024-25</h5>
            </Link>
            <Link to='/inbound/students/srp-is' className="marquee_a">
              <MdFiberNew size={25} color="red" />
              <h5 style={{ 
                display: 'inline', 
                marginRight: 20,
                marginLeft: 5, 
                verticalAlign: 'middle'
                }}>Short-Term Research Fellowships for International PhD Students [Deadline Extended]</h5>
            </Link>
            <Link to='/inbound/students/srp-ugpg' className="marquee_a">
              <MdFiberNew size={25} color="red" />
              <h5 style={{ 
                display: 'inline', 
                marginRight: 20,
                marginLeft: 5, 
                verticalAlign: 'middle' 
                }}>Semester Exchange Program International UG and PG Students' [Deadline Extended]</h5>
            </Link>
            <Link to='/outbound/students/srp-iitis'className="marquee_a">
              <MdFiberNew size={25} color="red" />
              <h5 style={{ 
                display: 'inline', 
                marginRight: 20,
                marginLeft: 5, 
                verticalAlign: 'middle' 
                }}>Short-Term Research Fellowships for the PhD Students of IIT Indore [Deadline Extended]</h5>
            </Link>
            <Link to='/outbound/ug_pg_fellowship'className="marquee_a">
              <MdFiberNew size={25} color="red" />
              <h5 style={{ 
                display: 'inline', 
                marginRight: 20,
                marginLeft: 5, 
                verticalAlign: 'middle' }}>Semester Exchange Program for IIT Indore UG and PG Students' [Deadline Extended]</h5>
            </Link> 
            <Link to='/opportunities/internships'className="marquee_a">
              <MdFiberNew size={25} color="red" />
              <h5 style={{ 
                display: 'inline', 
                marginRight: 20,
                marginLeft: 5, 
                verticalAlign: 'middle' }}>Opportunities</h5>
            </Link>   
      </span>
    </Marquee_stop>
      </div>
    );
  }
  
  const movingText = keyframes`
0%{
  transform:translateX(100%);
}
100%{
  transform:translateX(-100%);
}
`;

const Marquee_stop = styled.p`
  overflow: hidden;
  @media (prefers-reduced-motion: no-preference) {
    animation: ${movingText} 35s linear infinite;
    animation-delay: -80s;
    :hover {
      animation-play-state: paused;
    }
  }
  cursor: default;
`;

  export default Marquee;
