export const data = [
  // {
  //   key: "1",
  //   Ranking: 4,
  //   Heading: "THE World University Ranking 2022 India",
  // },
  {
    key: "2",
    Ranking: 16,
    Heading: "NIRF 2024 Engineering",
  },
  {
    key: "3",
    Ranking: 33,
    Heading: "NIRF 2024 Overall",
  },
  // {
  //   key: "4",
  //   Ranking: 9,
  //   Heading: "QS World University Ranking 2023 India"
  // },
  {
    key: "5",
    Ranking: 185,
    Heading: "QS World University Ranking 2024 Asia",
  },
  // {
  //   key: "6",
  //   Ranking: 84,
  //   Heading: "THE Emerging Economies 2022",
  // },
  // {
  //   key: "7",
  //   Ranking: 86,
  //   Heading: "THE Young University Ranking 2022",
  // },
  {
    key: "8",
    Ranking: 477,
    Heading: "QS World University Ranking 2024 World",
  },
];
