export const data = {
  Asia: [
    {
      "Institutions/Organization/Country": "National Yang Ming Chiao Tung University (NYCU), Taiwan",
      Nature: "MoU",
      "Purpose/Title": "Exchange of staff and researchers, Student exchange program, Collaborative research projects, and other academic activities of mutual consent.",
       "Coordinating faculty member": "DoIR\r\nDirector",
      "Signed on": "September 20, 2023",
      Duration: "5 Years",
      "Country with which there is collation": "Taiwan"
    },
    {
      "Institutions/Organization/Country": "National Ilan University Taiwan",
      Nature: "MoU",
      "Purpose/Title": "To enhance relations between the two institutions and to develop academic and cultural interchange in the areas of education, research, and other activities, and agree to cooperate and work together toward the internationalization of higher education through this partnership.",
       "Coordinating faculty member": "Prof. Avinash Sonawane, DoIR\r\n,Director",
      "Signed on": "May 19, 2023",
      Duration: "5 Years",
      "Country with which there is collation": "Taiwan"
    },
    {
      "Institutions/Organization/Country": "National Taipei University of Technology, Taiwan",
      Nature: "MoU",
      "Purpose/Title": "1) Exchange of faculty members, research scientists, Post-doctrol Fellows, and staff members\r\n2) Exchange of students,\r\n3) Joint supervision of Masters and PhD students\r\n4) Development of Curricula. 5) Exchange of Academic information and materials 6) Exchange of periodicals, publications, and research materials in the field of mutual interest without violating the copyrights and terms and conditions of subscription 7) Conduct academic activities such as joint research programs, Lectures, Symposium, Conferences, Workshops, any mutually beneficial projects and activities. 8) Conduct other academic exchanges agreeable to both the institutes 9) To explore the possibilities of internships, joint degree and sandwich programs",
       "Coordinating faculty member": "Dr. M. Tanveer\r\nDoIR",
      "Signed on": "January 17, 2022",
      Duration: "5 Years",
      "Country with which there is collation": "Taiwan"
    },
    {
      "Institutions/Organization/Country": "National Dong Hwa University, Taiwan, R.O.C",
      Nature: "MoU",
      "Purpose/Title": "Students and academic and administrative staff exchanges;Joint research activities;Exchanges of academic materials",
       "Coordinating faculty member": "Dr. Rupesh S. Devan",
      "__EMPTY_5": "DoRD",
      "Signed on": "November 24, 2017",
      "Country with which there is collation": "Taiwan"
    },
    {
      "Institutions/Organization/Country": "Ming Chi University Of Technology, Taiwan",
      Nature: "MoU",
      "Purpose/Title": "Academic and Research Collaboration, Scholarship Program to PhDStudents",
       "Coordinating faculty member": "Dr. Somaditya Sen\r\nDoRD",
      "Signed on": "May 8, 2017",
      Duration: "5 Years",
      "Country with which there is collation": "Taiwan"
    },
    {
      "Institutions/Organization/Country": "Ming Chi University of Technology, New Taipei, Taiwan",
      Nature: "MoU",
      "Purpose/Title": "Academic and Research Collaboration",
       "Coordinating faculty member": "Dr. Somaditya Sen\r\nDoRD",
      "Signed on": "February 11, 2016",
      Duration: "5 Years",
      "Country with which there is collation": "Taiwan"
    },
    {
      "Institutions/Organization/Country": "Kwansei Gakuin University, Japan",
      Nature: "Agreement",
      "Purpose/Title": "Wishes to enhance relations between the two institutions and to develop academic and cultural interchange in the areas of education, research, and other activities, agree to cooperate and work together toward the internationalization of higher education.",
       "Coordinating faculty member": "Professor Pritee Sharma,\r\nDirector",
      "Signed on": "February 21, 2023",
      Duration: "5 Years",
      "Country with which there is collation": "Japan"
    },
    {
      "Institutions/Organization/Country": "Shinshu University Japan",
      Nature: "MoU",
      "Purpose/Title": "Academic Cooperation and Exchange of students",
       "Coordinating faculty member": "Dr. Kapil Ahuja",
      "Signed on": "July 28, 2020",
      Duration: "5 Years",
      "Country with which there is collation": "Japan"
    },
    {
      "Institutions/Organization/Country": "Nagaoka University of Technology",
      Nature: "Agreement",
      "Purpose/Title": "The exchange of academic, research and administrative staffs;The exchange of students;Joint research on scientific and technological subjects;The exchange of academic findings, publications, and other academic and educational information as appropriate; and Other academic and educational collaboration and exchnage as approved by the two universities/ instittues.",
      "Coordinating faculty member": "HoD, MEMS\r\nDirector",
      "Signed on": "July 6, 2018",
      Duration: "5 Years",
      "Country with which there is collation": "Japan"
    },
     
    {
      "Institutions/Organization/Country": "Graduate School of Life Science and Systems Engineering, Kyushu Institute of Technology, Japan",
      "Purpose/Title": "Exchange of students, faculty members for research, discussion and lectures;Joint research activities;Exchanges of academic materials",
       "Coordinating faculty member": "Dr. Vinod Kumar\r\nDoRD",
      "Signed on": "April 18, 2018",
      Duration: "5 Years",
      "Country with which there is collation": "Japan"
    },
    {
      "Institutions/Organization/Country": "Enricssion Co. Ltd.",
      Nature: "MoU",
      "Purpose/Title": "Establishment of Shiru Café at IIT",
       "Coordinating faculty member": "Prof. Pradeep Mathur\r\nDirector",
      "Signed on": "March 4, 2016",
      "Country with which there is collation": "Japan"
    },
    {
      "Institutions/Organization/Country": "Kyushu Institute of Technology, Japan",
      Nature: "MoU",
      "Purpose/Title": "Academic",
       "Coordinating faculty member": "Dr. Vipul Singh\r\nDirector",
      "Signed on": "June 11, 2012",
      Duration: "5 Years",
      "Country with which there is collation": "Japan"
    },
    
    {
      "Institutions/Organization/Country": "Rajamangala University of Technology PHRA Nakhon",
      Nature: "MoU",
      "Purpose/Title": "Exchanging of schoalrs, students, academic information and materials to enhance the research and educational process",
       "Coordinating faculty member": "Dr. Kapil Ahuja\r\nDirector",
      "Signed on": "February 20,2020",
      Duration: "5 Years",
      "Country with which there is collation": "Thailand"
    },
    {
      "Institutions/Organization/Country": "Thammsasat University Bangkok Thailand",
      Nature: "MoU",
      "Purpose/Title": "Exchange of students, scholars, researchers and staff; Collborative activities such as oint research, lectures and symposium;Sharing of information, publications, documents, and research materials in fields of mutual interest;Other mutually beneficial projects;",
       "Coordinating faculty member": "Dr. Kapil Ahuja\r\nDirector",
      "Signed on": "December 24, 2019",
      Duration: "5 Years",
      "Country with which there is collation": "Thailand"
    },
  ],
  Australia: [    {
    "Institutions/Organization/Country": "Swinburne University of Technology, Australia",
    Nature: "MoU",
    "Purpose/Title": "To establish and expand academic cooperation between the two institutes",
     "Coordinating faculty member": "Dr. Ruchi Sharma\r\nDoIR",
    "Signed on": "October 8, 2021",
    Duration: "5 Years",
    "Country with which there is collation": "Australia"
  },
  {
    "Institutions/Organization/Country": "Edith Cowan University, Perth Australia",
    Nature: "MoU",
    "Purpose/Title": "Research visit of faculty members, Research Scientists, Postdoctoral Associates and students;Joint supervision of M.S. and Ph.D. students;Development of curricula;Promote international cooperation and academic exchange",
     "Coordinating faculty member": "Dr. Nirmala Menon",
    "__EMPTY_5": "DoRD",
    "Signed on": "May 23, 2019",
    Duration: "5 Years",
    "Country with which there is collation": "Australia"
  },
  {
    "Institutions/Organization/Country": "Deakin University, Australia",
    Nature: "MoU",
    "Purpose/Title": "Research activities, possible short-course programs and joint research degree",
     "Coordinating faculty member": "Dr. Pankaj R. Sagdeo\r\nDoRD",
    "Signed on": "March 6, 2013",
    Duration: "5 Years",
    "Country with which there is collation": "Australia"
  }
],
  Africa: [    {
    "Institutions/Organization/Country": "University of Johannesburg",
    Nature: "MoU",
    "Purpose/Title": "Areas of joint activity may include (but are not necessarily limited to) the following:1) Exchange of the academic employees,2) Exchange of the students,3) Explore the establishment and promotion of joint research in different fields of Technology and Engineering. This may include conducting collaborative research projects and the exchange of academic information and materials 4) Other activities as may be mutually agreed upon.",
     "Coordinating faculty member": "DoIR\r\nDirector",
    "Signed on": "September 1, 2021",
    Duration: "3 Years",
    "Country with which there is collation": "South Africa"
  }
],
Americas: [
  {
      
    "Institutions/Organization/Country": "International Food Policy Research Institute (IFPRI), USA",
    Nature: "MoU",
    "Purpose/Title": "Exchange of faculty and researchers, joint research projects, and activities of mutual consent.",
     "Coordinating faculty member": " Dr. Mohanasundari Thangavel",
    "Signed on": "July 12, 2023",
    Duration: "5 Years",
    "Country with which there is collation": "USA"
  },
  {
    "Institutions/Organization/Country": "Rsayan Inc, USA",
    Nature: "MoU",
    "Purpose/Title": "Collaborative research opportunities",
     "Coordinating faculty member": "Prof. Apurba K. Das,\r\nDoRD",
    "Signed on": "April 26, 2023",
    Duration: "5 Years",
    "Country with which there is collation": "USA"
  },
  {

    "Institutions/Organization/Country": "The University of Kansas, US",
    Nature: "MoU",
    "Purpose/Title": "1) Joint research and educational activities\n2) Exchange of invitations to scholars for lectures and visits, invitations to participate in conferences, symposia, and seminars. (Faculty, research personnel and graduate students)\n3) Exchange of information in fields of interest to both the parties\n4) Exchange of faculty, research personnel, and students for study and research\n5) Exchange of academic material of mutual interest. (Scholarly publications, curriculum information, and pertinent research reports)",
     "Coordinating faculty member": "Dr. Venkatesh Chelvam",
    "__EMPTY_5": "Director DoIR",
    "Signed on": "March 16, 2022",
    Duration: "5 Years",
    "Country with which there is collation": "USA"
  },
  {
    "Institutions/Organization/Country": "Foundation for Innovation and Social Entrepreneurship (FISE), Texas Global Health Security Innovation Consortium (TEXGHS) and Health Innovation Exchange(HIEx)",
    Nature: "MoU",
    "Purpose/Title": "To collaborate and set-up India's first Global Health Security Intelligence Hub with special focus on Pandemic preparedness, prevention and Management.",
     "Coordinating faculty member": "Prof. Avinash Sonawane, DoIR\r\nDirector",
    "Signed on": "August 1, 2021",
    Duration: "5 Years",
    "Country with which there is collation": "USA &Switzerland"
  },
  {
    "Institutions/Organization/Country": "UT-Battelle, LLC",
    Nature: "Cooperation Agreement",
    "Purpose/Title": "Operator of Oak Ridge National Laboratory ( hereinafter \"Laboratory\" or \"ORNL\" under Prime contract No. DE-AC05-00OR22725 with the United States Government( hereinafter \"U.S. Government\" or \"Government\"), as represented by the United States Department of Energy (hereinafter \"DOE\") and IIT Indore",
     "Coordinating faculty member": "Dr. Shaibal Mukherjee\r\nRegistrar",
    "Signed on": "July 27, 2017",
    Duration: "5 Years",
    "Country with which there is collation": "USA"
  },
  
  {
    "Institutions/Organization/Country": "The University of Wiscosin, Madison, USA",
    Nature: "MoU",
    "Purpose/Title": "Specific Astro Physical Research Projects",
     "Coordinating faculty member": "Dr. Siddharth S. Malu\r\nDirector",
    "Signed on": "January 28, 2013",
    Duration: "10 Years",
    "Country with which there is collation": "USA"
  },
  {
    "Institutions/Organization/Country": "Uchicago, Argonne LLC, IL, USA",
    Nature: "MoU",
    "Purpose/Title": "Academic",
     "Coordinating faculty member": "Prof. Pradeep Mathur\r\nDirector",
    "Signed on": "December 5, 2012",
    Duration: "5 Years",
    "Country with which there is collation": "USA"
  },
  {
    "Institutions/Organization/Country": "University of Wisconsin-Milwaukee, USA",
    Nature: "MoU",
    "Purpose/Title": "Academic",
     "Coordinating faculty member": "Prof. Pradeep Mathur\r\nDirector",
    "Signed on": "October 3, 2012",
    Duration: "Till mutually agreed",
    "Country with which there is collation": "USA"
  },
  {
    "Institutions/Organization/Country": "New Mexico State University, USA",
    Nature: "MoU",
    "Purpose/Title": "Academic",
     "Coordinating faculty member": "Prof. Pradeep Mathur\r\nDirector",
    "Signed on": "October 1, 2012",
    Duration: "5 Years",
    "Country with which there is collation": "USA"
  },
  {
    "Institutions/Organization/Country": "Carleton University",
    Nature: "MoU",
    "Purpose/Title": "Student mobility, exchange of faculty and staff, joint research projects, and activities of mutual consent.",
     "Coordinating faculty member": "Dr. Gaurab Sil,\r\nDirector",
    "Signed on": "June 21, 2023",
    Duration: "5 Years",
    "Country with which there is collation": "Canada"
  },
  {     
    "Institutions/Organization/Country": "The Governors of The University of Alberta",
    Nature: "MoU",
    "Purpose/Title": "Exchange of faculty members, research scientists, and postdoctoral associates • Exchange of students • Exchange of administrative staff • Joint supervision of M.S. and Ph.D. students • Development of curricula • Exchange of academic information and materials • Exchange of periodicals and other publications • Submission and execution of joint research programs • Organization of joint research programs • Organization of joint conferences • Organization of other academic exchanges agreeable to both Institute(s)/University",
     "Coordinating faculty member": "Prof. Avinash Sonawane, DoIR",
    "Signed on": "May 25, 2021",
    Duration: "5 Years",
    "Country with which there is collation": "Canada"
  },
],
Europe: 
     [
      {
      
        "Institutions/Organization/Country": "INSTITUT Mines -Telecom, France   ",
        Nature: "MoU",
        "Purpose/Title": "Exchange of faculty and researchers, joint research programs, joint supervision, and activities of mutual consent.",
         "Coordinating faculty member": "DoIR",
        "Signed on": "September 9, 2023",
        Duration: "5 Years",
        "Country with which there is collation": "France"
      },
      {
        "Institutions/Organization/Country": "Sapienza University, Italy",
        Nature: "Agreement",
        "Purpose/Title": "Exchange of visits by scholars and scientific papers, participation in research programs, organisation of scientific meetings and seminars.",
         "Coordinating faculty member": "DorD\r\nDr. Mirza S. Baig",
        "Signed on": "August 30, 2023",
        Duration: "5 Years",
        "Country with which there is collation": "Italy"
      },
      {
      
        "Institutions/Organization/Country": "Lodz University of Technology",
        Nature: "MoU",
        "Purpose/Title": "1. Exchange of undergraduate and/or graduate students\n2. Exchange of faculty and/or staff\n3. Joint research activities and publications\n4. Participation in seminars and academic meetings  Coordinating faculty member\n5. Exchange of academic materials and other information\n6. Special short-term academic programs",
         "Coordinating faculty member": "DoIR",
        "Signed on": "August 03, 2023",
        Duration: "5 Years",
        "Country with which there is collation": "Poland"
      },
      {
        "Institutions/Organization/Country": "Centre national de la recherche scientifique (CNRS)",
        Nature: "MoU",
        "Purpose/Title": "Research, training for Masters level and upwards, and scientific mobility, technical information, Exchange of researchers and students, Workshops, seminars and conferences.",
         "Coordinating faculty member": "Prof. Raghunath Sahoo,\r\nDirector",
        "Signed on": "June 14, 2023",
        Duration: "5 Years",
        "Country with which there is collation": "France"
      },
      {
        "Institutions/Organization/Country": "University of Regensburg",
        Nature: "MoU",
        "Purpose/Title": "Exchange of students and academic staff, faculty visits.",
         "Coordinating faculty member": "Dr. Debayan Sarkar",
        "__EMPTY_5": "DoIR",
        "Signed on": "June 05, 2023",
        Duration: "5 Years",
        "Country with which there is collation": "Germany"
      },
    
    
      {
        "Institutions/Organization/Country": "Julius- Maximilians-University Wurzburg (JMUW)",
        Nature: "MoU",
        "Purpose/Title": "The aim of this Memorandum of Understanding is to establish and expand academic cooperation between IITI and JMUW",
         "Coordinating faculty member": "Prof. Avinash Sonawane, DoIR\r\nDirector",
        "Signed on": "March 13, 2023",
        Duration: "5 Years",
        "Country with which there is collation": "Germany"
      },
   
      {
 
        "Institutions/Organization/Country": "University of Zilina in Zilina",
        Nature: "MoU",
        "Purpose/Title": "1.Both Parties agree to promote employee and student exchange and cooperation programmes between the two Parties for educational and research purposes. Such programmes may include:(i)\u0001Exchange of employees and students in accordance with requirements accepted by both Parties.(ii)\u0001Exchange of information about scientific achievements, organisation of conferences and symposia as well as scientific publications in fields of mutual interest.(iii)\u0001Joint research in the fields of mutual interest in accordance with available funds and joint publications of the results of this research.(iv)\u0001Joint educational project in the fields of mutual interest in accordance with available funds and joint publications of the results of this activity.",
         "Coordinating faculty member": "Professor Vimal Bhatia,\r\nDirector",
        "Signed on": "December 13, 2022",
        Duration: "5 Years",
        "Country with which there is collation": "Slovakia"
      },
     
      {
     
        "Institutions/Organization/Country": "The University of Bordeaux",
        Nature: "MoU",
        "Purpose/Title": "The purpose of this Agreement is to establish the terms for the Master's Student Exchange Program in the field of Chemistry to the Parties (hereinafter referred to as \"the Program\").",
         "Coordinating faculty member": "Dr. Chelvam Venkatesh,\r\nDirector",
        "Signed on": "November 29, 2022",
        Duration: "5 Years",
        "Country with which there is collation": "France"
      },
      {
 
        "Institutions/Organization/Country": "EIVP, France",
        Nature: "MoU",
        "Purpose/Title": "Study and research placement (10-12 weeks),  and other academic activities of mutual consent.",
         "Coordinating faculty member": "Dr. Gaurab Sil\r\nDirector",
        "Signed on": "November 14, 2022",
        Duration: "5 Years",
        "Country with which there is collation": "France"
      },
      {
        "Institutions/Organization/Country": "University West Sweden.",
        Nature: "MoU",
        "Purpose/Title": "By signing this agreement the two parties indicate their willingness to encourage and facilitate cooperation and contact in the following areas through:•\u0001the exchange of students•\u0001the exchange of academic and professional staff•\u0001joint research activities•\u0001other actives as mutually agreed",
         "Coordinating faculty member": "Professor Vimal Bhatia\r\nDirector",
        "Signed on": "October 18, 2022",
        Duration: "This agreement shall commence on the mutually agreed upon execution date and shall remain in effect until terminated by either institution",
        "Country with which there is collation": "Sweden"
      },
      {

        "Institutions/Organization/Country": "Universite de Lille, France",
        Nature: "MoU",
        "Purpose/Title": "IIT Indore and the Universite de Lille through this Memorandum of Understanding intends to establish and strengthen relations to contribute to the development of educational, research, and cultural activities.",
         "Coordinating faculty member": "Prof. Avinash Sonawane, DoIR\r\nDirector",
        "Signed on": "June 23, 2022",
        Duration: "5 Years",
        "Country with which there is collation": "France"
      },
      {
        "Institutions/Organization/Country": "University of Agder, Norway",
        Nature: "MoU",
        "Purpose/Title": "IIT Indore and the University of Agder, Norway through this MoU intend to recognize the mutual interest in the fields of research, development, education, training, transfer of technology, and dissemination of knowledge on a long-term non-commercial basis, and also recognize the importance of institutes of higher education’s role in promoting international collaboration and increased contribution of social development.",
         "Coordinating faculty member": "Dr. Santosh K. Vishvakrma\r\nDoIR",
        "Signed on": "May 3, 2022",
        Duration: "5 Years",
        "Country with which there is collation": "Norway"
      },
      {
        "Institutions/Organization/Country": "Széchenyi István University Győr, Hungary",
        Nature: "MoU",
        "Purpose/Title": "IIT Indore and the Széchenyi István University Győr, Hungary through this MoU aims to establish a spirit of cooperation in pursuing educational, research and training opportunities to foster the development of the parties via collaborative endeavors.",
         "Coordinating faculty member": "Prof. Avinash Sonawane, DoIR\r\nDirector",
        "Signed on": "June 23, 2022",
        Duration: "5 Years",
        "Country with which there is collation": "Hungary"
      },
      {
        "Institutions/Organization/Country": "University of Luxembourg",
        Nature: "MoU",
        "Purpose/Title": "This agreement is based on the principle of reciprocity and expresses the interest of both Institute(s)/University in exchanging scholars, students, academic information and materials in the belief that the research and educational process at both the institutions will be enhanced and that mutual understanding between their respective scholars and students will be increased by the establishment of such exchange programs.",
         "Coordinating faculty member": "Dr. Sumit Gautam\r\nDirector DoIR",
        "Signed on": "April 5, 2022",
        Duration: "5 Years",
        "Country with which there is collation": "Luxembourg"
      },
      {
        "Institutions/Organization/Country": "Leibniz University Hannover, Germany",
        Nature: "MoU",
        "Purpose/Title": "This agreement is based on the principle of reciprocity and expresses the interest of both Institute(s)/University in exchanging scholars, students, academic information and materials in the belief that the research and educational process at both the institutions will be enhanced and that mutual understanding between their respective scholars and students will be increased by the establishment of such exchange programs.",
         "Coordinating faculty member": "Dr. Kapil Ahuja\r\nDirector",
        "Signed on": "March 17, 2022",
        Duration: "5 Years",
        "Country with which there is collation": "Germany"
      },
      {
        "Institutions/Organization/Country": "Faculty of Mathematics and General Sciences, University of Rostock, Germany",
        Nature: "MoU",
        "Purpose/Title": "1) Student training\n2) Exchange of students\n3) Exchange Academic Staff\n4) Exchange of Scientific, Educational and Scholarly Materials\n5) Exchange of information\n6) Special Projects\n7) Faculty member’s visits",
         "Coordinating faculty member": "Dr. Venkatesh Chelvam\r\nDirector DoIR",
        "Signed on": "March 30, 2022",
        Duration: "5 Years",
        "Country with which there is collation": "Germany"
      },
    
    
  
      {

        "Institutions/Organization/Country": "Wigner Research Centre for Physics, Hungarian Academy of Sciences",
        Nature: "MoU",
        "Purpose/Title": "To reach an agreement on collaboration between the Participants in the mutually agreed areas in general but high-energy nuclear physics in particular.",
         "Coordinating faculty member": "Dr. Raghunath Sahoo Dr. Pankaj Sagdeo Dr. Rajesh Kumar\r\nDoIR",
        "Signed on": "September 7, 2021",
        Duration: "5 Years",
        "Country with which there is collation": "Hungary"
      },
      {
 
        "Institutions/Organization/Country": "Aristotle University of Thessaloniki, Greece",
        Nature: "MoU",
        "Purpose/Title": "1) Exchange of students,\r\n2) Exchange of Academic staff,\r\n3) Exchange of Scientific, Scholarly and Educational materials\r\n4) Exchange of Information. 5) Special Projects 6) Faculty Members visit",
         "Coordinating faculty member": "Dr. Manish K. Goyal\r\nDirector DoIR",
        "Signed on": "August 26, 2021",
        Duration: "5 Years",
        "Country with which there is collation": "Greece"
      },
  
   
      {
        "Institutions/Organization/Country": "The University of Gothenberg, Sweden",
        Nature: "MoU",
        "Purpose/Title": "1) Exchange of faculty and experts 2)Exchange of students for academic education, training, practical experience and training 3) Cooperative research 4) Technical assistance and/or training 5) Other cooperative efforts as mutually agreed from time to time.",
         "Coordinating faculty member": "Dr. Devendra Deshmukh\r\nDoIR",
        "Signed on": "June 15, 2021",
        Duration: "5 Years",
        "Country with which there is collation": "Sweden"
      },
  
      {
    
        "Institutions/Organization/Country": "Universitàdeglistudi Di Udine",
        Nature: "MoU",
        "Purpose/Title": "a) exchange of information, as well as exchange of experiences in research and practice in cooperation b) joint events, conferences, workshops and presentations with the goal of exchanging of scientific and technical experiences c) development, acquisition and conducting of joint projects d) joint publications by co-workers of both the Institutes e) exchange of co-workers from both the Institutes",
         "Coordinating faculty member": "Dr. Neelima Satyam",
        "Signed on": "May 24, 2021",
        Duration: "5 Years",
        "Country with which there is collation": "Italy"
      },
      {
    
        "Institutions/Organization/Country": "The faculty of Electrical Engineering, Computer Science and Information Technology Osijek, within Josip Juraj Strossmayer University of Osijek",
        Nature: "MoU",
        "Purpose/Title": "General agreement for academic, scientific, technological and cultural cooperation",
         "Coordinating faculty member": "Prof. Avinash Sonawane, DoIR\r\nDirector",
        "Signed on": "May 24, 2021",
        Duration: "5 Years",
        "Country with which there is collation": "Croatia"
      },
      {
        "Institutions/Organization/Country": "Forschungszentrum Borstel - Leibniz Lung Center",
        Nature: "MoU",
        "Purpose/Title": "(i) Exchange of faculty and experts (ii) Exchange of students for academic education, training, practical experience, and research (iii) Exchange of technologies (iv) Cooperative research (v) Technical assistance and/or training (vi) Other cooperative efforts as mutually agreed from time to time.",
         "Coordinating faculty member": "Prof. Avinash Sonawane, DoIR\r\nDirector",
        "Signed on": "April 30, 2021",
        Duration: "5 years",
        "Country with which there is collation": "Germany"
      },
      {
        "Institutions/Organization/Country": "Dipartimento di Scienze Biomedichedell’ Università di Sassari (UNISS); Sassari, Italy",
        Nature: "MoU",
        "Purpose/Title": "Academic collaboration",
         "Coordinating faculty member": "Prof. Avinash Sonawane, DoIR\r\nDirector",
        "Signed on": "April 9, 2021",
        Duration: "5 years",
        "Country with which there is collation": "Italy"
      },
      {
        "Institutions/Organization/Country": "Western Norway Research Institute",
        Nature: "Agreement",
        "Purpose/Title": "Transnational Partnership for Excellent Research and Education in Disruptive Technologies for a resilient future",
         "Coordinating faculty member": "Dr. Abhishek Srivastava\r\nDr. I. A. Palani, DORD",
        "Signed on": "August 11, 2020",
        Duration: "3 Years",
        "Country with which there is collation": "Norway"
      },
    
     
      {
        "Institutions/Organization/Country": "University of Florence, Italy",
        Nature: "Agreement",
        "Purpose/Title": "Cultural and Scientific Cooperation between the dept of earth sciences",
         "Coordinating faculty member": "Dr. Neelima Satyam\r\nDoRD",
        "Signed on": "December 23, 2019",
        Duration: "7 years",
        "Country with which there is collation": "Italy"
      },
      {
        "Institutions/Organization/Country": "Univesity of Technology Sydney",
        Nature: "MoU",
        "Purpose/Title": "Visits by and exchange of students for the purpose of research; Visits by interchange of staff for the purposes of research, teaching and discussions;Joint research activities;Joint participation in internationally funded research projects;",
         "Coordinating faculty member": "Dr. Kapil Ahuja",
        "Signed on": "October 24, 2019",
        Duration: "5 Years",
        "Country with which there is collation": "Sydney"
      },
      {

        "Institutions/Organization/Country": "Insitut National Polytechnique de Toulouse France",
        Nature: "Agreement",
        "Purpose/Title": "Exchange of materials in education and research, in particular to promote international information; Exchange of faculty members and Research Scholars;One way exchange of students;Joint research and meetings for education and research;Technical assistance; Utilizing a hybrid online learning model for graduate training;",
         "Coordinating faculty member": "Dr. Kapil Ahuja\r\nDirector",
        "Signed on": "October 23, 2019",
        Duration: "5 Years",
        "Country with which there is collation": "France"
      },
     
      {
        "Institutions/Organization/Country": "Max Planck Institute for Astronomy, Heidelberg, Germany",
        Nature: "Collaborative Agreement",
        "Purpose/Title": "To perform collaborative research activities according to the collaborative research program",
         "Coordinating faculty member": "Dr. Bhargav Vaidya\r\nDirector",
        "Signed on": "July 1, 2019",
        Duration: "5 Years",
        "Country with which there is collation": "Germany"
      },
     
      {
        "Institutions/Organization/Country": "Uppsala Universitet",
        Nature: "MoU",
        "Purpose/Title": "Exchange of faculty members, Postdoctoral Associates, Research Scholars and Master Students;Joint supervision of M.S. and Ph.D. students;Development of curricula;Exchange of academic information and materials Exchange of periodicals and other publications",
         "Coordinating faculty member": "HOD, Chemistry HoD, Physics",
        "Signed on": "April 24, 2019",
        Duration: "5 Years",
        "Country with which there is collation": "Sweden"
      },
    
      {
        "Institutions/Organization/Country": "Royal Academy of Engineering",
        Nature: "MoU",
        "Purpose/Title": "To support the aims of the UK government’s Newton Fund by facilitating international visits and exchanges between industry and academic partners in the UK and their counterparts in elected Newton Fund Partner Countries",
         "Coordinating faculty member": "Dr. Bhupesh Lad\r\nRegistrar I/c",
        "Signed on": "March 14, 2019",
        Duration: "2 Years",
        "Country with which there is collation": "London"
      },
      {
        "Institutions/Organization/Country": "Fundacao Universidade De Caxias Do Sul",
        Nature: "MoU",
        "Purpose/Title": "Exchange programs for Professors, Researchers, Students, Administrators and technical person;Joint develipment of projects and research;Development of scholarship programs;Exchange of bibliography, programs, didactic, technical and scientific material; Elaboration of scientific articles and other publications; Other forms of cooperation agreed by the parties.Activities of attainment of graduate and Post-graduate academic levels.",
         "Coordinating faculty member": "Dr. Santosh S. Hosmani\r\nDoRD",
        "Signed on": "February 12, 2019",
        Duration: "5 Years",
        "Country with which there is collation": "Brazil"
      },
 
      {
        "Institutions/Organization/Country": "University of Strasbourg, France",
        Nature: "MoU",
        "Purpose/Title": "Exchange of faculty members, researchers, administrative staff;Exchange of students;Joint supervision of thesis;Development of joint research projects;Organization of mutual courses and joint seminars",
        "Coordinating faculty member": "Director",
        "Signed on": "June 13, 2018",
        Duration: "2 Years",
        "Country with which there is collation": "France"
      },
     
      {
        "Institutions/Organization/Country": "ECAM Lyon, France",
        Nature: "MoU",
        "Purpose/Title": "Exchange of faculty members, Research Scientists, Postdoctoral Associates and administrative staff;Exchange of students (undergraduate and/or graduate);Joint supervision of M.S. and Ph.D. students;Development of curricula;Exchange of academic information and materials Exchange of periodicals and other publications;Organization of joint research programs, conferences",
         "Coordinating faculty member": "Dr. Kapil Ahuja",
        "Signed on": "May 23, 2018",
        Duration: "5 Years",
        "Country with which there is collation": "France"
      },
   
      {
        "Institutions/Organization/Country": "Oregon State University",
        Nature: "MoU",
        "Purpose/Title": "Exchange of students, faculty members for research, discussion and lectures;Joint research activities;Exchanges of academic materials",
         "Coordinating faculty member": "Dr. Kapil Ahuja\r\nDirector",
        "Signed on": "May 15, 2018",
        Duration: "5 Years",
        "Country with which there is collation": "United States"
      },
   
      {
        "Institutions/Organization/Country": "Chonnam National University, Korea",
        Nature: "MoU",
        "Purpose/Title": "Cooperation in Academic and Research Related Activities;",
         "Coordinating faculty member": "Dr. Rupesh S. Devan\r\nDoRD",
        "Signed on": "March 15, 2018",
        Duration: "5 Years",
        "Country with which there is collation": "Korea"
      },
      {
        "Institutions/Organization/Country": "Ecole Nationale Superieure Mines-Telecom Atlantique Bretagne Pays Dela Loire( IMT Atlantique )",
        Nature: "MoU",
        "Purpose/Title": "Students, Faculty and Administrative staff exchange, Joint supervision of M.S. and PhDStudents, Exchanges of academic materials",
         "Coordinating faculty member": "Dr. Kapil Ahuja",
        "Signed on": "March 10, 2018",
        Duration: "5 Years",
        "Country with which there is collation": "France"
      },
      {
        "Institutions/Organization/Country": "Department of Engineering, University of Messina, Italy",
        Nature: "MoU",
        "Purpose/Title": "Students and academic and administrative staff exchanges;Joint research activities;Exchanges of academic materials;",
         "Coordinating faculty member": "Dr. Parashram M. Shirage\r\nDirector",
        "Signed on": "March 05, 2018",
        Duration: "Till consent",
        "Country with which there is collation": "Italy"
      },
      {
        "Institutions/Organization/Country": "University of Stuttgart, Germany (on behalf of TU9 Universities, Germany)",
        Nature: "MoU",
        "Purpose/Title": "Academic and Research collaboration in areas of mutual interest;Exchange of academic information, scholarly information, materials and publications;Exchange of students, research scientists, postdoctoral associates, and faculty members;Sponsorship of cooperative seminars, workshops and other academic meetings.",
         "Coordinating faculty member": "Dr. Kapil Ahuja\r\nDirector",
        "Signed on": "January 8, 2018",
        Duration: "5 Years",
        "Country with which there is collation": "Germany"
      },
     
      {
        "Institutions/Organization/Country": "The Universite Catholique De Louvain( Louvain-LA-Neuve, Belgium)",
        Nature: "MoU",
        "Purpose/Title": "Planning, development and implementation of",
         "Coordinating faculty member": "Dr. Abhinav Kranti\r\nDirector",
        "Signed on": "November 08, 2017",
        Duration: "5 Years",
        "Country with which there is collation": "Belgium"
      }, 
   
      {
        "Institutions/Organization/Country": "Jeju National University, Korea",
        Nature: "Non Proprietary User Agreement",
        "Purpose/Title": "Academics",
         "Coordinating faculty member": "Dr. I. A. Palani\r\nDoRD",
        "Signed on": "June 26, 2017",
        Duration: "5 Years",
        "Country with which there is collation": "Korea"
      },
     
     
      {
        "Institutions/Organization/Country": "Technische Universität Dresden’s (TUD), Germany",
        Nature: "MoU",
        "Purpose/Title": "Students, Faculty and Administrative staff exchange, Joint supervision of M.S. and PhDStudents, Exchanges of academic materials",
         "Coordinating faculty member": "HoD, Electrical Engineering\r\nDoRD",
        "Signed on": "November 7, 2016",
        Duration: "5 Years",
        "Country with which there is collation": "Germany"
      },
      {
        "Institutions/Organization/Country": "Max Planck Institute For Dynamics Of Complex Technical Systems (Mpi), Magdeburg, Germany",
        Nature: "MoU",
        "Purpose/Title": "Students, Faculty and Administrative staff exchange, Joint supervision of M.S. and PhDStudents, Exchanges of academic materials",
         "Coordinating faculty member": "Dr. Kapil Ahuja\r\nDirector",
        "Signed on": "November 3, 2016",
        Duration: "5 Years",
        "Country with which there is collation": "Germany"
      },
      {
        "Institutions/Organization/Country": "Technische Universität Berlin (TU Berlin), Germany",
        Nature: "MoU",
        "Purpose/Title": "Students, Faculty and Administrative staff exchange, Joint supervision of M.S. and PhDStudents, Exchanges of academic materials",
         "Coordinating faculty member": "Dr. Sarika Jalan DOIR",
        "Signed on": "November 2, 2016",
        Duration: "5 Years",
        "Country with which there is collation": "Germany"
      },
      {
        "Institutions/Organization/Country": "Leibniz Universität Hannover, Germany",
        Nature: "MOU",
        "Purpose/Title": "Students and academic and administrative staff exchanges;Joint research activities;Exchanges of academic materials",
         "Coordinating faculty member": "Dr. Kapil Ahuja",
        "__EMPTY_5": "Director",
        "Signed on": "October 28, 2016",
        Duration: "Initially for 3 Years",
        "Country with which there is collation": "Germany"
      },
      {
        "Institutions/Organization/Country": "Ubiquity Press Limited, London",
        Nature: "MoU",
        "Purpose/Title": "Academics",
         "Coordinating faculty member": "Dr. Nirmala Menon\r\nDoRD",
        "Signed on": "June 30, 2016",
        Duration: "3 Years",
        "Country with which there is collation": "London"
      },

  
      {
        "Institutions/Organization/Country": "Kurnakov Institute of General and Inorganic Chemistry of the Russian Academy of Sciences (IGIC RAS), Russia",
        Nature: "MoU",
        "Purpose/Title": "Academic",
        "Coordinating faculty member": "Director",
        Duration: "3 Years (with automatic prolongation for the next one Year)",
        "Country with which there is collation": "Russia"
      },
      {
        "Institutions/Organization/Country": "Technische Universität Braunschweig (TU Braunschweig), Braunschweig, Germany",
        Nature: "MoU",
        "Purpose/Title": "Academic and Research",
         "Coordinating faculty member": "Dr. Kapil Ahuja\r\nDoRD",
        "Signed on": "October 1, 2015",
        Duration: "5 Years",
        "Country with which there is collation": "Germany"
      },
      {
        "Institutions/Organization/Country": "RWTH Aachen University, Germany",
        Nature: "MoU",
        "Purpose/Title": "Teaching and Research",
         "Coordinating faculty member": "Dr. Sudeshna Chattopadhyay\r\nDirector DoRD",
        "Signed on": "September 14, 2015",
        Duration: "5 Years",
        "Country with which there is collation": "Germany"
      },
      {
        "Institutions/Organization/Country": "Universitat Rovira l Virgili, Spain",
        Nature: "MoU",
        "Purpose/Title": "Mobility Programmes for teaching and research staff",
         "Coordinating faculty member": "Dr. E. Anil Kumar\r\nDoRD",
        "Signed on": "June 1, 2015",
        Duration: "1 Year",
        "Country with which there is collation": "Spain"
      },
      {
        "Institutions/Organization/Country": "INSTITUT Mines -Telecom, France",
        Nature: "MoU",
        "Purpose/Title": "Academic and Scientific Collaboration",
         "Coordinating faculty member": "Prof. Pradeep Mathur\r\nDoRD",
        "Signed on": "October 25, 2013",
        Duration: "5 Years",
        "Country with which there is collation": "France"
      },
      {
        "Institutions/Organization/Country": "ALICE Collaboration, CERN, Geneva",
        Nature: "MoU",
        "Purpose/Title": "For Collaboration in the Construction, the Maintenance and Operation of the ALICE Detector",
         "Coordinating faculty member": "Dr. Raghunath Sahoo\r\nDoRD",
        "Signed on": "May 2, 2013",
        Duration: "10 Years",
        "Country with which there is collation": "Geneva"
      },
      {
        "Institutions/Organization/Country": "Keele University, UK",
        Nature: "MoU",
        "Purpose/Title": "Academic",
         "Coordinating faculty member": "Prof. Pradeep Mathur\r\nDirector",
        "Signed on": "October 31, 2012",
        Duration: "Initially 5 Years",
        "Country with which there is collation": "UK"
      },
      {
        "Institutions/Organization/Country": "Fraunhofer - Gesellschaft zur Forderung der Angewandten Forschung, Germany",
        Nature: "MoU",
        "Purpose/Title": "Academic",
         "Coordinating faculty member": "Prof. Pradeep Mathur\r\nDirector",
        "Signed on": "October 30, 2012",
        Duration: "5 Years",
        "Country with which there is collation": "Germany"
      },
    
  
      {
        "Institutions/Organization/Country": "Ecole Internationale Des Sciences Du Traitement De L'Information-France",
        Nature: "MoU",
        "Purpose/Title": "Academic",
         "Coordinating faculty member": "Prof. Pradeep Mathur\r\nDirector",
        "Signed on": "May 21, 2012",
        Duration: "Till consent",
        "Country with which there is collation": "France"
      },
      {
        "Institutions/Organization/Country": "Saarland University, Germany",
        Nature: "MoU",
        "Purpose/Title": "Student Exchange Programme",
         "Coordinating faculty member": "Prof. Pradeep Mathur\r\nDirector",
        "Signed on": "November 12, 2010",
        Duration: "Initially 5 Years",
        "Country with which there is collation": "Germany"
      }
    ]
}
  