export const data = {
	student: {
		Europe: [
			{
				title: "TU9s (Germany)",
				cols: ["IITI Student (under IITI Faculty)", "TU9 Faculty & Institute", "Duration"],
				data: [
					{
						"#": 7,
						"IITI Student (under IITI Faculty)": "Mr. Chetan Sharma (Dr. Venkatesh Chelvam)",
						"TU9 Faculty & Institute": "Prof. Dr. Andreas Krischning LU Hannover",
						Duration: "Sept 2018 - Mar 2019",
					},
					{
						"#": 1,
						"IITI Student (under IITI Faculty)": "Mr. Nishant Saxena (Dr. Anbarasu Manivannan)",
						"TU9 Faculty & Institute": "Prof. Dr. Matthias Wuttig RWTH Aachen",
						Duration: "Aug 2017 – Sept 2017",
					},
					{
						"#": 2,
						"IITI Student (under IITI Faculty)": "Mr. Abhinav Himanshu",
						"TU9 Faculty & Institute": "Prof. Dr. Majid Zamani TU Munich",
						Duration: "May 2017 – July 2017",
					},
					{
						"#": 4,
						"IITI Student (under IITI Faculty)": "Mr. Sarthak Acharya (Dr. Abhinav Kranti)",
						"TU9 Faculty & Institute": "Prof. Dr. Joachim Knoch RWTH Aachen",
						Duration: "Sept 2016 – Mar 2017",
					},
					{
						"#": 3,
						"IITI Student (under IITI Faculty)": "Mr. Navneet Pratap Singh (Dr. Kapil Ahuja)",
						"TU9 Faculty & Institute": "Prof. Dr. Heike Fassbender TU Braunschweig",
						Duration: "Sept 2016 – Oct 2016",
					},
					{
						"#": 5,
						"IITI Student (under IITI Faculty)": "Mr. Swati Mishra (Dr. Santhakumar Mohan)",
						"TU9 Faculty & Institute": "Prof. Dr. -Ing. Tobias Ortmaie LU Hannover",
						Duration: "May 2016 – July 2016",
					},
					{
						"#": 6,
						"IITI Student (under IITI Faculty)": "Mr. Vikram Katti (Dr. Santhakumar Mohan)",
						"TU9 Faculty & Institute": "Prof. Dr. -Ing. Tobias Ortmaier LU Hannover",
						Duration: "May 2015 - July 2015",
					},
				],
			},
			{
				title: "Non-TU9s (Germany)",
				cols: ["IITI Student (Under IITI Faculty)", "Non-TU9 faculty & Institute", "Duration"],
				data: [
					{
						"#": 1,
						"IITI Student (Under IITI Faculty)": "Mr. Rode Sudhir Pandurang (Under Dr. Ankhi Roy)",
						"Non-TU9 faculty & Institute": "Prof. Dr. Ralf Averbeck GSI Darmstadt",
						Duration: "Oct 2017 – Dec 2017",
					},
					{
						"#": 2,
						"IITI Student (Under IITI Faculty)": "Mr. Akash K. (Under Dr. I. A. Palani)",
						"Non-TU9 faculty & Institute": "Prof. Dr. Simon Pauly IFW Dresden",
						Duration: "Sept 2017 – Nov 2017",
					},
					{
						"#": 3,
						"IITI Student (Under IITI Faculty)": "Mr. Ruchir Garg (Under Dr. Kapil Ahuja)",
						"Non-TU9 faculty & Institute": "Prof. Dr. Jens Saak Max Planck Institute, Magdeburg",
						Duration: "May 2017 – Jul 2017",
					},
					{
						"#": 4,
						"IITI Student (Under IITI Faculty)": "Rajendra Choudhary (Under Dr. Kapil Ahuja)",
						"Non-TU9 faculty & Institute": "Prof. Dr. Peter Benner Max Planck Institute, Magdeburg",
						Duration: "Sept 2016 – Oct 2016",
					},
				],
			},
			{
				title: "Bulgaria",
				cols: ["Name of the Student", "Department", "Visit", "Duration"],
				data: [
					{
						"Name of the Student": " Dr. Pragati",
						Department: "Chemistry",
						Visit: "Under Indo-Bulgaria joint cooperation project in Bulgaria",
						Duration: "29th May to 11th June 2022",
					},
				]
			},
			{
				title: "Switzerland",
				cols: ["Name of the Student", "Department", "Visit", "Duration"],
				data: [
					{
						"Name of the Student": "Ravindra Singh",
						Department: "Physics",
						Visit: "CERN, the European Organization for Nuclear Research, Switzerland",
						Duration: "1st April to 31st May 2022",
					},
					{
						"Name of the Student": "Yoshini Bailung",
						Department: "Physics",
						Visit: "CERN, the European Organization for Nuclear Research, Switzerland",
						Duration: "1st April to 23rd May 2022",
					},
				]
			},
			{
				title: "France",
				cols: ["Name of the Student", "Department", "Visit", "Duration"],
				data: [
					{
						"Name of the Faculty": "Prof. Kapil Ahuja",
						"Name of the FacultyLink": "",
						Department: "CSE",
						Visit: "Prof. Yann Busnel, France",
						Duration: "28-Apr-2023 to 18-May-2023",
					},
					{
						"Name of the Student": "Praneet Jain",
						Department: "Electrical Engineering",
						Visit: "University of Rennes 1, France",
						Duration: "15th May to 15th July 2022",
					},
				]
			},
		],
		
		America: [
			{
				title: "USA",
				cols: ["Faculty/Student", "Visit", "Duration"],
				data: [
					{
						"#": 1,
						"Faculty/Student": "Ms. Mena Asha Krishnan (Ph.D.)",
						Visit: "DST SERB-Purdue Exchange visit by Ph.D student to Purdue University, West Lafayette, USA",
						Duration: 2020,
					},
					{
						"#": 2,
						"Faculty/Student": "Mr. Rohit Kothari (Ph.D.)",
						Visit: "DST SERB-Purdue Exchange visit by Ph.D student to Purdue University, West Lafayette, USA",
						Duration: 2020,
					},
				],
			},
			{
				title: "Canada",
				cols: ["Name of the Student", "Department", "Visit", "Duration"],
				data: [
					{
						"#": 1,
						"Name of the Student": "Narayan Kabra",
						Department: "Metallurgy Engineering and Material Science",
						Visit: "Ontario Tech University- Oshawa, Canada",
						Duration: "12th May to 9th August 2022",
					},
					{
						"#": 2,
						"Name of the Student": "Vardhan Paliwal",
						Department: "Electrical Engineering",
						Visit: "Simon Fraser University- Burnaby, Canada",
						Duration: "9th May to 4th August 2022",
					},
					{
						"#": 3,
						"Name of the Student": "Sagar Shirish",
						Department: "Electrical Engineering",
						Visit: "University of Victoria, Canada",
						Duration: "9th May to 5th August 2022",
					},
					{
						"#": 4,
						"Name of the Student": "Satyam Vatsa",
						Department: "Civil Engineering",
						Visit: "McMaster University- Hamilton, Canada",
						Duration: "9th May to 5th August 2022",
					},
					{
						"#": 5,
						"Name of the Student": "Ankit Kumar",
						Department: "Chemistry",
						Visit: "University of Alberta, Canada",
						Duration: "1-30 June 2022",
					},
					{
						"#": 6,
						"Name of the Student": "Bhanu Priya",
						Department: "Chemistry",
						Visit: "University of Alberta, Canada",
						Duration: "1-30 June 2022",
					},
					{
						"#": 7,
						"Name of the Student": "Sumiran Mehra",
						Department: "Electrical Engineering",
						Visit: "Université de Sherbrooke, Canada",
						Duration: "1st April to 31st July 2022",
					},		
				]
			}
		],
	},

	faculty: {
		Australia: [
			{
				title: "Australia",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						"Name of the Faculty": "Dr. M. Tanveer",
						"Name of the FacultyLink": "",
						Department: "Mathematics",
						Visit: "C T Lin, UTS, Australia",
						Duration: "June 18-23, 2023",
					},
					{
						"Name of the Faculty": "Dr. M. Tanveer",
						"Name of the FacultyLink": "",
						Department: "Mathematics",
						Visit: "Kai Qin, Swinburne University of Technology, Australia",
						Duration: "June 18-23, 2023",
					},
					{
						"Name of the Faculty": "Dr. M. Tanveer",
						"Name of the FacultyLink": "",
						Department: "Mathematics",
						Visit: "Imran Razzak, UNSW, Australia",
						Duration: "June 18-23, 2023",
					},
					{
						"Name of the Faculty": "Dr. M. Tanveer",
						"Name of the FacultyLink": "",
						Department: "Mathematics",
						Visit: "Brijesh Verma, Griffith University, Australia General Chair",
						Duration: "June 18-23, 2023",
					},
					{
						"Sr. No.": "54",
						"Name of the Faculty": "Prof. Sanjay Kumar Singh",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~sksingh/sksingh.html",
						Department: "Chemistry",
						Visit: "Melbourne, Australia",
						Duration: "July, 2016",
					},
				],
			},
		],
		Asia: [
			{
				title: "China",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Lalit Borana",
						"Name of the FacultyLink": "",
						Department: "Civil Engineering",
						Visit: "The Hong Kong Polytechnic University",
						Duration: "16 May to 16 July 2022",
					},
				]
			},
			{
				title: "Russia",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Ritunesh Kumar",
						"Name of the FacultyLink": "",
						Department: "Mechanical Engineering",
						Visit: "Saint Petersburg State Technological University, Russia",
						Duration: "20 June to 30 June 2022",
						Funding:"DST india and RFBR, Russia",
					},
				]
			},
			{
				title: "Japan",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Prof. Shaibal Mukherjee",
						"Name of the FacultyLink": "",
						Department: "Electrical Engineering",
						Visit: "Shinshu University, Japan",
						Duration: "May 31 to July 31 2022",
						Funding:"JSPS Invitational Fellowship for research",
					},
					{
						"Sr. No.": "61.   ",
						"Name of the Faculty": "Dr. Shaikh M. Mobin",
						Visit: "Dr. Shaikh led the Organizing Committee to hold a session on Solid-State Reactivity and Structural Transformations at 43rd International Conference on Coordination Chemistry-2018 (ICCC-2018) at Sendai, Japan. This event witnessed very eminent scientists across globe.",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/60464",
						Department: "Chemistry",
						Duration: "July - August, 2018",
					},
					{
						"Sr. No.": "59.   ",
						"Name of the Faculty": "Dr.I.A.Palani",
						Visit: "Prof.D.Nakamura, Kyushu University, Japan",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/62176",
						Department: "Mechanical engineering",
						Duration: "June, 2018",
					},
					{
						"Sr. No.": "58.   ",
						"Name of the Faculty": "Preeti Anand Bhobe",
						Visit: "Photon Factory Synchrotron source, KEK, Tsukuba, Japan",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/62146",
						Department: "Physics",
						Duration: "Dec, 2017",
					},
					{
						"Sr. No.": "60.   ",
						"Name of the Faculty": "Dr.I.A.Palani",
						Visit: "Prof.T.Okada, Kyushu Univeristy, Japan",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/62176",
						Department: "Mechanical engineering",
						Duration: "Sept, 2015",
					},
					{
						"Sr. No.": "57.   ",
						"Name of the Faculty": "Preeti Anand Bhobe",
						Visit: "Photon Factory Synchrotron source, KEK, Tsukuba, Japan",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/62146",
						Department: "Physics",
						Duration: "May, 2014",
					},
				],
			},
			{
				title: "Taiwan",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						"Sr. No.": "61.   ",
						"Name of the Faculty": "Dr. Rupesh S. Devan",
						Visit: "Prof. Y. R. Ma, NDHU Taiwan",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/60142",
						Department: "Metallurgy Engineering and Materials Science (MEMS)",
						Duration: "Apr, 2019",
					},
					{
						"Sr. No.": "59.   ",
						"Name of the Faculty": "Dr. Rupesh S. Devan",
						Visit: "Prof. Y. R. Ma, NDHU Taiwan",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/60142",
						Department: "Metallurgy Engineering and Materials Science (MEMS)",
						Duration: "Dec, 2014",
					},
				],
			},
			{
				title: "S. Korea",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						"Sr. No.": "56.   ",
						"Name of the Faculty": "Dr. Rupesh S. Devan",
						Visit: "Prof. J. H. Kim, CNU, S. Korea",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/60142",
						Department: "Metallurgy Engineering and Materials Science (MEMS)",
						Duration: "Dec, 2018",
					},
					{
						"Sr. No.": "55.   ",
						"Name of the Faculty": "Sk. Safique Ahmad",
						Visit: "Seoul ICM 2014, August 13-21, Coex, Seoul, Korea.",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/62123",
						Department: "Mathematics",
						Duration: "2014",
					},
				],
			},
			{
				title: "Malaysia",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						"Sr. No.": "51.   ",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						Visit: "International Medical University, Kuala Lumpur, Malaysia ",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering (BSBE)",
						Duration: "July, 2016",
					},
					{
						"Sr. No.": "52.   ",
						"Name of the Faculty": "Dr Anirban Sengupta",
						Visit: "IEEE ASQED, Penang, Malaysia ",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93753",
						Department: "Computer Science and Engineering",
						Duration: "Aug, 2013",
					},
				],
			},
			{
				title: "Singapore",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Prof Eswar Prasad Korimilli",
						"Name of the FacultyLink": "",
						Department: "MEMS",
						Visit: "Nanyang Technological University, Singapore",
						Duration: "27 June to 1 July 2022",
					},
					{
						"Sr. No.": "50",
						"Name of the Faculty": "Dr Anirban Sengupta",
						Visit: "NTU Singapore in Singapore ",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93753",
						Department: "Computer Science and Engineering",
						Duration: "June, 2016",
					},
				],
			},
			{
				title: "Bangladesh",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						"Sr. No.": "35",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						Visit: "International Centre for Diarrheal Disease and Research (icddrb) Dhaka, Bangladesh",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering (BSBE)",
						Duration: "Dec, 2018",
					},
				],
			},
		],
		Africa: [
			{
				title: "South Africa",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						"Name of the Faculty": "Dr. Bhargav Vaidya",
						Visit: "Dr. Brian Van Soelen, University of Free State, Bloemfonstein, South Africa",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93693",
						Department: "Astronomy, Astrophysics and Space Engineering",
						Duration: "June, 2019",
					},
				],
			},
		],
		OtherCountries: [
			{
				title: "Other Countries",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						"Sr. No.": "84.    ",
						"Name of the Faculty": "Dr.I.A.Palani",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~palaniia/",
						Department: "Mechanical Engineering",
						Visit: "Prof.Natalia , Resnina",
						Duration: "Dec, 2019",
					},
					{
						"Sr. No.": "76.    ",
						"Name of the Faculty": "Dr. Santanu Manna",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~santanu/dr.santanu%20manna.html",
						Department: "Mathematics",
						Visit: "Russia, Saint Petersburg",
						Duration: "June, 2019",
					},
					{
						"Sr. No.": "78.    ",
						"Name of the Faculty": "Dr. Santanu Manna",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~santanu/dr.santanu%20manna.html",
						Department: "Mathematics",
						Visit: "Brazil, Rio de Janeiro",
						Duration: "July 2018 - August 2018",
					},
					{
						"Sr. No.": "69.    ",
						"Name of the Faculty": "Dr. Shaikh M. Mobin",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~xray/",
						Department: "Chemistry",
						Visit: "Dr. Shaikh delivered an Invited talk at Royal Society of Chemistry – IIT Indore Symposium on Advancement in Chemical Sciences on",
						Duration: "January, 2018",
					},
					{
						"Sr. No.": "83.    ",
						"Name of the Faculty": "Sk. Safique Ahmad",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~safique/",
						Department: "Mathematics",
						Visit: "ICM 2018 - Rio de Janeiro eigenpairs",
						Duration: "2018",
					},
					{
						"Sr. No.": "79.    ",
						"Name of the Faculty": "Sk. Safique Ahmad",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~safique/",
						Department: "Mathematics",
						Visit: "Department of Mathematics, OSU in May 2018",
						Duration: "2018",
					},
					{
						"Sr. No.": "80.    ",
						"Name of the Faculty": "Sk. Safique Ahmad",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~safique/",
						Department: "Mathematics",
						Visit: "Visited MIAMI University for the research Collaboration with Dr. Kumar Vikram Singh during September and December 2017.",
						Duration: "2017",
					},
					{
						"Sr. No.": "81.    ",
						"Name of the Faculty": "Sk. Safique Ahmad",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~safique/",
						Department: "Mathematics",
						Visit: "Contributed talk in the AMS conference held in UCF, Orlando",
						Duration: "2017",
					},
					{
						"Sr. No.": "82.    ",
						"Name of the Faculty": "Sk. Safique Ahmad",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~safique/",
						Department: "Mathematics",
						Visit: "Contributed talk in the AMS conference held in UCF, Orlando",
						Duration: "2017",
					},
					{
						"Sr. No.": "70.    ",
						"Name of the Faculty": "Dr. Shaikh M. Mobin",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~xray/",
						Department: "Chemistry",
						Visit: "Title: Multitalented small molecules: Cellular Organelles targets, sensing and crystal engineering.",
					},
					{
						"Sr. No.": "71.    ",
						"Name of the Faculty": "Dr. Shaikh M. Mobin",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~xray/",
						Department: "Chemistry",
						Visit: "Host: Royal Society of Chemistry, India Chapter.",
					},
					{
						"Sr. No.": "72.    ",
						"Name of the Faculty": "Dr. Shaikh M. Mobin",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~xray/",
						Department: "Chemistry",
						Visit: "Title: Vapor Mediated Single-Crystal-to-Single-Crystal Transformations and Effective Removal of Dyes by Using Inorganic Co-crystal.",
					},
					{
						"Sr. No.": "73.    ",
						"Name of the Faculty": "Dr. Shaikh M. Mobin",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~xray/",
						Department: "Chemistry",
						Visit: "Host: Masahiro Yamashita (Tohoku Univ.)",
					},
					{
						"Sr. No.": "74.    ",
						"Name of the Faculty": "Dr. Shaikh M. Mobin",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~xray/",
						Department: "Chemistry",
						Visit: "Title: Oxyanions (SO42–, NO3–) Dependant Varying Unique Single-Crystal-to-Single-Crystal Transformations in Cobalt (II) Complexes.",
					},
					{
						"Sr. No.": "75.    ",
						"Name of the Faculty": "Dr. Shaikh M. Mobin",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~xray/",
						Department: "Chemistry",
						Visit: "Host: Prof. LeRoy Jones II.",
					},
				],
			},
		],
		Europe: [
			{
				title: "Luxemburg",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Sumit Gautam",
						"Name of the FacultyLink": "",
						Department: "Electrical Engineering",
						Visit: "University of Luxemburg, Luxemburg",
						Duration: "1 Nov 2016 to 30 July 2021",
					}
				]
			},
			{
				title: "Finland",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Prof. Vimal Bhatia",
						"Name of the FacultyLink": "",
						Department: "Electrical Engineering",
						Visit: "University of Oulu, Finland",
						Duration: "22 May to 4th June 2022",
						Funding:"TFK Project",
					},
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Prof. Prabhat Kumar Upadhyay",
						"Name of the FacultyLink": "",
						Department: "Electrical Engineering",
						Visit: "University of Oulu, Finland",
						Duration: "27 May to 12 August 2022",
						Funding:"Nokia Foundation under Nokia Visiting Professor program",
					},

				]
			},
			{
				title: "Netherlands",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Gourab Sil",
						"Name of the FacultyLink": "",
						Department: "Civil Engineering",
						Visit: "Oud-west Amsterdam, Netherlands",
						Duration: "26th June to 29th June 2022",
						Funding:"CPDA",
					}
				]
			},
			{
				title: "Germany",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					// {
						// "Sr. No.": "",
						// "Name of the Faculty": "",
						// "Name of the FacultyLink": "",
						// Department: "",
						// Visit: "",
						// Duration: "",
					// },
					{
						"Name of the Faculty": "Prof. Kapil Ahuja",
						"Name of the FacultyLink": "",
						Department: "CSE",
						Visit: "Dr. Bala Ramani and Dr. Birgit Barden-Laufer, Germany",
						Duration: "20-May-2022 to 17-June-2022",
					},
					{
						"Name of the Faculty": "Prof. Kapil Ahuja",
						"Name of the FacultyLink": "",
						Department: "CSE",
						Visit: "Prof. Thomas Wick and Prof. Marc Steinbach, Germany",
						Duration: "6-Dec2022 to 31-Dec-2022",
					},
					{
						"Name of the Faculty": "Prof. Kapil Ahuja",
						"Name of the FacultyLink": "",
						Department: "CSE",
						Visit: "Prof. Thomas Wick and Prof. Marc Steinbach, Germany",
						Duration: "19-May-2023 to 12-June-2023",
					},
					{
						"Name of the Faculty": "Prof. Kapil Ahuja",
						"Name of the FacultyLink": "",
						Department: "CSE",
						Visit: "Prof. Akash Kumar, Germany",
						Duration: "31-May-2023 to 02-June-2023",
					},
					{
						"Name of the Faculty": "Prof. Sarika Jalan",
						"Name of the FacultyLink": "",
						Department: "Physics",
						Visit: "Prof. Jurgen Kurths, Germany",
						Duration: "Mar-23",
					},
					{
						"Name of the Faculty": "Prof. Sarika Jalan",
						"Name of the FacultyLink": "",
						Department: "Physics",
						Visit: "Dr. Aana Zakharova TU Berlin",
						Duration: "Nov-Dec 2022",
					},
					{
						"Name of the Faculty": "Prof. Sarika Jalan",
						"Name of the FacultyLink": "",
						Department: "Physics",
						Visit: "Prof. Holger Kantz, MPIPKS Germany",
						Duration: "June-July 2022",
					},
					{
						"Name of the Faculty": "Prof. Sarika Jalan",
						"Name of the FacultyLink": "",
						Department: "Physics",
						Visit: "Prof. Jurgen Kurths, Germany",
						Duration: "Aug-22",
					},
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Preeti Bhobe",
						"Name of the FacultyLink": "",
						Department: "Physics",
						Visit: "Leibniz Universität Hannover, Germany",
						Duration: "6 June to 20 June 2022",
					},
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Kiran Bala",
						"Name of the FacultyLink": "",
						Department: "BSBE",
						Visit: "Leibniz Universität Hannover, Germany",
						Duration: "12 June to 25 June 2022",
						Funding:"DAAD and Federal Govt. of Germany",
					},
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Bhargav Vaidya",
						"Name of the FacultyLink": "",
						Department: "Astronomy, Astrophysics and Space Engineering",
						Visit: "Max-Planck-Institut für Astronomie, Heidelberg, Germany",
						Duration: "8 June to 10 July 2022",
						Funding:"MPG Partner Group",
					},
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Prof. Rajneesh Mishra",
						"Name of the FacultyLink": "",
						Department: "Chemistry",
						Visit: "Leibniz Universität Hannover, Germany",
						Duration: "12 May to 16 November 2022",
						Funding:"AvH Fellowship",
					},
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Ram Bilas Pachori",
						"Name of the FacultyLink": "",
						Department: "Electrical Engineering",
						Visit: "Free University of Berlin, Germany",
						Duration: "15 July to 14 September 2022",
						Funding:"DAAD Bilateral Exchange of Academics 2022",
					},
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Sudeshna Chattopadhyay (Bandhopadhyay)",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~sudeshna/",
						Department: "Physics",
						Visit: "Leibniz Universität Hannover, Germany",
						Duration: "8-24 June 2022",
					},
					{
						"Sr. No.": "9.      ",
						"Name of the Faculty": "Dr. Venkatesh Chelvam",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~cvenkat/",
						Department: "Chemistry",
						Visit: "Prof. H-U Reissig, Freie University, Berlin, Germany",
						Duration: "Oct, 2019",
					},
					{
						"Sr. No.": "17.   ",
						"Name of the Faculty": "Dr. Bhargav Vaidya",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~bvaidya/",
						Department: "Astronomy, Astrophysics and Space Engineering ",
						Visit: "Prof. Christian Fendt, Max Planck Institute for Astronomy, Heidelberg Germany ",
						Duration: "July, 2019",
						
					},
					{
						"Sr. No.": "14.   ",
						"Name of the Faculty": "Preeti Anand Bhobe",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~pbhobe/index.html",
						Department: "Physics",
						Visit: "Prof. Dr. Herbert Pfnür Institut für Festkörperphysik, ATMOS Leibniz Universität Hannover (LUH), Germany",
						Duration: "May, 2019",
					},
					{
						"Sr. No.": "1.      ",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering",
						Visit: "Research Center Borstel, Leibniz Lung Centre, Borstel, Germany",
						Duration: "April, 2019",
					},
					{
						"Sr. No.": "16.   ",
						"Name of the Faculty": "Rajesh Kumar",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~rajeshkumar/",
						Department: "Physics",
						Visit: "LUH Hannover, Germany",
						Duration: "Dec, 2018",
					},
					{
						"Sr. No.": "6.      ",
						"Name of the Faculty": "Dr. Venkatesh Chelvam",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~cvenkat/",
						Department: "Chemistry",
						Visit: "Prof. Andreas Kirschning, Leibnitz University of Hannover, Germany",
						Duration: "Dec, 2018",
					},
					{
						"Sr. No.": "13.   ",
						"Name of the Faculty": "Dr.I.A.Palani",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~palaniia/",
						Department: "Mechanical Engineering",
						Visit: "Prof.Simon Pauly, IFW Dresden, Germany",
						Duration: "Nov, 2018",
					},
					{
						"Sr. No.": "2.      ",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering",
						Visit: "Research Center Borstel, Leibniz Lung Centre, Borstel, Germany",
						Duration: "Apr, 2018",
					},
					{
						"Sr. No.": "15.   ",
						"Name of the Faculty": "Preeti Anand Bhobe",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~pbhobe/index.html",
						Department: "Physics",
						Visit: "Dr. Edmund Welter, DESY, Hamburg, Germany",
						Duration: "June, 2017",
					},
					{
						"Sr. No.": "4.      ",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering",
						Visit: "Max Planck Institute for Infection Biology, Berlin, Germany",
						Duration: "June, 2017",
					},
					{
						"Sr. No.": "3.      ",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering",
						Visit: "Research Center Borstel, Leibniz Lung Centre, Borstel, Germany",
						Duration: "Apr, 2017",
					},
					{
						"Sr. No.": "8.      ",
						"Name of the Faculty": "Prof. Sanjay Kumar Singh",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~sksingh/sksingh.html",
						Department: "Chemistry",
						Visit: "TU Berlin, Germany",
						Duration: "Nov, 2016",
					},
					{
						"Sr. No.": "10.   ",
						"Name of the Faculty": "Sk. Safique Ahmad",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~safique/",
						Department: "Mathematics",
						Visit: "Volker Mhermann, TU Berlin, Germany",
						Duration: "2016",
					},
					{
						"Sr. No.": "11.   ",
						"Name of the Faculty": "Sk. Safique Ahmad",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~safique/",
						Department: "Mathematics",
						Visit: "Third 1-day workshop on Matrix Equations TU Braunschweig; 09. October 2009.",
						Duration: "2016",
					},
					{
						"Sr. No.": "12.   ",
						"Name of the Faculty": "Sk. Safique Ahmad",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~safique/",
						Department: "Mathematics",
						Visit: "IIT Indore - TU9 Research held at Berlin,",
						Duration: "2016",
					},
					{
						"Sr. No.": "5.      ",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering",
						Visit: "Institute of Medical Microbiology, University of Ulm, Germany ",
						Duration: "June, 2014",
					},
					{
						"Sr. No.": "7.      ",
						"Name of the Faculty": "Dr. Mohanasundari Thangavel",
						"Name of the FacultyLink": "https://scholar.google.com/citations?user=vBoX-1IAAAAJ&hl=en",
						Department: "Humanities and Social Sciences",
						Visit: "Federal Institute of Hydrology, Koblenz, Germany.",
						Duration: "June, 2013",
					},
				],
			},
			{
				title: "Portugal",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Sandeep Singh",
						"Name of the FacultyLink": "",
						Department: "Mechanical Engineering",
						Visit: "WMVC Lisbon, Portugal",
						Duration: "1 July to 10 July 2022",
						Funding:"Project Contingency and Travel grant/ CPDA",
					},
					{
						"Sr. No.": "65.   ",
						"Name of the Faculty": "Prof. Suman Mukhopadhyay",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/62102",
						Department: "Chemistry",
						Visit: "University of Trás-os-Montes and Alto Douro",
						Duration: "",
					},
					// {
					// 	"Sr. No.": "66.   ",
					// 	"Name of the Faculty": "Prof. Suman Mukhopadhyay",
					// 	"Name of the FacultyLink": "https://iiti.irins.org/profile/62102",
					// 	Department: "Chemistry",
					// 	Visit: "University of Trás-os-Montes and Alto Douro",
					// 	Duration: "42522",
					// },
					// {
					// 	"Sr. No.": "67.   ",
					// 	"Name of the Faculty": "Prof. Suman Mukhopadhyay",
					// 	"Name of the FacultyLink": "https://iiti.irins.org/profile/62102",
					// 	Department: "Chemistry",
					// 	Visit: "University of Trás-os-Montes and Alto Douro",
					// 	Duration: "44393",
					// },
					// {
					// 	"Sr. No.": "68.   ",
					// 	"Name of the Faculty": "Prof. Suman Mukhopadhyay",
					// 	"Name of the FacultyLink": "https://iiti.irins.org/profile/62102",
					// 	Department: "Chemistry",
					// 	Visit: "University of Trás-os-Montes and Alto Douro",
					// 	Duration: "44457",
					// },
				],
			},
			{
				title: "Spain",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						"Sr. No.": "63.   ",
						"Name of the Faculty": "Dr. Santanu Manna",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~santanu/dr.santanu%20manna.html",
						Department: "Mathematics",
						Visit: "Spain, Valencia",
						Duration: "July, 2019",
					},
					{
						"Name of the Faculty": "Preeti Anand Bhobe",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~pbhobe/index.html",
						Department: "Physics",
						Visit: "Energy, Materials, and Nanotechnology (EMN) Meeting, Donostia, Spain",
						Duration: "Aug - Sept 2015",
					},
				],
			},
			{
				title: "Switzerland",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						"Sr. No.": "49",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering",
						Visit: "Institute of Microbiology, ETH, Switzerland",
						Duration: "July, 2014",
					},
					{
						"Sr. No.": "48",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering",
						Visit: "Institute of Medical Microbiology, University of Zurich, Switzerland",
						Duration: "May, 2014",
					},
				],
			},
			{	
				title: "Sweden",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						// "Sr. No.": "46",
						"Name of the Faculty": "Dr. Biswarup Pathak",
						"Name of the FacultyLink": "",
						Department: "Chemistry",
						Visit: "Uppsala universitet, Sweden",
						Duration: "30 June to 29 July 2022",
						Funding:"ASEM-DUO and IIT Indore",
					},
					{
						"Sr. No.": "46",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering",
						Visit: "Karolinska Institute, Stockholm, Sweden",
						Duration: "May, 2016",
					},
					{
						"Sr. No.": "47",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering",
						Visit: "Department of Infectious Diseases, University of Lund, Swede",
						Duration: "Aug, 2013",
					},
				],
			},
			{
				title: "Poland",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						"Sr. No.": "41",
						"Name of the Faculty": "Dr. Ananya Ghoshal",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93843",
						Department: "Humanities and Social Sciences",
						Visit: "Jagiellonian University and the Pedagogical University of Kraków, Poland DH 2016 Conference",
						Duration: "July, 2016",
					},
				],
			},
			{
				title: "Italy",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						"Name of the Faculty":"Prof. Sarika Jalan",
						Department: "Physics",
						Visit: "Prof. Stefano Boccaletti, Italy",
						Duration: "Jun-23",
					},
					{
						"Sr. No.": "39.   ",
						"Name of the Faculty": "Dr. Bhargav Vaidya",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~bvaidya/",
						Department: "Astronomy, Astrophysics and Space Engineering ",
						Visit: "Prof. Andera Mignone, University of Torino, Italy",
						Duration: "May, 2018",
					},
					{
						"Sr. No.": "40.   ",
						"Name of the Faculty": "Preeti Anand Bhobe",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~pbhobe/index.html",
						Department: "Physics",
						Visit: "ELETTRA Synchrotron Source, Trieste, Italy",
						Duration: "May, 2016",
					},
				],
			},
			{
				title: "UK",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					// {
						// "Sr. No.": "",
						// "Name of the Faculty": "",
						// "Name of the FacultyLink": "",
						// Department: "",
						// Visit: "",
						// Duration: "",
					// },
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Tapasya J.",
						"Name of the FacultyLink": "",
						Department: "HSS",
						Visit: "Queens University, Belfast, UK",
						Duration: "6 July to 9 July 2022",
					},
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Neminath Hubballi",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~neminath/",
						Department: "Computer Science and Engineering",
						Visit: "City University of London, UK",
						Duration: "10th May to 10th June 2022",
					},
					{
						"Sr. No.": "37.   ",
						"Name of the Faculty": "Dr. Santanu Manna",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~santanu/dr.santanu%20manna.html",
						Department: "Mathematics",
						Visit: "United Kingdom",
						Duration: "Feb 2019 to Sept 2019",
					},
					{
						"Sr. No.": "36.   ",
						"Name of the Faculty": "Munir Ahmad Nayak",
						"Name of the FacultyLink": "https://loop.frontiersin.org/people/912739/overview",
						Department: "Civil Engineering",
						Visit: "UK",
						Duration: "May, 2018",
					},
					{
						"Sr. No.": "38.   ",
						"Name of the Faculty": "Sk. Safique Ahmad",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~safique/",
						Department: "Mathematics",
						Visit: "IMA Conference on Numerical Linear Algebra and Optimisation held at University, of Birmingham, UK during 13-15, September 2010",
						Duration: "2010",
					},
				],
			},
			{
				title: "Norway",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						"Sr. No.": "33",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering",
						Visit: "University of Oslo, Norway",
						Duration: "Nov, 2019",
					},
					{
						"Sr. No.": "34",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering",
						Visit: "University of Oslo, Norway",
						Duration: "Apr, 2017",
					},
				],
			},
			{
				title: "French University",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					{
						"Name of the Faculty": "Dr. Bhargav Vaidya",
						Visit: "Dr. Benoit Commercon, ENS Lyon, France",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93693",
						Department: "Astronomy, Astrophysics and Space Engineering",
						Duration: "Oct, 2019",
					},
					{
						"#": 1,
						"Name of the Faculty": "Dr. Kapil Ahuja",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~kahuja/",
						Department: "Computer Science & Engineering",
						Visit: "Invited for talk and research collaboration at IMT Atlantique, Rennes",
						Duration: 2018,
					},
					{
						"#": 2,
						"Name of the Faculty": "Dr. Mrigendra Dubey",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~mdubey/",
						Department: "Metallurgy Engineering and Materials Science",
						Visit: "Invited Scientific Visitor at ENS-Lyon, University of Rennes, Grenoble and Burgundy",
						Duration: 2017,
					},
					{
						"#": 3,
						"Name of the Faculty": "Dr. Mrigendra Dubey",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~mdubey/",
						Department: "Metallurgy Engineering and Materials Science",
						Visit: "Invited Research Visitor in France (CNRS- Rennes, Paris VI University and ENS-Lyon)",
						Duration: 2016,
					},
					{
						"#": 4,
						"Name of the Faculty": "Dr. Mrigendra Dubey",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~mdubey/",
						Department: "Metallurgy Engineering and Materials Science",
						Visit: "Invited Research Visitor in University of Rennes and ENS Lyon, France",
						Duration: 2015,
					},
				],
			},

			{
				title: "TU9s (Germany)",
				cols: ["Name of the Faculty", "TU9 Faculty & Institute", "Duration","Funding"],
				data: [
					{
						"#": 1,
						"Name of the Faculty": "Dr. Neelima Stayam",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~neelima.satyam/",
						Department: "Civil Engineering",
						"TU9 Faculty & Institute": "Prof. Dr. Joswig M and Prof. Dr. Novak",
						Duration: "June - July 2018",
					},
					{
						"#": 2,
						"Name of the Faculty": "Dr. Kapil Ahuja",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~kahuja/",
						Department: "Computer Science & Engineering",
						"TU9 Faculty & Institute": "Prof. Dr.-Ing. Balthasar Novák, Univ. of Stuttgart",
						Duration: "Jan, 2018",
					},
					{
						"#": 3,
						"Name of the Faculty": "Prof. Dr. Sarika Jalan",
						"Name of the FacultyLink": "https://www.iiti.ac.in/people/~sarika/",
						Department: "Biosciences & Biomedical Engineering",
						"TU9 Faculty & Institute": "Prof. Dr. Anna Zakharova TU Berlin",
						Duration: "Aug, 2017",
					},
					{
						"#": 4,
						"Name of the Faculty": "Dr. Kapil Ahuja",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~kahuja/",
						Department: "Computer Science & Engineering",
						"TU9 Faculty & Institute": "Prof. Dr. Akash Kumar TU Dresden",
						Duration: "May - June 2017",
					},
					{
						"#": 5,
						"Name of the Faculty": "Dr. Rajneesh Misra",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~rajneesh/",
						Department: "Chemistry",
						"TU9 Faculty & Institute": "Prof. Dr. Holger Butenschön, LU Hannover",
						Duration: "June, 2017",
					},
					{
						"#": 6,
						"Name of the Faculty": "Dr. Abhinav Kranti",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~akranti/",
						Department: "Electrical Engineering",
						"TU9 Faculty & Institute": "Prof. Dr. Joachim Knoch RWTH Aachen",
						Duration: "June, 2017",
					},
					{
						"#": 7,
						"Name of the Faculty": "Prof. Dr. Pradeep Mathur",
						"Name of the FacultyLink": "https://scholar.google.co.in/citations?user=tzaYOskAAAAJ&hl=en",
						Department: "Chemistry",
						"TU9 Faculty & Institute": "MoU signing and First IIT Indore-TU9 Research Workshop, TU Berlin",
						Duration: "Nov, 2016",
					},
					{
						"#": 8,
						"Name of the Faculty": "Dr. Kapil Ahuja",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~kahuja/",
						Department: "Computer Science & Engineering",
						"TU9 Faculty & Institute": "Prof. Dr. Akash Kumar TU Dresden Dr. Norbert Klitzsch RWTH Aachen",
						Duration: "Nov, 2016",
					},
					{
						"#": 9,
						"Name of the Faculty": "Dr. Anbarasu Manivannan",
						"Name of the FacultyLink": "https://iitm.irins.org/profile/150467",
						Department: "Electrical & Electronics Engineering",
						"TU9 Faculty & Institute":
							"Prof. Dr. Matthias Wuttig Prof. Dr. Riccardo Mazzarello RWTH Aachen",
						Duration: "Oct 2016 - Dec 2016",
					},
					{
						"#": 10,
						"Name of the Faculty": "Prof. Dr. Pradeep Mathur",
						"Name of the FacultyLink": "https://scholar.google.co.in/citations?user=tzaYOskAAAAJ&hl=en",
						Department: "Chemistry",
						"TU9 Faculty & Institute":
							"Represent all higher education institues in India-German Joint Working Group Meeting (JWG) Bonn and RWTH Aachen",
						Duration: "June, 2016",
					},
					{
						"#": 11,
						"Name of the Faculty": "Dr. Kapil Ahuja",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~kahuja/",
						Department: "Computer Science & Engineering",
						"TU9 Faculty & Institute":
							"Prof. Dr. Joerg Liesen TU Berlin Prof. Dr. Heike Fassdender TU Braunschweig Dr. Birgit Barden LU Hanover",
						Duration: "June, 2016",
					},
					{
						"#": 12,
						"Name of the Faculty": "Dr. Sudeshna Chattopadhyay",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~sudeshna/",
						Department: "Physics",
						"TU9 Faculty & Institute": "Prof. Dr. Uwe Klemradt RWTH Aachen",
						Duration: "June, 2016",
					},
					{
						"#": 13,
						"Name of the Faculty": "Dr. Santhakumar Mohan",
						"Name of the FacultyLink": "https://iitpkd.ac.in/people/santhakumar",
						Department: "Mechanical Engineering",
						"TU9 Faculty & Institute": "Prof. Dr. Burkhard Corves RWTH Aachen",
						Duration: "June 2016 - May 2017",
					},
					{
						"#": 14,
						"Name of the Faculty": "Dr. Rajneesh Misra",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~rajneesh/",
						Department: "Chemistry",
						"TU9 Faculty & Institute": "Prof. Dr. Holger Butenschön LU Hannover, Germany",
						Duration: "June, 2016",
					},
					{
						"#": 15,
						"Name of the Faculty": "Prof. Dr. Pradeep Mathur",
						"Name of the FacultyLink": "https://scholar.google.co.in/citations?user=tzaYOskAAAAJ&hl=en",
						Department: "Chemistry",
						"TU9 Faculty & Institute": "IIT Indore - TU9 Prelimary Meeting in Embassy of India, Berlin",
						Duration: "Apr, 2016",
					},
					{
						"#": 16,
						"Name of the Faculty": "Dr. Anbarasu Manivannan",
						"Name of the FacultyLink": "https://iitm.irins.org/profile/150467",
						Department: "Electrical & Electronics Engineering",
						"TU9 Faculty & Institute": "Prof. Dr. Matthias Wuttig RWTH Aachen",
						Duration: "Mar 2016 - Apr 2016",
					},
					{
						"#": 17,
						"Name of the Faculty": "Dr. Anbarasu Manivannan",
						"Name of the FacultyLink": "https://iitm.irins.org/profile/150467",
						Department: "Electrical & Electronics Engineering",
						"TU9 Faculty & Institute":
							"Prof. Dr. Matthias Wuttig Prof. Dr. Riccardo Mazzarello RWTH Aachen",
						Duration: "Sept, 2014",
					},
					{
						"#": 18,
						"Name of the Faculty": "Dr. Kapil Ahuja",
						"Name of the FacultyLink": "http://people.iiti.ac.in/~kahuja/",
						Department: "Computer Science & Engineering",
						"TU9 Faculty & Institute": "Prof. Dr. Heike Fassdender TU Braunschweig",
						Duration: "June, 2014",
					},
					{
						"#": 19,
						"Name of the Faculty": "Dr. Sudeshna Chattopadhyay",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~sudeshna/",
						Department: "Physics",
						"TU9 Faculty & Institute": "Prof. Dr. Uwe Klemradt RWTH Aachen",
						Duration: "June, 2013",
					},
				],
			},
			{
				title: "TU9 (Germany) Faculty Visiting IITI",
				cols: ["TU9 Faculty & Institute", "Department", "IITI Faculty", "Duration","Funding"],
				data: [
					{
						"#": 1,
						"TU9 Faculty & Institute":
							"Prof. Dr. Herbert Pfnür, Prof. Dr. Regina Nogueira, Prof. Dr. Andreas Kirschning, Dr. Gerald Dräger, Dr. Balasubramanian Ramani LU Hannover",
						"IITI Faculty": "Dr. Kapil Ahuja",
						"IITI FacultyLink": "http://people.iiti.ac.in/~kahuja/",
						Department: "Computer Science & Engineering",
						Duration: "April, 2018",
					},
					{
						"#": 2,
						"TU9 Faculty & Institute": "Prof. Dr. Anna Zakharova TU Berlin",
						"IITI Faculty": "Prof. Dr. Sarika Jalan",
						"IITI FacultyLink": "https://www.iiti.ac.in/people/~sarika/",
						Department: "Biosciences & Biomedical Engineering",
						Duration: "Oct, 2017",
					},
					{
						"#": 3,
						"TU9 Faculty & Institute": "Prof. Dr. Holger Butenschön LU Hannover",
						"IITI Faculty": "Dr. Rajneesh Misra",
						"IITI FacultyLink": "https://iiti.ac.in/people/~rajneesh/",
						Department: "Chemistry",
						Duration: "July and Oct 2017",
					},
					{
						"#": 4,
						"TU9 Faculty & Institute": "Prof. Dr. Burkhard Corves RWTH Aachen",
						"IITI Faculty": "Dr. Santhakumar Mohan",
						"IITI FacultyLink": "https://iitpkd.ac.in/people/santhakumar",
						Department: "Mechanical Engineering",
						Duration: "Oct, 2017",
					},
					{
						"#": 5,
						"TU9 Faculty & Institute": "Prof. Dr. Riccardo Mazzarello RWTH Aachen",
						"IITI Faculty": "Dr. Anbarasu Manivannan",
						"IITI FacultyLink": "https://iitm.irins.org/profile/150467",
						Department: "Electrical & Electronics Engineering",
						Duration: "Oct, 2017",
					},
				],
			},
			{
				title: "Non-TU9s (Germany)",
				cols: ["IITI Faculty", "Department", "Non-TU9 faculty & Institute", "Duration","Funding"],
				data: [
					{
						"#": 1,
						"IITI Faculty": "Dr. Bharath Kumar",
						"IITI FacultyLink": "https://iittp.ac.in/dr-bharath-kumar",
						Department: "Humanities and Social Sciences",
						"Non-TU9 faculty & Institute":
							"Prof. Dr. Wolfgang Knöbl Hamburg Institute for Social Research, Hamburg",
						Duration: "Oct - Nov 2017",
					},
					{
						"#": 2,
						"IITI Faculty": "Dr. I. A. Palani",
						"IITI FacultyLink": "https://iiti.ac.in/people/~palaniia/",
						Department: "Mechanical Engineering",
						"Non-TU9 faculty & Institute": "Prof. Dr. Simon Pauly IFW Dresden, Germany",
						Duration: "Oct – Nov 2017",
					},
					{
						"#": 3,
						"IITI Faculty": "Dr. Kapil Ahuja",
						"IITI FacultyLink": "http://people.iiti.ac.in/~kahuja/",
						Department: "Computer Science & Engineering",
						"Non-TU9 faculty & Institute": "Prof. Dr. Peter Benner Max Planck Institute, Magdeburg",
						Duration: "Jun 2016",
					},
					{
						"#": 4,
						"IITI Faculty": "Dr. Kapil Ahuja",
						"IITI FacultyLink": "http://people.iiti.ac.in/~kahuja/",
						Department: "Computer Science & Engineering",
						"Non-TU9 faculty & Institute": "Prof. Dr. Peter Benner Max Planck Institute, Magdeburg",
						Duration: "Jun 2014",
					},
					{
						"#": 5,
						"IITI Faculty": "Dr. Ankhi Roy",
						"IITI FacultyLink": "http://people.iiti.ac.in/~ankhi/Page1.html",
						Department: "Physics",
						"Non-TU9 faculty & Institute":
							"Prof. Dr. James Ritman, Prof. Dr. Susan Schadmand IKP, Forschungszentrum, Juelich",
						Duration: "Jun – Aug 2012",
					},
				],
			},
			{
				title: "Non-TU9s (Germany) Faculty visiting IITI",
				cols: ["Non-TU9 faculty & Institute", "IITI Faculty", "Department", "Duration","Funding"],
				data: [
					{
						"#": 1,
						"Non-TU9 faculty & Institute": "Prof. Dr. Ralf Averbeck (GSI, Darmstadt)",
						"IITI Faculty": "Dr. Ankhi Roy",
						"IITI FacultyLink": "http://people.iiti.ac.in/~ankhi/Page1.html",
						Department: "Physics",
						Duration: "Sept 2018",
					},
					{
						"#": 2,
						"Non-TU9 faculty & Institute":
							"Prof. Dr. Fabian Pfrengle, Max-Planck Institute of Colloids and Interface, Potsdam, Berlin",
						"IITI Faculty": "Dr. Venkatesh Chelvam",
						"IITI FacultyLink": "https://www.iiti.ac.in/people/~cvenkat/",
						Department: "Chemistry",
						Duration: "Nov 2017",
					},
					{
						"#": 3,
						"Non-TU9 faculty & Institute": "Prof. Dr. Malte Brasholz, University of Rostock, Rostock",
						"IITI Faculty": "Dr. Venkatesh Chelvam",
						"IITI FacultyLink": "https://www.iiti.ac.in/people/~cvenkat/",
						Department: "Chemistry",
						Duration: "Sept-Oct, 2016",
					},
				],
			},
		],
		America: [
			{
				title: "Canada",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration", "Funding"],
				data: [
					// {
						// "Sr. No.": "",
						// "Name of the Faculty": "",
						// "Name of the FacultyLink": "",
						// Department: "",
						// Visit: "",
						// Duration: "",
					// },
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Abhishek Srivastava",
						"Name of the FacultyLink": "",
						Department: "Computer Science and Engineering",
						Visit: "Alberta Machine Intellligence Institute (AMII), Canada",
						Duration: "21-29 of May 2022",
						Funding:"",
					},
					{
						"Sr. No.": "42.   ",
						"Name of the Faculty": "Prof. Avinash Sonawane",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93713",
						Department: "Biosciences and Biomedical Engineering (BSBE)",
						Visit: "TB meeting, Vancouver, Canada",
						Duration: "Oct, 2017",
					},
					{
						"Sr. No.": "45.   ",
						"Name of the Faculty": "Dr. Ananya Ghoshal",
						Visit: "McGill University and the niversité de Montréal, Canada DH 2017 Conference",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93843",
						Department: "School of Humanities and Social Sciences",
						Duration: "Aug, 2017",
					},
					{
						"Sr. No.": "44.   ",
						"Name of the Faculty": "Dr Anirban Sengupta",
						Visit: "IEEE CCECE in Halifax, Nova Scotia (Canada) ",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93753",
						Department: "Computer Science and Engineering",
						Duration: "May, 2015",
					},
					{
						"Sr. No.": "43.   ",
						"Name of the Faculty": "Dr Anirban Sengupta",
						Visit: "IEEE CCECE, Ryerson University in Toronto (Canada) ",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93753",
						Department: "Computer Science and Engineering",
						Duration: "May, 2014",
					},
				],
			},
			{
				title: "USA",
				cols: ["Name of the Faculty", "Department", "Visit", "Duration","Funding"],
				data: [
					// {
						// "Sr. No.": "",
						// "Name of the Faculty": "",
						// "Name of the FacultyLink": "",
						// Department: "",
						// Visit: "",
						// Duration: "",
					// },
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Saurabh Das",
						"Name of the FacultyLink": "",
						Department: "Astronomy, Astrophysics and Space Engineering",
						Visit: "Colorado State University, USA",
						Duration: "1 Aug to 1 Nov 2022",
						Funding:"SERB",
					},
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Ashok Kumar Mocherla",
						"Name of the FacultyLink": "",
						Department: "HSS",
						Visit: "School of Divinity, Harvard University, US",
						Duration: "15 July 2022 to 30 June 2023",
						Funding:"Yang Visiting Scholar Fellowship",
					},
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. A. Palani",
						"Name of the FacultyLink": "",
						Department: "Mechanical Engineering",
						Visit: "Purdue University, West Lafayette, USA",
						Duration: "9 May to 8 June 2022",
						Funding:"SERB OVDF grant and RDF",
					},
					{
						// "Sr. No.": "",
						"Name of the Faculty": "Dr. Abhishek Srivastava",
						"Name of the FacultyLink": "https://iiti.ac.in/people/~asrivastava/work%20exp.html",
						Department: "Computer Science and Engineering",
						Visit: "Purdue University, West Lafayette, USA",
						Duration: "1-30 of April 2022",
					},
					{
						"Sr. No.": "28.   ",
						"Name of the Faculty": "Dr. Ananya Ghoshal",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93843",
						Department: "School of Humanities and Social Sciences",
						Visit: "Stanford University, California, USA. Speaker at Exploring Humanities Pedagogies: Conversations, Commonalities, and Connections (Virtual Conference due to COVID restrictions)",
						Duration: "Jul, 2021",
					},
					{
						"Sr. No.": "21.   ",
						"Name of the Faculty": "Dr Anirban Sengupta",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93753",
						Department: "Computer Science and Engineering",
						Visit: "IEEE ICCE in Las Vegas, USA ",
						Duration: "Jan, 2020",
					},
					{
						"#": 1,
						"Name of the Faculty": "Prof. Venkatesh Chelvam",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93723",
						Department: "Chemistry",
						Visit: "DST SERB-Purdue University, USA Exchange visit at Purdue University, West Lafayette, USA",
						Duration: 2020,
					},
					{
						"Sr. No.": "30.   ",
						"Name of the Faculty": "Dr. Santanu Manna",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/113213",
						Department: "Mathematics",
						Visit: "USA, Brown University,",
						Duration: "August, 2019",
					},
					{
						"Sr. No.": "29.   ",
						"Name of the Faculty": "Dr. Santanu Manna",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/113213",
						Department: "Mathematics",
						Visit: "USA, Syracuse University",
						Duration: "July - August, 2019",
					},
					{
						"Sr. No.": "20.   ",
						"Name of the Faculty": "Dr Anirban Sengupta",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93753",
						Department: "Computer Science and Engineering",
						Visit: "IEEE ICCE in Las Vegas, USA ",
						Duration: "Jan, 2019",
					},
					{
						"#": 2,
						"Name of the Faculty": "Dr. Ananya Ghoshal",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93843",
						Department: "School of Humanities and Social Sciences",
						Visit: "The University of Chicago, Illinois\nSpeaker at the Chicago Colloquium on Digital Humanities and Computer Science (DHCS)",
						Duration: 2019,
					},
					{
						"Sr. No.": "32.   ",
						"Name of the Faculty": "Preeti Anand Bhobe",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/62146",
						Department: " Physics",
						Visit: "Prof. I. Karaman, Texas A&M University, USA",
						Duration: "Nov, 2018 - Feb, 2019",
					},
					{
						"Sr. No.": "19.   ",
						"Name of the Faculty": "Dr Anirban Sengupta",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93753",
						Department: "Computer Science and Engineering",
						Visit: "IEEE ICCE in Las Vegas, USA ",
						Duration: "Jan, 2018",
					},
					{
						"Sr. No.": "23.   ",
						"Name of the Faculty": "Dr. Ananya Ghoshal",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93843",
						Department: "School of Humanities and Social Sciences",
						Visit: "IUPUI, Indianapolis, Bloomington, USA Workshop 'titled Anthropology of the Anthropocene",
						Duration: "May, 2017",
					},
					{
						"Sr. No.": "31.   ",
						"Name of the Faculty": "Dr. Santanu Manna",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/113213",
						Department: "Mathematics",
						Visit: "USA, San Francisco,",
						Duration: "December, 2015",
					},
					{
						"Sr. No.": "27.   ",
						"Name of the Faculty": "Dr. Shaikh M. Mobin",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/60464",
						Department: "Chemistry",
						Visit: "Professor Janet Morrow, Prof. Jason Banedict and Late Prof. Phil Coppens at SUNY Buffalo, USA",
						Duration: "May, 2014",
					},
					{
						"Sr. No.": "28.   ",
						"Name of the Faculty": "Dr. Shaikh M. Mobin",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/60464",
						Department: "Chemistry",
						Visit: "Dr. Shaikh delivered an Invited talk at University of Chicago in the IURS.",
						Duration: "May, 2014",
					},
					{
						"Sr. No.": "28.   ",
						"Name of the Faculty": "Dr. Ananya Ghoshal",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93843",
						Department: "School of Humanities and Social Sciences",
						Visit: "MIT, Cambridge, USA. Speaker at Conference titled 'Media in Transition 8'",
						Duration: "May, 2013",
					},
					{
						"Sr. No.": "24.   ",
						"Name of the Faculty": "Dr. Ananya Ghoshal",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93843",
						Department: "School of Humanities and Social Sciences",
						Visit: "MIT, Cambridge, USA Conference titled 'Media in Transition 7'",
						Duration: "May, 2011",
					},
					{
						"Sr. No.": "25.   ",
						"Name of the Faculty": "Dr. Ananya Ghoshal",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93843",
						Department: "School of Humanities and Social Sciences",
						Visit: "Fulbright Fellowship at UC Berkeley, USA",
						Duration: "2010 to 2011",
					},
					{
						"Sr. No.": "26.   ",
						"Name of the Faculty": "Dr. Ananya Ghoshal",
						"Name of the FacultyLink": "https://iiti.irins.org/profile/93843",
						Department: "School of Humanities and Social Sciences",
						Visit: "MIT, Cambridge, USA Conference titled 'Media in Transition 6'",
						Duration: "Apr, 2009",
					},
				],
			},
		],
	},
};
