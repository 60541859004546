import React from "react";
import { NsdCards } from "../../../reusables/NsdCards";
import { data as lecData } from "./data";
import { data as eventData } from "../EventsPage/data";

export const LectureSeries = ({ event }) => {
	const data = lecData.filter((l) => l.link === event)[0];
	const eventNames = lecData.filter((l) => l.link === event)[0].eventNames;
	// console.log(eventNames);

	const finalEventData = eventData.filter((e) => eventNames.includes(e.name));
	// console.log(finalEventData);

	return (
		<>
			<div className="places nsdPage pt-3 mt-3 mb-3 bg-light font-ubuntu container-fluid">
				<h2 className="m-1 mb-4 mainTitle text-center">{data.title}</h2>
				<div>
					{finalEventData.map((cards, index) => {
						return (
							<NsdCards
								className="text-justify"
								img={cards.img}
								title={cards.title}
								text={cards.desc !== "" ? cards.desc : cards.speakers[0].desc}
								key={index}
								index={index}
								name={cards.name}
							/>
						);
					})}
				</div>
			</div>
		</>
	);
};
