import React, { useEffect,  useState } from "react";
import { MdLocationOn, } from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa";
import "./vidcard.css"

export const VidCard = ({person}) =>{
    const [width, setWidth] = useState(600);

    const updateDimensions = () => {
        // setWidth(window.innerWidth);
        console.log(`width: ${window.innerWidth}`);
        if (window.innerWidth >= 1000) {
            setWidth(600);
        } else {
            setWidth(0.5 * window.innerWidth);
        }
    };

    useEffect(() => {
        updateDimensions();
    }, []);

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    return(
        <div className="vidcard font-ubuntu mt-3 mb-4">
            <div className="embed-video-div m-0 p-0" style={{ marginTop: "100px !important" }}>
                <iframe
                    className="embed-iframe"
                    src={person.VidLink}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    width={width}
                    height={(9 / 16) * width}
                ></iframe>
            </div>
            <div className="p-2 pl-4 pr-4">
            <h2 className="font-acme">{person.name}</h2>
                <p>
                    <FaGraduationCap />
                    {person.program}
                </p>
                <p>
                    <MdLocationOn />
                    {person.country}
                </p>
                <p className="text-justify" style={{ whiteSpace: "pre-wrap" }}>
                    <i>{person.text}</i>
                </p>
            </div>
        </div>
    );
}