// Short term research program page

import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { image4, strpIsStudPdf, proposal_intphd, srpisnForm,avatar } from "../../../../../assets";
import { FiExternalLink } from "react-icons/fi";
import { AiFillFileText } from "react-icons/ai";
import { strp_international_phd,strp_international_phd1,faqData_intphd } from "./data.js";
import FAQ from './FAQ';
import "./Strp.css"

function StudentStrp() {
  const [accKey, setAccKey] = useState(-1);

  const handleAccClick = (i) => {
    if (i === accKey) setAccKey(-1);
    else setAccKey(i);
  };

  return (
    <div className="container pb-5 pt-4">
      <h2 className="font-acme text-center mb-5">
        Short-Term Research Fellowships for International PhD Students (1-3 months)
      </h2>
      {/* <img
        src={image4}
        alt=""
        style={{ maxWidth: "90%", marginLeft: "5%", marginBottom: "30px" }}
      /> */}
      <div className="font-ubuntu text-justify mb-4 strp-intro">
        <h2 className="font-acme text-center mb-5">
          Short-Term Research Fellowships for International PhD Students(2023-24)
        </h2>
        <div class="strp-internation-phd1">
        {strp_international_phd1.map((item) => {

          const imgSrc = item["img"] || avatar;

          return (
            <div class="card" style={{ width: '21rem' }}>
              <img alt="" src={imgSrc} style={{ height: '20rem' }} class="card-img-top" />
              <div class="card-body">
                <h5 class="card-title">{item["Name"]}</h5>
                <hr></hr>
                <p class="card-text">{item["dis"]}</p>
              </div>
            </div>
          );
        })}
      </div>
      


        {/* <p style={{ color: 'Red', fontWeight: 'bold' }}>Application deadline for current call: <span>15th January 2023</span></p> */}
        
        </div>


      <h2 class="font-acme text-center mb-5">
        Our previous year's fellowship awardees (2022-23)
      </h2>
      <div class="strp-internation-phd">
        {strp_international_phd.map((item) => {
          return (
            <div class="card" style={{ width: '21rem' }}>
              <img alt="" src={item["img"]} style={{ height: '20rem' }} class="card-img-top" />
              <div class="card-body">
                <h5 class="card-title">{item["Name"]}</h5>
                <hr></hr>
                <p class="card-text">{item["dis"]}</p>
              </div>
            </div>
          );
        })}
      </div>
      <a href={strpIsStudPdf} target="_blank" rel="noreferrer">
          <div className="appPortalBtn" style={{ width: 210 }}>
            <AiFillFileText /> Flyer of the program
          </div>
        </a>
        <br />
      <br />
      
      
      <Accordion>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(0);
            }}
          >
            <span>{accKey === 0 ? "-" : "+"}</span>&nbsp;&nbsp;Who can apply?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <ul>
                <li>
                  Students registered in a PhD program at any reputed foreign University/Institute
                </li>
                <li>Applicants who have completed 3.5 years or more are not eligible to apply</li>
                <li>Students possessing the <b>letter of acceptance</b> from the faculty supervisor of the host institute</li>
                <li>Students possessing the <b>letter of consent</b> from the faculty supervisor of the home institute</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="1"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(1);
            }}
          >
            <span>{accKey === 1 ? "-" : "+"}</span>&nbsp;&nbsp;What can be funded?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <ul>
                <li>INR 35,000 per month</li>
                <li>
                  Round Trip Economy class airfare, as per the
                  actuals or to a maximum of INR 1,20,000 for
                  US, Canada, and Australia; INR 1,00,000 for
                  Europe, UK, Africa and Japan and INR
                  80,000 for SAARC, Asian, ASEAN, and
                  Middle East countries
                </li>
                <li>Basic OPD Consultation at IIT Indore Health Centre on a payment of INR 1500 (6 months)</li>
                <li>
                  Visitor should ensure that his/her visit to India is completely covered by a suitable overseas travel and medical insurance
                </li>
                <li>Funded amount can only be used to carry out academic and research activities only</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="2"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(2);
            }}
          >
            <span>{accKey === 2 ? "-" : "+"}</span>&nbsp;&nbsp;Duration of the funding
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <ul>
                <li>1-3 months</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="3"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(3);
            }}
          >
            <span>{accKey === 3 ? "-" : "+"}</span>&nbsp;&nbsp;What is Expected?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <ul>
                <li>The awardee should submit a detailed progress report after the completion of the fellowship post which the student will be awarded with a completion certificate.</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="4"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(4);
            }}
          >
            <span>{accKey === 4 ? "-" : "+"}</span>&nbsp;&nbsp;Selection Criteria
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <ul>
                <li>Quality of the research proposal</li>
                <li>Relevance and Complementarity between the research groups</li>
                <li>Outcome of the project</li>
              </ul>
              {/* <ol>
                <li>
                  Applications will be invited and evaluated twice in a year (January and July), and
                  results will be announced in February and August, respectively. However, awardees
                  need to avail the award within one year from the date of award.
                </li>
                <li>
                  Applications (relevant academic documents as endorsed by the home institute,
                  letter of mentorship from a IIT Indore faculty, and a preliminary research plan
                  for the visit) have to be submitted to the International Office, IIT Indore.
                </li>
                <li>
                  Applications completed in all the aspects will be forwarded to the respective
                  departments, and nominations for the shortlisted candidates will be submitted by
                  the respective departments to the international office. Final decision on the
                  selected candidates will be made by the institute level selection committee
                  comprises of Student Mobility and Exchange Committee, International Relations
                  team, and/or External expert.
                </li>
              </ol> */}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="6"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(6);
            }}
          >
            <span>{accKey === 6 ? "-" : "+"}</span>&nbsp;&nbsp;FAQs
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <div><FAQ faqData={faqData_intphd} /></div>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="5"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(5);
            }}
          >
            <span>{accKey === 5 ? "-" : "+"}</span>&nbsp;&nbsp;Application Dates and Online Portal
            for applying
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              <p style={{ color: 'Red', fontWeight: 'bold' }}>Please fill up the Application along with the Research Proposal (in the attached format) by 30th of September 2024.</p>
              {/* <div>Application Period: January 15th, 2022 to February 14th, 2022</div> */}
              {/* <a href={srpisnForm} target="_blank" rel="noreferrer">
                <div className="appPortalBtn">
                  <FiExternalLink /> Application Form
                </div>
              </a>*/}
              <a href={proposal_intphd} target="_blank" rel="noreferrer">
                <div className="appPortalBtn" style={{ width: 210 }}>
                  <FiExternalLink /> Research Proposal Form
                </div>
              </a>
              <a href='https://irglobal.iiti.ac.in/' target="_blank" rel="noreferrer">
                <div className="appPortalBtn" style={{ width: 210 }}>
                  <FiExternalLink /> Application Form
                </div>
              </a>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default StudentStrp;
