import { 
    santanu,
    rupesh,
    nirmala,
    kiran,
    pathak,
    vimal,
    safique,
    ashok,
    gaurabsil,
  sanjeevsingh,
    neminath,
    thapasya,
    biswaruppathak,
    ashokkumar,
    profdhinakaran,
    parimal,
    saurabh,

    } from "../../../../assets";

export const mobility_prof = [
    {
        "Name":"Prof. Nirmala Menon",
        "dis":"Humanities and Social Sciences",
        img : nirmala,
    },
    {
        "Name":"Dr. Rupesh S. Devan",
        "dis":"Metallurgical Engineering and Materials Science",
        img : rupesh,
    },
    // {
    //     "Name":"Dr. Santanu Manna",
    //     "dis":"Mathematics",
    //     img : santanu,
    // },
    
]

export const mobility_prof2 = [
    {
        "Name":"Dr. Sanjeev Singh",
        "dis":"Mathematics",
        img : sanjeevsingh,
    },  {
        "Name":"Dr. Gaurab sil",
        "dis":"Civil Engineering",
        img : gaurabsil,
    },
]
export const strp_int_grants=[
    {
        "Name":"Prof. Kiran Bala",
        "dis":"from BSBE Department will be visiting Technical University of Munich, Germany ",
        img:kiran,
       

    },
    {
        "Name":"Sk. Safique Ahmad",
        "dis":"from Mathematics Department will be visiting University of Split Croatia",
        img:safique,
    },
    {
        "Name":"Prof. Biswarup Pathak",
        "dis":"from Chemistry Department will be visiting Khon Kaen University, Khon Kaen, Thailand", 
        img:pathak,
    },
    {
        "Name":"Dr. Ashok Kumar Mocherla",
        "dis":"from Humanities and Social Sciences  Department will be visiting Coventry University, United kingdom ",
        img:ashok,
    },
    {
        "Name":"Prof. Vimal Bhatia",
        "dis":"from Electrical Engineering department will be visiting University of Zilina, Zilina, Slovakia ",
        img:vimal,
        
    },
    {
        "Name":"Dr. Thapasya J. Assistant Professor",
        "dis":"from Humanities and Social Sciences Department will be visiting The Endangered Languages Documentation Programme (ELDP), Berlin Brandenburg Academy of Sciences and Humanities, Germany ",
        img:thapasya,
        
    },
    {
        "Name":"Prof. Neminath Hubballi",
        "dis":"from Computer Science and Engineering Department will be visiting Curtin University, Perth, Australia",
        img:neminath,
        
    },
    {
        "Name":"Dr. Saurabh Das",
        "dis":"from Astronomy, Astrophysics and Space Engineering Department will be visiting University of Bologna, Italy ",
         img:saurabh,
        
    },
    {
        "Name":"Dr. Parimal Kar",
        "dis":"from Bioscience and Biomedical Engineering Department will be visiting University of Oklahoma, USA",
         img : parimal,
        
    },
    {
        "Name":"Prof. Shanmugam Dhinakaran",
        "dis":"from Mechanical Engineering Department will be visiting Loughborough University, UK",
       img :profdhinakaran,
        
    },
]
