import { React, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { AiFillFileText } from "react-icons/ai";
import {strp_in_UG,proposal_intUG_PG,avatar} from "../../../../../assets";
import { FiExternalLink } from "react-icons/fi";
import {strp_international_ugpg,faqData_IntUG_PG} from './data.js';
import FAQ from './FAQ';
import "./Strp.css"


function UgPgFellowship() {
  const [accKey, setAccKey] = useState(-1);

  const handleAccClick = (i) => {
    if (i === accKey) setAccKey(-1);
    else setAccKey(i);
  };

  return (
    <div className="container pb-5 pt-4">
      <h2 className="font-acme text-center mb-5">
         Semester Exchange Program International UG and PG Students' (2023-24)
      </h2>
      <div class="strp-internation-ugpg">
        {strp_international_ugpg.map((item) => {

          const imgSrc = item["img"] || avatar;

          return (
            <div class="card" style={{ width: '21rem' }}>
              <img alt="" src={imgSrc} style={{ height: '20rem' }} class="card-img-top" />
              <div class="card-body">
                <h5 class="card-title">{item["Name"]}</h5>
                <hr></hr>
                <p class="card-text">{item["dis"]}</p>
              </div>
            </div>
          );
        })}
      </div>

      <br />
      

      <a href={strp_in_UG} target="_blank" rel="noreferrer">
        <div className="appPortalBtn" style={{ width: 210 }}>
          <AiFillFileText /> Flyer of the program
        </div>
      </a>
      <br />
      <p>
      IIT Indore is introducing UG/PG Students' Semester Exchange Fellowship Program in which UG students (7th and 8th semester) and PG students (3rd and 4th Semester) can work or study in IIT Indore for a semester. Students are required to contact the IITI Professor or department and submit an invitation letter at the time of application. Preference will be given to the institutions having an MoU with IIT Indore . Kindly visit International Relations website for the list of MoUs signed with IIT Indore.
      </p>
      <p>The main objectives of this program is solely to provide excellent opportunities for foreign students to obtain exposure to high-quality research and teaching at IIT Indore. We encourage you all to please take advantage of this program.</p>
      <br />
      {/* <p><p style={{ color: 'Red', fontWeight:'bold'}}>Application deadline for current call: <span>15th December 2022</span></p> Kindly send applications to <a href="mailto:managerir@iiti.ac.in">managerir@iiti.ac.in</a></p> */}
      <Accordion>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="4"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(4);
            }}
          >
            <span>{accKey === 4 ? "-" : "+"}</span>&nbsp;&nbsp;Selection Procedure
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <p>Students will be selected (based on CGPA in the current/previous (or both) semester, motivation letter, course mapping document.</p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="5"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(5);
            }}
          >
            <span>{accKey === 5 ? "-" : "+"}</span>&nbsp;&nbsp;Application Procedure
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              <ul>
                <li>Submit the application duly recommended by the Head of the Department</li>
                <li>Curriculum Vitae</li>
                <li>Motivation Letter</li>
                <li>Academic Transcripts</li>
                <li>Course mapping document</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="7"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(7);
            }}
          >
            <span>{accKey === 7 ? "-" : "+"}</span>&nbsp;&nbsp;FAQs
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="7">
            <div><FAQ faqData={faqData_IntUG_PG} /></div>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="6"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(6);
            }}
          >
            <span>{accKey === 6 ? "-" : "+"}</span>&nbsp;&nbsp;Application Dates
            and Online Portal for applying
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              <p style={{ color: 'Red', fontWeight: 'bold' }}>Please fill up the Application along with the Research Proposal (in the attached format) by 30th of September 2024.</p>
              <a href='https://irglobal.iiti.ac.in/' target="_blank" rel="noreferrer">
                <div className="appPortalBtn" style={{ width: 210 }}>
                  <FiExternalLink /> Application Form
                </div>
              </a>
              <a href={proposal_intUG_PG} target="_blank" rel="noreferrer">
                <div className="appPortalBtn" style={{ width: 210 }}>
                  <FiExternalLink /> Research Proposal Form
                </div>
              </a>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default UgPgFellowship;
