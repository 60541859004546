import React from "react";
import { NotFound } from "../../Others";
import { ChemTalks } from "../Chemtalks/Chemtalks";
import { LectureSeries } from "../LectureSeries/lectureSeries";
import { Nsd } from "../Nsd/Nsd";

export const Outreach = (props) => {
	const name = props.match.params.name;

	const nameToComponent = {
		nsd: <Nsd />,
		chemtalks: <ChemTalks />,
		nobel: <LectureSeries event={"nobel"} />,
		roleOfScience: <LectureSeries event={"roleOfScience"} />,
	};

	if (Object.keys(nameToComponent).includes(props.match.params.name)) return nameToComponent[name];
	else return <NotFound />;
};
