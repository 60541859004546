import React from "react"
import { VidCard } from "./vidCard"
import {data} from "./data"

export const VidTestimonials = () => {
	return (
		<div className="container">
			<h2 className="p-2 text-center mainTitle mt-3">Testimonials by International Students</h2>
			{data.map((person) => {
				return <VidCard person={person} />;
			})}
		</div>
	);
};