import React from "react";

export const PersonCard = ({ person }) => {
	return (
		<div className={"pcard" + (!person.desc || person.desc.length < 400 ? " minimizeWidth" : "")}>
			<img src={person.img} alt="person" />
			<div>
				<h5 className="font-acme">{person.name}</h5>
				<h6 className="font-acme" style={{ color: "var(--dark-blue)" }}>
					{person.designation}
				</h6>
				{person.desc && <p className="font-ubuntu pcardDesc">{person.desc}</p>}
			</div>
		</div>
	);
};
