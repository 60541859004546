import React from "react";
import { Table } from "react-bootstrap";
import "./Admission.css";
import { RiExternalLinkFill } from "react-icons/ri";
import { Link } from "react-router-dom";

export const Admission = () => {
	return (
		<article className="container bg-light pt-3 mt-3 font-ubuntu">
			<section>
				<h3 className="font-acme">Admission Category</h3>
				<p className="text-justify">
					Anyone who is not a national of India will be considered as an international student for the
					purposes of admission and fees. International students can seek admission to various Postgraduate
					and Research Programmes (Ph.D., M.Tech., M.S. (Research) and M.Sc.) under the following categories:
				</p>
				<Table striped bordered hover variant="primary">
					<thead>
						<tr>
							<th>Sr. No.</th>
							<th>Scheme</th>
							<th>Category</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>1</td>
							<td>ISF (International Self Finance)</td>
							<td> Self-Financing Students</td>
						</tr>
						<tr>
							<td>2</td>
							<td>ISW (International Sponsored by Industry or NGO)</td>
							<td>a) Industry Sponsorship b) NGO (Non-Government Organization) Sponsorship</td>
						</tr>
						<tr>
							<td>3</td>
							<td> GSW (International Sponsored by Government organization)</td>
							<td>
								a) ICCR Scholarship of Government of India Link{" "}
								<a target="_blank" rel="noreferrer" href="http://a2ascholarships.iccr.gov.in/">
									{" "}
									<RiExternalLinkFill />
								</a>
								<br />
								b) Study In India, Government of India Link
								<a target="_blank" rel="noreferrer" href="https://www.studyinindia.gov.in/">
									{" "}
									<RiExternalLinkFill />
								</a>
								<br />
								c) ASEAN Scholarship, Government of India Link
								<a target="_blank" rel="noreferrer" href="https://www.asean-iit.in/">
									{" "}
									<RiExternalLinkFill />
								</a>
								<br />
								d) Foreign Government Sponsorships
							</td>
						</tr>
					</tbody>
				</Table>
			</section>

			<section>
				<h3 className=" mb-4 font-acme">Required forms for International Exchange students</h3>

				<Table striped bordered hover variant="primary">
					<thead>
						<tr>
							<th>Forms</th>
							<th style={{ textAlign: "center" }}>Download</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Declaration Form for Anti-Ragging Rules</td>

							<td style={{ textAlign: "center" }}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://academic.iiti.ac.in/New_student/Declaration%20Form/Anti-Ragging%20Rules%20&%20Decl..pdf"
								>
									<RiExternalLinkFill />
								</a>
							</td>
						</tr>
						<tr>
							<td>Declaration Form for Dining Rules</td>
							<td style={{ textAlign: "center" }}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://academic.iiti.ac.in/New_student/Declaration%20Form/Dining%20Rules%20%20&%20Decl.pdf"
								>
									<RiExternalLinkFill />
								</a>
							</td>
						</tr>
						<tr>
							<td>Declaration Form for Internet Usage Policy</td>
							<td style={{ textAlign: "center" }}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://academic.iiti.ac.in/New_student/Declaration%20Form/Internet%20usage%20Policy%20&%20Decl.%20(1).pdf"
								>
									<RiExternalLinkFill />
								</a>
							</td>
						</tr>
						<tr>
							<td>Declaration Form for Safety and Security Rules</td>
							<td style={{ textAlign: "center" }}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://academic.iiti.ac.in/New_student/Declaration%20Form/Safety%20&%20Security%20Rules%20&%20Decl.%20(1).pdf"
								>
									<RiExternalLinkFill />
								</a>
							</td>
						</tr>
						<tr>
							<td>Library Membership Form for Students</td>
							<td style={{ textAlign: "center" }}>
								<a target="_blank" rel="noreferrer" href="http://library.iiti.ac.in/?page_id=41">
									<RiExternalLinkFill />
								</a>
							</td>
						</tr>
						<tr>
							<td>Medical Fitness Form</td>
							<td style={{ textAlign: "center" }}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://academic.iiti.ac.in/New_student/Declaration%20Form/Medical%20Fitness%20Form.pdf"
								>
									<RiExternalLinkFill />
								</a>
							</td>
						</tr>
					</tbody>
				</Table>
			</section>

			<section className="text-justify">
				<h3 className="font-acme">Selection Procedure</h3>
				<p>
					Admission of International Students to IIT Indore's Ph.D., M. Tech., M.S. (Research) and M.Sc.
					programs require the below besides the academic requirements:
					<ul>
						<li>
							a valid score of TOEFL/IELTS <strong>or equivalent</strong>
						</li>
						<li>a statement of purpose</li>
						<li>letters of recommendation</li>
						<li>an interview (in-person or over video conference/skype)</li>
					</ul>
				</p>
				<p>
					Students seeking admission in any one of the aforesaid categories (at Sr. No. 1) will have to
					satisfy the minimum eligibility criteria for admission to the programmes as prescribed by the
					Institute. But merely fulfilling the minimum eligibility criteria does not guarantee the selection
					of a candidate into any program.
				</p>
			</section>

			<section className="text-justify">
				<h3 className="font-acme">Fees for International Students:</h3>
				<Table style={{border: '1px solid black',borderCollapse: 'collapse',textAlign: 'center',boxSizing: 'border-box',backgroundColor: '#cfe2ff',width: '100%',}}>
					<tr>
						<th colspan="5"  style={{border: "1px solid black",padding : 10 }}>Fee structure</th>
					</tr>
					<tr>
						<th rowspan="2" style={{border: "1px solid black",padding : 10 }}>Countries</th>
						<th colspan="2" style={{border: "1px solid black",padding : 10 }}>Tuition Fees per semester</th>
						<th colspan="2" style={{border: "1px solid black",padding : 10 }}>Living Expenses*** <br /> per semester</th>
					</tr>
					<tr>
						<th style={{border: "1px solid black",padding : 10 }}>Sponsored</th>
						<th style={{border: "1px solid black",padding : 10 }}>Self-Finance</th>
						<th style={{border: "1px solid black",padding : 10 }}>Sponsored</th>
						<th style={{border: "1px solid black",padding : 10 }}>Self-Finance</th>
					</tr>
					<tr>
						<td style={{border: "1px solid black",padding : 10 }}>SAARC</td>
						<td style={{border: "1px solid black",padding : 10 }}>1000 USD <br />(No Change)</td>
						<td style={{border: "1px solid black",padding : 10 }}>800 USD</td>
						<td rowspan="4" colspan="3" style={{border: "1px solid black",padding : 10 }}>(a)  600 USD <br /><b> Or</b>   <br />(b)  400 USD <br /><b> Or</b><br />(c)At par with Indian students</td>
					</tr>
					<tr>
						<td style={{border: "1px solid black",padding : 10 }}>ASEAN</td>
						<td style={{border: "1px solid black",padding : 10 }}>1000 USD <br />(No Change)</td>
						<td style={{border: "1px solid black",padding : 10 }}>800 USD</td>
					</tr>
					<tr>
						<td style={{border: "1px solid black",padding : 10 }}>African</td>
						<td style={{border: "1px solid black",padding : 10 }}>1000 USD <br />(No Change)</td>
						<td style={{border: "1px solid black",padding : 10 }}>800 USD</td>
					</tr>
						<tr>
						<td style={{border: "1px solid black",padding : 10 }}>Other countries</td>
						<td style={{border: "1px solid black",padding : 10 }}>1200 USD</td>
						<td style={{border: "1px solid black",padding : 10 }}>1000 USD</td>
						</tr>
				</Table>
			<div>
				(a) applicable for JC Bose Unit with basic amenities and the option of cooking facility.***
			</div>
			<div>
				(b) applicable for Hall of Residence along with basic amenities. The dining charges to be paid as semester advance.
			</div>
			<div>
				(c) applicable for facilities at par with Indian students.
			</div>
				
		
	</section>
				<section className="text-justify">
					<h3 className="font-acme">Admission</h3>
					<p>
						For more information,{" "}
						<a href="https://academic.iiti.ac.in/admission.php" target="_blank" rel="noreferrer">
							Click here
						</a>
					</p>
				</section>
			<section className="text-justify">
				<h3 className="font-acme">International Student List</h3>
				<p>
					For more information, <Link to="/int/students">Click here</Link>
				</p>
			</section>
		</article>
	);
};
