export const data = [
	{
		title: "Role of Science in Nation Building during Global Health Crisis",
		eventNames: ["healthVI", "healthV", "health"],
		link: "roleOfScience",
	},
	{
		title: "Nobel Laureate Lectures",
		eventNames: ["atomic", "quantumReform"],
		link: "nobel",
	},
];
