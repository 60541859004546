// Short term research program page

import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { IRgrant,avatar} from "../../../../assets";
import { FiExternalLink } from "react-icons/fi";
import { AiFillFileText } from "react-icons/ai";
import { Table } from "react-bootstrap";
import {strp_int_grants} from "./data.js"
import "./strp.css"


function InternationalGrant() {
  const [accKey, setAccKey] = useState(-1);

  const handleAccClick = (i) => {
    if (i === accKey) setAccKey(-1);
    else setAccKey(i);
  };

  return (
    <div className="container pb-5 pt-4">
       <h2 className="font-acme text-center mb-5">
       International Travel Grant (2024-2025)
      </h2>
      <h2 className="font-acme text-center mb-5">
        International Travel Grant awardees(2023-2024)
      </h2>
     
      <div class="strp-int-grants">
        {strp_int_grants.map((item) => {
          const imgSrc = item["img"] || avatar;
          
          return (
            <div class="card" style={{ width: '21rem' }}>
              <img src={imgSrc} style={{ height: '20rem' }} class="card-img-top" />
              <div class="card-body">
                <h5 class="card-title">{item["Name"]}</h5>
                <hr></hr>
                <p class="card-text">{item["dis"]}</p>
              </div>
            </div>
          );
        })}
      </div>
     
      <br />
      <div className="font-ubuntu text-justify mb-4 strp-intro">
        {/* <p style={{ color: 'Red', fontWeight:'bold'}}>Application deadline for current call: <span>30th November 2022</span></p> */}
        <a href={IRgrant} target="_blank" rel="noreferrer">
          <div className="appPortalBtn" style={{ width: 210 }}>
            <AiFillFileText /> Flyer of the program
          </div>
        </a>
        <br />
      </div>
      <Accordion>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="1"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(0);
            }}
          >
            <span>{accKey === 0 ? "-" : "+"}</span>&nbsp;&nbsp;Who can apply?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <ul>
                <li>
                IIT Indore faculty members intended to
                explore new research collaborations by
                visiting international universities/institutes
                </li>
                <li>
                  Applicant needs to submit a proposal with
                  a detailed visit plan which includes:
                  <p>Place of visit, travel dates, objective of
                  the collaboration, how this collaboration
                  will help our faculty members and
                  students, and what will be the benefits to
                  the institute</p>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="7"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(6);
            }}
          >
            <span>{accKey === 6 ? "-" : "+"}</span>&nbsp;&nbsp;Instructions
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              <p>It is requested that the applicant kindly fill out the basic information in the application form.</p>
              <p>Applicants are requested to mention:</p>
              <ul>
                  <li>"Objective of the proposed collaboration"</li>
                  <li>"Benefits of this collaboration for the institute, faculty members and students"</li>
              </ul>
              <p>A brief description of the program schedule for the visit, along with detailed information about the counterpart university/institute and contact details of the coordinator. A travel plan may be uploaded with dates, places, and duration of stay during the visit as per approved norms of the institute as mentioned in the flyer under International Travel Grant 2024–25.</p>
              <p>Please upload the Visit Proposal for International Travel Grant 2024–25.</p>
              <p><a href="https://drive.google.com/uc?export=download&id=1v6BJpdqkJU5Ewe-k7RWUwhsWUDHo0v4v">Click here to download the template for the visit proposal.</a></p>
              <p>This is Round-I (July 15– September 30, 2024)</p>
              <p>It is requested that Air tickets be booked from any one of the travel agencies:</p>
              <ul>
                  <li>M/s. Balmer Lawrie & Company Ltd.</li>
                  <li>M/s. Ashoka Travels and Tours</li>
                  <li>IRCTC</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="2"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(1);
            }}
          >
            <span>{accKey === 1 ? "-" : "+"}</span>&nbsp;&nbsp;Financial Support
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <ul>
                <li>Number of International Grant - 10</li>
                <li>
                  A round trip of economical travel grants as per approved institute norms (from Rs. 80,000 to Rs. 1.2 lacs) + living expensese of 250 $ per day up to a period of maximum 05 days only 
                </li>
                <li>visit to commence before <b>July 31, 2025</b></li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="4"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(3);
            }}
          >
            <span>{accKey === 3 ? "-" : "+"}</span>&nbsp;&nbsp;What is Expected?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <p>
              Faculty members who are awarded the International Travel Grant can expect the following:
              <ul>
                <li>A round-trip economical airfare grant as per approved Institute norms (from Rs. 80,000 to Rs. 1.2 lacs).</li>
                <li>Living expenses of 250 USD per day up to a maximum period of 5 days.</li>
                <li>The grant can be used to cover the cost of travel, accommodation, meals, and incidental expenses incurred during the visit.</li>
                <li>The grant is for a maximum period of 5 days and must be used before July 31, 2025.</li>
                <li>The grant will be awarded on a merit basis and the selection committee will consider the following factors:</li>
                  <ul>
                    <li>The quality of the research proposal</li>
                    <li>The potential benefits of the collaboration to the faculty member, students, and institute</li>
                    <li>The applicant's track record of research and publications</li>
                  </ul>
              </ul>
            </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="5"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(4);
            }}
          >
            <span>{accKey === 4 ? "-" : "+"}</span>&nbsp;&nbsp;Selection
            Criteria
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              <ul>
                <li>The committee will evaluate the applications and recommend them on a merit basis</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordionCard">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="6"
            className="accordionHeader"
            onClick={() => {
              handleAccClick(5);
            }}
          >
            <span>{accKey === 5 ? "-" : "+"}</span>&nbsp;&nbsp;Application Dates
            and Online Portal for applying
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
            <p style={{ color: 'Red', fontWeight: 'bold' }}>Please fill up the Application by the deadline given below.</p>
            <Table striped bordered hover variant="primary" responsive className="mb-3">
              <thead>
                <tr>
                  <th>Application Round</th>
                  <th>Application Open</th>
                  <th>Application Close</th>
                  <th>Screening Decision</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Round-I</td>
                  <td>July 15, 2024</td>
                  <td>September 30, 2024</td>
                  <td>Within 2 weeks from closing date</td>
                </tr>
                <tr>
                  <td>Round-II</td>
                  <td>October 15, 2024</td>
                  <td>December 15, 2024</td>
                  <td>Within 2 weeks from closing date</td>
                </tr>
              </tbody>
            </Table>
              <a href='https://irglobal.iiti.ac.in/' target="_blank" rel="noreferrer">
                <div className="appPortalBtn">
                  <FiExternalLink /> Application Form
                </div>
              </a>
              <a href='https://drive.google.com/uc?export=download&id=1v6BJpdqkJU5Ewe-k7RWUwhsWUDHo0v4v' target="_blank" rel="noreferrer">
                <div className="appPortalBtn">
                  <FiExternalLink /> Proposal Template
                </div>
              </a>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}


export default InternationalGrant;
