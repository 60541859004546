export const data = [
    {
        deptName: "Department of Metallurgy Engineering and Materials Science",
        deptLink: "http://mems.iiti.ac.in/",
        data: [
            {
                fcName: "Dr. Rupesh S Devan",
                fcLink: "https://rupesh76.wixsite.com/rupesh",
                topics: [
                    "Hybrid Materials for Energy Storage",
                    "Photo-active Materials",
                    "Hybrid solar cells",
                ],
            },
            {
                fcName: "Dr. Sunil Kumar",
                Department: "MEMS",
                email: "sunil@iiti.ac.in",
                fcLink: "https://iiti.ac.in/people/~sunil/index.php",
                topics: [
                    "All-Solid-State Lithium Batteries",
                    "Cathodes for Na-ion Batteries",
                    "Piezoelectric Ceramics",
                ],
            },
        ],
    },
    {
        deptName: "Department of Computer Science and Engineering",
        deptLink: "http://cse.iiti.ac.in/",
        data: [
            {
                fcName: "Dr. Nagendra Kumar",
                fcLink: "https://sites.google.com/view/nagendrak/home",
                topics: [
                    "Social Network Analysis",
                    "Natural Language Processing",
                    "Machine Learning and Deep Learning",
                ],
            },
            {
                fcName: "Dr. Surya Prakash",
                Department: "CSE",
                email: "surya@iiti.ac.in",
                fcLink: "https://www.iiti.ac.in/people/~surya/",
                topics: ["Deep Learning", "Computer Vision", "Artificial Intelligence"],
            },
            {
                fcName: "Dr. Ayan Mondal",
                Department: "Computer Science and Engineering",
                email: "ayanm@iiti.ac.in",
                fcLink: "https://www.iiti.ac.in/people/~ayanm/",
                topics: [
                    "Internet of Things (IoT) Networks",
                    "Traffic Management in Software-Defined Networks",
                    "Provisioning Sensors-as-a-Service in Sensor-Cloud",
                ],
            },
        ],
    },
    {
        deptName: "Department of Electrical Engineering ",
        deptLink: "http://ee.iiti.ac.in/",
        data: [
            {
                fcName: "Dr. Swaminathan R",
                Department: "Electrical Engineering",
                email: "swamiramabadran@iiti.ac.in",
                fcLink: "https://swamiramabadran.wixsite.com/website",
                topics: ["Wireless Communications", "Communication Systems", "6G Communications"],
            },
            {
                fcName: "Dr. Trapti Jain",
                Department: "Electrical Engineering",
                email: "traptij@iiti.ac.in",
                fcLink: "https://poweriiti.weebly.com/",
                topics: [
                    "Synchrophasor applications to power system",
                    "Microgrid control",
                    "Data analytics in smart grid",
                ],
            },
            {
                fcName: "Dr. Vivek Kanhangad",
                Department: "EE",
                email: "kvivek@iiti.ac.in",
                fcLink: "http://people.iiti.ac.in/~kvivek/",
                topics: [
                    "Deep learning for computer vision",
                    "Biometrics",
                    "Medical image analysis",
                ],
            },
        ],
    },
    {
        deptName: "Department of Astronomy, Astrophysics and Space Engineering",
        deptLink: "https://aase.iiti.ac.in/",
        data: [
            {
                fcName: "Dr. Suman Majumdar",
                Department: "Department of Astronomy, Astrophysics and Space Engineering",
                email: "suman.majumdar@iiti.ac.in",
                fcLink: "http://people.iiti.ac.in/~sumanm/",
                topics: [
                    "Cosmology with next generation telescopes",
                    "Statistical Inference and Machine Learning with BIG DATA cosmology",
                    "State-of-the art simulations of the Universe",
                ],
            },
            {
                fcName: "Dr. Unmesh Khati",
                Department: "AASE",
                email: "unmesh.khati@iiti.ac.in",
                topics: [
                    "Synthetic Aperture Radar remote sensing",
                    "Fusion of multi-sensor remotely sensed data",
                    "Change detection using remote sensing data",
                ],
            },
            {
                fcName: "Dr. Abhirup Datta",
                Department: "Astronomy, Astrophysics and Space Engineering",
                email: "abhirup.datta@iiti.ac.in",
                fcLink: "https://sites.google.com/iiti.ac.in/abhirupdatta/",
                topics: [
                    "Observational Cosmology - Radio and X-ray Astronomy",
                    "Machine Learning Applications in Space Sciences",
                    "GNSS, Ionosphere and Space Weather",
                ],
            },
        ],
    },
    {
        deptName: "Department of Civil Engineering",
        deptLink: "http://ce.iiti.ac.in/",
        data: [
            {
                fcName: "Dr. Kaustav Bakshi",
                Department: "Civil Engineering",
                email: "kaustav.bakshi@iiti.ac.in",
                fcLink: "https://sites.google.com/view/kaustavbakshi/home",
                topics: [
                    "Nonlinear static analysis of laminated composite shells",
                    "Nonlinear vibration analysis of laminated composite shells",
                    "Buckling of laminated composite shells",
                ],
            },
            {
                fcName: "Dr. Guru Prakash",
                Department: "Civil engineering",
                email: "Guruprakash@iiti.ac.in",
                topics: [
                    "Damage detection",
                    "Degradation modelling",
                    "Structural health monitoring",
                ],
            },
            {
                fcName: "Dr. Gourab Sil",
                Department: "Civil engineering",
                email: "gourabsil@iiti.ac.in",
                topics: [
                    "Naturalistic driving behavior studies",
                    "Effect of geometric design on roadway safety",
                    "Traffic flow analysis and modelling",
                ],
            },
            {
                fcName: "Dr. Manish Kumar Goyal",
                Department: "Civil ENgineering",
                email: "mkgoyal@iiti.ac.in",
                fcLink: "https://sites.google.com/view/mkg1/home",
                topics: [
                    "Climate change and disaters",
                    "Drought and Heat Wave, Extreme events",
                    "Water managment, Hydrological Modeling, Wetland",
                ],
            },
            {
                fcName: "Dr. Ashootosh Mandpe",
                Department: "Civil Engineering",
                email: "as_mandpe@iiti.ac.in",
                fcLink: "http://ce.iiti.ac.in/faculty.php",
                topics: [
                    "Bio-valorization of solid wastes",
                    "Contaminants of Emerging Concern in Municipal Landfills",
                    "Additive-aided-Thermophilic composting",
                ],
            },
        ],
    },
    {
        deptName: "Department of Mathematics",
        deptLink: "http://math.iiti.ac.in/",
        data: [
            {
                fcName: "Dr. Santanu Manna",
                Department: "Mathematics",
                email: "santanu@iiti.ac.in",
                fcLink: "http://people.iiti.ac.in/~santanu/dr.santanu%20manna.html",
                topics: [
                    "Mathematical Modelling",
                    "Local/Nonlocal Elastic Wave Propagation",
                    "Earthquake Prediction Analysis",
                ],
            },
            {
                fcName: "Dr. Ashisha Kumar",
                Department: "Department of Mathematics",
                email: "akumar@iiti.ac.in",
                fcLink: "http://www.iiti.ac.in/people/~akumar",
                topics: ["The Radon Transform", "The X-ray transform", "The k-plane transform"],
            },
            {
                fcName: "Dr. Md. Aquil Khan",
                topics: ["Mathematical Logic"],
                fcLink: "https://iiti.irins.org/profile/62130",
            },
            {
                fcName: "Dr. Sanjeev Singh",
                topics: ["Special functions"],
                fcLink: "https://iiti.irins.org/profile/93815",
            },
            {
                fcName: "Dr. Bapan Ghosh",
                topics: [
                    "Fractional and Delay Differential Equations",
                    "Dynamical Systems and Chaos",
                    "Mathematical Biology",
                ],
                fcLink: "https://iiti.irins.org/profile/113211",
            },
            {
                fcName: "Dr. Mohd. Arshad",
                topics: [
                    "Statistical Inference",
                    "Statistical Decision Theory",
                    "Ranking and Selection",
                ],
                fcLink: "http://people.iiti.ac.in/~arshad/index.html",
            },
            {
                fcName: "Dr. Niraj Kumar Shukla",
                topics: ["Wavelet, Frame and Shift Invariant Space"],
                fcLink: "https://iiti.irins.org/profile/93807",
            },
        ],
    },
    {
        deptName: "Department of Mechanical Engineering",
        deptLink: "http://me.iiti.ac.in/",
        data: [
            {
                fcName: "Dr. Kazi Sabiruddin",
                Department: "Mechanical Engineering",
                email: "skazi@iiti.ac.in",
                fcLink: " https://scholar.google.co.in/citations?hl=en&user=ukVKN0wAAAAJ",
                topics: [
                    "Surface Engineering",
                    "Thermally Sprayed coatings",
                    "Conventional Machining",
                ],
            },
            {
                fcName: "Dr. Harekrishna Yadav",
                topics: [
                    "Experimental Fluid Dynamics and Heat Transfer, Heat Transfer Enhancement",
                    "Fluid Structure Interaction, Shear Flow",
                    "Flow and Turbulence Measurement using Optical Techniques",
                    "Renewable and Sustainable Energy",
                ],
                fcLink: "https://iiti.irins.org/profile/113216",
            },
        ],
    },
    {
        deptName: "Department of Physics",
        deptLink: "http://physics.iiti.ac.in/",
        data: [
            {
                fcName: "Dr. Preeti Bhobe",
                Department: "Physics",
                email: "pbhobe@iiti.ac.in",
                fcLink: "https://iiti.ac.in/people/~pbhobe/index.html",
                topics: [
                    "Thermoelectric materials and measurements",
                    "Magnetic Heusler Alloys",
                    "X-ray Absorption Spectroscopy",
                ],
            },
            {
                fcName: "Dr. Pankaj R Sagdeo",
                Department: "PHYSICS",
                email: "prs@iiti.ac.in",
                fcLink: "http://people.iiti.ac.in/~prs/index.php/dr-pankaj-sagdeo/",
                topics: [
                    "Nanomaterials for spintronics and potential devices",
                    "Solar energy and photovoltics system",
                    "Critical Charactrization of materials using synchtrotron based and lab based techniques",
                ],
            },
            {
                fcName: "Prof. Sarika Jalan",
                topics: ["Complex Systems and Nonlinear Dynamics"],
                fcLink: "https://iiti.irins.org/profile/93833",
            },
            {
                fcName: "Dr. Rajesh Kumar",
                topics: [
                    "Electrochromic materials and devices",
                    "Nanomaterials synthesis and applications",
                    "Raman spectroscopy and Microscopy",
                ],
                fcLink: "https://iiti.irins.org/profile/62110",
            },
        ],
    },
    {
        deptName: "Department of Biosciences and Biomedical Engineering",
        deptLink: "http://bsbe.iiti.ac.in/",
        data: [
            {
                fcName: "Dr. Mirza S Baig",
                Department: "BSBE",
                email: "msb.iit@iiti.ac.in",
                fcLink: "https://msblaboratory.wixsite.com/msb-lab",
                topics: [
                    "Inflammation and Cancer",
                    "Anti-Inflammatory Drug Discovery",
                    "Drug Discovery, Design and Development",
                ],
            },
            {
                fcName: "Dr. Kiran Bala",
                topics: ["Algal Biology", "Phycotechnology"],
                fcLink: "https://iiti.irins.org/profile/93703",
            },
        ],
    },
    {
        deptName: "Department of Chemistry",
        deptLink: "http://chemistry.iiti.ac.in/",
        data: [
            {
                fcName: "Dr. Suman Mukhopadhyay",
                Department: "Chemistry",
                email: "suman@iiti.ac.in",
                fcLink: "https://suman729.wixsite.com/mysite",
                topics: [
                    "Metal based anticancer compounds",
                    "Metal Based Soft Materials",
                    "Metalloenzyme and catalysis",
                ],
            },
            {
                fcName: "Dr. C. Venkatesh",
                topics: [
                    "Chemical Biology and Medicinal Chemistry of Carbocycles and Heterocycles",
                    "Total Synthesis of Biologically Active Natural Products",
                    "Drug Delivery and Bioconjugate Chemistry",
                    "Synthesis of Small Molecule Inhibitors for Treatment of Cancer, Infectious and Inflammatory Diseases.",
                    "Radiopharmaceutical and Radionuclide Chemistry using 99mTc, 18F, 64Cu, 68Ga and 177Lu for Diagnosis and Treatment of Cancer and Inflammatory Diseases.",
                    "3D-Spheroid Models for Diagnosis and Treatment Cancer",
                ],
                fcLink: "https://iiti.irins.org/profile/93723",
            },
            {
                fcName: "Prof. Sanjay Kumar Singh",
                topics: ["Hydrogen Production processes"],
                fcLink: "https://iiti.irins.org/profile/62121",
            },
        ],
    },
    {
        deptName: "School of Humanities and Social Sciences",
        deptLink: "http://hss.iiti.ac.in/",
        data: [
            {
                fcName: "Dr. Aratrika Das",
                Department: "SHSS",
                email: "aratrika@iiti.ac.in",
                topics: ["Gothic", "Victorian Literature", "Translation Studies"],
            },
            {
                fcName: "Dr. Ananya Ghoshal",
                Department: "School of Humanities and Social Sciences",
                email: "aghoshal@iiti.ac.in",
                fcLink: "https://iiti.irins.org/profile/93843",
                topics: [
                    "Literature and the Other Arts (How Literature evolves in relation to Music, Painting and Cinema)",
                    "Environmental Literature in the Anthropocene",
                    "Fundamentals of Creative Writing (Writing Short Stories)",
                ],
            },
            {
                fcName: "Dr. Nirmala Menon",
                Department: "HSS",
                email: "nmenon@iiti.ac.in",
                fcLink: "https://dhlabiitindore.com/",
                topics: [
                    "Digital Humanities",
                    "Postcolonial Digital Humanities",
                    "Computational tools for Translation",
                ],
            },
            {
                fcName: "Dr. Ashok Kumar Mocherla",
                Department: "Humanities and Social Sciences",
                email: "ashokmocherla@iiti.ac.in",
                topics: [
                    "Sociology of Faith Healing and Public Health",
                    "Religion and Systems of Social Stratification",
                    "Sociology of Higher Education and Minorities",
                ],
            },
            {
                fcName: "Dr. Akshaya Kumar",
                Department: "Humanities and Social Sciences",
                email: "akshaya.kumar@iiti.ac.in",
                topics: [
                    "Indian Cinema",
                    "Platform Capitalism",
                    "Indian Media Studies (News, Television & OTT platforms)",
                ],
            },
        ],
    },
];
