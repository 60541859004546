import React, { useEffect, useState } from "react";
import "./CustomNavbar.css";
//import { NavAboutUs } from "./NavAboutUs";
//import { NavCooperation } from "./NavCooperation";
//import { NavInbound } from "./NavInbound";
//import { NavOutbound } from "./NavOutbound";
//import { NavOutreach } from "./NavOutreach";
import { FiChevronDown } from "react-icons/fi";
import {MdFiberNew} from "react-icons/md" ;
import { Link } from "react-router-dom";
import iaoBrochure from "../../../../assets/Institute Brochure-IAO.pdf";
import SOP_IR from "../../../../assets/SOP_IR.pdf"

export const CustomNavbar = ({ val, setVal }) => {
	const [showDiv, setShowDiv] = useState(false);
	const [currentVal, setCurrentVal] = useState(0);
	const [hoverVal, setHoverVal] = useState(0);

	const linkClick = () => {
		handleLinkClick();
	};

	const showMyDiv = (value) => {
		setShowDiv(true);
		setCurrentVal(value);
		setHoverVal(value);
	};

	useEffect(() => {
		console.log(hoverVal);
	}, [hoverVal]);

	const hideMyDiv = () => {
		setShowDiv(false);
		setCurrentVal(0);
		setHoverVal(0);
	};

	const handleLinkClick = (value) => {
		setVal(value);
		hideMyDiv();
	};

	return (
		<div className="customNavbar font-ubuntu" style={{ overflow: "visible", height: "50px", width: "100vw" }}>
			<div onMouseLeave={hideMyDiv} style={{ overflow: "visible", height: "50px", marginLeft: "-1.5vw" }}>
				<ul className="customNavbarLinks">
					<div className="mainLink mainLink1 ">
						<li
							className="mainLink-heading AboutUsMenuBar"
							onMouseEnter={() => {
								showMyDiv(1);
							}}
							style={
								hoverVal === 1
									? { color: "#00467f", backgroundColor: "#fff" }
									: val === 1
									? { color: "#fff", backgroundColor: "#0066b9" }
									: {}
							}
						>
							About Us <FiChevronDown />
							<div className="AboutUsMenu">
								<ul>
									<li className="AboutUs">About Us
										<div className="AboutUs1">
											<p>
												<Link onClick={linkClick} to="/Aboutioa">
													About IR
												</Link>
											</p>
											{/* <p>
												<Link onClick={linkClick} to="/Aboutiiti">
													About IITI
												</Link>
											</p> */}
											<p>
												<Link onClick={linkClick} to="/LifeIITI">
													<span>Campus Life @ IITI</span>
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/Pros/places">
													Places To Visit
												</Link>
											</p>
											<p>
												<a href={iaoBrochure} target="_blank" rel="noreferrer">
													IR Brochure
												</a>
											</p>
											<p>
												<a href={SOP_IR} target="_blank" rel="noreferrer">
													IR SOP for Visitors
												</a>
											</p>
										</div>
									</li>
									<li className="People">People
										<div className="People1">
											<p>
												<Link onClick={linkClick} to="/people/oia">
													International Relations
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/people/sc">
													SIR Cell
												</Link>
											</p>
											{ <p>
												<Link onClick={linkClick} to="/committee/oc">
													Outreach Committee
												</Link>
											</p> }
											<p>
												<Link onClick={linkClick} to="/committee/mec">
													Academic and Research Advisory Committee
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/committee/ifs">
													International Funding Scheme Committee
												</Link>
											</p>
										</div>
									</li>
									<li className="Facilities">Facilities
										<div style={{ marginRight: "40px" }} className="Facilities1">
											<p>
												<a href="http://hostel.iiti.ac.in/" target="_blank" rel="noreferrer">
													Hostels
												</a>
											</p>
											<p>
												<Link onClick={linkClick} to="/guestHouse">
													Guest House
												</Link>
											</p>
											<p>
												<a href="http://iiti.ac.in/page/campus-facilities" target="_blank" rel="noreferrer">
													Eateries
												</a>
											</p>
											<p>
												<a href="http://people.iiti.ac.in/~medical/" target="_blank" rel="noreferrer">
													Health Centre
												</a>
											</p>
											<p>
												<a href="http://iiti.ac.in/page/e-payments" target="_blank" rel="noreferrer">
													E-Payments
												</a>
											</p>
											<p>
												<a href="http://iiti.ac.in/page/counselling-cell" target="_blank" rel="noreferrer">
													Counselling Cell
												</a>
											</p>
											<p>
												<a href="http://cc.iiti.ac.in/" target="_blank" rel="noreferrer">
													Computer and IT Center
												</a>
											</p>
											<p>
												<a href="http://placement.iiti.ac.in" target="_blank" rel="noreferrer">
													Training and Placement
												</a>
											</p>
											<p>
												<a href="http://people.iiti.ac.in/~creche/" target="_blank" rel="noreferrer">
													Nursery School
												</a>
											</p>
											<p>
												<a href="http://vbs.iiti.ac.in/" target="_blank" rel="noreferrer">
													Transport Booking
												</a>
											</p>
											<p>
												<a
													href="https://www.iiti.ac.in/public/storage/Green%20vehicle%20schedule-%202021.pdf"
													target="_blank"
													rel="noreferrer"
												>
													Green vehicle schedule
												</a>
											</p>
											<p>
												<a href="http://www.iitindore.kvs.ac.in/" target="_blank" rel="noreferrer">
													KV IIT Indore
												</a>
											</p>
										</div>
									</li>
									<li className="AAR">Academics and Research
										<div className="AAR1">
											<p>
												<a href="http://rnd.iiti.ac.in/" target="_blank" rel="noreferrer">
													Research and Development
												</a>
											</p>
											<p>
												<a href="https://academic.iiti.ac.in/" target="_blank" rel="noreferrer">
													Academic Programs
												</a>
											</p>
											<p>
												<a href="https://iiti.ac.in/departments" target="_blank" rel="noreferrer">
													Departments
												</a>
											</p>
											<p>
												<a href="https://iiti.ac.in/page/centers" target="_blank" rel="noreferrer">
													Centers
												</a>
											</p>
										</div>
									</li>
									<li className="engage">Engage With Us
										<div className="engage1">
											<p>
												<Link onClick={linkClick} to="/contact">
													Contact
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/outreach/news">
													News and Activities
												</Link>
											</p>
										</div>
									</li>
								</ul>
							</div>
						</li>
					</div>
					<div className="mainLink mainLink1">
						<li
							className="mainLink-heading InboundMenuBar"
							onMouseEnter={() => {
								showMyDiv(2);
							}}
							style={
								hoverVal === 2
									? { color: "#00467f", backgroundColor: "#fff" }
									: val === 2
									? { color: "#fff", backgroundColor: "#0066b9" }
									: {}
							}
						>
							Inbound <FiChevronDown />
							<div style={{color: 'red'}}>
                                <MdFiberNew size={25} />
                            </div>
							<div className="InboundMenu">
								<ul>
									<li className="Visitors">Visitors
										<div className="Visitors1">
            							  <p>
            							    <Link onClick={linkClick} to="/Pros/visitors_experience">
            							      Alumni's Experience
            							    </Link>
            							  </p>
            							  <p>
            							    <Link onClick={linkClick} to="/Pros/places">
            							      Places To Visit
            							    </Link>
            							  </p>
            							  <p>
            							    <Link onClick={linkClick} to="/int/students">
            							      International Student's List
            							    </Link>
            							  </p>
            							  <p>
            							    <Link onClick={linkClick} to="/int/vidtests">
            							      Testimonials by International Students
            							    </Link>
            							  </p>
            							  <p>
            							    <Link onClick={linkClick} to="/int/visitors">
            							      International Visitors
            							    </Link>
            							  </p>
            							  <p>
            							    <Link onClick={linkClick} to="/LifeIITI">
            							      Campus Life@IITI
            							    </Link>
            							  </p>
            							  <p>
            							    <a href={iaoBrochure} target="_blank" rel="noreferrer">
            							      IR Brochure
            							    </a>
            							  </p>
            							  <p>
            							    <a href={SOP_IR} target="_blank" rel="noreferrer">
            							      IR SOP for Visitors
            							    </a>
            							  </p>
            							</div>
									</li>
									<li className="AcadRes">Academics and Research
										<div className="AcadRes1">
        								    <p>
        								      <a href="http://rnd.iiti.ac.in/" target="_blank" rel="noreferrer">
        								        Research and Development
        								      </a>
        								    </p>
        								    <p>
        								      <a
        								        href="https://academic.iiti.ac.in/"
        								        target="_blank"
        								        rel="noreferrer"
        								      >
        								        Academic Programs
        								      </a>
        								    </p>
        								    <p>
        								      <a
        								        href="https://iiti.ac.in/departments"
        								        target="_blank"
        								        rel="noreferrer"
        								      >
        								        Departments
        								      </a>
        								    </p>
        								    <p>
        								      <a
        								        href="https://iiti.ac.in/page/centers"
        								        target="_blank"
        								        rel="noreferrer"
        								      >
        								        Centers
        								      </a>
        								    </p>
        								  </div>
									</li>
									<li className="Students">Students
										<div className="Students1">
            							  <p>
            							    <Link onClick={linkClick} to="/admission">
            							      Admission
            							    </Link>
            							  </p>
            							  <p>
            							    <a
            							      href="https://academic.iiti.ac.in/academic_program.php"
            							      target="_blank"
            							      rel="noreferrer"
            							    >
            							      Programs
            							    </a>
            							  </p>
            							  <p>
            							    <Link onClick={linkClick} to="/visa">
            							      Visa Process
            							    </Link>
            							  </p>
            							</div>
									</li>
									<li className="Researchers">Researchers
										<div className="Researchers1">
            								<p>
            								  <Link onClick={linkClick} to="/visa">
            								    Visa Process
            								  </Link>
            								</p>
            								<p>
            								  <a href={iaoBrochure} target="_blank" rel="noreferrer">
            								    IR Brochure
            								  </a>
            								</p>
        								  </div>
									</li>
									<li className="Opportunities">Opportunities
										<div className="Opportunities1">
        								    <p>
        								      <a
        								        href="https://www.insaindia.res.in/scroll_news_pdf/ISRF.pdf"
        								        target="_blank"
        								        rel="noreferrer"
        								      >
        								        India Science and Research Fellowship (ISRF) Programme for
        								        researchers
        								      </a>
        								    </p>
        								    <p>
        								      <a
        								        href="https://studyinindia.gov.in/"
        								        target="_blank"
        								        rel="noreferrer"
        								      >
        								        Study In India
        								      </a>
        								    </p>
        								    <p>
        								      <a
        								        href="https://www.iccr.gov.in/"
        								        target="_blank"
        								        rel="noreferrer"
        								      >
        								        ICCR
        								      </a>
        								    </p>
        								    <p>
        								      <a href="https://asean-iit.in/" target="_blank" rel="noreferrer">
        								        ASEAN India STIC
        								      </a>
        								    </p>
        								    <p>
        								      <a
        								        href="https://www.britishcouncil.in/programmes/higher-education/ukieri/ukieri-mobility-programme-study-india"
        								        target="_blank"
        								        rel="noreferrer"
        								      >
        								        UKIERI Mobility Programme
        								      </a>
        								    </p>
        								    <p>
        								      <a
        								        href="https://marie-sklodowska-curie-actions.ec.europa.eu/actions/postdoctoral-fellowships"
        								        target="_blank"
        								        rel="noreferrer"
        								      >
        								        Global Postdoctoral Fellowships for EU citizens
        								      </a>
        								    </p>
        								  </div>
									</li>
									<li className="MHRD">MHRD Schemes
										<div className="MHRD1">
        								    <p>
        								      <Link onClick={linkClick} to="/mhrd/gian">
        								        GIAN
        								      </Link>
        								    </p>
        								    <p>
        								      <Link onClick={linkClick} to="/mhrd/vajra">
        								        VAJRA
        								      </Link>
        								    </p>
        								    <p>
        								      <Link onClick={linkClick} to="/mhrd/sparc">
        								        SPARC
        								      </Link>
        								    </p>
        								    <p>
        								      <Link onClick={linkClick} to="/mhrd/asem">
        								        ASEM - DUO
        								      </Link>
        								    </p>
        								</div>
									</li>
									<li className="STFellowships">Short-Term Fellowships  <MdFiberNew size={25} color={"red"} />
        								<div className="STFellowships1">
        								    <p>
        								      <Link onClick={linkClick} to="/inbound/students/srp-is">
        								        For International PhD Students
        								        <MdFiberNew size={25} color={"red"} />
        								      </Link>
        								    </p>
        								    <p>
        								      <Link onClick={linkClick} to="/inbound/students/srp-ugpg">
        								        For UG and PG Students
        								        <MdFiberNew size={25} color={"red"} />
        								      </Link>
        								    </p>
        								</div>
									</li>
									<li className="OnlinePrograms">Online Programs of IIT Indore <MdFiberNew size={25} color={"red"} />
										<div className="OnlinePrograms1">
        								    <p>
        								      <Link onClick={linkClick} to="/inbound/students/riisshe">
        								        Research Internship for International Students
        								      </Link>
        								    </p>
        								    <p>
        								      <Link onClick={linkClick} to="/inbound/students/gelp">
        								        Global e-Learning Program
        								        <MdFiberNew size={25} color={"red"} />
        								      </Link>
        								    </p>
        								</div>
									</li>
								</ul>
							</div>
						</li>
					</div>
					<div className="mainLink mainLink1">
						<li
							className="mainLink-heading OutboundMenuBar"
							onMouseEnter={() => {
								showMyDiv(3);
							}}
							style={
								hoverVal === 3
									? { color: "#00467f", backgroundColor: "#fff" }
									: val === 3
									? { color: "#fff", backgroundColor: "#0066b9" }
									: {}
							}
						>    
						
							Outbound <FiChevronDown />
							<div style={{color: 'red'}}>
                                <MdFiberNew size={25} />
                            </div>
							<div className="OutboundMenu">
								<ul>
									<li className="ForStudents">For Students
										<div className="ForStudents1">
											<ul>
												<li className="UKScholarships">UK Scholarships
													<div className="UKScholarships1">
														<ul>
															<li>
																<a href="https://www.britishcouncil.in/study-uk/scholarships" target="_blank" rel="noreferrer">
                                        						British Council India
                                    							</a>
															</li>
															<li>
																<a
                                							        href="https://www.ukri.org/our-work/collaborating-internationally/our-international-offices/ukri-india/"
                                							        target="_blank"
                                							        rel="noreferrer"
                                							    >
                                							        UKRI India
                                							    </a>
															</li>
															<li>
																<a href="https://www.charleswallaceindiatrust.com/" target="_blank" rel="noreferrer">
                                    								Charles Wallace India Trust
                                    							</a>
															</li>
															<li>
																<a href="https://www.britishcouncil.in/study-uk/scholarships/commonwealth-scholarships" target="_blank" rel="noreferrer">
                                    							    Commonwealth Scholarships
                                    							</a>
															</li>
															<li>
																<a href="https://study-uk.britishcouncil.org/scholarships/great-scholarships/india" target="_blank" rel="noreferrer">
                                    							    GREAT Scholarships India
                                    							</a>
															</li>
															<li>
																<a href="https://www.britishcouncil.in/programmes/higher-education/newton-fund" target="_blank" rel="noreferrer">
                                    							    Newton Bhabha Fund
                                    							</a>
															</li>
														</ul>
													</div>
												</li>
												<li className="GermanScholarships">German Scholarships
													<div className="GermanScholarships1">
														<ul>
															<li>
																<a href="https://www.humboldt-foundation.de/en/" target="_blank" rel="noreferrer">
                                    							    Alexander von Humboldt Foundation
                                    							</a>
															</li>
															<li>
																<a href="https://www.indianstudentsgermany.org/" target="_blank" rel="noreferrer">
                                               						ISG
                                    							</a>
															</li>
															<li>
																<a href="https://www.daad.in/en/study-research-in-germany/" target="_blank" rel="noreferrer">
                                        							DAAD
                                    							</a>
															</li>
															<li>
																<a href="https://www.igstc.org" target="_blank" rel="noreferrer">
                                     								Indo-German Science & Technology Center
                                    							</a>
															</li>
															<li>
																<a href="https://www.goethe.de/ins/in/en/sta/new.html" target="_blank" rel="noreferrer">
                                        							German Language
                                    							</a>
															</li>
															<li>
																<a href="https://indien.ahk.de/education/indo-german-training-centre" target="_blank" rel="noreferrer">
                                        							Indo-German Training Centre
                                    							</a>
															</li>
														</ul>
													</div>
												</li>
												<li className="USScholarships">US Scholarships
													<div className="USScholarships1">
														<ul>
															<li>
																<a href="https://iusstf.org/visitations-fellowship" target="_blank" rel="noreferrer">
                                    							    IUSSTF Scholarship
                                    							</a>
															</li>
															<li>
																<a href="https://www.usief.org.in/Fellowships-for-Indian-Citizens.aspx" target="_blank" rel="noreferrer">
                                    							    USIEF Scholarship
                                    							</a>
															</li>
														</ul>
													</div>
												</li>
												<li className="EUScholarships">European Union Scholarships
													<div className="EUScholarships1">
														<ul>
															<li>
																<a href="https://erasmus-plus.ec.europa.eu/" target="_blank" rel="noreferrer">
                                      						  		Erasmus Plus
                                    							</a>
															</li>
															<li>
																<a
                                    							    href="https://marie-sklodowska-curie-actions.ec.europa.eu/about-marie-sklodowska-curie-actions"
                                    							    target="_blank"
                                    							    rel="noreferrer"
                                    							>
                                    							    MSCA funding support
                                    							</a>
															</li>
														</ul>
													</div>
												</li>
												<li className="STFellowship">Short-Term Fellowship <MdFiberNew size={25} color={"red"}/>
													<div className="STFellowship1">
														<ul>
															<li>
																<Link onClick={linkClick} to="/outbound/students/srp-iitis">
                                    								For PhD Students of IIT Indore
                                        							<MdFiberNew size={25}color={"red"}  filter = "brightness(500%)"/>
                                    							</Link>
															</li>
															<li>
																<Link onClick={linkClick} to="/outbound/ug_pg_fellowship">
                                    								UG and PG Students' Semester Exchange Program
                                    								<MdFiberNew size={25}color={"red"}  filter = "brightness(500%)"/>
                                    							</Link>
															</li>
														</ul>
													</div>
												</li>
												<li className="OIO">Other Important Opportunities <MdFiberNew size={25} color={"red"}/>
													<div className="OIO1">
														<ul>
															<li>
																<Link onClick={linkClick} to="/opportunities/internships">
                                    							    Opportunities
                                    							    <MdFiberNew size={25}color={"red"}  filter = "brightness(500%)"/>
                                    							</Link>
															</li>
														</ul>
													</div>
												</li>
												<li className="FrenchScholarships">French Scholarships
													<div className="FrenchScholarships1">
														<ul>
															<li>
																<a href="http://www.cefipra.org/Raman_Charpak.aspx" target="_blank" rel="noreferrer">
                                    							   	Raman-Charpak Fellowship
                                    							</a>
															</li>
															<li>
																<a href="http://www.cefipra.org/Cefipra_ESONN.aspx" target="_blank" rel="noreferrer">
                                     								CEFIPRA-ESONN Fellowship
                                    							</a>
															</li>
															<li>
																<a href="http://www.ens-lyon.fr/en/studies/admissions/application-research-internship" target="_blank" rel="noreferrer">
                                        							ENS Internship program
                                    							</a>
															</li>
														</ul>
													</div>
												</li>
												<li className="CanadaScholarships">Canadian Scholarships
													<div className="CanadaScholarships1">
														<ul>
															<li>
																<a href="https://www.shastriinstitute.org/" target="_blank" rel="noreferrer">
                                    								Shastri Indo-Canadian Institute
                                    							</a>
															</li>
															<li>
																<a href="https://indocanadaeducation.org/" target="_blank" rel="noreferrer">
                                      								Indo-Canada Education Council
                                    							</a>
															</li>
															<li>
																<a href="https://www.mitacs.ca/en/programs/globalink/come-to-canada" target="_blank" rel="noreferrer">
                                        							MITACS
                                    							</a>
															</li>
														</ul>
													</div>
												</li>
												<li className="JapanScholarships">Japanese Scholarships
													<div className="JapanScholarships1">
														<ul>
															<li>
																<a href="https://www.jasso.go.jp/en/" target="_blank" rel="noreferrer">
                                    							    JASSO
                                    							</a>
															</li>
															<li>
																<a href="https://www.jsps.go.jp/english/" target="_blank" rel="noreferrer">
                                    							    JSPS
                                    							</a>
															</li>
															<li>
																<a href="https://www.in.emb-japan.go.jp/Education/japanese_government_scholarships.html" target="_blank" rel="noreferrer">
                                    							    MEXT Scholarship
                                    							</a>
															</li>
														</ul>
													</div>
												</li>
												<li className="OtherScholarships">Other Scholarships
													<div className="OtherScholarships1">
														<ul>
															<li>
																<a href="https://aistic.gov.in/ASEAN/HomePage" target="_blank" rel="noreferrer">
                                        						    ASEAN
                                        						</a>
															</li>
															<li>
																<a href="http://www.anso.org.cn/programmes/talent/scholarship/" target="_blank" rel="noreferrer">
                                        						    China
                                        						</a>
															</li>
															<li>
																<a href="https://www.icdf.org.tw/ct.asp?xItem=12505&CtNode=30316&mp=2" target="_blank" rel="noreferrer">
                                        						    Taiwan
                                        						</a>
															</li>
															<li>
																<a href="https://studyindenmark.dk/" target="_blank" rel="noreferrer">
                                        						   Denmark
                                        						</a>
															</li>
															<li>
																<a href="https://www.crg.eu/en/content/training/undergraduates-and-masters" target="_blank" rel="noreferrer">
                                        						    Spain
                                        						</a>
															</li>
															<li>
																<a href="https://www.scholarships.at/default.aspx" target="_blank" rel="noreferrer">
                                        						    Austria
                                        						</a>
															</li>
															<li>
																<a href="https://www.nzscholarships.govt.nz/" target="_blank" rel="noreferrer">
                                        						    New Zealand
                                        						</a>
															</li>
															<li>
																<a href="https://www.ait.ac.th/admissions/scholarships/bangchak-master-scholarships/" target="_blank" rel="noreferrer">
                                        						    Thailand
                                        						</a>
															</li>
														</ul>
													</div>
												</li>
												<li className="SICI">Shastri Indo-Canadian Institute (SICI)
													<div className="SICI1">
														<ul>
															<li>
																<a href="https://www.shastriinstitute.org/SCLSG" target="_blank" rel="noreferrer">
                                    							 	Shastri Conference & Lecture Series Grant (SCLSG)
                                    							</a>
															</li>
															<li>
																<a href="https://www.shastriinstitute.org/SMMIW" target="_blank" rel="noreferrer">
                                    							    Shastri Mapping & Matching Interest Workshop (SMMIW)
                                    							</a>
															</li>
															<li>
																<a href="https://www.shastriinstitute.org/SDKR" target="_blank" rel="noreferrer">
                                    							    Showcasing and Disseminating Knowledge & Research (SDKR)
                                    							</a>
															</li>
														</ul>
													</div>
												</li>
											</ul>
										</div>
									</li>
									<li className="ForReseachers">For Researchers
										<div className="ForReseachers1">
											<ul>
												<li className="USScholarships">US Scholarships
													<div className="USScholarships1">
														<ul>
															<li>
																<a href="https://iusstf.org/visitations-fellowship" target="_blank" rel="noreferrer">
                                    							    IUSSTF
                                    							</a>
															</li>
															<li>
																<a href="https://www.usief.org.in/Fellowships-for-Indian-Citizens.aspx" target="_blank" rel="noreferrer">
                                    							    USIEF
                                    							</a>
															</li>	
														</ul>	
													</div>											
												</li>
												<li className="IndGer">Indo - Germany
													<div className="IndGer1">
														<ul>
															<li>
																<a href="https://www.humboldt-foundation.de/en/" target="_blank" rel="noreferrer">
                                    							    Alexander von Humboldt Foundation
                                    							</a>	
															</li>	
															<li>
																<a href="https://www.daad.in/en/study-research-in-germany/" target="_blank" rel="noreferrer">
                                    							    DAAD
                                    							</a>	
															</li>	
															<li>
																<a href="https://www.igstc.org" target="_blank" rel="noreferrer">
                                    							    Indo-German Science & Technology Center
                                    							</a>	
															</li>	
														</ul>	
													</div>				
												</li>
												<li className="Others">Others
													<div className="Others1">
														<ul>
															<li>
																<a href="http://www.cefipra.org/" target="_blank" rel="noreferrer">
                                    							    Indo - French
                                    							</a>	
															</li>	
															<li>
																<a href="https://www.jsps.go.jp/english/" target="_blank" rel="noreferrer">
                                    							    Indo - Japan
                                    							</a>
															</li>	
															<li>
																<a
                                    							    href="https://www.ukri.org/our-work/collaborating-internationally/our-international-offices/ukri-india/"
                                    							    target="_blank"
                                    							    rel="noreferrer"
                                    							>
                                    							    Indo - UK
                                    							</a>
															</li>	
															<li>
																<a href="https://aistic.gov.in/ASEAN/HomePage" target="_blank" rel="noreferrer">
                                    							    Indo - ASEAN
                                    							</a>
															</li>	
															<li>
																<a href="https://www.shastriinstitute.org/" target="_blank" rel="noreferrer">
                                        							Indo - Canada
                                    							</a>
															</li>	
														</ul>	
													</div>				
												</li>
											</ul>
										</div>
									</li>
									<li className="ForFaculty">For Faculty
										<div className="ForFaculty1">
											<ul>
												<li className="InstituteGrants">Institute Grants <MdFiberNew size={25}color={"red"}  filter = "brightness(500%)"/>
													<div className="InstituteGrants1">
														<ul>
															<li>
																<Link onClick={linkClick} to="/outbound/Faculty/mobility_Grant">
                                    							    Mobility Grant <br/> for international MOU coordinator
                                    							    <MdFiberNew size={25}color={"red"}  filter = "brightness(500%)"/>
                                    							</Link>
															</li>
															<li>
																<Link onClick={linkClick} to="/outbound/Faculty/internationalgrant">
                                    							       International Travel Grant
                                    							    <MdFiberNew size={25}color={"red"}  filter = "brightness(500%)"/>
                                    							</Link>
															</li>
														</ul>
													</div>
												</li>
											</ul>
										</div>
									</li>
								</ul>
							</div>
						</li>
					</div>
					<div className="mainLink mainLink1">
						<li
							className="mainLink-heading CnEMenuBar"
							onMouseEnter={() => {
								showMyDiv(4);
							}}
							style={
								hoverVal === 4
									? { color: "#00467f", backgroundColor: "#fff" }
									: val === 4
									? { color: "#fff", backgroundColor: "#0066b9" }
									: {}
							}
						>
							Cooperation & Engagements <FiChevronDown />
							<div className="CnEMenu">
								<ul>
									<li className="MoUs">MoUs
										<div className="MoUs1">
											<p>
												<Link onClick={linkClick} to="/mou/asia">
													Asian Universities
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/mou/australia">
													Australian Universities
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/mou/america">
													American Universities
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/mou/africa">
													African Universities
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/mou/europe">
													European Universities
												</Link>
											</p>
										</div>
									</li>
									<li className="FacVisits">Faculty Visits
										<div className="FacVisits1">
											<p>
												<Link onClick={linkClick} to="/mobility/faculty/africa">
													Africa
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/mobility/faculty/america">
													America
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/mobility/faculty/asia">
													Asia
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/mobility/faculty/australia">
													Australia
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/mobility/faculty/europe">
													Europe
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/mobility/faculty/others">
													Other Countries
												</Link>
											</p>
										</div>
									</li>
									<li className="StuVisits">Student Visits
										<div className="StuVisits1">
											<p>
												<Link onClick={linkClick} to="/mobility/student/america">
													America
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/mobility/student/europe">
													Europe
												</Link>
											</p>
										</div>
									</li>
									<li className="IntGrants">International Grants
										<div className="IntGrants1">
											<p>
												<Link onClick={linkClick} to="/grants">
													Bilateral International Grants
												</Link>
											</p>
										</div>
									</li>
									<li className="MHRD">MHRD Schemes
										<div className="MHRD1">
											<p>
												<Link onClick={linkClick} to="/mhrd/gian">
													GIAN
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/mhrd/vajra">
													VAJRA
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/mhrd/sparc">
													SPARC
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/mhrd/asem">
													ASEM - DUO
												</Link>
											</p>
										</div>
									</li>
									<li className="Publications">Publications
										<div className="Publications1">
											<p>
												<Link onClick={linkClick} to="/publications">
													Publications
												</Link>
											</p>
										</div>
									</li>
								</ul>
							</div>
						</li>
					</div>
					<div className="mainLink mainLink1">
						<li
							className="mainLink-heading OutreachMenuBar"
							onMouseEnter={() => {
								showMyDiv(5);
							}}
							style={
								hoverVal === 5
									? { color: "#00467f", backgroundColor: "#fff" }
									: val === 5
									? { color: "#fff", backgroundColor: "#0066b9" }
									: {}
							}
						>
							Outreach <FiChevronDown />
							<div className="OutreachMenu">
								<ul>
									<li className="LectureSeries">Lecture Series
										<div className="LectureSeries1">
											<p>
												<Link onClick={linkClick} to="/outreach/events/bruce-berndt">
													Hardy Ramanujan Lecture Series
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/outreach/events/automotive">
													Automotive Light-weighting Technologies
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/outreach/events/prosperity">
													Prosperity Through Science and Technology
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/outreach/events/paresh-date">
													Forecasting Crude Oil Futures Prices Using the Kalman Filter and News
												</Link>
											</p>
										</div>
									</li>
									<li className="PublicLectures">Public Lectures
										<div className="PublicLectures1">
											<p>
												<Link onClick={linkClick} to="/outreach/roleOfScience">
													Role of Science in Nation Building during Global Health Crisis
												</Link>
											</p>
											<p>
												<Link to="/outreach/events/nobelPrizes-2021" onClick={linkClick}>
													Public Outreach Lectures on Nobel Prizes - 2021
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/outreach/nobel">
													Nobel Laureate Lectures
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/outreach/events/frontiers">
													Frontiers in Physics
												</Link>
											</p>
											<p>
												<Link to="/outreach/events/nobelPrizes" onClick={linkClick}>
													Nobel Prizes-2020
												</Link>
											</p>
											<p>
												<Link onClick={linkClick} to="/outreach/chemtalks">
													Acharya Prafulla Chandra Ray Chemistry Lecture Series
												</Link>
											</p>
										</div>
									</li>
									<li className="EveAcad">Events & Activities
										<div className="EveAcad1">
											<div>
												<Link onClick={linkClick} to="/outreach/events/budget2022">
													Budget 2022: A Panel Discussion
												</Link>
											</div>
											<div>
												<Link onClick={linkClick} to="/outreach/nsd">
													National Science Day
												</Link>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</li>
					</div>
				</ul>
				{showDiv && currentVal !== 0 && (
					<div className="navDiv">
						
						
						
						
						
					</div>
				)}
			</div>
		</div>
	);
};
